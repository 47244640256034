import { ProspectRegistration, ProspectRegistrationResult } from '@/domain/usecases'
import { RemoteProspectRegistration } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'

export const makeProspectRegistration = (): ProspectRegistration => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<ProspectRegistrationResult | string>()

  return new RemoteProspectRegistration(url, httpClient)
}
