import React, { useCallback } from 'react'
import { GestureResponderEvent, SafeAreaView } from 'react-native'
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil'
import { ScrollView } from 'react-native-gesture-handler'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { NavigationRoute } from '@/main/config/router-navigation'
import { AddTravelAlertOs, AddTravelAlertOsParams, AddTravelAlertResponsibleType } from '@/domain/usecases'
import { DateAdapter } from '@/data/protocols'
import { useAuth, useErrorHandler, useMediaQueries, useNotify, useTheme, useThemeClient } from '@/presentation/hooks'
import { TextInput, Box, Button, HStack, PageHeader, TAB_BOTTOM_CONTAINER_HEIGTH, Card, CardSwitch, TimePicker, NavigationPagesProps, ReactNativePaperTimePros, InputField, VStack, Loading } from '@/presentation/components'
import { ModalAddResponsible, ModalAddVehicle, ResponsiblesPanel, VehiclePanel, travelAlertTimePickerState, travelAlertState, SelectDateTime, isOpenTravelAlertAddVehicle, isLoadingState, travelAlertAuthorizedsState, travelAlertResponsiblesKeysState, travelAlertVehiclesState, travelAlertAddResponsibleState, addResponsibleState } from '@/presentation/pages/TravelAlert/components'
import { styles } from './styles'

export type TravelAlertParams = {
  dataSaida: string
  horaSaida: string
  dataRetorno: string
  horaRetorno: string
  solicitante: string
  email: string
  telefone1: string
  telefone2: string
  recomendacoes: string
  chavesSomenteComCentral: boolean
  possuiAutorizados: boolean
  possuiVeiculosCuidadosCentral: boolean
}

type TravelAlertProps = NavigationPagesProps & {
  addTravelAlertOs: AddTravelAlertOs
  dateAdapter: DateAdapter
}

export const TravelAlert: React.FC<TravelAlertProps> = ({ navigation, dateAdapter, addTravelAlertOs }) => {
  const theme = useTheme()
  const notify = useNotify()
  const { getClient } = useAuth()
  const { isMobile } = useMediaQueries()
  const { getThemeClient } = useThemeClient()
  const [modalTimePicker, setModalTimePicker] = useRecoilState(travelAlertTimePickerState)
  const [isLoading, setLoading] = useRecoilState(isLoadingState)
  const [travelAlert, setTravelAlert] = useRecoilState(travelAlertState)
  const [authorizeds, setAuthorizeds] = useRecoilState(travelAlertAuthorizedsState)
  const [responsiblesKeys, setResponsiblesKeys] = useRecoilState(travelAlertResponsiblesKeysState)
  const [vehicles, setVehicles] = useRecoilState(travelAlertVehiclesState)
  const setOpenAddResponsible = useSetRecoilState(travelAlertAddResponsibleState)
  const setOpenAddVehicle = useSetRecoilState(isOpenTravelAlertAddVehicle)
  const reset = useResetRecoilState(travelAlertState)
  const resetAuthorizeds = useResetRecoilState(travelAlertAuthorizedsState)
  const resetResponsibleKeys = useResetRecoilState(travelAlertResponsiblesKeysState)
  const resetVehicles = useResetRecoilState(travelAlertVehiclesState)
  const resetAddResponsible = useResetRecoilState(addResponsibleState)
  const handleError = useErrorHandler()

  const client = getClient()
  const themeClient = getThemeClient()

  const handleAddTravelAlertOs = (event: GestureResponderEvent): void => {
    event.preventDefault()

    if (isValidDataTravelAlertOs()) {
      setLoading(true)

      const addTravelOsParams: AddTravelAlertOsParams = {
        ...travelAlert,
        cliente: client.codCliente,
        empresa: client.empresa,
        modalidade: client.modalidade,
        responsaveisChave: responsiblesKeys,
        autorizados: authorizeds,
        veiculos: vehicles
      }

      addTravelAlertOs
        .add(addTravelOsParams)
        .then(ordem => {
          navigation.navigate(NavigationRoute.ServiceOrders)
          notify.show(`Aviso de viagem registrado com sucesso! Criada OS ${ordem?.toString().padStart(6, '0')}`)
          handleReset()
        })
        .catch(handleError)
        .finally(() => setLoading(false))
    } else {
      handleError(new Error('Todos os dados os campos obrigatórios(*) devem ser preenchidos'))
    }
  }

  const isValidDataTravelAlertOs = (): boolean => {
    if (
      !travelAlert.email ||
      !travelAlert.telefone1 ||
      !travelAlert.dataRetorno ||
      !travelAlert.horaRetorno ||
      !travelAlert.dataSaida ||
      !travelAlert.horaSaida ||
      !travelAlert.recomendacoes
    ) {
      return false
    } else {
      return true
    }
  }

  const handleChange = (name: keyof TravelAlertParams, value: string | boolean): void => {
    setTravelAlert(currentState => ({ ...currentState, [name]: value }))
  }

  const handleChangeTime = useCallback((field: keyof TravelAlertParams, time: ReactNativePaperTimePros): void => {
    const hours = time.hours.toString().padStart(2, '0')
    const minutes = time.minutes.toString().padStart(2, '0')

    setTravelAlert(currentFilters => ({
      ...currentFilters,
      [field]: `${hours}:${minutes}`
    }))
  }, [modalTimePicker.currentTime])

  const handleClose = useCallback((): void => {
    setModalTimePicker(currentState => ({ ...currentState, isOpen: false }))
  }, [])

  const handleReset = (): void => {
    reset()
    resetAuthorizeds()
    resetResponsibleKeys()
    resetVehicles()
    resetAddResponsible()
  }

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={{ flexGrow: 0 }}>
        {isLoading
          ? <Loading text='Registrando aviso de viagem' />
          : <Box style={styles.content}>
            <PageHeader
              text='Aviso de Viagem'
              icon={<MaterialCommunityIcons size={24} color={theme.colors.grey} name='airplane-alert' />}
            />
            <Card style={styles.formArea}>
              <InputField>
                <TextInput
                  color={themeClient.mainColor}
                  editable={!isLoading}
                  value={travelAlert?.solicitante}
                  label="Solicitante"
                  testID='travel-alert-solicitante'
                  onChangeText={(text) => handleChange('solicitante', text)}
                  style={styles.input}
                />
                <TextInput
                  color={themeClient.mainColor}
                  editable={!isLoading}
                  value={travelAlert?.email}
                  keyboardType='email-address'
                  label="E-mail*"
                  testID='travel-alert-email'
                  style={styles.input}
                  onChangeText={(text) => handleChange('email', text)}
                />
              </InputField>

              <InputField>
                <TextInput
                  color={themeClient.mainColor}
                  editable={!isLoading}
                  value={travelAlert?.telefone1}
                  keyboardType='phone-pad'
                  testID='travel-alert-phone'
                  label="Telefone 1*"
                  style={styles.input}
                  onChangeText={(text) => handleChange('telefone1', text)}
                />
                <TextInput
                  color={themeClient.mainColor}
                  editable={!isLoading}
                  value={travelAlert?.telefone2}
                  keyboardType='phone-pad'
                  testID='travel-alert-phone-pad'
                  label="Telefone 2"
                  style={styles.input}
                  onChangeText={(text) => handleChange('telefone2', text)}
                />
              </InputField>

              <SelectDateTime dateAdapter={dateAdapter} />

              <InputField>
                <TextInput
                  color={themeClient.mainColor}
                  editable={!isLoading}
                  value={travelAlert?.recomendacoes}
                  label="Recomendações*"
                  testID='travel-alert-recomendacoes'
                  style={styles.input}
                  onChangeText={(text) => handleChange('recomendacoes', text)}
                  multiline
                  numberOfLines={4}
                />
              </InputField>

            </Card>

            <VStack mt={12}>
              <CardSwitch
                text='Somente central de monitoramento ficará com as chaves da residência?'
                value={travelAlert.chavesSomenteComCentral}
                showIfValueFalsy={true}
                handleSwitch={() => handleChange('chavesSomenteComCentral', !travelAlert.chavesSomenteComCentral)}
              >
                <ResponsiblesPanel
                  responsibles={responsiblesKeys}
                  onAddNew={() => {
                    setOpenAddResponsible({
                      isOpen: true,
                      typeCurrent: AddTravelAlertResponsibleType.KEYS
                    })
                  }}
                  onRemove={(idResp) => {
                    setResponsiblesKeys(responsiblesKeys.filter(resp => resp.id !== idResp))
                  }}
                />
              </CardSwitch>

              <CardSwitch
                text='Alguém está autorizado a entrar em sua residência?'
                value={travelAlert.possuiAutorizados}
                handleSwitch={() => handleChange('possuiAutorizados', !travelAlert.possuiAutorizados)}
              >
                <ResponsiblesPanel
                  responsibles={authorizeds}
                  onAddNew={() => {
                    setOpenAddResponsible({
                      isOpen: true,
                      typeCurrent: AddTravelAlertResponsibleType.AUTHORIZED
                    })
                  }}
                  onRemove={(idResp) => {
                    setAuthorizeds(authorizeds.filter(resp => resp.id !== idResp))
                  }}
                />
              </CardSwitch>

              <CardSwitch
                text='Ficará veículo na residência sob cuidados da empresa?'
                value={travelAlert.possuiVeiculosCuidadosCentral}
                handleSwitch={() => handleChange('possuiVeiculosCuidadosCentral', !travelAlert.possuiVeiculosCuidadosCentral)}
              >
                <VehiclePanel
                  openAddNew={() => setOpenAddVehicle(true)}
                  onRemove={(idVehicle) => {
                    setVehicles(vehicles => vehicles.filter(vehicle => vehicle.id !== idVehicle))
                  }}
                />
              </CardSwitch>
            </VStack>
          </Box>
        }
      </ScrollView>

      <HStack
        items='center'
        justify='end'
        pt={24}
        pr={16}
        mb={isMobile ? (TAB_BOTTOM_CONTAINER_HEIGTH + 12) : 12}
        spacing={8}
      >
        <Button
          title='LIMPAR'
          variant='text'
          color={theme.colors.greyLighten}
          onPress={handleReset}
          disabled={isLoading}
        />
        <Button
          title='SALVAR'
          variant='outlined'
          color={themeClient.mainColor}
          onPress={handleAddTravelAlertOs}
          disabled={isLoading || !isValidDataTravelAlertOs()}
        />
      </HStack>

      <ModalAddResponsible />
      <ModalAddVehicle />
      <TimePicker
        label='Selecione a hora'
        onConfirm={(time) => {
          handleChangeTime(modalTimePicker.currentTime, time)
          handleClose()
        }}
        onDimiss={handleClose}
        time={{ hours: 12, minutes: 58 }}
        visible={modalTimePicker.isOpen}
      />
    </SafeAreaView>
  )
}
