import React, { useCallback } from 'react'
import { SafeAreaView } from 'react-native'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { ScrollView } from 'react-native-gesture-handler'
import { useFocusEffect } from '@react-navigation/native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { GetDigitalAccepts, OpenDocumentAccept, OpenUrlDocumentAccept } from '@/domain/usecases'
import { DateAdapter } from '@/data/protocols'
import { useAuth, useErrorHandler, useTheme } from '@/presentation/hooks'
import { Box, CardList, PageHeader } from '@/presentation/components'
import { SideFilters, acceptDigitalState, CardAcceptDigital, FiltersChipPanel, isOpenAcceptDigitalFiltersState, isLoadingAcceptDigitalState } from '@/presentation/pages/AceiteDigital/components'
import { styles } from './styles'

type AcceptDigitalProps = {
  dateAdapter: DateAdapter
  getAcceptDigital: GetDigitalAccepts
  getDocAcceptDigital: OpenUrlDocumentAccept
  openDocumentAccept: OpenDocumentAccept
}

export const AcceptDigital: React.FC<AcceptDigitalProps> = ({
  dateAdapter,
  getAcceptDigital,
  openDocumentAccept,
  getDocAcceptDigital
}: AcceptDigitalProps) => {
  const theme = useTheme()
  const { getClient } = useAuth()
  const handleError = useErrorHandler()
  const [accepts, setAccepts] = useRecoilState(acceptDigitalState)
  const [isLoading, setLoading] = useRecoilState(isLoadingAcceptDigitalState)
  const setOpen = useSetRecoilState(isOpenAcceptDigitalFiltersState)

  const client = getClient()

  useFocusEffect(
    useCallback(() => {
      setLoading(true)

      const paramsGetAcceptDigital = {
        codCliente: client.codCliente,
        dtInicial: dateAdapter.format(dateAdapter.daysAgo(GetDigitalAccepts.RN.DEFAULT_DAYS_AGO), 'YYYY-MM-DD'),
        dtFinal: dateAdapter.format(dateAdapter.daysLater(GetDigitalAccepts.RN.DEFAULT_DAYS_LATER), 'YYYY-MM-DD'),
        status: GetDigitalAccepts.RN.DEFAULT_STATUS
      }

      getAcceptDigital
        .get(paramsGetAcceptDigital)
        .then(setAccepts)
        .catch(handleError)
        .finally(() => setLoading(false))
    }, []))

  const handleOnFilter = useCallback((): void => {
    setOpen(true)
  }, [])

  return (
    <SafeAreaView style={styles.container}>
      <SideFilters dateAdapter={dateAdapter} getAcceptDigital={getAcceptDigital} />
      <ScrollView style={styles.scroll}>
        <Box style={styles.content}>
          <PageHeader
            icon={<MaterialCommunityIcons size={24} color={theme.colors.grey} name='file-document-edit-outline' />}
            text='Aceite Digital'
            openFilters={handleOnFilter}
          />
          <FiltersChipPanel dateAdapter={dateAdapter} />
          <CardList
            isLoading={isLoading}
            isEmpty={!accepts.length}
            textLoading='Buscando Aceite Digital'
          >
            {accepts?.map(accept => (
              <CardAcceptDigital
                key={accept.idAceite}
                accept={accept}
                dateAdapter={dateAdapter}
                openUrlDocAccept={getDocAcceptDigital}
                openDocumentAccept={openDocumentAccept}
              />
            ))}
          </CardList>
        </Box>
      </ScrollView>
    </SafeAreaView>
  )
}
