import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    display: 'flex',
    gap: 18
  },
  userTitle: {
    fontWeight: '500',
    textAlign: 'center'
  },
  itemMenu: {
    fontSize: 14
  }
})
