import React, { useCallback } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { GestureResponderEvent } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { NFModel } from '@/domain/models'
import { OpenLeaseReceipt, OpenLeaseReceiptParams } from '@/domain/usecases'
import { DateAdapter } from '@/data/protocols'
import { MenuActions, Menu, Box, IconButton, Text, PageHeader, CardList, CardBase } from '@/presentation/components'
import { FiltersChipPanel, isLoadingNfState, isOpenNfsFiltersState, leaseReceiptState } from '@/presentation/pages/NotaFiscal/components'
import { useErrorHandler, useFormat, useNotify, useTheme } from '@/presentation/hooks'
import { styles } from '../../styles'

type LeaseReceiptProps = {
  openLeaseReceipt: OpenLeaseReceipt
  dateAdapter: DateAdapter
}

export const LeaseReceipt: React.FC<LeaseReceiptProps> = ({ openLeaseReceipt, dateAdapter }) => {
  const theme = useTheme()
  const notify = useNotify()
  const { formatCurrency } = useFormat()
  const handleError = useErrorHandler()
  const leaseReceipts = useRecoilValue(leaseReceiptState)
  const [isLoading, setLoading] = useRecoilState(isLoadingNfState)
  const [currentIndex, setCurrentIndex] = React.useState(-1)
  const setOpenFilters = useSetRecoilState(isOpenNfsFiltersState)

  const openMenu = (index: number): void => setCurrentIndex(index)
  const closeMenu = (): void => setCurrentIndex(-1)

  const handleOpenLeaseReceipt = useCallback((event: GestureResponderEvent, recibo: NFModel) => {
    event.preventDefault()
    setLoading(true)

    const openLeaseReceiptParams: OpenLeaseReceiptParams = {
      codigoRecibo: recibo.nfNumeroNota
    }

    openLeaseReceipt
      .open(openLeaseReceiptParams)
      .then(() => notify.show(`O recibo de locação Nº ${recibo.notaNumero} foi aberto em uma nova aba`))
      .catch(handleError)
      .finally(() => setLoading(false))
  }, [])

  const handleOnFilter = useCallback((): void => {
    setOpenFilters(true)
  }, [])

  return (
    <ScrollView style={styles.scroll}>
      <Box style={styles.content}>
        <PageHeader
          icon={<MaterialCommunityIcons size={24} color={theme.colors.grey} name='file-document-outline' />}
          text='Recibos de Locação'
          openFilters={handleOnFilter}
        />
        <FiltersChipPanel dateAdapter={dateAdapter} />
        <CardList
          isEmpty={!leaseReceipts?.length}
          isLoading={isLoading}
          textLoading='Buscando Recibos de Locação'
        >
          {leaseReceipts?.map((recibo, index) => (
            <CardBase key={recibo.notaNumero}>
              <Box style={styles.title}>
                <Text style={styles.bold}>{recibo.notaNumero.toString().padStart(6, '')}/{recibo.notaSerie}</Text>
                <MenuActions
                  key={`menu-${recibo.notaNumero}`}
                  visible={currentIndex >= 0 && currentIndex === index}
                  onDismiss={closeMenu}
                  anchor={<IconButton
                    onPress={() => openMenu(index)}
                    testID='notas-recibo-locacao-opcoes'
                    style={{ width: 24, height: 24 }}
                    icon={(props: any) => <MaterialCommunityIcons name="dots-vertical" {...props} />}></IconButton>
                  }
                >
                  <Menu.Item
                    onPress={(event) => handleOpenLeaseReceipt(event, recibo)}
                    title="Visualizar Recibo de Locação"
                    testID='notas-recibo-locacao-visualizar-recibo'
                    leadingIcon={props => <MaterialCommunityIcons name="link" {...props} />}
                  />
                </MenuActions>
              </Box>
              <Text><span style={styles.bold}>Valor: </span>{formatCurrency(recibo.notaValor)}</Text>
              <Text><span style={styles.bold}>Emissão: </span>{new Date(recibo.notaData).toLocaleDateString('pt-BR')}</Text>
            </CardBase>
          ))}
        </CardList>
      </Box>
    </ScrollView>
  )
}
