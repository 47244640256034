import { initializeApp } from 'firebase/app'
import { getMessaging } from 'firebase/messaging'
import { makeEnvironmentDecoderAdapterFactory } from '@/main/adapters'
import { isSupported } from '@firebase/messaging'
import env from '@/main/config/env'

const environmentDecoder = makeEnvironmentDecoderAdapterFactory()

const firebaseConfig = {
  apiKey: env.FIREBASE_API_KEY,
  authDomain: env.FIREBASE_AUTH_DOMAIN,
  projectId: env.FIREBASE_PROJECT_ID,
  storageBucket: env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: env.FIREBASE_MESSAGING_SENDER_ID,
  appId: env.FIREBASE_APP_ID
}

const treatedFirebaseConfig = environmentDecoder.objectDecode(firebaseConfig)

export const messaging = async () => {
  try {
    const isSupportedBrowser = await isSupported()
    if (isSupportedBrowser) {
      const firebaseApp = initializeApp(treatedFirebaseConfig)
      return getMessaging(firebaseApp)
    }
    return null
  } catch (err) {
    console.error(err)
    return null
  }
}
