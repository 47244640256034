import { HttpClient, HttpRequest, HttpStatusCode } from '@/data/protocols'
import { AccessDeniedError, ForbiddenError, UnexpectedError, UnexpectedResultFormatError } from '@/domain/errors'
import { CreateSac, CreateSacParams } from '@/domain/usecases'

export class RemoteCreateSac implements CreateSac {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<number | string>
  ) {}

  async create (sacParams: CreateSacParams): Promise<number> {
    const params = {
      isPortal: true
    }

    const request: HttpRequest = {
      url: `${this.url}/api/portal/gravar-sac`,
      method: 'post',
      params,
      body: sacParams
    }

    const { statusCode, body } = await this.httpClient.request(request)

    switch (statusCode) {
      case HttpStatusCode.ok:
        if (!body) {
          throw new UnexpectedResultFormatError()
        }
        return body as number
      case HttpStatusCode.forbidden: throw new ForbiddenError(body as string)
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}
