import { HttpClient, HttpStatusCode } from '@/data/protocols/http'
import { AccessDeniedError, ForbiddenError, UnexpectedError } from '@/domain/errors'
import { DeleteNotification, DeleteNotificationParams } from '@/domain/usecases'

export class RemoteDeleteNotification implements DeleteNotification {
  constructor (
    private readonly url: string,
    private readonly httpGetClient: HttpClient<string>
  ) {}

  async delete (params: DeleteNotificationParams): Promise<void> {
    const { body, statusCode } = await this.httpGetClient.request({
      url: `${this.url}/api/portal/deletar-notificacao-cliente`,
      method: 'delete',
      body: params
    })

    switch (statusCode) {
      case HttpStatusCode.ok:
        break
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      case HttpStatusCode.forbidden: throw new ForbiddenError(body)
      default: throw new UnexpectedError()
    }
  }
}
