import { atom } from 'recoil'
import { TicketModel } from '@/domain/models'

export const isOpenTicketPixState = atom({
  key: 'isOpenTicketPixState',
  default: false
})

export const isLoadingTicketPixState = atom({
  key: 'isLoadingTicketPixState',
  default: false
})

export const selectedTicketPixState = atom({
  key: 'selectedTicketPixState',
  default: null as unknown as TicketModel
})
