import { atom } from 'recoil'

export const statusOSsState = atom({
  key: 'selectStatusOSsState',
  default: [
    { _id: 'Todas', value: 'Todas' },
    { _id: 'Fechadas', value: 'Fechadas' },
    { _id: 'Em Aberto', value: 'Em Aberto' }
  ]
})
