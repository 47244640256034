import React from 'react'
import { ChipFilter, HStack } from '@/presentation/components'
import { DateAdapter } from '@/data/protocols'
import { isOpenNfsFiltersState, nfsFiltersState } from '@/presentation/pages/NotaFiscal/components'
import { useRecoilValue, useSetRecoilState } from 'recoil'

type FiltersChipPanelProps = {
  dateAdapter: DateAdapter
}

export const FiltersChipPanel: React.FC<FiltersChipPanelProps> = ({ dateAdapter }) => {
  const setOpen = useSetRecoilState(isOpenNfsFiltersState)
  const filters = useRecoilValue(nfsFiltersState)

  const period = dateAdapter.format(filters.dataInicio, 'DD/MM/YYYY') +
    ' até ' +
    dateAdapter.format(filters.dataFim, 'DD/MM/YYYY')

  return (
    <HStack spacing={6} mb={18} wrap>
      <ChipFilter
        title='Data de Emissão'
        subtitle={period}
        onPress={() => setOpen(true)}
      />
    </HStack>
  )
}
