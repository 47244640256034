import { GetRedesSociais } from '@/domain/usecases'
import { makeAxiosHttpClient } from '../../http'
import { RedesSociaisModel } from '@/domain/models'
import { RemoteGetRedesSociais } from '@/data/usecases'
import env from '@/main/config/env'

export const makeGetRedesSociais = (): GetRedesSociais => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<RedesSociaisModel>()

  return new RemoteGetRedesSociais(url, httpClient)
}
