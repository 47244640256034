import React, { useState, useCallback, useEffect } from 'react'
import { SafeAreaView } from 'react-native'
import { useRecoilState } from 'recoil'
import { useFocusEffect } from '@react-navigation/native'
import { ScrollView } from 'react-native-gesture-handler'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { NotFoundError } from '@/domain/errors'
import { GetTheme, GetThemeParams, GetUnits, GetVersions, SaveTheme, UploadFile } from '@/domain/usecases'
import { useErrorHandler, useImage, useMediaQueries, useNotify, useTheme, useThemeClient } from '@/presentation/hooks'
import { Box, HStack, VStack, Text, ServerUrlSelector, Loading, Chip, unitsState, currentUnitState } from '@/presentation/components'
import { ActionsButtons, ColorPicker, LoginBackgroundSelector, LogoSelector, ModalOnDiscardChanges, ModalOnSaveChanges, errorThemeState, isLoadingState } from '@/presentation/pages/Theme/components'
import env from '@/main/config/env'
import { styles } from './styles'
import { version as portalVersion } from '../../../../package.json'

export type ThemeProps = {
  getTheme: GetTheme
  getUnits: GetUnits
  saveTheme: SaveTheme
  uploadFile: UploadFile
  getVersions: GetVersions
}

export const Theme: React.FC<ThemeProps> = ({
  getTheme,
  saveTheme,
  getUnits,
  uploadFile,
  getVersions
}) => {
  const theme = useTheme()
  const notify = useNotify()
  const { isMobile } = useMediaQueries()
  const { getImage } = useImage()
  const { hasChanged, setThemeClient, getThemeClient } = useThemeClient()
  const [logoEmpresa, setLogoEmpresa] = useState('')
  const [backgroundEmpresa, setBackgroundEmpresa] = useState('')
  const [isLoading, setLoading] = useRecoilState(isLoadingState)
  const [error, setError] = useRecoilState(errorThemeState)
  const [currentUnit, setCurrentUnit] = useRecoilState(currentUnitState)
  const [units, setUnits] = useRecoilState(unitsState)
  const handleError = useErrorHandler(error => {
    if (error instanceof NotFoundError) {
      setError('O endereço do IntegraService da unidade não está respondendo, verifique se foi configurado corretamente e tente novamente')
    }
  })

  const themeClient = getThemeClient()
  const hasUnitServerUrl = units.some(unit => unit.enderecoExterno)
  const isIpHost = /^(?:\d{1,3}\.){3}\d{1,3}$/.test(window.location.hostname)
  const isHttp = window.location.protocol === 'http:'
  const enderecoIntegra = (
    isHttp &&
    isIpHost &&
    currentUnit?.enderecoExterno !== window.location.origin &&
    !!currentUnit?.enderecoInterno
  )
    ? currentUnit.enderecoInterno
    : currentUnit?.enderecoExterno

  const validateOutdatedApps = (): void => {
    getVersions
      .get({ enderecoExternoIntegra: enderecoIntegra })
      .then(versions => {
        let needUpdate = false

        const currentAppVersionNumber = +portalVersion.replaceAll('.', '')
        const lastAppVersionNumber = +versions.versaoAtualPortal.replaceAll('.', '')
        if ((lastAppVersionNumber && currentAppVersionNumber) && (lastAppVersionNumber > currentAppVersionNumber)) {
          needUpdate = true
        }

        const currentIntegraVersionNumber = +versions.versaoLocalIntegra.replaceAll('.', '')
        const lastIntegraVersionNumber = +versions.versaoAtualIntegra.replaceAll('.', '')
        if ((lastIntegraVersionNumber && currentIntegraVersionNumber) && lastIntegraVersionNumber > currentIntegraVersionNumber) {
          needUpdate = true
        }

        needUpdate && notify.warning('Versão desatualizada. Por favor, entre em contato com o suporte e atualize para a versão mais recente.')
      })
      .catch(console.error)
  }

  useEffect(() => {
    if (!currentUnit) {
      setLoading(true)

      getUnits
        .getAll()
        .then(async units => {
          if (units.length) {
            setCurrentUnit(units[0])
            setUnits(units)
          }
        })
        .catch(handleError)
        .finally(() => setLoading(false))
    }
  }, [])

  useFocusEffect(
    useCallback(() => {
      if (currentUnit) {
        const getThemeParams: GetThemeParams = {
          codigoUnidade: currentUnit.codigoUnidade,
          enderecoExternoIntegra: __DEV__ ? env.API_URL : enderecoIntegra
        }

        setError('')
        setLoading(true)
        getTheme
          .get(getThemeParams)
          .then(theme => {
            if (theme.codigoUnidade === currentUnit.codigoUnidade) {
              setThemeClient(theme)
              if (enderecoIntegra) {
                void getImage(theme.imageLogo, 'logo', enderecoIntegra)
                  .then(logo => { setLogoEmpresa(logo) })
                  .catch(console.error)
                void getImage(theme.bgLogin, 'login', enderecoIntegra)
                  .then(background => { setBackgroundEmpresa(background) })
                  .catch(console.error)
              } else {
                void getImage(theme.imageLogo, 'logo')
                  .then(logo => { setLogoEmpresa(logo) })
                  .catch(console.error)
                void getImage(theme.bgLogin, 'login')
                  .then(background => { setBackgroundEmpresa(background) })
                  .catch(console.error)
              }
              validateOutdatedApps()
            }
          })
          .catch(handleError)
          .finally(() => { setLoading(false) })
      }
    }, [currentUnit])
  )

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={styles.scroll}>
        <HStack justify='center' items='center' style={{
          backgroundColor: `${themeClient.mainColor}CC`
        }}>
          <MaterialCommunityIcons size={24} color={theme.colors.white} name='brush-variant' />
          <Text variant="subtitle1" style={{ ...styles.headerText, color: theme.colors.white }} >
            Tema{isMobile ? '' : ' do Endereço'}
          </Text>

          {(!hasUnitServerUrl || !units.length)
            ? !isLoading && <Chip color={theme.colors.error} text='Requer configuração de Endereço Externo da Unidade' />
            : <ServerUrlSelector disabled={isLoading} />
          }
        </HStack>
        {error
          ? <Box style={{ ...styles.content, alignItems: 'center' }}>
              <Chip color={theme.colors.error} text={error} />
              <Text variant='h5'>{enderecoIntegra}</Text>
            </Box>
          : (isLoading
              ? <Loading text='Buscando Tema da Unidade' />
              : hasUnitServerUrl
                ? <Box style={styles.content}>
                {['primeirotema', 'padrao'].includes(themeClient?.fonte) &&
                  <HStack pv={12} wrap justify='center' spacing={24}>
                    <Chip color={theme.colors.error} text='Tema Padrão' />
                    <Text style={{ marginLeft: 12, fontWeight: '600', fontSize: 12 }}>Requer primeira gravação</Text>
                  </HStack>
                }
                <HStack wrap spacing={24}>
                  <ColorPicker label='Cor principal' name='mainColor' />
                  <ColorPicker label='Cor dos botões de confirmação' name='buttonSave' />
                </HStack>
                <HStack wrap spacing={24}>
                  <ColorPicker label='Cor do título da tela de login' name='loginColor' />
                  <ColorPicker label='Cor dos ícones do menu superior' name='menuColor' />
                </HStack>
                <VStack m={16}>
                  <LogoSelector fileName={logoEmpresa} />
                  <LoginBackgroundSelector fileName={backgroundEmpresa} />
                </VStack>
              </Box>
                : <Box style={styles.content}>
                <Text style={{ marginLeft: 12, fontWeight: '600', fontSize: 14, textTransform: 'uppercase', textAlign: 'center' }}>
                  Unidades devem configurar seu endereço externo para que obtenham o tema correto
                </Text>
              </Box>)
        }
      </ScrollView>
      {(hasChanged() || ['primeirotema', 'padrao'].includes(themeClient?.fonte)) &&
        <ActionsButtons firstRecording={['primeirotema', 'padrao'].includes(themeClient?.fonte)} />
      }
      <ModalOnSaveChanges uploadFile={uploadFile} saveTheme={saveTheme} />
      <ModalOnDiscardChanges />
    </SafeAreaView>
  )
}
