import React, { useEffect, useState } from 'react'
import { ImageBackground, StyleSheet } from 'react-native'
import { useImage, useThemeClient } from '@/presentation/hooks'

export const BackgroundTheme: React.FC = () => {
  const { getImage } = useImage()
  const { getThemeClient } = useThemeClient()
  const [backgroundEmpresa, setBackgroundEmpresa] = useState('')

  const themeClient = getThemeClient()
  const backgroundDefault = !__DEV__ && backgroundEmpresa
    ? { uri: backgroundEmpresa }
    : require('../../../../assets/hexagon-bg.svg')

  useEffect(() => {
    themeClient.bgLogin && !__DEV__ &&
      getImage(themeClient.bgLogin, 'login')
        .then(background => { setBackgroundEmpresa(background) })
        .catch(console.error)
  }, [themeClient.bgLogin])

  return (
    <ImageBackground
      source={backgroundDefault}
      style={{
        ...styles.image,
        backgroundColor: `linear-gradient(180deg, ${themeClient.mainColor}, ${themeClient.mainColor}CC)`,
        opacity: 1
      }}
    />
  )
}

const styles = StyleSheet.create({
  image: {
    flex: 1,
    justifyContent: 'center',
    alignSelf: 'stretch'
  }
})
