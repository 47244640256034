import React from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { RadioButton } from 'react-native-paper'
import { acceptDigitalFiltersState, statusAcceptState } from '@/presentation/pages/AceiteDigital/components'
import { useMediaQueries, useThemeClient } from '@/presentation/hooks'

export const SelectStatusAcceptFilter = (): JSX.Element => {
  const { getThemeClient } = useThemeClient()
  const { isMobile } = useMediaQueries()
  const [filters, setFilters] = useRecoilState(acceptDigitalFiltersState)
  const statusAccept = useRecoilValue(statusAcceptState)

  const themeClient = getThemeClient()

  const handleOnSelect = (selectedStatus: 'Ambos' | 'Autorizados' | 'Pendentes'): void => {
    setFilters((currentFilters) => ({ ...currentFilters, status: selectedStatus }))
  }

  return (
    <>
      {
        statusAccept.map((statusOption) => (
          <RadioButton.Item
            key={statusOption._id}
            label={statusOption.value}
            value={statusOption.value}
            status={filters.status === statusOption.value ? 'checked' : 'unchecked'}
            onPress={() => handleOnSelect(statusOption.value as 'Ambos' | 'Autorizados' | 'Pendentes')}
            labelStyle={{ fontSize: isMobile ? 14 : 16 }}
            style={{
              borderRadius: 4,
              borderWidth: 1,
              borderColor: `${themeClient.mainColor}26`,
              marginBottom: 8,
              paddingVertical: isMobile ? 0 : 16
            }}
            color={themeClient.mainColor}
          />
        ))
      }
    </>
  )
}
