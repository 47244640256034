import { AccessDeniedError, ResultWithoutRecordsError } from '@/domain/errors'
import { useAuth, useNotify } from '@/presentation/hooks'

type CallBackType = (error: Error) => void
type ResultType = CallBackType

export const useErrorHandler = (callback?: CallBackType): ResultType => {
  const notify = useNotify()
  const { logout } = useAuth()

  return (error: Error): void => {
    if (error instanceof AccessDeniedError) {
      notify.error(error.message)
      logout()
    } else if (error instanceof ResultWithoutRecordsError) {
      notify.info(error.message)
    } else {
      notify.error(error?.message || 'Ocorreu um erro desconhecido, tente novamente em alguns instantes')
      callback?.(error)
    }
  }
}
