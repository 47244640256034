import React from 'react'
import { StyleSheet, Image, ImageBackground } from 'react-native'
import { useMediaQueries, useTheme, useThemeClient } from '@/presentation/hooks'
import { Box, HStack, Text, VStack } from '@/presentation/components'
import { CreditCardModel } from '@/domain/models'

type CredCardProps = {
  creditCard: CreditCardModel
}

export const CreditCard: React.FC<CredCardProps> = ({ creditCard }) => {
  const theme = useTheme()
  const { isMobile } = useMediaQueries()
  const { getThemeClient } = useThemeClient()

  const themeClient = getThemeClient()

  return (
    <Box style={{
      ...styles.container,
      backgroundColor: themeClient.mainColor,
      width: '100%',
      ...(isMobile && { minWidth: '90%' })
    }}>
      <ImageBackground
        style={{
          ...styles.card,
          minHeight: isMobile ? 200 : 260,
          maxWidth: 428
        }}
        source={require('../../../../../../assets/card-bg.png')}
        resizeMode="cover"
      >
        <Image
          style={styles.insideLogo}
          source={require('../../../../../../assets/inside-logo-white.svg')}
        />
        <Image
          style={styles.chipCard}
          source={require('../../../../../../assets/chip-card.svg')}
        />
        <VStack spacing={8} mt={8}>
          <Text style={{
            ...styles.text,
            fontSize: isMobile ? 14 : 18,
            letterSpacing: isMobile ? 3 : 4,
            color: theme.colors.white
          }}>
            {creditCard?.numero}
          </Text>
          <VStack spacing={8}>
            <Text style={{ color: theme.colors.white }}>{creditCard?.nome}</Text>
            <HStack justify='between'>
              <Text style={{ color: theme.colors.white }}>{creditCard?.expiracao}</Text>
              <Text style={{ color: theme.colors.white }}>{creditCard?.ccv}</Text>
            </HStack>
          </VStack>
        </VStack>
      </ImageBackground>
    </Box>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: 428,
    borderRadius: 4
  },
  card: {
    width: '100%',
    maxWidth: 428,
    padding: 16,
    paddingBottom: 32,
    flex: 1,
    borderRadius: 4,
    justifyContent: 'space-between',
    shadowColor: 'rgba(0, 0, 0, 0.12)',
    shadowOffset: {
      width: 4,
      height: 8
    },
    shadowRadius: 8,
    borderColor: 'rgba(0, 0, 0, 0.12)',
    borderWidth: 1
  },
  insideLogo: {
    height: 38,
    width: 102,
    alignSelf: 'flex-end'
  },
  chipCard: {
    height: 38,
    width: 52,
    marginLeft: 16
  },
  text: {
    fontSize: 18,
    letterSpacing: 4
  }
})
