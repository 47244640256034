import { RedesSociaisModel } from '@/domain/models'
import { SaveRedesSociais, SaveRedesSociaisParams } from '@/domain/usecases/'
import { AccessDeniedError, UnexpectedError, UnexpectedResultFormatError } from '@/domain/errors'
import { HttpClient, HttpRequest, HttpStatusCode } from '@/data/protocols'

export class RemoteSaveRedesSociais implements SaveRedesSociais {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<RedesSociaisModel>
  ) {}

  async save (params: SaveRedesSociaisParams): Promise<RedesSociaisModel> {
    const authParams: HttpRequest = {
      url: `${this.url}/api/Portal/LinksRedesSociais`,
      method: 'post',
      body: params
    }

    const { statusCode, body } = await this.httpClient.request(authParams)

    switch (statusCode) {
      case HttpStatusCode.ok:
        if (!body) {
          throw new UnexpectedResultFormatError()
        }
        return body
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}
