import { atom } from 'recoil'
import { AcceptDigitalModel } from '@/domain/models'

export const isLoadingAcceptDigitalState = atom({
  key: 'isLoadingAcceptDigitalState',
  default: true
})

export const acceptDigitalState = atom({
  key: 'acceptDigitalState',
  default: [] as AcceptDigitalModel[]
})

export const currentAcceptDigitalState = atom({
  key: 'currentAcceptDigitalState',
  default: -1
})
