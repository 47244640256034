import { HttpClient, HttpStatusCode } from '@/data/protocols/http'
import { ForbiddenError, UnexpectedError } from '@/domain/errors'
import { ValidatePasswordRecoveryResult, ValidatePasswordRecovery, ValidatePasswordRecoveryParams } from '@/domain/usecases'

export class RemoteValidatePasswordRecovery implements ValidatePasswordRecovery {
  constructor (
    private readonly url: string,
    private readonly httpPostClient: HttpClient<ValidatePasswordRecoveryResult | string>
  ) {}

  async validate (params: ValidatePasswordRecoveryParams): Promise<number> {
    const { body, statusCode } = await this.httpPostClient.request({
      method: 'post',
      url: `${this.url}/api/LoginPortalService`,
      params
    })

    switch (statusCode) {
      case HttpStatusCode.ok: return (body as ValidatePasswordRecoveryResult).codigoCliente
      case HttpStatusCode.forbidden: throw new ForbiddenError(body as string)
      default: throw new UnexpectedError()
    }
  }
}
