import React, { useCallback, useEffect } from 'react'
import { GestureResponderEvent } from 'react-native'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { Box, Divider, DatePicker, InputField, SideFilter } from '@/presentation/components'
import { useAuth, useErrorHandler } from '@/presentation/hooks'
import { SelectStatusTicketFilter, isLoadingFinancialState, isOpenFinancialFiltersState, ticketsFiltersState, ticketsState } from '@/presentation/pages/Financial/components'
import { GetTickets, GetTicketsParams, GetTicketSituation } from '@/domain/usecases'
import { DateAdapter } from '@/data/protocols'

type DateParams = {
  dataInicio: string
  dataFim: string
}

export type FiltrosFatura = {
  situacao: GetTicketSituation
  dataInicio: string
  dataFim: string
}

type SideFiltersProps = {
  getTickets: GetTickets
  dateAdapter: DateAdapter
}

export const MARGIN_MODALS = 30

export const SideFilters: React.FC<SideFiltersProps> = ({ getTickets, dateAdapter }) => {
  const { getClient } = useAuth()
  const [isOpen, setOpen] = useRecoilState(isOpenFinancialFiltersState)
  const [filters, setFilters] = useRecoilState(ticketsFiltersState)
  const [isLoading, setLoading] = useRecoilState(isLoadingFinancialState)
  const errorHandler = useErrorHandler()
  const setTickets = useSetRecoilState(ticketsState)

  const client = getClient()

  useEffect(() => {
    setFilters({
      situacao: GetTickets.RN.DEFAULT_STATUS,
      dataInicio: dateAdapter.format(dateAdapter.yearsAgo(GetTickets.RN.DEFAULT_YEARS_AGO), 'YYYY-MM-DD'),
      dataFim: dateAdapter.format(dateAdapter.daysLater(GetTickets.RN.DEFAULT_DAYS_LATER), 'YYYY-MM-DD')
    })
  }, [])

  const handleGetTickets = (event: GestureResponderEvent): void => {
    event.preventDefault()
    setLoading(true)

    const paramsGetTicket: GetTicketsParams = {
      codigoCliente: client.codCliente,
      situacao: filters.situacao,
      dataInicial: dateAdapter.format(filters.dataInicio, 'YYYY-MM-DD'),
      dataFinal: dateAdapter.format(filters.dataFim, 'YYYY-MM-DD')
    }

    getTickets
      .get(paramsGetTicket)
      .then(setTickets)
      .catch(errorHandler)
      .finally(() => setLoading(false))

    setOpen(false)
  }

  const handleChangeDate = useCallback((field: keyof DateParams, date: Date): void => {
    setFilters(currentFilters => ({
      ...currentFilters,
      [field]: date.toString()
    }))
  }, [])

  const handleResetState = (): void => {
    setFilters({
      situacao: GetTickets.RN.DEFAULT_STATUS,
      dataInicio: dateAdapter.format(dateAdapter.daysAgo(GetTickets.RN.DEFAULT_DAYS_AGO), 'YYYY-MM-DD'),
      dataFim: dateAdapter.format(dateAdapter.daysLater(GetTickets.RN.DEFAULT_DAYS_LATER), 'YYYY-MM-DD')
    })
  }

  const handleClose = (): void => setOpen(false)

  return (
    <SideFilter.Drawer
      open={isOpen}
      onClose={handleClose}
      loading={isLoading}
      onSubmit={handleGetTickets}
      resetState={handleResetState}
    >
      <Box testID='data-filtro'>
        <SideFilter.Title title='Período de Vencimento' />
        <InputField>
          <DatePicker
            label='De'
            value={filters.dataInicio ? dateAdapter.parse(filters.dataInicio) : undefined}
            onChange={(date) => handleChangeDate('dataInicio', date ?? dateAdapter.daysAgo(GetTickets.RN.DEFAULT_DAYS_AGO))}
            dateAdapter={dateAdapter}
          />
          <Divider />
          <DatePicker
            label='Até'
            value={filters.dataFim ? dateAdapter.parse(filters.dataFim) : undefined}
            onChange={(date) => handleChangeDate('dataFim', date ?? dateAdapter.daysLater(GetTickets.RN.DEFAULT_DAYS_LATER))}
            dateAdapter={dateAdapter}
          />
        </InputField>
      </Box>
      <Box>
        <SideFilter.Title title='Status da Fatura' />
        <SelectStatusTicketFilter />
      </Box>
    </SideFilter.Drawer>
  )
}
