import React, { useCallback, useEffect } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { DateAdapter } from '@/data/protocols'
import { DatePicker, Stack, TextInput } from '@/presentation/components'
import { useMediaQueries, useTheme, useThemeClient } from '@/presentation/hooks'
import { travelAlertTimePickerState, travelAlertState, isLoadingState } from '@/presentation/pages/TravelAlert/components'
import { TravelAlertParams } from '@/presentation/pages/TravelAlert'

type SelectDateTimeProps = {
  dateAdapter: DateAdapter
}

export const SelectDateTime: React.FC<SelectDateTimeProps> = ({ dateAdapter }) => {
  const theme = useTheme()
  const { getThemeClient } = useThemeClient()
  const { isMobile } = useMediaQueries()
  const [travelAlert, setTravelAlert] = useRecoilState(travelAlertState)
  const isLoading = useRecoilValue(isLoadingState)
  const setModalTimePicker = useSetRecoilState(travelAlertTimePickerState)

  const themeClient = getThemeClient()

  useEffect(() => {
    setTravelAlert(currentState => ({
      ...currentState,
      dataSaida: dateAdapter.today().toString(),
      dataRetorno: dateAdapter.daysLater(15).toString()
    }))
  }, [])

  const handleChangeDate = useCallback((field: keyof TravelAlertParams, date: Date): void => {
    setTravelAlert(currentFilters => ({ ...currentFilters, [field as string]: date.toString() }))
  }, [])

  return (
    <>
      <Stack
        spacing={12}
        direction={isMobile ? 'column' : 'row'}
        style={{
          ...styles.dateRow,
          ...(!isMobile && { alignItems: 'center' })
        }}
      >
        <DatePicker
          label='Saída*'
          value={travelAlert.dataSaida
            ? dateAdapter.parse(travelAlert.dataSaida)
            : dateAdapter.today()
          }
          disabled={isLoading}
          onChange={(date) => handleChangeDate('dataSaida', date ?? dateAdapter.today())}
          dateAdapter={dateAdapter}
        />
        <TextInput
          color={themeClient.mainColor}
          editable={false}
          value={travelAlert.horaSaida}
          keyboardType='number-pad'
          label='Hora Saída*'
          variant='filled'
          trailing={
            <TouchableOpacity onPress={() => setModalTimePicker({ isOpen: true, currentTime: 'horaSaida' })}>
              <MaterialCommunityIcons size={24} color={theme.colors.grey} name='clock-time-eight-outline' />
            </TouchableOpacity>
          }
        />
      </Stack>
      <Stack
        spacing={12}
        style={styles.dateRow}
        direction={isMobile ? 'column' : 'row'}
      >
        <DatePicker
          label='Retorno*'
          value={travelAlert.dataRetorno
            ? dateAdapter.parse(travelAlert.dataRetorno)
            : dateAdapter.daysLater(15)
          }
          disabled={isLoading}
          onChange={(date) => handleChangeDate('dataRetorno', date ?? dateAdapter.daysLater(15))}
          dateAdapter={dateAdapter}
        />
        <TextInput
          color={themeClient.mainColor}
          editable={false}
          value={travelAlert.horaRetorno}
          keyboardType='number-pad'
          label='Hora Retorno*'
          variant='filled'
          trailing={
            <TouchableOpacity onPress={() => setModalTimePicker({ isOpen: true, currentTime: 'horaRetorno' })}>
              <MaterialCommunityIcons size={24} color={theme.colors.grey} name='clock-time-eight-outline' />
            </TouchableOpacity>
          }
        />
      </Stack>
    </>
  )
}

const styles = StyleSheet.create({
  dateRow: {
    width: '100%',
    gap: 12
  }
})
