import React from 'react'
import { ScrollView } from 'react-native'
import { useRecoilState } from 'recoil'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { FormaPagamento } from '@/domain/models'
import { useMediaQueries } from '@/presentation/hooks'
import { Text, Box, Loading } from '@/presentation/components'
import { financialConfigsState } from '@/presentation/pages/Settings/components'
import { styles } from './styles'

export const PaymentAvailableToUpdate: React.FC = () => {
  const { isMobile } = useMediaQueries()
  const [financialConfigs, setFinancialConfigs] = useRecoilState(financialConfigsState)

  const handleChoosePaymentForm = (codFormaPagamento: string, update: boolean): void => {
    let formasPagamentoAtualiza: FormaPagamento[]
    let formasPagamentoNaoAtualiza: FormaPagamento[]

    if (update) {
      const formaPagamentoSelecionada = financialConfigs.formasPagamentoNaoAtualiza
        .find(formaPagamento => formaPagamento.codigo === Number(codFormaPagamento)) as FormaPagamento

      formasPagamentoNaoAtualiza = financialConfigs.formasPagamentoNaoAtualiza
        .filter(formaPagamento => formaPagamento.codigo !== formaPagamentoSelecionada.codigo)

      formasPagamentoAtualiza = [...financialConfigs.formasPagamentoAtualiza, formaPagamentoSelecionada]
    } else {
      const formaPagamentoSelecionada = financialConfigs.formasPagamentoAtualiza
        .find(formaPagamento => formaPagamento.codigo === Number(codFormaPagamento)) as FormaPagamento

      formasPagamentoAtualiza = financialConfigs.formasPagamentoAtualiza
        .filter(formaPagamento => formaPagamento.codigo !== formaPagamentoSelecionada.codigo)

      formasPagamentoNaoAtualiza = [...financialConfigs.formasPagamentoNaoAtualiza, formaPagamentoSelecionada]
    }

    const newConfig = Object.assign({}, financialConfigs)
    newConfig.formasPagamentoAtualiza = formasPagamentoAtualiza
    newConfig.formasPagamentoNaoAtualiza = formasPagamentoNaoAtualiza

    setFinancialConfigs(newConfig)
  }

  const onDrag = (event: React.DragEvent<HTMLDivElement>, codFormaPagamento: string): void => {
    event.dataTransfer.setData('codFormaPagamento', codFormaPagamento)
  }

  const onDrop = (event: React.DragEvent<HTMLDivElement>, update: boolean): void => {
    const codFormaPagamento = event.dataTransfer.getData('codFormaPagamento')
    handleChoosePaymentForm(codFormaPagamento, update)
  }

  const handleClick = (codFormaPagamento: string, update: boolean): void => {
    handleChoosePaymentForm(codFormaPagamento, update)
  }

  const acceptDrop = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault()
  }

  return (
    <ScrollView>
      {!financialConfigs
        ? <Loading />
        : <>
            <Box style={isMobile ? styles.selectorContainerMobile : styles.selectorContainer }>
              <div
                style={styles.selectBox}
                onDrop={(event) => onDrop(event, true)}
                onDragOver={(event) => acceptDrop(event)}
              >
                <Text style={styles.titleBox}>Formas de pagamento atualizadas</Text>
                <Box style={styles.optionBox}>
                  {financialConfigs.formasPagamentoAtualiza.map((formaPagamento) =>
                    <div
                      draggable
                      key={formaPagamento.codigo}
                      onDragStart={(event) => onDrag(event, formaPagamento.codigo.toString())}
                      style={styles.optionSelectorChip}
                      onClick={() => handleClick(formaPagamento.codigo.toString(), false)}
                    >
                      <MaterialCommunityIcons name="drag" size={24} style={styles.optionIcon} />
                      <Text style={styles.optionText}>{`${formaPagamento.codigo} - ${formaPagamento.descricao}`}</Text>
                    </div>
                  )}
                </Box>
              </div>

              <div
                style={styles.selectBox}
                onDrop={(event) => onDrop(event, false)}
                onDragOver={(event) => acceptDrop(event)}
              >
                <Text style={styles.titleBox}>Formas de pagamento não atualizadas</Text>
                <div style={styles.optionBox}>
                  {financialConfigs.formasPagamentoNaoAtualiza.map((item) =>
                    <div
                      draggable
                      key={item.codigo}
                      onDragStart={(event) => onDrag(event, item.codigo.toString())}
                      onClick={() => handleClick(item.codigo.toString(), true)}
                      style={styles.optionSelectorChip}
                    >
                      <MaterialCommunityIcons name="drag" size={24} style={styles.optionIcon} />
                      <Text style={styles.optionText}>{`${item.codigo} - ${item.descricao}`}</Text>
                    </div>
                  )}
                </div>
              </div>
            </Box>
            <Text style={styles.tooltip}>Clique e arraste o elemento pra a lista desejada.</Text>
        </>
      }
    </ScrollView>
  )
}
