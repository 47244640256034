import { ServiceOrderDetailModel } from '@/domain/models'
import { GetServiceOrderDetail } from '@/domain/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import { RemoteGetServiceOrderDetail } from '@/data/usecases/'
import env from '@/main/config/env'

export const makeGetServiceOrderDetail = (): GetServiceOrderDetail => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<ServiceOrderDetailModel>()

  return new RemoteGetServiceOrderDetail(url, httpClient)
}
