import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  headerBox: {
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, 0.12)',
    paddingBottom: 20,
    paddingHorizontal: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: 20
  },
  headerTitle: {
    fontWeight: '600',
    fontSize: 20
  }
})
