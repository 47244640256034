import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  generalContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  selectorContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    borderRadius: 4
  },

  selectorContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-between',
    borderRadius: 4
  },

  selectBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },

  titleBox: {
    color: 'rgba(0, 0, 0, 0.60)',
    textAlign: 'center',
    fontWeight: '500',
    fontSize: 14
  },

  optionBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },

  optionSelectorChip: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    width: '18.5rem',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: 8,
    fontWeight: '500',
    fontSize: 14
  },

  optionIcon: {
    color: 'rgba(0, 0, 0, 0.32)',
    marginTop: 12,
    marginBottom: 12,
    marginLeft: 16,
    marginRight: 16
  },

  optionText: {
    color: 'rgba(0, 0, 0, 0.32)',
    fontWeight: '500',
    fontSize: 14,
    overflow: 'hidden',
    whiteSpace: 'noWrap',
    textOverflow: 'ellipsis',
    marginRight: 16
  },

  tooltip: {
    marginTop: 32,
    fontWeight: '500',
    color: 'rgba(0, 0, 0, 0.32)',
    fontSize: 14
  }

})
