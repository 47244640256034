export type OpenNfseParams = {
  codEmpresa: number
  codUnidade: number
  numeroRPS: number
  serieRps: string
  linkAutenticidade: string
  utilizarDevExpress: boolean
}

export interface OpenNfse {
  open: (params: OpenNfseParams) => Promise<void>
}

export namespace OpenNfse {
  export const RN = {
    FOLDER_DEFAULT_NFSE: 'TempRelatorios/EspelhoNFSe'
  }
}
