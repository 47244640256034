import React from 'react'
import { Divider } from 'react-native-paper'
import { Box, HStack, VStack, Text } from '@react-native-material/core'
import { GenericSectorModel, ClientMonitoringType } from '@/domain/models'
import { useAuth, useMediaQueries } from '@/presentation/hooks'
import { theme } from '@/presentation/styles'
import { styles } from './styles'

type CardSectorProps = {
  sector: GenericSectorModel
}

export const CardSector: React.FC<CardSectorProps> = ({ sector }: CardSectorProps) => {
  const { isMobile } = useMediaQueries()
  const { getClient } = useAuth()
  const client = getClient()

  const containerStyles = {
    ...styles.container,
    ...(!isMobile && {
      flex: 1,
      maxWidth: 600
    }),
    backgroundColor: theme.colors.white,
    color: theme.colors.greyLighten
  }

  const textTitleStyles = {
    ...styles.contentText,
    color: theme.colors.texts
  }

  return (
    <Box style={containerStyles}>
      <HStack items='center' justify='between' spacing={8} mb={8} mt={8}>
        <Text variant='subtitle1' style={textTitleStyles} testID='setor-name'>
          <span style={styles.contentTitle}>
            {[ClientMonitoringType.SO_WILL, ClientMonitoringType.SIGMA_CLOUD].includes(client.tipoConexao)
              ? 'Zona:'
              : 'Setor:'}
          </span>
          {sector.nome}
        </Text>

        {sector?.codigoZona &&
          <Text variant='subtitle1' style={textTitleStyles} testID='setor-codigo-zona'>
            <span style={styles.contentTitle}>
              Código:
            </span>
            {sector.codigoZona}
          </Text>}
      </HStack>

      <VStack spacing={8} style={{ flex: 1 }}>
        <Divider style={styles.divider} />
        <>
          {sector?.codigoCliente &&
            <Text variant='subtitle1' style={textTitleStyles} testID='setor-codigo-cliente'>
              <span style={styles.contentTitle}>
                Codigo do cliente:
              </span>
              {sector.codigoCliente}
            </Text>}
          {sector?.idEquipamento &&
            <Text variant='subtitle1' style={textTitleStyles} testID='setor-codigo-id-equipamento'>
              <span style={styles.contentTitle}>
                Id do Equipamento:
              </span>
              {sector.idEquipamento}
            </Text>}
          {sector?.ativo &&
            <Text variant='subtitle1' style={textTitleStyles} testID='setor-status'>
              <span style={styles.contentTitle}>
                Status:
              </span>
              {sector.ativo ? 'Ativo' : ' Inativo'}
            </Text>}
          {sector?.observacao &&
            <Text variant='subtitle1' style={textTitleStyles} testID='setor-observacao'>
              <span style={styles.contentTitle}>
                Observação:
              </span>
              {sector?.observacao}
            </Text>}
        </>
      </VStack>

    </Box>
  )
}
