import React from 'react'
import { Text } from '@react-native-material/core'
import { MaterialIcons } from '@expo/vector-icons'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { useThemeClient } from '@/presentation/hooks'
import { HStack } from '@/presentation/components'

type ChipFilterProps = {
  title: string
  subtitle: string
  onPress: () => void
}

export const ChipFilter: React.FC<ChipFilterProps> = ({
  title,
  subtitle,
  onPress
}) => {
  const { getThemeClient } = useThemeClient()

  const themeClient = getThemeClient()

  return (
    <TouchableOpacity
      activeOpacity={0.8}
      style={{
        backgroundColor: `${themeClient.mainColor}26`,
        paddingHorizontal: 16,
        paddingVertical: 6,
        borderRadius: 4
      }}
      onPress={onPress}
    >
      <HStack justify='between' items='center'>
        <Text color={themeClient.mainColor} style={{ fontWeight: '500', fontSize: 12 }}>
          {title}
        </Text>
        <MaterialIcons name='edit' color={themeClient.mainColor} size={12} />
      </HStack>
      <Text color={themeClient.mainColor} style={{ fontSize: 12 }}>
        {subtitle}
      </Text>
    </TouchableOpacity>
  )
}
