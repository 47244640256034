import { atom, selector } from 'recoil'
import { BoletoInsideConfigs, ConfigModel, FinancialConfigs, GeneralConfigs, InvoiceConfigs, MenuAccessPermissionsConfigs, ServiceOrderConfigs } from '@/domain/models'

export const isLoadingState = atom({
  key: 'settingsLoadingState',
  default: true
})

export const initialConfigsState = atom({
  key: 'initialConfigsState',
  default: null as unknown as ConfigModel
})

export const financialConfigsState = atom<FinancialConfigs & BoletoInsideConfigs>({
  key: 'financialConfigsState',
  default: null as unknown as FinancialConfigs & BoletoInsideConfigs
})

export const generalConfigsState = atom<GeneralConfigs>({
  key: 'generalConfigsState',
  default: null as unknown as GeneralConfigs
})

export const invoiceConfigsState = atom<InvoiceConfigs>({
  key: 'invoiceConfigsState',
  default: null as unknown as InvoiceConfigs
})

export const serviceOrderConfigsState = atom<ServiceOrderConfigs>({
  key: 'serviceOrderConfigsState',
  default: null as unknown as ServiceOrderConfigs
})

export const menuAccessPermissionsConfigsState = atom<MenuAccessPermissionsConfigs>({
  key: 'menuAccessPermissionsConfigsState',
  default: null as unknown as MenuAccessPermissionsConfigs
})

export const configsState = selector({
  key: 'configsState',
  get: ({ get }) => {
    const financialConfigs = get(financialConfigsState)
    const generalConfigs = get(generalConfigsState)
    const invoiceConfigs = get(invoiceConfigsState)
    const serviceOrderConfigs = get(serviceOrderConfigsState)
    const menuAccessPermissionsConfigs = get(menuAccessPermissionsConfigsState)

    return ({
      ...financialConfigs,
      ...generalConfigs,
      ...invoiceConfigs,
      ...serviceOrderConfigs,
      liberacoesPortal: menuAccessPermissionsConfigs
    }) as ConfigModel
  }
})
