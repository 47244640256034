import { AccessDeniedError, ForbiddenError, UnexpectedError } from '@/domain/errors'
import { GenericUserModel, SowillUserResult } from '@/domain/models'
import { GetSowillUsers, GetSowillUsersParams } from '@/domain/usecases'
import { HttpClient, HttpStatusCode } from '@/data/protocols/http'

export class RemoteGetSowillUsers implements GetSowillUsers {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<SowillUserResult | string>
  ) { }

  async getUsers (params: GetSowillUsersParams): Promise<GenericUserModel[]> {
    const bodyParams = {
      IdCliente: params.idCliente,
      CodInternoChave: params.codInternoChave
    }

    const { body, statusCode } = await this.httpClient.request({
      url: `${this.url}/api/sowill/listaUsuarios`,
      method: 'post',
      body: bodyParams
    })

    switch (statusCode) {
      case HttpStatusCode.ok: return this.mapToGeneric(body as SowillUserResult)
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      case HttpStatusCode.forbidden: throw new ForbiddenError(body as string)
      default: throw new UnexpectedError()
    }
  }

  mapToGeneric (users: SowillUserResult): GenericUserModel[] {
    return users.map(user => ({
      nome: user.usuarioAlarme,
      codCliente: user.nroUserSistema?.toString(),
      fone1: user.telefone1,
      fone2: user.telefone2
    }))
  }
}
