import { OsAttendanceModel, ServiceOrderModel } from '@/domain/models'
import { atom } from 'recoil'

export const isLoadingOsViewState = atom({
  key: 'isLoadingOsViewState',
  default: false
})

export const serviceOrderViewState = atom({
  key: 'serviceOrderViewState',
  default: {
    currentOs: null as unknown as ServiceOrderModel,
    attendances: [] as OsAttendanceModel[]
  }
})
