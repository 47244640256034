import { AccessDeniedError, ResultWithoutRecordsError, UnexpectedError } from '@/domain/errors'
import { OpenNfse, OpenNfseParams } from '@/domain/usecases'
import { HttpClient, HttpStatusCode } from '@/data/protocols/http'

export class RemoteOpenNfse implements OpenNfse {
  constructor (
    private readonly url: string,
    private readonly httpGetClient: HttpClient<string>
  ) {}

  async open (params: OpenNfseParams): Promise<void> {
    const { linkAutenticidade, ...dataParams } = params

    if (dataParams.utilizarDevExpress) {
      const { body, statusCode } = await this.httpGetClient.request({
        url: `${this.url}/api/EspelhoNotaFiscal`,
        method: 'get',
        params: {
          ...dataParams,
          producao: true
        }
      })

      switch (statusCode) {
        case HttpStatusCode.ok:
          if (!body) {
            throw new ResultWithoutRecordsError()
          }

          window.open(`/${OpenNfse.RN.FOLDER_DEFAULT_NFSE}/${body}`, '_blank')
          break
        case HttpStatusCode.unauthorized: throw new AccessDeniedError()
        default: throw new UnexpectedError()
      }
    } else if (linkAutenticidade) {
      window.open(linkAutenticidade, '_blank')
    }
  }
}
