import React from 'react'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { GestureResponderEvent } from 'react-native'
import { MaterialIcons } from '@expo/vector-icons'
import { AddNewOs, AddNewOsParams } from '@/domain/usecases'
import { ClientAddress, ServiceOrderModel, ServiceOrderStatus } from '@/domain/models'
import { DateAdapter } from '@/data/protocols'
import { Box, Button, Text, TextInput, ModalBase, HStack, Loading, Select } from '@/presentation/components'
import { useAuth, useErrorHandler, useNotify, useTheme } from '@/presentation/hooks'
import { addNewOsState, isLoadingAddNewOS, isOpenAddNewOS, isOpenAddressNewOSState, serviceOrdersConfigsState, serviceOrdersState } from '@/presentation/pages/ServiceOrders/components'
import { styles } from './styles'

type AddNewOsProps = {
  solicitante: string
  observacoes: string
}

type ModalAddNewOsProps = {
  addNewOs: AddNewOs
  dateAdapter: DateAdapter
}

export const ModalAddNewOs: React.FC<ModalAddNewOsProps> = ({ addNewOs, dateAdapter }) => {
  const theme = useTheme()
  const notify = useNotify()
  const handleError = useErrorHandler()
  const { getClient } = useAuth()
  const [addNewOS, setAddNewOs] = useRecoilState(addNewOsState)
  const [serviceOrders, setServiceOrders] = useRecoilState(serviceOrdersState)
  const [isOpen, setOpen] = useRecoilState(isOpenAddNewOS)
  const serviceOrdersConfigs = useRecoilValue(serviceOrdersConfigsState)
  const [isLoading, setLoading] = useRecoilState(isLoadingAddNewOS)
  const [isOpenAddressNewOS, setOpenAddressNewOS] = useRecoilState(isOpenAddressNewOSState)
  const resetAddNewOs = useResetRecoilState(addNewOsState)

  const client = getClient()

  const handleChangeInput = (name: keyof AddNewOsProps, value: string): void => {
    setAddNewOs(currentValues => ({ ...currentValues, [name]: value }))
  }

  const handleCreateOs = (event: GestureResponderEvent): void => {
    event.preventDefault()
    setLoading(true)

    const addNewOsParams: AddNewOsParams = {
      cliente: client.codCliente,
      solicitante: addNewOS.solicitante,
      observacoes: addNewOS.observacoes,
      codEndereco: addNewOS.codEndereco,
      empresa: client.empresa,
      enviaListaChave: client.enviaListaChave,
      modalidade: client.modalidade,
      codigoUnidade: client.unidade
    }

    addNewOs
      .add(addNewOsParams)
      .then(codigo => {
        if (codigo) {
          successNewOs(addNewOsParams, codigo)
          setOpen(false)
          resetAddNewOs()
        } else {
          throw new Error('Não foi possível gravar a OS!')
        }
      })
      .catch(handleError)
      .finally(handleClose)
  }

  const successNewOs = (createSacParams: AddNewOsParams, codigo: number): void => {
    const listNewServiceOrders = [
      {
        ...createSacParams as unknown as ServiceOrderModel,
        ordem: codigo,
        status: ServiceOrderStatus.Aberta,
        dataAbertura: dateAdapter.today().toString()
      },
      ...serviceOrders
    ]
    setServiceOrders(listNewServiceOrders)
    notify.success('Ordem de serviço gravada com sucesso')
  }

  const handleClose = (): void => {
    setOpen(false)
    setLoading(false)
    resetAddNewOs()
  }

  const formatAddress = (addressCode: number): string | undefined => {
    const address = client.enderecoAlternativo?.find(endereco => endereco.codInterno === addressCode)
    return address?.endereco && `${address.endereco}, ${address.numero} - ${address.bairro}`
  }

  return (
    <ModalBase visible={isOpen} onClose={handleClose}>
      {isLoading
        ? <Loading text='Gravando Ordem de Serviço' />
        : <Box style={styles.container}>
        <Text variant="subtitle1" style={{ fontWeight: '500' }}>Criar nova OS</Text>
        {serviceOrdersConfigs.portalExibeMensagem &&
        serviceOrdersConfigs.portalMensagem &&
          <HStack p={12} justify='center' items='center' spacing={12}
            style={{
              backgroundColor: theme.colors.errorContainer,
              borderLeftWidth: 2,
              borderLeftColor: theme.colors.error
            }}>
            <MaterialIcons name='info' size={24} color={theme.colors.error} />
            <Text color={theme.colors.grey}>{serviceOrdersConfigs.portalMensagem}</Text>
          </HStack>
        }

        <TextInput
          label="Solicitante*"
          testID='os-solicitante'
          onChangeText={text => handleChangeInput('solicitante', text)}
          value={addNewOS.solicitante}
          maxLength={30}
        />

        {!!client.enderecoAlternativo.length &&
          <Select.Trigger
            label='Endereço Atendimento*'
            description={formatAddress(addNewOS.codEndereco) ?? ''}
            onOpen={() => setOpenAddressNewOS(true)}
            style={{ minWidth: '100%' }}
          />
        }

        <TextInput
          label="Observações*"
          testID='os-descricao'
          inputContainerStyle={{ paddingTop: 24 }}
          onChangeText={text => handleChangeInput('observacoes', text)}
          value={addNewOS.observacoes}
          multiline
          numberOfLines={5}
        />

        <Box style={styles.buttonArea}>
          <Button
            color={theme.colors.shadow}
            variant="text"
            title="Cancelar"
            testID='os-cancelar'
            onPress={handleClose}
            disabled={isLoading}
          />
          <Button
            title="GRAVAR"
            testID='os-gravar'
            onPress={handleCreateOs}
            disabled={isLoading || !addNewOS.solicitante.length || !addNewOS.observacoes.length}
          />
        </Box>

        {!!client.enderecoAlternativo.length &&
          <Select.Modal
            title='Selecione o endereço do atendimento'
            isOpen={isOpenAddressNewOS}
            itemsColor='grey'
            onClose={() => setOpenAddressNewOS(false)}
            items={client.enderecoAlternativo?.map(endereco => ({
              id: endereco.codInterno,
              descricao: formatAddress(endereco.codInterno) ?? '',
              data: endereco,
              isSelected: endereco.codInterno === addNewOS.codEndereco
            }))}
            onSelect={(addressCode, _) => {
              setAddNewOs(currentState => ({ ...currentState, codEndereco: addressCode }))
            }}
          />
        }
      </Box>
    }
    </ModalBase>
  )
}
