import { StyleSheet } from 'react-native'
import { layoutStyles } from '@/presentation/styles/globals'

export const styles = StyleSheet.create({
  container: {
    ...layoutStyles.pageContent,
    maxWidth: 740
  },
  content: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 18,
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  itemUser: {
    marginRight: 24,
    marginBottom: 12,
    alignItems: 'flex-end',
    alignSelf: 'flex-end',
    flex: 1
  }
})
