import { SaveProspectConfigs, SaveProspectConfigsParams } from '@/domain/usecases/'
import { AccessDeniedError, ForbiddenError, UnexpectedError } from '@/domain/errors'
import { HttpClient, HttpStatusCode } from '@/data/protocols/http'

export class RemoteSaveProspectConfig implements SaveProspectConfigs {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<string>
  ) { }

  async save (config: SaveProspectConfigsParams): Promise<void> {
    const { statusCode, body } = await this.httpClient.request({
      method: 'post',
      url: `${this.url}/api/portal/configuracoes-prospect`,
      body: config
    })

    switch (statusCode) {
      case HttpStatusCode.ok: return
      case HttpStatusCode.forbidden: throw new ForbiddenError(body as unknown as string)
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}
