import { PrintEvents } from '@/domain/usecases'
import { RemotePrintEvents } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'

export const makePrintEvents = (): PrintEvents => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<string>()

  return new RemotePrintEvents(url, httpClient)
}
