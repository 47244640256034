import { TrackDetailsModel, TrackDetailsResult, FuelType } from '@/domain/models'
import { GetTrackDetails, GetTrackDetailsParams } from '@/domain/usecases'
import { AccessDeniedError, UnexpectedError, UnexpectedResultFormatError } from '@/domain/errors'
import { HttpClient, HttpRequest, HttpStatusCode } from '@/data/protocols'

export class RemoteGetDetailTrack implements GetTrackDetails {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<TrackDetailsResult>
  ) { }

  async getDetails (params: GetTrackDetailsParams): Promise<TrackDetailsModel> {
    const request: HttpRequest = {
      url: `${this.url}/api/portal/detalhesRastreamento`,
      method: 'get',
      params
    }

    const { statusCode, body } = await this.httpClient.request(request)

    switch (statusCode) {
      case HttpStatusCode.ok:
        if (!body) {
          throw new UnexpectedResultFormatError()
        }
        return this.mapToModel(body)
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }

  mapToModel (trackDetails: TrackDetailsResult): TrackDetailsModel {
    return {
      id: trackDetails.Id,
      cor: trackDetails.Cor,
      ano: trackDetails.Ano,
      placa: trackDetails.Placa,
      modelo: trackDetails.Modelo,
      combustivel: trackDetails.Combustivel.toUpperCase() as FuelType
    }
  }
}
