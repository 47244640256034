import React from 'react'
import { useRecoilState } from 'recoil'
import { GestureResponderEvent } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { UpdatePassword, UpdatePasswordParams } from '@/domain/usecases'
import { useAuth, useErrorHandler, useNotify, useTheme } from '@/presentation/hooks'
import { Box, Button, IconButton, Loading, ModalBase, SecurityPassword, Text, TextInput } from '@/presentation/components'
import { UpdateSettingsStateProps, isLoadingUpdatePasswordState, isOpenUpdatePasswordState, updateSettingsState, showPasswordState } from './atom'
import { styles } from './styles'

type UpdatePasswordProps = {
  updatePassword: UpdatePassword
}

export const UpdatePasswordC: React.FC<UpdatePasswordProps> = ({ updatePassword }) => {
  const theme = useTheme()
  const notify = useNotify()
  const { getClient, isPasswordSecure } = useAuth()
  const handleError = useErrorHandler()
  const [isLoading, setLoading] = useRecoilState(isLoadingUpdatePasswordState)
  const [showPassword, setShowPassword] = useRecoilState(showPasswordState)
  const [state, setState] = useRecoilState(updateSettingsState)
  const [isOpen, setOpen] = useRecoilState(isOpenUpdatePasswordState)

  const client = getClient()

  const handleUpdatePassword = (event: GestureResponderEvent): void => {
    event.preventDefault()

    if (!isPasswordSecure(state.novaSenha)) {
      notify.error('A senha informada é insegura, a senha deve conter no mímino 6 caracteres, possuir letrar maiúsculas e minúsculas, carácter especial e números')
    }

    setLoading(true)

    const updatePasswordParams: UpdatePasswordParams = {
      cliente: client.codCliente,
      novaSenha: state.novaSenha,
      confirmacaoSenha: state.confirmacaoSenha
    }

    updatePassword
      .update(updatePasswordParams)
      .then(changed => {
        if (changed) {
          notify.show('Senha alterada, a nova senha foi gravada com sucesso')
        } else {
          notify.error('Não foi possível alterar a senha, tente novamente')
        }
      })
      .catch(handleError)
      .finally(() => {
        handleClose()
        setLoading(false)
      })
  }

  const handleTogglePasswordVisibility = (): void => {
    setShowPassword(currentState => !currentState)
  }

  const handleChangeInput = (name: keyof UpdateSettingsStateProps, value: string): void => {
    setState(currentValues => ({ ...currentValues, [name]: value }))
  }

  const handleClose = (): void => {
    setOpen(false)
    setLoading(false)
    handleReset()
  }

  const handleReset = (): void => {
    setState(currentValues => ({
      ...currentValues,
      senhaAtual: '',
      novaSenha: '',
      confirmacaoSenha: ''
    }))
  }

  return (
    <ModalBase visible={isOpen} onClose={handleClose}>
      {!client
        ?<Loading text='Buscando informações do cliente' />
        : <Box style={styles.container}>
        <Text variant="h4" testID='update-alterar-senha'>Alterar Senha</Text>

        <TextInput
          label="Nova Senha"
          testID='update-nova-senha'
          secureTextEntry={!showPassword}
          onChangeText={text => handleChangeInput('novaSenha', text)}
          value={state.novaSenha}
          maxLength={30}
          trailing={
            <IconButton
              icon={<MaterialCommunityIcons
                name={showPassword ? 'eye-off' : 'eye'}
                color={theme.colors.greyLight}
                size={18}
              />}
              onPress={handleTogglePasswordVisibility}
            />
          }
        />

        <SecurityPassword
          minLenght={state.novaSenha.length >= 8}
          hasLowerCase={/[a-z]/.test(state.novaSenha)}
          hasUpperCase={/[A-Z]/.test(state.novaSenha)}
          hasNumber={/\d/.test(state.novaSenha)}
          hasSpecialChar={/[!@#$%^&*(),.?":{}|<>]/.test(state.novaSenha)}
        />

        <TextInput
          label="Confirmar Nova Senha"
          testID='update-confirmar-senha'
          secureTextEntry
          onChangeText={text => handleChangeInput('confirmacaoSenha', text)}
          value={state.confirmacaoSenha}
          maxLength={30}
        />

        <Box style={styles.buttonArea}>
          <Button
            color={theme.colors.shadow}
            tintColor={theme.colors.white}
            variant="text"
            testID='update-cancelar-senha'
            title="CANCELAR"
            onPress={handleClose}
            disabled={isLoading}
          />
          <Button
            title="GRAVAR"
            testID='update-gravar-senha'
            onPress={handleUpdatePassword}
            tintColor={theme.colors.white}
            color={theme.colors.success}
            loading={!client}
            disabled={
              isLoading ||
              !isPasswordSecure(state.novaSenha) ||
              !state.novaSenha ||
              state.novaSenha !== state.confirmacaoSenha ||
              !client
            }
          />
        </Box>
      </Box>
      } 
    </ModalBase>
  )
}
