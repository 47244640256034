import React from 'react'
import { MaterialIcons } from '@expo/vector-icons'
import { HStack, Text } from '@/presentation/components'
import { useTheme } from '@/presentation/hooks'

export type MessageBlockStatus = 'info' | 'success' | 'error' | 'warning' | undefined

type LogoutProps = {
  status?: MessageBlockStatus
  message: string
}

export const MessageBlock: React.FC<LogoutProps> = ({
  status = 'info',
  message
}) => {
  const theme = useTheme()

  const colorStatusStyle = {
    info: theme.colors.info,
    success: theme.colors.success,
    error: theme.colors.error,
    warning: theme.colors.secondary
  }[status]

  const colorContainerStatusStyle = {
    info: theme.colors.infoContainer,
    success: theme.colors.successContainer,
    error: theme.colors.errorContainer,
    warning: theme.colors.secondaryContainer
  }[status]

  return (
    <HStack mv={8} p={12} justify='center' items='center' spacing={12}
      style={{
        backgroundColor: colorContainerStatusStyle,
        borderLeftWidth: 4,
        borderLeftColor: colorStatusStyle
      }}>
      <MaterialIcons name='info' size={24} color={colorStatusStyle} />
      <Text color={theme.colors.grey}>
        {message}
      </Text>
    </HStack>
  )
}
