import { TrackModel, TrackResult } from '@/domain/models'
import { GetTrackList, GetTrackParams } from '@/domain/usecases'
import { AccessDeniedError, UnexpectedError, UnexpectedResultFormatError } from '@/domain/errors'
import { HttpClient, HttpRequest, HttpStatusCode } from '@/data/protocols'

export class RemoteGetTrackList implements GetTrackList {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<TrackResult[]>
  ) { }

  async getTrackList (params: GetTrackParams): Promise<TrackModel[]> {
    const request: HttpRequest = {
      url: `${this.url}/api/portal/rastreamento`,
      method: 'get',
      params
    }

    const { statusCode, body } = await this.httpClient.request(request)

    switch (statusCode) {
      case HttpStatusCode.ok:
        if (!body) {
          throw new UnexpectedResultFormatError()
        }
        return this.mapToGeneric(body)
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }

  mapToGeneric (trackRaw: TrackResult[]): TrackModel[] {
    return trackRaw?.map(track => ({
      codInterno: track.CodInterno,
      id: track.Id,
      placa: track.Placa
    }))
  }
}
