import { atom } from 'recoil'

export const isLoadingAddNewOS = atom({
  key: 'isLoadingAddNewOS',
  default: false
})

export const isOpenAddNewOS = atom({
  key: 'isOpenAddNewOS',
  default: false
})

export const isOpenAddressNewOSState = atom({
  key: 'isOpenAddressNewOS',
  default: false
})

export const addNewOsState = atom({
  key: 'addNewOs',
  default: {
    solicitante: '',
    observacoes: '',
    codEndereco: 0
  }
})
