import React from 'react'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useAuth, useMediaQueries, useTheme, useThemeClient } from '@/presentation/hooks'
import { Box, Button, ChipFilter, HStack } from '@/presentation/components'
import { DateAdapter } from '@/data/protocols'
import { isOpenServiceOrdersFiltersState, serviceOrdersFiltersState } from '@/presentation/pages/ServiceOrders/components'
import { useRecoilValue, useSetRecoilState } from 'recoil'

type FiltersChipPanelProps = {
  dateAdapter: DateAdapter
  handleAddNewOs: () => void
}

export const FiltersChipPanel: React.FC<FiltersChipPanelProps> = ({ dateAdapter, handleAddNewOs }) => {
  const theme = useTheme()
  const { getThemeClient } = useThemeClient()
  const { isMobile } = useMediaQueries()
  const setOpen = useSetRecoilState(isOpenServiceOrdersFiltersState)
  const filters = useRecoilValue(serviceOrdersFiltersState)
  const { getClient } = useAuth()

  const { codTecnico, osSemTecnico } = getClient()

  const themeClient = getThemeClient()
  const period = dateAdapter.format(filters.dataInicio, 'DD/MM/YYYY') +
    ' até ' +
    dateAdapter.format(filters.dataFim, 'DD/MM/YYYY')

  const situation = {
    Todas: 'Todas',
    'Em Aberto': isMobile ? 'Abertas' : 'Somente Abertas',
    Fechadas: isMobile ? 'Fechadas' : 'Somente Fechadas'
  }[filters.situacao]

  return (
    <HStack spacing={6} mb={18} wrap>
      <Box>
        <ChipFilter
          title='Período'
          subtitle={period}
          onPress={() => setOpen(true)}
        />
      </Box>
      <Box>
        <ChipFilter
          title='Status'
          subtitle={situation}
          onPress={() => setOpen(true)}
          />
      </Box>
      <HStack>
        {!isMobile &&
          <Button
            leading={<MaterialCommunityIcons color={theme.colors.white} size={18} name='plus' />}
            disabled={codTecnico === 0 && !osSemTecnico }
            title='Abrir Ordem de Serviço'
            testID='cadastrar-os'
            color={themeClient.mainColor}
            tintColor={theme.colors.white}
            onPress={handleAddNewOs}
          />
        }
      </HStack>
    </HStack>
  )
}
