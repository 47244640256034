import { NotificationOnDBModel } from '@/domain/models'
import { RemoteGetClientNotifications } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import { GetClientNotifications } from '@/domain/usecases'
import env from '@/main/config/env'

export const makeGetClientNotifications = (): GetClientNotifications => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<NotificationOnDBModel[]>()

  return new RemoteGetClientNotifications(url, httpClient)
}
