import React, { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { ScrollView } from 'react-native-gesture-handler'
import { GetOsAttendance } from '@/domain/usecases'
import { ServiceOrderModel, ServiceOrderStatus } from '@/domain/models'
import { DateAdapter } from '@/data/protocols'
import { useAuth, useMediaQueries, useNotify, useTheme } from '@/presentation/hooks'
import { ModalBase, Box, Button, Text, Chip, HStack, Divider, VStack, Loading } from '@/presentation/components'
import { OsAttendandes, isLoadingOsViewState, serviceOrderViewState } from '@/presentation/pages/ServiceOrders/components'
import { styles, OSIcon, MarkerIcon } from './styles'

type ModalServiceOrderViewProps = {
  getOsAttendance: GetOsAttendance
  dateAdapter: DateAdapter
}

const MARGIN_ITEMS = 8

export const ModalOsView: React.FC<ModalServiceOrderViewProps> = ({
  getOsAttendance,
  dateAdapter
}) => {
  const theme = useTheme()
  const { isMobile } = useMediaQueries()
  const { getAddress } = useAuth()
  const notify = useNotify()
  const [serviceOrder, setState] = useRecoilState(serviceOrderViewState)
  const [isLoading, setLoading] = useRecoilState(isLoadingOsViewState)

  const address = getAddress(serviceOrder.currentOs.codEndereco)

  useEffect(() => {
    if (serviceOrder.currentOs?.codInterno) {
      setLoading(true)

      getOsAttendance
        .getAttendences({ codigoOs: serviceOrder.currentOs.codInterno })
        .then(attendances => setState(currentState => ({ ...currentState, attendances })))
        .catch(error => notify.show(error.message, { variant: 'error' }))
        .finally(() => setLoading(false))
    }
  }, [])

  const handleClose = (): void => {
    setState(currentState => ({
      ...currentState,
      currentOs: null as unknown as ServiceOrderModel,
      attendances: []
    }))
  }

  const colorStatusStyle = (status: ServiceOrderStatus): string => {
    return {
      A: theme.colors.success,
      B: theme.colors.error,
      F: theme.colors.info
    }[status]
  }

  const makeTextStatus = (status: ServiceOrderStatus): string => {
    return {
      A: 'Aberta',
      B: 'Fechada',
      F: 'Faturada'
    }[status]
  }

  return (
    <ModalBase visible={!!serviceOrder.currentOs} onClose={handleClose}>
      <Box style={styles.container}>
        <Box>
          <HStack justify='between' mb={8} testID='os-numero'>
            <OSIcon style={{ fontSize: 24 }} name="home-repair-service" />
            <Chip text={`Nº ${serviceOrder.currentOs?.ordem.toString().padStart(6, '0')}`} />
          </HStack>
          <Box style={{ marginBottom: MARGIN_ITEMS }} testID='os-solicitante'>
            <Text variant="subtitle1" style={styles.title}>Solicitante</Text>
            <Text variant="subtitle1">{serviceOrder.currentOs?.solicitante.toUpperCase()}</Text>
          </Box>
          <HStack mb={MARGIN_ITEMS} testID='os-status'>
            <Box style={{ flex: 1 }}>
              <Text style={styles.title} variant='subtitle1'>Status</Text>
              <Chip
                text={makeTextStatus(serviceOrder.currentOs?.status)}
                containerStyle={{ backgroundColor: colorStatusStyle(serviceOrder.currentOs?.status) }}
                textStyle={{ fontSize: 12 }}
              />
            </Box>
            <Box testID='os-abertura'>
              <Text variant="subtitle1" style={styles.title} >Data Abertura</Text>
              <Text variant="subtitle1">
                {dateAdapter.format(serviceOrder.currentOs?.dataAbertura, 'DD/MM/yyyy HH:mm')}
              </Text>
            </Box>
          </HStack>
          <ScrollView style={{ maxHeight: '40vh' }}>
            <Box style={{ marginBottom: MARGIN_ITEMS }} testID='os-observacoes'>
              <Text variant="subtitle1" style={styles.title}>Observações</Text>
              <Text variant="subtitle1">
                {serviceOrder.currentOs?.observacoes}
              </Text>
            </Box>
          </ScrollView>
          <HStack items='center' mt={MARGIN_ITEMS} mb={16}>
            <MarkerIcon name='marker' style={{ fontSize: 24, paddingHorizontal: MARGIN_ITEMS }} color={theme.colors.grey} />
            <VStack style={{ marginBottom: 16, maxWidth: 'fit-content' }} testID='os-endereco'>
              <Text style={{ fontSize: 12, fontWeight: '700' }}>{address?.identificacao.toUpperCase()}</Text>
              <Text variant="subtitle1" style={{ fontSize: 12 }}>
                {!!address &&
                  (isMobile
                    ? `${address.endereco}, ${address.numero} - ${address.bairro}`
                    : `${address.endereco}, ${address.numero} - ${address.bairro} - ${address.cidade}/${address.estado} - ${address.cep}`
                  )}
              </Text>
            </VStack>
          </HStack>
          <Divider />
        </Box>
        {!isMobile && (
          isLoading ? <Loading /> : <OsAttendandes dateAdapter={dateAdapter} />
        )}
        <HStack>
          <Button
            color={theme.colors.greyDark}
            variant="text"
            testID='os-card-fechar'
            title="Fechar"
            onPress={handleClose}
            disabled={isLoading}
            style={{ marginLeft: 'auto' }}
          />
        </HStack>
      </Box>
    </ModalBase>
  )
}
