import { Decoder, ObjectDecoder } from '@/data/protocols'

export class EnvironmentDecoderAdapter implements ObjectDecoder, Decoder {
  decode (value: string): string {
    const byteValue = Buffer.from(value, 'base64')
    const stringValue = byteValue.toString('ascii')

    const result = stringValue.replace(/\\n/g, '\n')

    return result
  }

  objectDecode (inputObject: { [key: string]: string }): { [key: string]: string } {
    const resultObject: { [key: string]: string } = {}

    for (const key in inputObject) {
      if (Object.prototype.hasOwnProperty.call(inputObject, key)) {
        resultObject[key] = this.decode(inputObject[key])
      }
    }

    return resultObject
  }
}
