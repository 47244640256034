import { atom } from 'recoil'
import { SnackBarProps } from '@/presentation/components'

export const snackBarProviderState = atom<SnackBarProps[]>({
  key: 'snackBarProvider',
  default: []
})

export const errorAuthState = atom({
  key: 'errorAuthState',
  default: ''
})
