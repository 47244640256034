import { NotificationOnDBModel } from '@/domain/models'
import { atom } from 'recoil'

export const isOpenNotificationsModalState = atom({
  key: 'isOpenNotificationsModalState',
  default: false
})

export const notificationsState = atom<NotificationOnDBModel[]>({
  key: 'notificationsState',
  default: []
})
