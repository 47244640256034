import { GenericContactModel } from '@/domain/models'
import { atom } from 'recoil'

export const isLoadingContactEventState = atom({
  key: 'isLoadingContactEventState',
  default: false
})

export const contactListState = atom({
  key: 'contactListState',
  default: [] as GenericContactModel[]
})

export const contactListHasError = atom({
  key: 'contactListHasError',
  default: false
})
