import React from 'react'
import { TouchableOpacity } from 'react-native'
import { useRecoilState } from 'recoil'
import { MaterialIcons } from '@expo/vector-icons'
import { CreditCardModel } from '@/domain/models'
import { useMediaQueries, useThemeClient } from '@/presentation/hooks'
import { Checkbox, HStack, Text, VStack } from '@/presentation/components'
import { creditCardSelectedState } from '@/presentation/pages/Financial/components'

type CreditCardItemProps = {
  creditCard: CreditCardModel
  isEditable?: boolean
}

export const CreditCardItem: React.FC<CreditCardItemProps> = ({
  creditCard,
  isEditable = true
}) => {
  const { getThemeClient } = useThemeClient()
  const { isMobile } = useMediaQueries()
  const [creditCardSelected, setCreditCardSelected] = useRecoilState(creditCardSelectedState)

  const themeClient = getThemeClient()
  const isSelected = creditCard.numero === creditCardSelected?.numero

  return (
    <TouchableOpacity
      activeOpacity={0.8}
      style={{
        padding: isMobile ? 8 : 16,
        marginTop: 12,
        width: '100%',
        height: 'fit-content',
        borderRadius: 4,
        backgroundColor: isSelected ? `${themeClient.mainColor}24` : 'rgba(230, 230, 230, 1)'
      }}
      onPress={() => {
        isSelected
          ? setCreditCardSelected(null as unknown as CreditCardModel)
          : setCreditCardSelected(creditCard)
      }}
    >
      <HStack spacing={12} justify='between' items='center'>
        <VStack>
          <MaterialIcons name='credit-card' size={24} />
        </VStack>
        <VStack fill>
          <Text variant='subtitle1'>
            {creditCard.nome}
          </Text>
          <Text variant='subtitle1'>
            **** **** **** {creditCard.numero.slice(-4)}
          </Text>
        </VStack>
        {isEditable &&
          <Checkbox
            status={isSelected ? 'checked' : 'unchecked'}
            onPress={() => {
              isSelected
                ? setCreditCardSelected(null as unknown as CreditCardModel)
                : setCreditCardSelected(creditCard)
            }}
          />
        }
      </HStack>
    </TouchableOpacity>
  )
}
