import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    display: 'flex'
  },
  title: {
    fontWeight: '700',
    marginBottom: 8
  }
})
