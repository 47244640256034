import { atom } from 'recoil'
import { AreaInterest, EntidadeService, ProspectConfigsModel, Unidade } from '@/domain/models'

export const isLoadingState = atom({
  key: 'prospectSettingsLoadingState',
  default: true
})

export const initialProspectConfigsState = atom({
  key: 'initialProspectConfigsState',
  default: {} as ProspectConfigsModel
})

export const prospectConfigsState = atom({
  key: 'prospectConfigsState',
  default: {
    ativo: false,
    descricao: '',
    moduloLiberado: false,
    listaInteresse: [] as EntidadeService[]
  }
})

export const prospectInterestAreaState = atom({
  key: 'prospectInterestAreaState',
  default: [] as AreaInterest[]
})

export const prospectUnitsState = atom({
  key: 'prospectUnitsState',
  default: [] as Unidade[]
})
