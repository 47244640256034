import React, { useState } from 'react'
import { Image, StyleSheet, TouchableOpacity } from 'react-native'
import { useSetRecoilState } from 'recoil'
import * as ImagePicker from 'expo-image-picker'
import { ThemeModel } from '@/domain/models'
import { useImage, useTheme, useThemeClient } from '@/presentation/hooks'
import { selectedLogoState, themeState } from '@/presentation/pages/Theme/components'
import { Text, VStack } from '@/presentation/components'

type LogoSelectorProps = {
  fileName: string
}

export const LogoSelector: React.FC<LogoSelectorProps> = ({ fileName }) => {
  const theme = useTheme()
  const { base64toFile } = useImage()
  const { getThemeClient } = useThemeClient()
  const setTheme = useSetRecoilState(themeState)
  const setSelectedLogo = useSetRecoilState(selectedLogoState)
  const [logo, setLogo] = useState<string>()

  const themeClient = getThemeClient()
  const logoImage = !__DEV__ && fileName
    ? { uri: fileName }
    : require('../../../../../../assets/logo-white.svg')

  const handlePickImage = async (): Promise<void> => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1
    })

    if (!result.canceled) {
      const base64Logo = result.assets?.[0].uri?.replace('data:image/png;base64,', '')
      const file = base64toFile(base64Logo, `Logo_${new Date().getMilliseconds()}.png`)
      setSelectedLogo(file)
      setTheme(currentState => ({ ...currentState, theme: ({ ...currentState.theme, imageLogo: file.name }) as ThemeModel }))
      setLogo(result.assets?.[0].uri)
    }
  }

  return (
    <TouchableOpacity
      activeOpacity={0.8}
      onPress={handlePickImage}
      style={{
        backgroundColor: themeClient.mainColor,
        padding: 12,
        borderRadius: 4,
        marginBottom: 12
      }}
    >
      <VStack spacing={24} mb={24} items='center' fill>
        <Text style={{ fontWeight: '500', color: theme.colors.white }} variant='subtitle1'>
          Logo da empresa
        </Text>
          <Image
            style={{ ...styles.imageLogo, borderColor: `${theme.colors.white}CC` }}
            resizeMode='contain'
            source={logo ? { uri: logo } : logoImage}
          />
      </VStack>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  imageLogo: {
    width: 80,
    height: 40,
    borderWidth: 1,
    borderStyle: 'dashed'
  }
})
