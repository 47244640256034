import { GenericSectorModel } from '@/domain/models'
import { atom } from 'recoil'

export const isLoadingSectorEventsState = atom({
  key: 'isLoadingSectorEventsState',
  default: false
})

export const sectorListState = atom({
  key: 'sectorListState',
  default: [] as GenericSectorModel[]
})

export const sectorListHasError = atom({
  key: 'sectorListHasError',
  default: false
})
