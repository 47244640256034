import { RemoteSaveUnitConfig } from '@/data/usecases'
import { SaveUnitConfig } from '@/domain/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'

export const makeSaveUnitConfig = (): SaveUnitConfig => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<boolean | string>()

  return new RemoteSaveUnitConfig(url, httpClient)
}
