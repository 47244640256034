import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 20,
    width: 'fit-content'
  },
  title: {
    lineHeight: 13
  }
})
