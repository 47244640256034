import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  input: {
    outlineWidth: 0,
    marginLeft: 12,
    marginBottom: 12
  },
  inputColor: {
    marginLeft: -56
  }
})
