import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  bold: {
    fontWeight: '500'
  },
  title: {
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, 0.12)',
    paddingBottom: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  iconButton: {
    width: 32,
    height: 32
  }
})
