import { useRecoilValue } from 'recoil'
import { getToken } from '@firebase/messaging'
import { messaging } from '@/main/config/firebaseConfig'
import { localStorageState } from '@/presentation/components'
import { useNotify, usePermissions } from '@/presentation/hooks'
import { SaveNotificationToken } from '@/domain/usecases'
import env from '@/main/config/env'
import { makeEnvironmentDecoderAdapterFactory } from '@/main/adapters'

type FirebaseNotificationsProps = {
  serviceWorkerRegistration: () => Promise<void>
}

export const useFirebaseNotifications = (): FirebaseNotificationsProps => {
  const notify = useNotify()
  const permissions = usePermissions()
  const localStorageAdapter = useRecoilValue(localStorageState)
  const environmentDecoder = makeEnvironmentDecoderAdapterFactory()

  const firebaseConfig = {
    apiKey: env.FIREBASE_API_KEY,
    authDomain: env.FIREBASE_AUTH_DOMAIN,
    projectId: env.FIREBASE_PROJECT_ID,
    storageBucket: env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: env.FIREBASE_MESSAGING_SENDER_ID,
    appId: env.FIREBASE_APP_ID,
    firebaseVapiKey: env.FIREBASE_VAPID_KEY
  }

  const treatedFirebaseConfig = environmentDecoder.objectDecode(firebaseConfig)

  const serviceWorkerRegistration = async (): Promise<void> => {
    const notificationPermission = await permissions.requestNotification()
    let scriptURL = './firebase-messaging-sw.js'
    scriptURL += `?apiKey=${treatedFirebaseConfig.apiKey}`
    scriptURL += `&authDomain=${treatedFirebaseConfig.authDomain}`
    scriptURL += `&projectId=${treatedFirebaseConfig.projectId}`
    scriptURL += `&storageBucket=${treatedFirebaseConfig.storageBucket}`
    scriptURL += `&messagingSenderId=${treatedFirebaseConfig.messagingSenderId}`
    scriptURL += `&appId=${treatedFirebaseConfig.appId}`

    if ('serviceWorker' in navigator) {
      try {
        const serviceWorkerRegistration = await navigator.serviceWorker.register(scriptURL)
        notificationPermission.isGranted
          ? await handleGetToken(serviceWorkerRegistration)
          : notify.error(notificationPermission.errorMessage as string)
      } catch (error) {
        console.error(error)
        notify.error(`Falha no registro do Service Worker: ${error as string}`)
      }
    }
  }

  const handleGetToken = async (serviceWorkerRegistration: ServiceWorkerRegistration): Promise<void> => {
    try {
      const messagingResolve = await messaging()
      if (messagingResolve) {
        getToken(messagingResolve, {
          vapidKey: treatedFirebaseConfig.firebaseVapiKey,
          serviceWorkerRegistration
        })
          .then((currentToken) => {
            currentToken
              ? localStorageAdapter.set(SaveNotificationToken.RN.LOCAL_STORAGE_TOKEN, currentToken)
              : notify.error('Nenhum token de registro disponível. Solicite permissão para gerar um.')
          })
          .catch(async (error: Error) => {
            if (error.message === "Failed to execute 'subscribe' on 'PushManager': Subscription failed - no active Service Worker") {
              return await handleGetToken(serviceWorkerRegistration)
            }
            notify.error(`Ocorreu um erro ao recuperar o token. Erro: ${error.message}`)
          })
      }
    } catch (error) {
      console.error(error)
    }
  }

  return {
    serviceWorkerRegistration
  }
}
