import React from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Unidade } from '@/domain/models'
import { Box, Button, currentUnitState, Select, unitsState } from '@/presentation/components'
import { useTheme, useThemeClient } from '@/presentation/hooks'
import { isOpenMenuUnitsState } from './atom'

type UnitsSelectorProps = {
  disabled: boolean
}

export const UnitsSelector: React.FC<UnitsSelectorProps> = (props) => {
  const theme = useTheme()
  const { getThemeClient } = useThemeClient()
  const [isMenuOpen, setMenuOpen] = useRecoilState(isOpenMenuUnitsState)
  const [currentUnit, setCurrentUnit] = useRecoilState(currentUnitState)
  const units = useRecoilValue(unitsState)

  const themeClient = getThemeClient()

  const handleSelectUnit = (selectedUnit: Unidade): void => {
    setCurrentUnit(selectedUnit)
    setMenuOpen(false)
  }

  return (
    <Box>
      <Button
        disabled={props.disabled}
        color={themeClient.buttonSave}
        style={{ margin: 12, borderRadius: 15 }}
        tintColor={theme.colors.white}
        onPress={() => setMenuOpen(true)}
        loading={props.disabled || !currentUnit}
        loadingIndicatorPosition='overlay'
        title={currentUnit?.codigoUnidade?.toString().padStart(4, '0')}
      />
      <Select.Modal
        title='Selecione uma unidade'
        isOpen={isMenuOpen}
        onClose={() => setMenuOpen(false)}
        items={units?.map(unit => ({
          id: unit.codigoUnidade,
          descricao: `${unit.codigoUnidade.toString().padStart(4, '0')} - ${unit.nomeUnidade}`,
          data: unit,
          isSelected: unit.codigoUnidade === currentUnit?.codigoUnidade
        }))}
        onSelect={(_, unit) => { handleSelectUnit(unit) }}
      />

    </Box>
  )
}
