import React from 'react'
import { NavigationPagesProps } from '@/presentation/components'
import { RecoveryPassword } from '@/presentation/pages/RecoveryPassword'
import { makeUpdateDefaultPassword, makeValidatePasswordRecovery } from '../usecases'

export const makeRecoveryPasswordFactory = ({ navigation }: NavigationPagesProps): JSX.Element => {
  return (
    <RecoveryPassword
      navigation={navigation}
      updateDefaultPassword={makeUpdateDefaultPassword()}
      validatePasswordRecovery={makeValidatePasswordRecovery()}
    />
  )
}
