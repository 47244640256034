import React from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { Box, Button, Text, ModalBase } from '@/presentation/components'
import { useTheme } from '@/presentation/hooks'
import { creditCardState, isEditableState, isOpenEditConfirmationState } from '@/presentation/pages/Cartao/components'
import { styles } from './styles'

export const ModalModalEditConfirmation: React.FC = () => {
  const theme = useTheme()
  const [isOpen, setOpen] = useRecoilState(isOpenEditConfirmationState)
  const setCreditCard = useSetRecoilState(creditCardState)
  const setEditable = useSetRecoilState(isEditableState)

  const handleClose = (): void => {
    setOpen(false)
  }

  const handleEdit = (): void => {
    setEditable(true)
    setOpen(false)
    setCreditCard(currentState => ({
      ...currentState,
      numero: '',
      ccv: ''
    }))
  }

  return (
    <ModalBase visible={isOpen} onClose={handleClose}>
      <Box style={styles.container}>
        <Text variant="subtitle1" style={{ fontWeight: '500' }}>Deseja realmente alterar os dados do cartão?</Text>
        <Text variant="subtitle1">O número e CVC do cartão deverão ser informados novamente.</Text>

        <Box style={styles.buttonArea}>
          <Button
            color={theme.colors.shadow}
            tintColor={theme.colors.white}
            variant="text"
            title="CANCELAR"
            testID='cartao-cancelar-edicao'
            onPress={handleClose}
          />
          <Button
            title="EDITAR"
            testID='cartao-editar'
            onPress={handleEdit}
            tintColor={theme.colors.white}
            color={theme.colors.success}
          />
        </Box>
      </Box>
    </ModalBase>
  )
}
