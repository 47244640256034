import { OsAttendanceModel } from '@/domain/models'
import { GetOsAttendance } from '@/domain/usecases'
import { RemoteGetOsAttendance } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'

export const makeGetOsAttendance = (): GetOsAttendance => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<OsAttendanceModel[]>()

  return new RemoteGetOsAttendance(url, httpClient)
}
