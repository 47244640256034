import { ClientModel } from '@/domain/models'
import { GetClientByCode } from '@/domain/usecases'
import { RemoteGetClientByCode } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'

export const makeGetClientByCode = (): GetClientByCode => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<ClientModel>()

  return new RemoteGetClientByCode(url, httpClient)
}
