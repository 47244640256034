import { UpdatePasswordParams } from '@/domain/usecases'
import { atom } from 'recoil'

export const isOpenUpdatePasswordState = atom({
  key: 'isOpenUpdatePasswordState',
  default: false
})

export const showPasswordState = atom({
  key: 'showPasswordState',
  default: false
})

export const isLoadingUpdatePasswordState = atom({
  key: 'isLoadingUpdatePasswordState',
  default: false
})

export type UpdateSettingsStateProps = UpdatePasswordParams & {
  isLoading: boolean
  confirmacaoSenha: string
}

export const updateSettingsState = atom({
  key: 'updateSettingsState',
  default: {
    cliente: 0,
    senhaAtual: '',
    novaSenha: '',
    confirmacaoSenha: ''
  }
})

