import React, { useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { ScrollView } from 'react-native-gesture-handler'
import { PayWithCreditCard } from '@/domain/usecases'
import { useAuth, useErrorHandler, useFormat, useNotify, useTheme } from '@/presentation/hooks'
import { Button, Chip, Loading, Text, VStack } from '@/presentation/components'
import { CreditCardItem, creditCardSelectedState, isLoadingCreditCardsState, isOpenAddOtherCreditCardState, isOpenCreditCardsState, isOpenTicketViewState, ticketViewState } from '@/presentation/pages/Financial/components'
import { styles } from './styles'

type PayCreditCardsProps = {
  payWithCreditCard: PayWithCreditCard
}

export const PayCreditCards: React.FC<PayCreditCardsProps> = ({ payWithCreditCard }) => {
  const theme = useTheme()
  const notify = useNotify()
  const { getClient } = useAuth()
  const { formatCurrency } = useFormat()
  const [isPaymentConfirmation, setPaymentConfirmation] = useState(false)
  const [isLoading, setLoading] = useRecoilState(isLoadingCreditCardsState)
  const creditCardSelected = useRecoilValue(creditCardSelectedState)
  const setOpen = useSetRecoilState(isOpenCreditCardsState)
  const setOpenOtherCreditCard = useSetRecoilState(isOpenAddOtherCreditCardState)
  const setOpenTicketView = useSetRecoilState(isOpenTicketViewState)
  const ticketView = useRecoilValue(ticketViewState)
  const handlerError = useErrorHandler()

  const client = getClient()

  const handlePay = (): void => {
    setLoading(true)

    payWithCreditCard
      .pay({ duplicata: ticketView, cartao: creditCardSelected })
      .then(notify.info)
      .catch(handlerError)
      .finally(() => {
        setOpenTicketView(false)
        setLoading(false)
        handleClose()
      })
  }

  const handleClose = (): void => {
    setOpen(false)
    setPaymentConfirmation(false)
  }

  const handleOpenOtherCreditCard = (): void => {
    setOpenTicketView(false)
    setOpenOtherCreditCard(true)
    setPaymentConfirmation(false)
  }

  return (
    isLoading
      ? <Loading text='Aguarde enquanto o pagamento é processado' />
      : <VStack style={styles.container} spacing={8} mb={12}>
        <Chip
          text={isPaymentConfirmation
            ? 'Confirmação de pagamento'
            : client.listaCartoes.length > 0
              ? 'Selecione o cartão de crédito'
              : 'Nenhum cartão cadastrado'
            }
          color={theme.colors.info}
          variant='outlined'
          containerStyle={{ marginBottom: 8 }}
        />
        {isPaymentConfirmation
          ? <VStack spacing={8}>
              <Text style={{ fontWeight: '500' }}>Confirma os dados para pagamento com o cartão final {creditCardSelected?.numero?.slice(-4)} no valor de {formatCurrency(ticketView.valorAtualizado)}?</Text>
              <CreditCardItem isEditable={false} creditCard={creditCardSelected} />
            </VStack>
          : <ScrollView style={{ maxHeight: '25vh', flex: 1 }}>
              {client.listaCartoes?.map(creditCard =>
                <CreditCardItem key={creditCard.numero.replace(' ', '')} creditCard={creditCard} />
              )}
            </ScrollView>
        }

        <VStack mt={12} spacing={8}>
          {client.listaCartoes.length > 0 &&
            <Button
              color={creditCardSelected ? theme.colors.white : theme.colors.success}
              contentContainerStyle={{ backgroundColor: creditCardSelected ? theme.colors.success : theme.colors.background }}
              variant='outlined'
              title={isPaymentConfirmation ? 'Confirmar Pagamento' : 'Pagar' }
              disabled={!creditCardSelected}
              onPress={() => isPaymentConfirmation ? handlePay() : setPaymentConfirmation(true)}
            />
          }
          {!isPaymentConfirmation &&
            <Button
              color={theme.colors.greyDark}
              variant='outlined'
              title='adicionar novo cartão'
              onPress={handleOpenOtherCreditCard}
            />
          }
          <Button
            color={theme.colors.greyDark}
            variant='outlined'
            title='Outras opções de Pagto'
            onPress={handleClose}
          />
        </VStack>
      </VStack>
  )
}
