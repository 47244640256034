import React, { useCallback } from 'react'
import { SafeAreaView } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { useSetRecoilState } from 'recoil'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { GetNFs, GetNFsParams, OpenDanfe, OpenLeaseReceipt, OpenNfse } from '@/domain/usecases'
import { DateAdapter } from '@/data/protocols'
import { useAuth, useErrorHandler, useMediaQueries, useTheme, useThemeClient } from '@/presentation/hooks'
import { nfsState, SideFilters, NFProduct, NFService, LeaseReceipt, isLoadingNfState, leaseReceiptState, nfState, nfsConfigState } from '@/presentation/pages/NotaFiscal/components/'
import { styles } from './styles'

const Tab = createMaterialTopTabNavigator()

type NotaFiscalProps = {
  getNFs: GetNFs
  openDanfe: OpenDanfe
  openNfse: OpenNfse
  openLeaseReceipt: OpenLeaseReceipt
  dateAdapter: DateAdapter
}

export const NotaFiscal: React.FC<NotaFiscalProps> = ({
  getNFs,
  openDanfe,
  openNfse,
  openLeaseReceipt,
  dateAdapter
}) => {
  const theme = useTheme()
  const { isMobile } = useMediaQueries()
  const { getClient } = useAuth()
  const { getThemeClient } = useThemeClient()
  const handleError = useErrorHandler()
  const setNFsConfigs = useSetRecoilState(nfsConfigState)
  const setNFs = useSetRecoilState(nfState)
  const setNFSs = useSetRecoilState(nfsState)
  const setLeaseReceipts = useSetRecoilState(leaseReceiptState)
  const setLoading = useSetRecoilState(isLoadingNfState)

  const client = getClient()
  const themeClient = getThemeClient()

  useFocusEffect(
    useCallback(() => {
      setLoading(true)

      const getNFsParams: GetNFsParams = {
        cliCodigo: client.codCliente,
        dtInicial: dateAdapter.format(dateAdapter.monthAgo(GetNFs.RN.DEFAULT_MONTHS_AGO), 'DD-MM-YYYY'),
        dtFinal: dateAdapter.format(dateAdapter.today(), 'DD-MM-YYYY')
      }

      getNFs
        .get(getNFsParams)
        .then(nfResult => {
          setNFsConfigs({
            ocultaInfos: nfResult.ocultaInfos,
            ocultaLink: nfResult.ocultaLink
          })
          setLeaseReceipts(nfResult.arrRecibos)
          setNFs(nfResult.arrNF)
          setNFSs(nfResult.arrNFS)
        })
        .catch(handleError)
        .finally(() => setLoading(false))
    }, [])
  )

  return (
    <SafeAreaView style={styles.container}>
      <SideFilters dateAdapter={dateAdapter} getNFs={getNFs}/>

      <Tab.Navigator
        screenOptions={{
          tabBarItemStyle: {},
          tabBarLabelStyle: { color: theme.colors.background },
          tabBarIndicatorStyle: { backgroundColor: theme.colors.white },
          tabBarStyle: { backgroundColor: themeClient.mainColor, alignItems: 'center' }
        }}
      >
        <Tab.Screen name='Serviços' component={() => NFService({ openNfse, dateAdapter })} />
        <Tab.Screen name='Produtos' component={() => NFProduct({ openDanfe, dateAdapter })} />
        <Tab.Screen name={isMobile ? 'Recibos' : 'Recibos de locação'} component={() => LeaseReceipt({ openLeaseReceipt, dateAdapter })} />
      </Tab.Navigator>
    </SafeAreaView>
  )
}
