import React, { useCallback } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useAuth } from '@/presentation/hooks'
import { Box, Text, ModalBase, isOpenUserMenuState, VStack, Divider, Menu, isOpenLogoutState, isOpenUpdatePasswordState } from '@/presentation/components'
import { styles } from './styles'

export const UserMenu: React.FC = () => {
  const { getClient, isAdmin } = useAuth()
  const [isOpen, setOpen] = useRecoilState(isOpenUserMenuState)
  const setOpenLogout = useSetRecoilState(isOpenLogoutState)
  const setOpenUpdatePassword = useSetRecoilState(isOpenUpdatePasswordState)

  const client = getClient()

  const handleClose = (): void => setOpen(false)

  const handleOpenUpdatePassword = useCallback((): void => {
    handleClose()
    setOpenUpdatePassword(true)
  }, [])

  const handleOnLogout = useCallback((): void => {
    handleClose()
    setOpenLogout(true)
  }, [])

  return (
    <ModalBase visible={isOpen} onClose={handleClose} >
      <Box style={styles.container}>
        <VStack spacing={4} p={8} items='center'>
          <Text variant='subtitle2' style={styles.userTitle}>{client?.nomeCliente}</Text>
          <Text variant='caption'>{client?.cpfCnpj}</Text>
        </VStack>
        <Divider />
        {!isAdmin() &&
          <Menu.Item
            titleStyle={styles.itemMenu}
            title="Alterar Senha"
            testID='button-alterar-senha'
            onPress={handleOpenUpdatePassword}
            leadingIcon={props => <MaterialCommunityIcons name="form-textbox-password" {...props} />}
          />
        }
        <Menu.Item
          titleStyle={styles.itemMenu}
          title="Sair"
          testID='update-senha-sair'
          onPress={handleOnLogout}
          leadingIcon={props => <MaterialCommunityIcons name="logout" {...props} />}
        />
      </Box>
    </ModalBase>
  )
}
