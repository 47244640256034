import { GetSigmaCloudKeys } from '@/domain/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import { RemoteGetSigmaCloudKeys } from '@/data/usecases/events'
import { SigmaCloudKeyResult } from '@/domain/models'
import env from '@/main/config/env'

export const makeGetSigmaCloudKeysFactory = (): GetSigmaCloudKeys => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<SigmaCloudKeyResult>()

  return new RemoteGetSigmaCloudKeys(url, httpClient)
}
