import { atom } from 'recoil'

export const isLoadingRecoveryPasswordState = atom({
  key: 'isLoadingRecoveryPasswordState',
  default: false
})

export const isOpenRecoveryPasswordState = atom({
  key: 'isOpenRecoveryPasswordState',
  default: true
})

export const recoverySettingsState = atom({
  key: 'recoverySettingsState',
  default: {
    cliente: 0,
    novaSenha: '',
    confirmacaoSenha: ''
  }
})
