import { GenericEventModel } from '@/domain/models'
import { atom } from 'recoil'

export const isLoadingMonitoringEventState = atom({
  key: 'isLoadingMonitoringEventState',
  default: false
})

export const monitoringEventListState = atom({
  key: 'monitoringEventListState',
  default: [] as GenericEventModel[]
})

export const monitoringListHasError = atom({
  key: 'monitoringListHasError',
  default: false
})
