import React from 'react'
import { StyleSheet } from 'react-native'
import { Card } from 'react-native-paper'
import { useSetRecoilState } from 'recoil'
import { useMediaQueries, useTheme } from '@/presentation/hooks'
import { Button, HStack, Text } from '@/presentation/components'
import { modalOnDiscardChangesThemeState } from '../ModalOnDiscardChanges/atom'
import { modalOnSaveChangeThemeState } from '../ModalOnSaveChanges/atom'

type ActionsButtonsProps = {
  firstRecording: boolean
}

export const ActionsButtons: React.FC<ActionsButtonsProps> = ({ firstRecording }) => {
  const theme = useTheme()
  const { isMobile } = useMediaQueries()
  const setOpenDiscardChanges = useSetRecoilState(modalOnDiscardChangesThemeState)
  const setOpenSaveChanges = useSetRecoilState(modalOnSaveChangeThemeState)

  const handleSubmit = (): void => {
    setOpenSaveChanges(true)
  }

  const handleDiscardChanges = (): void => {
    setOpenDiscardChanges(true)
  }

  return (
    <>
      <Card style={styles.cardContainer} elevation={2}>
        <HStack reverse spacing={6} items='center'>
          <Button
            title='Gravar'
            color={theme.colors.success}
            tintColor={theme.colors.white}
            onPress={handleSubmit}
            />
          {firstRecording
            ? <Text style={{ marginRight: 12, fontWeight: '500' }}>
                {isMobile 
                  ? 'Configuração inicial'
                  : 'Tema inicial, unidade ainda não possui tema gravado'
                }
              </Text>
            : <Button
              variant='text'
              title='Desfazer alterações'
              color={theme.colors.grey}
              onPress={handleDiscardChanges}
              />
          }
        </HStack>
      </Card>
    </>
  )
}

const styles = StyleSheet.create({
  cardContainer: {
    margin: 12,
    padding: 8,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  }
})
