import { GetSigmaCloudSectors } from '@/domain/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import { RemoteGetSigmaCloudSectors } from '@/data/usecases/events'
import { SigmaSectorModel } from '@/domain/models'
import env from '@/main/config/env'

export const makeGetSigmaCloudSectorsFactory = (): GetSigmaCloudSectors => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<SigmaSectorModel[]>()

  return new RemoteGetSigmaCloudSectors(url, httpClient)
}
