import { HttpClient, HttpStatusCode } from '@/data/protocols/http'
import { AccessDeniedError, UnexpectedError } from '@/domain/errors'
import { SigmaCloudKeyResponseModel, SigmaCloudKeyResult } from '@/domain/models'
import { GetSigmaCloudKeys, GetSigmaCloudKeysParams } from '@/domain/usecases'

export class RemoteGetSigmaCloudKeys implements GetSigmaCloudKeys {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<SigmaCloudKeyResult>
  ) { }

  async getKeys (params: GetSigmaCloudKeysParams): Promise<SigmaCloudKeyResponseModel[]> {
    const { body, statusCode } = await this.httpClient.request({
      url: `${this.url}/api/sigmacloud/lista-chaves`,
      method: 'get',
      params
    })

    switch (statusCode) {
      case HttpStatusCode.ok:
        return body
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}
