import { GenericSectorModel, SigmaSectorModel } from '@/domain/models'
import { AccessDeniedError, UnexpectedError } from '@/domain/errors'
import { HttpClient, HttpStatusCode } from '@/data/protocols/http'
import { GetSigmaDeskSectors, GetSigmaDeskSectorParams } from '@/domain/usecases'

export class RemoteGetSigmaDeskSectors implements GetSigmaDeskSectors {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<SigmaSectorModel[]>
  ) { }

  async getSector (params: GetSigmaDeskSectorParams): Promise<GenericSectorModel[]> {
    const { body, statusCode } = await this.httpClient.request({
      url: `${this.url}/api/SetorSigma`,
      method: 'get',
      params
    })

    switch (statusCode) {
      case HttpStatusCode.ok:
        return this.mapToGeneric(body)
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }

  mapToGeneric (sectors: SigmaSectorModel[]): GenericSectorModel[] {
    return sectors?.map(sector => ({
      nome: sector.descProduto,
      idEquipamento: sector.idEvento,
      codigoCliente: sector.cdCliente.toString(),
      codigoZona: sector.cdEvento.toString(),
      observacao: sector.obs
    }))
  }
}
