import React, { useState } from 'react'
import { useRecoilState } from 'recoil'
import { ScrollView } from 'react-native'
import { MenuAccessPermissionsConfigs } from '@/domain/models'
import { CardSwitch, Box, Loading } from '@/presentation/components'
import { ActionsButtons, menuAccessPermissionsConfigsState } from '@/presentation/pages/Settings/components'

export const MenuAccessPermissions: React.FC = () => {
  const [menuAccessPermissionsConfigs, setMenuAccessPermissionsConfig] = useRecoilState(menuAccessPermissionsConfigsState)
  const [initialMenuAccessPermissionsConfigs] = useState(menuAccessPermissionsConfigs)

  const handleChangeSetting = (param: keyof MenuAccessPermissionsConfigs, value: boolean): void => {
    setMenuAccessPermissionsConfig(currentState => ({ ...currentState, [param]: value }))
  }

  const hasChanged = (): boolean => {
    return JSON.stringify(initialMenuAccessPermissionsConfigs) !== JSON.stringify(menuAccessPermissionsConfigs)
  }

  return (
    <Box style={{ flex: 1 }}>
      {!menuAccessPermissionsConfigs
        ? <Loading />
        : <>
          <ScrollView>
            <CardSwitch
              text='Aceite Digital'
              value={menuAccessPermissionsConfigs.aceiteDigital}
              handleSwitch={() => handleChangeSetting('aceiteDigital', !menuAccessPermissionsConfigs.aceiteDigital)}
            />
            <CardSwitch
              text='Contrato'
              value={menuAccessPermissionsConfigs.contrato}
              handleSwitch={() => handleChangeSetting('contrato', !menuAccessPermissionsConfigs.contrato)}
            />
            <CardSwitch
              text='Cartão de Crédito'
              value={menuAccessPermissionsConfigs.cartao}
              handleSwitch={() => handleChangeSetting('cartao', !menuAccessPermissionsConfigs.cartao)}
            />
            <CardSwitch
              text='Documentos Úteis'
              value={menuAccessPermissionsConfigs.documentosUteis}
              handleSwitch={() => handleChangeSetting('documentosUteis', !menuAccessPermissionsConfigs.documentosUteis)}
            />
            <CardSwitch
              text='Financeiro'
              value={menuAccessPermissionsConfigs.boleto}
              handleSwitch={() => handleChangeSetting('boleto', !menuAccessPermissionsConfigs.boleto)}
            />
            <CardSwitch
              text='Notas Fiscais'
              value={menuAccessPermissionsConfigs.notaFiscal}
              handleSwitch={() => handleChangeSetting('notaFiscal', !menuAccessPermissionsConfigs.notaFiscal)}
            />
            <CardSwitch
              text='Monitoramento'
              value={menuAccessPermissionsConfigs.monitoramento}
              handleSwitch={() => {
                handleChangeSetting('monitoramento', !menuAccessPermissionsConfigs.monitoramento)
              }}
            />
            <CardSwitch
              text='Ordens de Serviços'
              value={menuAccessPermissionsConfigs.os}
              handleSwitch={() => handleChangeSetting('os', !menuAccessPermissionsConfigs.os)}
            />
            <CardSwitch
              text='Rastreamento'
              value={menuAccessPermissionsConfigs.rastreamento}
              handleSwitch={() => handleChangeSetting('rastreamento', !menuAccessPermissionsConfigs.rastreamento)}
            />
            <CardSwitch
              text='SAC'
              value={menuAccessPermissionsConfigs.sac}
              handleSwitch={() => handleChangeSetting('sac', !menuAccessPermissionsConfigs.sac)}
            />
            <CardSwitch
              text='Telefones Úteis'
              value={menuAccessPermissionsConfigs.telefonesUteis}
              handleSwitch={() => handleChangeSetting('telefonesUteis', !menuAccessPermissionsConfigs.telefonesUteis)}
            />
            <CardSwitch
              text='Viagem'
              value={menuAccessPermissionsConfigs.viagem}
              handleSwitch={() => handleChangeSetting('viagem', !menuAccessPermissionsConfigs.viagem)}
            />
          </ScrollView>
          {hasChanged() && <ActionsButtons />}
        </>
      }
    </Box>
  )
}
