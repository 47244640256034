import { AddTravelAlertOsResponsible, AddTravelAlertResponsibleType } from '@/domain/usecases'
import { atom } from 'recoil'

export const travelAlertAddResponsibleState = atom({
  key: 'travelAlertAddResponsibleState',
  default: {
    isOpen: false,
    typeCurrent: AddTravelAlertResponsibleType.AUTHORIZED
  }
})

export const addResponsibleState = atom<AddTravelAlertOsResponsible>({
  key: 'addResponsibleState',
  default: {
    id: 0,
    nome: '',
    endereco: '',
    telefone1: '',
    telefone2: '',
    observacao: ''
  }
})
