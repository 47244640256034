export type CreditCardModel = {
  ccv: string
  codCliente: number
  email: string
  expiracao: string
  mask: string
  nome: string
  numero: string
  type: CardFlagsTypes
}

export type CardFlagsTypes = 'mastercard' | 'visa' | 'amex' | 'diners' | 'discover' | 'unknown'

export const cardMaskByFlag = {
  mastercard: '9999 9999 9999 9999',
  visa: '9999 9999 9999 9999',
  amex: '9999 999999 99999',
  diners: '9999 9999 9999 99',
  discover: '9999 9999 9999 9999',
  unknown: '9999 9999 9999 9999'
}
