
import { CardFlagsTypes } from '@/domain/models'
import { atom } from 'recoil'

export const isLoadingState = atom({
  key: 'isLoadingCreditCardState',
  default: true
})

export const isEditableState = atom({
  key: 'isEditableCreditCardState',
  default: true
})

export const creditCardState = atom({
  key: 'creditCardState',
  default: {
    ccv: '',
    codCliente: 0,
    email: '',
    expiracao: '',
    mask: '9999 9999 9999 9999',
    nome: '',
    numero: '',
    type: 'unknown' as CardFlagsTypes
  }
})
