import { makeAxiosHttpClient } from '@/main/factories/http'
import { ClaimsResult } from '@/domain/models'
import { RemoteGetSacList } from '@/data/usecases/sac'
import { GetSacList } from '@/domain/usecases'
import env from '@/main/config/env'

export const makeGetSacList = (): GetSacList => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<ClaimsResult>()
  return new RemoteGetSacList(url, httpClient)
}
