import { SaveRedesSociais } from '@/domain/usecases'
import { RedesSociaisModel } from '@/domain/models'
import { makeAxiosHttpClient } from '@/main/factories/http'
import { RemoteSaveRedesSociais } from '@/data/usecases'
import env from '@/main/config/env'

export const makeSaveRedesSociais = (): SaveRedesSociais => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<RedesSociaisModel>()

  return new RemoteSaveRedesSociais(url, httpClient)
}
