import React from 'react'
import AntDesignIcon from '@expo/vector-icons/AntDesign'
import { useMediaQueries, useTheme, useThemeClient } from '@/presentation/hooks'
import { HStack, Text, Button } from '@/presentation/components'

type PageHeaderProps = {
  text: string
  icon: React.ReactNode
  children?: React.ReactNode
  openFilters?: () => void
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  text,
  children,
  icon,
  openFilters
}) => {
  const theme = useTheme()
  const { isMobile } = useMediaQueries()
  const { getThemeClient } = useThemeClient()

  const themeClient = getThemeClient()

  return (
    <HStack items='center' justify='between' mb={8}>
      <HStack items='center' spacing={8}>
        {icon}
        <Text color={theme.colors.grey} variant='subtitle1'>{text}</Text>
      </HStack>
      {children}
      {openFilters &&
        <Button
          title='Filtros'
          trailing={props => <AntDesignIcon name='filter' {...props} size={isMobile ? 14 : 18} />}
          color={themeClient.mainColor}
          onPress={openFilters}
          testID='button-filtros'
        />
      }
    </HStack>
  )
}
