import React from 'react'
import { Linking, Platform } from 'react-native'
import { FontAwesome5 as Icon } from '@expo/vector-icons'
import { RedesSociaisModel } from '@/domain/models'
import { useMediaQueries, useTheme } from '@/presentation/hooks'
import { IconButton, HStack, phoneModalNumberState, phoneModalState } from '@/presentation/components'
import { useSetRecoilState } from 'recoil'

type SocialMidiasProps = {
  socialMidias: RedesSociaisModel
  color?: string
  size?: number
}

export const SocialMidias: React.FC<SocialMidiasProps> = ({ socialMidias, color, size }) => {
  const { isMobile } = useMediaQueries()
  const theme = useTheme()
  const setModalOpen = useSetRecoilState(phoneModalState)
  const setModalNumber = useSetRecoilState(phoneModalNumberState)

  const handleOpenModal = (): void => {
    setModalOpen(true)
  }

  const callNumber = (phone: string): void => {
    let phoneNumber = phone.replace(' ', '')
    if (!['android', 'web'].includes(Platform.OS)) {
      phoneNumber = `telprompt:${phone}`
      setModalNumber(phoneNumber)
    } else {
      phoneNumber = `tel:${phone}`
      setModalNumber(phoneNumber)
    }

    if (isMobile) {
      Linking.canOpenURL(phoneNumber)
        .then(async (supported: boolean) => {
          if (!supported) {
            console.error('não suportado')
          } else {
            return await Linking.openURL(phoneNumber)
          }
        })
        .catch(console.log)
    } else {
      handleOpenModal()
    }
  }

  const handleClick = (key: keyof RedesSociaisModel): void => {
    if (key === 'phone') {
      callNumber(socialMidias[key])
    } else if (key === 'whatsapp') {
      void Linking.canOpenURL(`whatsapp://send?phone=55${socialMidias[key].replace(/[\s-]/g, '')}, '')}`)
        .then(async () => {
          window.open(`https://api.whatsapp.com/send?phone=55${socialMidias[key].replace(/[\s-]/g, '')}`)
        })
    } else {
      void Linking.canOpenURL(socialMidias[key])
        .then(async () => await Linking.openURL(socialMidias[key]))
    }
  }

  return (
    <HStack spacing={8} items='center' justify='center' wrap>
      {Object.keys(socialMidias).map((props: keyof RedesSociaisModel) => (
        !!socialMidias[props] &&
        (<IconButton
          key={`${socialMidias[props].toString().replaceAll(' ', '')}-icon-button`}
          color={color ?? theme.colors.error}
          icon={<Icon key={`${socialMidias[props].toString().replaceAll(' ', '')}-icon`} color={color ?? theme.colors.white} size={size ?? 24} name={props}/>}
          onPress={() => handleClick(props)}
        />)
      ))}
    </HStack>
  )
}
