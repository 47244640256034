import { GetUserByDocument, GetUserByDocumentParams } from '@/domain/usecases/'
import { HttpClient, HttpStatusCode } from '@/data/protocols/http'
import { AccessDeniedError, InvalidCredentialsError, UnexpectedError, UnexpectedResultFormatError } from '@/domain/errors'
import { UserModel } from '@/domain/models'

export class RemoteGetUser implements GetUserByDocument {
  constructor (
    private readonly url: string,
    private readonly httpCliente: HttpClient<UserModel[]>
  ) {}

  async getUsersByDocument (params: GetUserByDocumentParams): Promise<UserModel[]> {
    const { statusCode, body } = await this.httpCliente.request({
      method: 'post',
      url: `${this.url}/api/portal/acessofacilitado`,
      body: params
    })

    switch (statusCode) {
      case HttpStatusCode.ok:
        if (!body) {
          throw new UnexpectedResultFormatError()
        }
        if (!body.length) {
          throw new InvalidCredentialsError()
        }
        return body
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}
