import { AddTravelAlertOs } from '@/domain/usecases'
import { RemoteAddTravelAlertOs } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import { makeTravelAlertBuilder } from '@/main/builders'
import env from '@/main/config/env'

export const makeAddTravelAlertOs = (): AddTravelAlertOs => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<number | string>()

  return new RemoteAddTravelAlertOs(
    url,
    httpClient,
    makeTravelAlertBuilder()
  )
}
