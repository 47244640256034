import React, { useCallback } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { SaveUnitConfig } from '@/domain/usecases'
import { Box, Button, Text, ModalBase, Loading } from '@/presentation/components'
import { useNotify, useSettings, useTheme } from '@/presentation/hooks'
import { configsState, hasErrorGeneralConfigState } from '@/presentation/pages/Settings/components'
import { isOpenSaveConfigState, isLoadingSaveConfigState } from './atom'
import { styles } from './styles'
import { GeneralConfigs } from '@/domain/models'

type ModalOnSaveChangesProps = {
  saveUnitConfig: SaveUnitConfig
}

export const ModalOnSaveChanges: React.FC<ModalOnSaveChangesProps> = ({ saveUnitConfig }) => {
  const theme = useTheme()
  const notify = useNotify()
  const { startConfig, isValidMail } = useSettings()
  const currentConfig = useRecoilValue(configsState)
  const [isLoading, setLoading] = useRecoilState(isLoadingSaveConfigState)
  const [isOpen, setOpen] = useRecoilState(isOpenSaveConfigState)
  const setError = useSetRecoilState(hasErrorGeneralConfigState)

  const generalConfigValidation = (): boolean => {
    const errors: Array<keyof GeneralConfigs> = []
    currentConfig.email && !isValidMail(currentConfig.email) && errors.push('email')
    currentConfig.emailViagem && currentConfig.enviaEmailViagem && !isValidMail(currentConfig.emailViagem) && errors.push('emailViagem')
    currentConfig.emailSac && !isValidMail(currentConfig.emailSac) && errors.push('emailSac')
    if (errors.length) {
      notify.error('Preencha os campos com e-mail válido!')
      setError(errors)
      setOpen(false)
    }

    return !errors.length
  }

  const handleSaveChanges = useCallback((): void => {
    if (!generalConfigValidation()) { return }

    setLoading(true)
    saveUnitConfig
      .save(currentConfig)
      .then(hasUpdated => {
        if (hasUpdated) {
          notify.show(`As configurações da unidade ${currentConfig.uniCodigo.toString().padStart(4, '0')} foram gravadas com sucesso`)
          startConfig(currentConfig)
          handleClose()
        } else {
          notify.show(`Não foi possível gravar as configurações da unidade ${currentConfig.uniCodigo}, tente novamente`, { variant: 'error' })
        }
      })
      .catch(error => {
        notify.show(error.message, { variant: 'error' })
        handleClose()
      })
      .finally(() => setLoading(false))
  }, [currentConfig])

  const handleClose = (): void => setOpen(false)

  return (
    <ModalBase visible={isOpen} onClose={handleClose}>
      {isLoading
        ? <Loading text='Gravando configurações da unidade' />
        : <Box style={styles.container}>
          <Text variant="subtitle1">Deseja realmente gravar as alterações?</Text>

          <Box style={styles.buttonArea}>
            {false && <Button
              color={theme.colors.shadow}
              variant="outlined"
              title="REPLICAR EM UNIDADES"
              onPress={handleClose}
              disabled={isLoading}
            />}
            <Button
              color={theme.colors.shadow}
              variant="text"
              title="CANCELAR"
              onPress={handleClose}
            />
            <Button
              title="GRAVAR"
              color={theme.colors.success}
              tintColor={theme.colors.white}
              onPress={handleSaveChanges}
              disabled={isLoading}
            />
          </Box>
        </Box>
      }
    </ModalBase>
  )
}
