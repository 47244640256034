import React from 'react'
import { NavigationPagesProps, TabBottomNavigator } from '@/presentation/components'
import { Track } from '@/presentation/pages/Track'
import { makeGetTrackDetailsFactory, makeGetTrackListFactory } from '@/main/factories/usecases'

export const makeTrackFactory: React.FC = ({ navigation }: NavigationPagesProps) => {
  return (
    <>
      <Track
        getTrackList={makeGetTrackListFactory()}
        getTrackDetails={makeGetTrackDetailsFactory()}
      />
      <TabBottomNavigator navigation={navigation} />
    </>
  )
}
