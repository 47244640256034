import { useCallback } from 'react'
import { useRecoilState } from 'recoil'
import { snackBarProviderState } from '@/presentation/components'

type StatusVariant = 'success' | 'error' | 'info' | 'warning'

type NotifyParams = {
  variant?: StatusVariant
  duration?: number
  delay?: number
}

type SnackBarProps = {
  id: string
  variant: StatusVariant
  duration: number
  message: string
}

type NotifyProps = {
  show: (message: string, options?: NotifyParams) => void
  success: (message: string, options?: NotifyParams) => void
  warning: (message: string, options?: NotifyParams) => void
  info: (message: string, options?: NotifyParams) => void
  error: (message: string, options?: NotifyParams) => void
  dimiss: (messageId: string) => void
}

function useNotify (): NotifyProps {
  const [messages, setMessages] = useRecoilState(snackBarProviderState)

  const showMessage = useCallback(
    (message: string, options: NotifyParams): void => {
      const newMessage: SnackBarProps = {
        id: new Date().getMilliseconds().toString(),
        variant: options.variant ?? 'success',
        duration: options.duration ?? 7000,
        message
      }

      if (!messages.some(currentMessage => currentMessage.message === message)) {
        setMessages(currentMessages => [...currentMessages, newMessage])
        setTimeout(() => dismiss(newMessage.id), newMessage.duration)
      }
    },
    [messages, setMessages]
  )

  const show = (message: string, options?: NotifyParams): void => {
    showMessage(message, { ...options })
  }

  const success = (message: string, options?: NotifyParams): void => {
    showMessage(message, { variant: 'success', ...options })
  }

  const info = (message: string, options?: NotifyParams): void => {
    showMessage(message, { variant: 'info', ...options })
  }

  const warning = (message: string, options?: NotifyParams): void => {
    showMessage(message, { variant: 'warning', ...options })
  }

  const error = (message: string, options?: NotifyParams): void => {
    showMessage(message, { variant: 'error', ...options })
  }

  const dismiss = useCallback((messageId: string): void => {
    setMessages(messages.filter(message => message.id !== messageId))
  }, [messages, setMessages])

  return { show, success, info, warning, error, dimiss: dismiss }
}

export default useNotify
