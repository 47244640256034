import React from 'react'
import { Login } from '@/presentation/pages/Login'
import { makeAuthentication, makeGetUser, makeForgotPassword, makeAuthenticationAdmin, makeProspectRegistration } from '@/main/factories/usecases'

export const makeLoginFactory = (): JSX.Element => {
  return (
    <Login
      authentication={makeAuthentication()}
      authenticationAdmin={makeAuthenticationAdmin()}
      getUser={makeGetUser()}
      forgotPassword={makeForgotPassword()}
      prospectRegistration={makeProspectRegistration()}
    />
  )
}
