import React, { useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useImage, useThemeClient } from '@/presentation/hooks'
import { currentUnitState, logoEmpresaState, unitsState } from '@/presentation/components'

type LogoThemeProps = {
  height?: number
  width?: number
  minHeight?: number
}

export const LogoTheme: React.FC<LogoThemeProps> = ({ width, height, minHeight }) => {
  const { getImage } = useImage()
  const { getThemeClient } = useThemeClient()
  const themeClient = getThemeClient()
  const [logoEmpresa, setLogoEmpresa] = useRecoilState(logoEmpresaState)
  const isSvg = !logoEmpresa
  const units = useRecoilValue(unitsState)
  const currentUnit = useRecoilValue(currentUnitState)

  const unit = units.find(unitData => unitData.codigoUnidade === currentUnit.codigoUnidade)
  const externalServer: string = unit?.enderecoExterno ?? ''

  useEffect(() => {
    if ((themeClient.imageLogo) && (!__DEV__) && (logoEmpresa !== themeClient.imageLogo)) {
      if (externalServer) {
        void getImage(themeClient.imageLogo, 'logo', externalServer)
          .then(logo => { setLogoEmpresa(logo) })
          .catch(console.error)
      } else {
        void getImage(themeClient.imageLogo, 'logo')
          .then(logo => { setLogoEmpresa(logo) })
          .catch(console.error)
      }
    }
  }, [themeClient.imageLogo])

  return (
    <img
      style={{
        width: width ?? 'auto',
        height: height ?? 'auto',
        minWidth: '60px',
        maxWidth: isSvg ? '180px' : 'auto',
        minHeight: minHeight ?? '',
        marginRight: 8
      }}
      src={logoEmpresa}
    />
  )
}
