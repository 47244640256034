import React, { useCallback } from 'react'
import { ScrollView } from 'react-native'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useFocusEffect } from '@react-navigation/native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Box, CardList, PageHeader, Stack } from '@/presentation/components'
import { GetMoniSectorsParams, GetMoniSectors, GetRemotySectorsParams, GetRemotySectors, GetSowillSectors, GetSowillSectorsParams, GetSigmaCloudSectors, GetSigmaCloudSectorsParams, GetSigmaDeskSectors, GetSigmaDeskSectorParams } from '@/domain/usecases'
import { ClientMonitoringType } from '@/domain/models'
import { useAuth, useErrorHandler, useMediaQueries } from '@/presentation/hooks'
import { theme } from '@/presentation/styles'
import { layoutStyles } from '@/presentation/styles/globals'
import { CardSector, isLoadingSectorEventsState, sectorListState, SelectSigmaCloudKey, SelectRemotyEventCompany, selectSigmaCloudKeyData, sowillIdentifierState, SelectSowillKey, remotyFilterState, sectorListHasError } from '@/presentation/pages/Events/components'

type SectorListProps = {
  getMoniSectors: GetMoniSectors
  getRemotySectors: GetRemotySectors
  getSowillSectors: GetSowillSectors
  getSigmaCloudSectors: GetSigmaCloudSectors
  getSigmaDeskSectors: GetSigmaDeskSectors
}

export const SectorList: React.FC<SectorListProps> = ({
  getRemotySectors,
  getMoniSectors,
  getSowillSectors,
  getSigmaCloudSectors,
  getSigmaDeskSectors
}) => {
  const { getClient } = useAuth()
  const { isMobile } = useMediaQueries()
  const handleError = useErrorHandler()
  const client = getClient()

  const [hasError, setHasError] = useRecoilState(sectorListHasError)
  const [isLoading, setLoading] = useRecoilState(isLoadingSectorEventsState)
  const [sectorList, setSectorList] = useRecoilState(sectorListState)
  const { remotyIdEquipamento } = useRecoilValue(remotyFilterState)
  const currentSigmaKey = useRecoilValue(selectSigmaCloudKeyData)
  const sowillIdentifier = useRecoilValue(sowillIdentifierState)

  useFocusEffect(
    useCallback(() => {
      const conditions = {
        [ClientMonitoringType.REMOTY]: remotyIdEquipamento !== 0 && remotyIdEquipamento,
        [ClientMonitoringType.MONI]: client.tipoConexao && client.tipoConexao === 1,
        [ClientMonitoringType.SIGMA_CLOUD]: !!(currentSigmaKey.codCliente),
        [ClientMonitoringType.SIGMA_DESK]: !!(currentSigmaKey.codCliente),
        [ClientMonitoringType.SO_WILL]: !!(sowillIdentifier.codInternoChave)
      }[client.tipoConexao]

      if (conditions && !hasError) { getEventsSector() }
    },
    [
      client.empresa,
      remotyIdEquipamento,
      currentSigmaKey.codCliente,
      currentSigmaKey.empresaSigma,
      sowillIdentifier.codInternoChave,
      hasError
    ])
  )

  const errorHandler = (error: Error): void => {
    handleError(error)
    setHasError(true)
  }

  const handleGetMoniSectorList = (): void => {
    const params: GetMoniSectorsParams = {
      unidade: client.unidade,
      empresaMoni: client.empresaMonitoramento,
      codCliente: client.codCliente
    }

    setLoading(true)

    getMoniSectors
      .getSectors(params)
      .then(setSectorList)
      .catch(errorHandler)
      .finally(() => setLoading(false))
  }

  const handleGetRemotySectorList = (): void => {
    const params: GetRemotySectorsParams = {
      idEquipamento: remotyIdEquipamento,
      empresaMonitoramento: client.empresaMonitoramento
    }

    setLoading(true)

    getRemotySectors
      .getSectors(params)
      .then(setSectorList)
      .catch(errorHandler)
      .finally(() => setLoading(false))
  }

  const handleGetSowillSectorList = (): void => {
    const getSowillSectorParams: GetSowillSectorsParams = {
      codInternoChave: sowillIdentifier.codInternoChave,
      idCliente: sowillIdentifier.idCliente
    }

    setLoading(true)

    getSowillSectors
      .getZones(getSowillSectorParams)
      .then(setSectorList)
      .catch(errorHandler)
      .finally(() => setLoading(false))
  }

  const handleGetSigmaCloudSectorList = (): void => {
    const params: GetSigmaCloudSectorsParams = {
      chave: currentSigmaKey.chave,
      particao: currentSigmaKey.particao,
      unidade: currentSigmaKey.empresaSigma
    }

    setLoading(true)

    getSigmaCloudSectors
      .getZones(params)
      .then(setSectorList)
      .catch(errorHandler)
      .finally(() => setLoading(false))
  }

  const handleGetSigmaDeskSectorList = (): void => {
    const params: GetSigmaDeskSectorParams = {
      empresaSigma: currentSigmaKey.empresaSigma,
      idCentral: currentSigmaKey.codInterno,
      unidade: currentSigmaKey.empresaSigma
    }

    setLoading(true)

    getSigmaDeskSectors
      .getSector(params)
      .then(setSectorList)
      .catch(errorHandler)
      .finally(() => setLoading(false))
  }

  const getEventsSector = {
    [ClientMonitoringType.MONI]: handleGetMoniSectorList,
    [ClientMonitoringType.REMOTY]: handleGetRemotySectorList,
    [ClientMonitoringType.SO_WILL]: handleGetSowillSectorList,
    [ClientMonitoringType.SIGMA_CLOUD]: handleGetSigmaCloudSectorList,
    [ClientMonitoringType.SIGMA_DESK]: handleGetSigmaDeskSectorList
  }[client.tipoConexao]

  return (
    <Box style={{ ...layoutStyles.pageContent }}>
      <PageHeader
        text={
          [ClientMonitoringType.SO_WILL].includes(client.tipoConexao)
            ? 'Zonas'
            : 'Setores'
        }
        icon={<MaterialCommunityIcons name="alarm-light-outline" size={24} color={theme.colors.grey} />}
      />

      <Stack
        direction={isMobile ? 'column-reverse' : 'row'}
        mr={window.innerWidth <= 1380 ? 36 : isMobile ? 'auto' : 0}
        ml={window.innerWidth <= 1380 ? 36 : isMobile ? 'auto' : 0}
        justify='between'
        items='center'
        spacing={8}
      >
        {[ClientMonitoringType.REMOTY].includes(client.tipoConexao) && <SelectRemotyEventCompany />}
        {[ClientMonitoringType.SIGMA_CLOUD, ClientMonitoringType.SIGMA_DESK].includes(client.tipoConexao) && <SelectSigmaCloudKey />}
        {[ClientMonitoringType.SO_WILL].includes(client.tipoConexao) && <SelectSowillKey />}
      </Stack>

      <ScrollView style={{ padding: '1rem' }}>
        <CardList
          isEmpty={!sectorList.length}
          isLoading={isLoading}
          textLoading={
            [ClientMonitoringType.SO_WILL].includes(client.tipoConexao)
              ? 'Buscando Zonas'
              : 'Buscando Setores'
          }
        >
          {sectorList?.map(sector => (
            <CardSector
              key={`cardSector-${sector.codigoZona}`}
              sector={sector}
            />
          ))}
        </CardList>
      </ScrollView>
    </Box>
  )
}
