import { CardFlagsTypes } from '@/domain/models'
import { atom } from 'recoil'

export const isOpenAddOtherCreditCardState = atom({
  key: 'isOpenAddOtherCreditCardState',
  default: false
})

export const otherCreditCardState = atom({
  key: 'otherCreditCardState',
  default: {
    ccv: '',
    codCliente: 0,
    email: '',
    expiracao: '',
    mask: '9999 9999 9999 9999',
    nome: '',
    numero: '',
    type: 'unknown' as CardFlagsTypes
  }
})

export const errorAddOtherCreditCardState = atom({
  key: 'errorAddOtherCreditCardState',
  default: ''
})
