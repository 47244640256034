import { GeneralConfigs } from '@/domain/models'
import { atom } from 'recoil'

export const isLoadingGeneralSettingsState = atom({
  key: 'isLoadingGeneralSettingsState',
  default: true
})

export const initialGeneralConfigsState = atom<GeneralConfigs>({
  key: 'initialGeneralConfigsState',
  default: null as unknown as GeneralConfigs
})

export const hasErrorGeneralConfigState = atom<Array<keyof GeneralConfigs>>({
  key: 'hasErrorGeneralConfigState',
  default: []
})
