import { atom } from 'recoil'
import { AreaInterest, EntidadeService } from '@/domain/models'

export const isOpenAddConfigUnitState = atom({
  key: 'isOpenAddConfigUnitState',
  default: false
})

export const addConfigUnitState = atom<AreaInterest>({
  key: 'addConfigUnitState',
  default: {
    descricao: '',
    email: '',
    interesse: null as unknown as EntidadeService,
    unidade: 0
  }
})

export const isDuplicatedOptionSate = atom({
  key: 'isDuplicatedProspectOptionState',
  default: false
})

export const isOpenSelectInterestState = atom({
  key: 'isOpenProspectSelectInterestState',
  default: false
})

export const isOpenSelectUnitState = atom({
  key: 'isOpenProspectSelectUnitState',
  default: false
})
