import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    maxHeight: 400,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto'
  },
  buttonArea: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 8
  },
  buttons: {
    width: '100%',
    marginBottom: 10,
    padding: 3
  }
})
