import { GenerateBankSlip, GenerateBankSlipParams } from '@/domain/usecases'
import { AccessDeniedError, UnexpectedError, UnexpectedResultFormatError } from '@/domain/errors'
import { HttpClient, HttpRequest, HttpStatusCode } from '@/data/protocols'
import { BANK } from '@/domain/models'

export const TEMP_FOLDER_REPORTS_INTEGRA = 'TempRelatorios'

export class RemoteGenerateBankSlip implements GenerateBankSlip {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<string>
  ) {}

  async generate (params: GenerateBankSlipParams): Promise<string> {
    const request: HttpRequest = {
      url: `${this.url}/api/boletos`,
      method: 'get',
      params
    }

    const { statusCode, body } = await this.httpClient.request(request)

    switch (statusCode) {
      case HttpStatusCode.ok:
        if (!body || typeof body !== 'string') {
          throw new UnexpectedResultFormatError()
        }

        if ([BANK.BoletoInside, BANK.CartaoInside].includes(params.codBanco)) {
          return body
        }
        return `/${TEMP_FOLDER_REPORTS_INTEGRA}/${body}`
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}
