import { AccessDeniedError, UnexpectedError } from '@/domain/errors'
import { PortalLogout, PortalLogoutParams } from '@/domain/usecases'
import { HttpClient, HttpStatusCode } from '@/data/protocols/http'

export class RemotePortalLogout implements PortalLogout {
  constructor (
    private readonly url: string,
    private readonly httpPostClient: HttpClient
  ) {}

  async logout (params: PortalLogoutParams): Promise<void> {
    const { statusCode } = await this.httpPostClient.request({
      method: 'post',
      url: `${this.url}/api/portal/RealizaLogoutPortal`,
      params
    })
    switch (statusCode) {
      case HttpStatusCode.ok: break
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}
