import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'
import { GetRemotyEvents } from '@/domain/usecases'
import { RemoteGetRemotyEvents } from '@/data/usecases/events'

export const makeGetRemotyEventsFactory = (): GetRemotyEvents => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<string>()

  return new RemoteGetRemotyEvents(url, httpClient)
}
