import React, { useCallback } from 'react'
import { SafeAreaView } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { useAuth, useErrorHandler, useTheme } from '@/presentation/hooks'
import { styles } from './styles'
import { Box, PageHeader, CardList } from '@/presentation/components'
import { MaterialIcons } from '@expo/vector-icons'
import { isLoadingTrackState, trackState } from './atom'
import { useRecoilState } from 'recoil'
import { useFocusEffect } from '@react-navigation/native'
import { GetTrackDetails, GetTrackList, GetTrackParams } from '@/domain/usecases'
import { CardTrack, TrackDetails } from '@/presentation/pages/Track/components'

type TrackProps = {
  getTrackList: GetTrackList
  getTrackDetails: GetTrackDetails
}

export const Track: React.FC<TrackProps> = ({ getTrackList, getTrackDetails }: TrackProps) => {
  const theme = useTheme()
  const { getClient } = useAuth()
  const handleError = useErrorHandler()
  const [isLoading, setLoading] = useRecoilState(isLoadingTrackState)
  const [tracks, setTracks] = useRecoilState(trackState)

  const client = getClient()

  useFocusEffect(
    useCallback(() => {
      setLoading(true)

      const params: GetTrackParams = {
        codCliente: client.codCliente
      }

      getTrackList
        .getTrackList(params)
        .then(setTracks)
        .catch(handleError)
        .finally(() => setLoading(false))
    }, [])
  )

  return (
    <SafeAreaView style={styles.container}>
      <Box style={styles.content}>
        <PageHeader
          text='Rastreamento'
          icon={<MaterialIcons name="track-changes" size={24} color={theme.colors.grey} />}
        />
        <ScrollView>
          <CardList
            isLoading={isLoading}
            isEmpty={!tracks?.length}
            textLoading='Buscando Rastreáveis'
          >
            {
              tracks?.map((track, index) => (
                <CardTrack
                  key={`id-${index}`}
                  track={track}
                  getTrackDetails={getTrackDetails}
                />
              ))
            }
          </CardList>
        </ScrollView>
        <TrackDetails

        />

      </Box>
    </SafeAreaView>
  )
}
