import { GetSigmaDeskConnections } from '@/domain/usecases'
import { RemoteGetSigmaDeskConnections } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import { SigmaDeskConnectionModel } from '@/domain/models'
import env from '@/main/config/env'

export const makeGetSigmaDeskConnectionsFactory = (): GetSigmaDeskConnections => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<SigmaDeskConnectionModel[]>()

  return new RemoteGetSigmaDeskConnections(url, httpClient)
}
