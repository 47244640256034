import React, { useState, useCallback } from 'react'
import { StyleSheet } from 'react-native'
import { Card } from 'react-native-paper'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useMediaQueries, useTheme, useThemeClient } from '@/presentation/hooks'
import { Button, HStack, IconButton } from '@/presentation/components'

export const NotificationButton: React.FC = () => {
  const theme = useTheme()
  const { isMobile } = useMediaQueries()
  const { getThemeClient } = useThemeClient()
  const [isOpen, setOpen] = useState(window.Notification?.permission !== 'granted' ?? true)

  const themeClient = getThemeClient()

  const handleActiveNotifications = useCallback((): void => {
    void Notification.requestPermission().then(permission => {
      console.log(permission)
      if (permission === 'granted') {
        console.log('Permissão de notificação concedida.')
      } else if (permission === 'denied') {
        console.log('Permissão de notificação negada.')
      }
    })
  }, [])

  const handleCloseButton = (): void => setOpen(false)

  const containerMobile = {
    right: 60,
    top: 12,
    minWidth: '70%'
  }

  const container = {
    right: 60,
    top: 24
  }

  return (
    <Card style={{
      ...styles.cardContainer,
      display: isOpen ? 'flex' : 'none',
      ...(isMobile ? containerMobile : container)
    }} elevation={2}>
      <HStack items='center' justify='around' style={{ flex: 1 }} spacing={8}>
        <MaterialCommunityIcons name='bell-alert-outline' size={24} color={themeClient.mainColor} />
        <Button
          title='Ativar notificações'
          color={themeClient.mainColor}
          tintColor={theme.colors.white}
          onPress={handleActiveNotifications}
        />
        <IconButton
          onPress={handleCloseButton}
          icon={<MaterialCommunityIcons name='close' size={18} color={theme.colors.greyLight} />}
        />
      </HStack>
    </Card>
  )
}

const styles = StyleSheet.create({
  cardContainer: {
    position: 'absolute',
    padding: 8,
    borderRadius: 4
  }
})
