import { AccessDeniedError, UnexpectedError } from '@/domain/errors'
import { GenericEventModel, SoWillEventModel } from '@/domain/models'
import { GetSowillEvents, GetSowillEventsParams } from '@/domain/usecases'
import { HttpClient, HttpStatusCode } from '@/data/protocols/http'

export class RemoteGetSowillEvents implements GetSowillEvents {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<SoWillEventModel[]>
  ) { }

  async getEvents (params: GetSowillEventsParams): Promise<GenericEventModel[]> {
    const bodyParams = {
      IdCliente: params.idCliente,
      codInternoChave: params.codInternoChave
    }

    const { body, statusCode } = await this.httpClient.request({
      url: `${this.url}/api/sowill/listaeventos`,
      method: 'post',
      params: { dateTimeInicioManutencao: params.dateTimeInicioManutencao },
      body: bodyParams
    })

    switch (statusCode) {
      case HttpStatusCode.ok: return this.mapToGeneric(body)
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }

  mapToGeneric (events: SoWillEventModel[]): GenericEventModel[] {
    return events?.map(sowillEvent => ({
      evento: sowillEvent?.code,
      descricao: sowillEvent?.description,
      dataEvento: sowillEvent?.startDate,
      aux: sowillEvent?.id,
      usuario: sowillEvent.account
    }))
  }
}
