import { atom } from 'recoil'

export const isOpenSaveOrDiscardConfigState = atom({
  key: 'isOpenSaveOrDiscardConfigState',
  default: false
})

export const isLoadingSaveOrDiscardConfigState = atom({
  key: 'isLoadingSaveOrDiscardConfigState',
  default: false
})
