import React from 'react'
import { EventsSettings } from '@/presentation/pages/EventsSettings'
import { makeShowEvents, makeGetEventsSettings, makeGetSigmaDeskConnectionsFactory } from '@/main/factories/usecases'

export const makeEventsSettingsFactory = (): JSX.Element => {
  return (
    <EventsSettings
      getSigmaDeskConnections={makeGetSigmaDeskConnectionsFactory()}
      getEventsSettings={makeGetEventsSettings()}
      showEvents={makeShowEvents()}
    />
  )
}
