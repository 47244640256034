import React from 'react'
import { MenuAccessPermissionsConfigs } from '@/domain/models'
import { Box, Loading } from '@/presentation/components'
import { ItemMenu, isLoadingState } from '@/presentation/pages/Home/components'
import { styles } from './styles'
import { useMediaQueries, useAuth } from '@/presentation/hooks'
import { useRecoilValue } from 'recoil'

const ITEMS_EXCLUDES = [
  'envialista',
  'telefonesuteis',
  'documentosuteis',
  'dadosconta',
  'seguro',
  'evento',
  'atualizaautomaticocartao',
  'indicarprospect'
]

type MenuProps = {
  navigation: any
}

export const Menu: React.FC<MenuProps> = ({ navigation }) => {
  const { isMobile } = useMediaQueries()
  const { getClient } = useAuth()
  const isLoading = useRecoilValue(isLoadingState)

  const client = getClient()

  const itemsMenu = client?.portal && Object
    .keys(client.portal)
    .filter((page: keyof MenuAccessPermissionsConfigs) => !ITEMS_EXCLUDES.includes(page.toLowerCase()) && !!client.portal[page])

  const makeItemsMenu = (): JSX.Element =>
    <Box style={{
      ...styles.content,
      ...(!isMobile && { marginTop: 36 })
    }}>
      {itemsMenu?.map((page: keyof MenuAccessPermissionsConfigs, index: number) =>
        <ItemMenu key={`item-menu-${index}`} page={page} navigation={navigation} />
      )}
    </Box>

  return (
    <Box style={styles.container} testID='home' accessibilityLabel="home">
      {isLoading || !client
        ? <Loading text='Buscando informações do cliente' />
        : makeItemsMenu()
      }
    </Box>
  )
}
