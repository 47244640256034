import { GetTicketPix } from '@/domain/usecases'
import { RemoteGetTicketPix } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'

export const makeGetTicketPix = (): GetTicketPix => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<string>()

  return new RemoteGetTicketPix(url, httpClient)
}
