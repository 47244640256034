import { NFModel } from '@/domain/models'

export type OpenDanfeParams = {
  cliCodigo: number
  notaFiscal: NFModel
}

export interface OpenDanfe {
  open: (params: OpenDanfeParams) => Promise<void>
}

export namespace OpenDanfe {
  export const RN = {
    FOLDER_DEFAULT_PDF_DANFE: 'TempRelatorios/Danfe'
  }
}
