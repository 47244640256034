import React from 'react'
import { Box, ChipFilter, HStack } from '@/presentation/components'
import { DateAdapter } from '@/data/protocols'
import { acceptDigitalFiltersState, isOpenAcceptDigitalFiltersState } from '@/presentation/pages/AceiteDigital/components'
import { useRecoilValue, useSetRecoilState } from 'recoil'

type FiltersChipPanelProps = {
  dateAdapter: DateAdapter
}

export const FiltersChipPanel: React.FC<FiltersChipPanelProps> = ({ dateAdapter }) => {
  const setOpen = useSetRecoilState(isOpenAcceptDigitalFiltersState)
  const filters = useRecoilValue(acceptDigitalFiltersState)

  const period = dateAdapter.format(filters.dtInicial, 'DD/MM/YYYY') +
    ' até ' +
    dateAdapter.format(filters.dtFinal, 'DD/MM/YYYY')

  const situation = {
    Pendentes: 'Somente Pendentes',
    Autorizados: 'Somente Autorizados',
    Ambos: 'Todos'
  }[filters.status]

  return (
    <HStack spacing={6} mb={18} wrap>
      <Box>
        <ChipFilter
          title='Período'
          subtitle={period}
          onPress={() => setOpen(true)}
        />
      </Box>
      <Box>
        <ChipFilter
          title='Status'
          subtitle={situation}
          onPress={() => setOpen(true)}
        />
      </Box>
    </HStack>
  )
}
