import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  cardBase: {
    display: 'flex',
    borderRadius: 10,
    flex: 1
  },
  cardContent: {
    display: 'flex',
    gap: 8
  }
})
