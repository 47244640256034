export type TicketModel = {
  codInterno: number
  empresa: string
  cliente: number
  dataEmissao: string
  dataPagamento: string
  formaPagamento: number
  numero: string
  codBanco: BANK
  vencimento: string
  valorDuplicata: number
  valorPago: number
  saldo: number
  pixUrl: string
  nossoNumero: string
  nomeFormaPagto: string
  atraso: number
  linhaDigitavel: string
  habilitaImpressao: boolean
  dupProtestada: boolean
  registrado: boolean
  permiteCartaoCredito: boolean
  linkBoleto: string
  idInBoleto: string
  valorAtualizado: number
  situacao: 'paga' | 'vencida' | 'em Aberto' | 'venceHoje'
}

export enum BANK {
  BoletoInside = 900,
  CartaoInside = 901,
  Caixa = 104,
  Sicredi = 748,
  Itau = 341,
  Bradesco = 237,
  Santander = 33,
  Sicoob = 756,
  Banestes = 21
}
