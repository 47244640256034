import { ShowEvents, ShowEventsParams } from '@/domain/usecases'
import { AccessDeniedError, ForbiddenError, UnexpectedError, UnexpectedResultFormatError } from '@/domain/errors'
import { HttpClient, HttpRequest, HttpStatusCode } from '@/data/protocols'

export class RemoteShowEvents implements ShowEvents {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<string>
  ) { }

  async show (params: ShowEventsParams): Promise<string> {
    const request: HttpRequest = {
      url: `${this.url}/api/portal/sigmadesk/gravar-eventos-exibidos`,
      method: 'post',
      body: params
    }

    const { statusCode, body } = await this.httpClient.request(request)

    switch (statusCode) {
      case HttpStatusCode.ok:
        if (!body) {
          throw new UnexpectedResultFormatError()
        }
        return body
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      case HttpStatusCode.forbidden: throw new ForbiddenError(body)
      default: throw new UnexpectedError()
    }
  }
}
