import { atom } from 'recoil'
import { ServiceOrderModel } from '@/domain/models'

export const isLoadingServiceOrdersState = atom({
  key: 'isLoadingServiceOrdersState',
  default: true
})

export const serviceOrdersState = atom({
  key: 'serviceOrdersState',
  default: [] as ServiceOrderModel[]
})

export const serviceOrdersConfigsState = atom({
  key: 'serviceOrdersConfigsState',
  default: {
    bloqueiaAberturaLimite: false,
    portalExibeMensagem: false,
    exibirOsFechadas: false,
    portalMensagem: ''
  }
})
