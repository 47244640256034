import React, { useState } from 'react'
import { View, Text, Modal, TouchableOpacity, TouchableWithoutFeedback } from 'react-native'
import { useRecoilState } from 'recoil'
import { SigmaFilterTitle } from '@/domain/usecases'
import { sigmaFilterState } from '../SideFilters/atom'
import { AntDesign } from '@expo/vector-icons'
import { styles } from './styles'

enum sigmaEventsFilterType {
  'Todos' = 'rbTodos',
  'Alarme' = 'rbAlarme',
  'Teste Automático' = 'rbTstAuto',
  'Armado' = 'rbArmado',
  'Desarmado' = 'rbDesarmado',
  'Sem Controle' = 'rbSControle',
  'Desarmado ronda' = 'rbRonda'
}

export const SelectTypeSigmaEvent = (): JSX.Element => {
  const [modalVisible, setModalVisible] = useState(false)
  const [filters, setFilters] = useRecoilState(sigmaFilterState)

  const sigmaFilterTitle: SigmaFilterTitle[] = ['Todos', 'Alarme', 'Teste Automático', 'Armado', 'Desarmado', 'Sem Controle', 'Desarmado ronda']

  const handleOpenModal = (): void => {
    setModalVisible(true)
  }

  const handleCloseModal = (): void => {
    setModalVisible(false)
  }

  const handleOptionPress = (option: SigmaFilterTitle): void => {
    const sigmaTipo = option.length ? sigmaEventsFilterType[option] : 'rbTodos'

    setFilters(currentFilters => ({
      ...currentFilters,
      sigmaTipo,
      sigmaTituloChip: option
    }))

    setModalVisible(false)
  }

  const stylesContainer = {
    ...styles,
    margin: 0
  }

  return (
    <View style={stylesContainer}>
      <TouchableWithoutFeedback onPress={handleOpenModal}>
        <View style={styles.selectInput}>
          <Text>{filters.sigmaTituloChip || 'Tipos de eventos'}</Text>
          <><AntDesign name="down" size={24} color="black" /></>
        </View>
      </TouchableWithoutFeedback>

      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={handleCloseModal}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            {sigmaFilterTitle.map((option) => (
              <TouchableOpacity
                key={option}
                onPress={() => handleOptionPress(option)}
                style={styles.optionItem}
              >
                <Text>{option}</Text>
              </TouchableOpacity>
            ))}
          </View>
        </View>
      </Modal>
    </View>
  )
}
