import { atom } from 'recoil'
import { SigmaFilterType } from '@/domain/usecases'

export const isOpenMonitoringFiltersState = atom({
  key: 'isOpenMonitoringFiltersState',
  default: false
})

export const monitoringDateFiltersState = atom({
  key: 'monitoringDateFiltersState',
  default: {
    dataInicial: '',
    dataFinal: ''
  }
})

export const remotyFilterState = atom({
  key: 'remotyFilterState',
  default: {
    remotyIdEquipamento: 0,
    remotyNmEquip: ''
  }
})

export const sigmaFilterState = atom({
  key: 'sigmaFilterState',
  default: {
    sigmaTipo: null as unknown as SigmaFilterType,
    sigmaTituloChip: ''
  }
})
