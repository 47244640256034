import { StyleSheet } from 'react-native'
import { layoutStyles } from '@/presentation/styles/globals'

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'stretch'
  },
  content: {
    ...layoutStyles.pageContent
  },
  scroll: {
    paddingBottom: 60
  },
  btnAction: {
    backgroundColor: 'none',
    textTransform: 'uppercase',
    fontSize: 12
  },
  footerContainer: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
    height: '100%',
    padding: 16
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  bold: {
    fontWeight: '600'
  },
  titleBox: {
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, 0.12)',
    paddingBottom: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  additionalTextBox: {
    borderTopWidth: 1,
    borderTopColor: 'rgba(0, 0, 0, 0.12)',
    paddingTop: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  actionsContent: {
    marginVertical: 8,
    marginRight: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
})
