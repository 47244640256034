import { GenerateBankSlip } from '@/domain/usecases'
import { RemoteGenerateBankSlip } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'

export const makeGenerateBankSlip = (): GenerateBankSlip => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<string>()

  return new RemoteGenerateBankSlip(url, httpClient)
}
