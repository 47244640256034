import { HttpClient, HttpStatusCode } from '@/data/protocols/http'
import { AccessDeniedError, ForbiddenError, UnexpectedError } from '@/domain/errors'
import { ForgotPassword, ForgotPasswordParams } from '@/domain/usecases'

export class RemoteForgotPassword implements ForgotPassword {
  constructor (
    private readonly url: string,
    private readonly httpPostClient: HttpClient<string>
  ) {}

  async requestAccess (params: ForgotPasswordParams): Promise<string> {
    const { body, statusCode } = await this.httpPostClient.request({
      method: 'post',
      url: `${this.url}/api/portal/recuperar-senha`,
      params
    })

    switch (statusCode) {
      case HttpStatusCode.ok: return body
      case HttpStatusCode.forbidden: throw new ForbiddenError(body)
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}
