import { HttpClient, HttpStatusCode } from '@/data/protocols/http'
import { ForbiddenError, UnexpectedError } from '@/domain/errors'
import { UpdateDefaultPassword, UpdateDefaultPasswordParams } from '@/domain/usecases'

export class RemoteUpdateDefaultPassword implements UpdateDefaultPassword {
  constructor (
    private readonly url: string,
    private readonly httpPostClient: HttpClient<boolean | string>
  ) {}

  async changePassword (params: UpdateDefaultPasswordParams): Promise<boolean> {
    const { statusCode, body } = await this.httpPostClient.request({
      method: 'post',
      url: `${this.url}/api/portal/alterar-senha-recuperacao`,
      params
    })

    switch (statusCode) {
      case HttpStatusCode.ok: return body as boolean
      case HttpStatusCode.forbidden: throw new ForbiddenError(body as string)
      default: throw new UnexpectedError()
    }
  }
}
