import React from 'react'
import { Settings } from '@/presentation/pages/Settings'
import { makeGetUnitConfig, makeTestEmail, makeSaveUnitConfig } from '@/main/factories/usecases'

export const makeSettingsFactory = (): JSX.Element => {
  return (
    <Settings
      getUnitConfigs={makeGetUnitConfig()}
      saveUnitConfig={makeSaveUnitConfig()}
      testEmail={makeTestEmail()}
    />
  )
}
