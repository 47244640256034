import React, { useCallback, useEffect } from 'react'
import { ScrollView } from 'react-native'
import { TextInput } from 'react-native-paper'
import { useRecoilState } from 'recoil'
import { GeneralConfigs } from '@/domain/models'
import { TestEmail } from '@/domain/usecases'
import { Box, CardSwitch, Loading } from '@/presentation/components'
import { useThemeClient } from '@/presentation/hooks'
import { ActionsButtons, EmailSendingTester, generalConfigsState } from '@/presentation/pages/Settings/components'
import { hasErrorGeneralConfigState, initialGeneralConfigsState } from './atom'

type GeneralSettingsProps = {
  testEmail: TestEmail
}

export const GeneralSettings: React.FC<GeneralSettingsProps> = ({ testEmail }) => {
  const { getThemeClient } = useThemeClient()
  const [generalConfigs, setGeneralConfig] = useRecoilState(generalConfigsState)
  const [initialGeneralConfigs, setInitialGeneralConfigs] = useRecoilState(initialGeneralConfigsState)
  const [hasError, setError] = useRecoilState(hasErrorGeneralConfigState)

  const themeClient = getThemeClient()

  useEffect(() => {
    setInitialGeneralConfigs(generalConfigs)
  }, [initialGeneralConfigs])

  const handleChange = (param: keyof GeneralConfigs, value: string | boolean | number): void => {
    setError([])
    setGeneralConfig(currentState => ({ ...currentState, [param]: value }))
  }

  const hasChanged = useCallback((): boolean => {
    return JSON.stringify(initialGeneralConfigs) !== JSON.stringify(generalConfigs)
  }, [generalConfigs])

  return (
    <Box style={{ flex: 1 }} >
      <ScrollView>
        {!generalConfigs
          ? <Loading />
          : <>
              <EmailSendingTester testEmail={testEmail} />

              <CardSwitch
                text='Enviar cópia para o remetente do e-mail?'
                value={generalConfigs.copia}
                handleSwitch={() => handleChange('copia', !generalConfigs.copia)}
              />

              <CardSwitch
                text='Enviar aviso por e-mail quando enviado aviso de viagem?'
                value={generalConfigs.enviaEmailViagem}
                handleSwitch={() => handleChange('enviaEmailViagem', !generalConfigs.enviaEmailViagem)}
              >
                <TextInput
                  mode='flat'
                  activeUnderlineColor={themeClient.mainColor}
                  label='E-mail destino'
                  underlineColorAndroid='transparent'
                  underlineColor='transparent'
                  value={generalConfigs.emailViagem}
                  onChangeText={(text: string) => handleChange('emailViagem', text)}
                  maxLength={300}
                  error={hasError.includes('emailViagem')}
                  style={{
                    flex: 1
                  }}
                />
              </CardSwitch>

              <CardSwitch
                text='Enviar SAC por e-mail'
                value
                readonly
                handleSwitch={() => {}}
              >
                <TextInput
                  mode='flat'
                  label='E-mail SAC'
                  underlineColorAndroid='transparent'
                  underlineColor='transparent'
                  value={generalConfigs.emailSac}
                  onChangeText={text => handleChange('emailSac', text)}
                  error={hasError.includes('emailSac')}
                />
              </CardSwitch>

              <CardSwitch
                text='Permitir que usuário deixe a chave da residencia com a central de monitoramento?'
                value={generalConfigs.exibeChavesAvisoViagem}
                handleSwitch={() => handleChange('exibeChavesAvisoViagem', !generalConfigs.exibeChavesAvisoViagem)}
              />

              <CardSwitch
                text='Visualiza detalhes do contrato?'
                value={generalConfigs.visualizaDetalhesContrato}
                handleSwitch={() => handleChange('visualizaDetalhesContrato', !generalConfigs.visualizaDetalhesContrato)}
              />

              <CardSwitch
                text='Limite de dias pós-fechamento para visualizar Detalhes da OS (0 para desabilitar o limite)'
                value={true}
                handleSwitch={() => {}}
                readonly={true}
              >
                <TextInput
                  mode="flat"
                  activeUnderlineColor={themeClient.mainColor}
                  label="Dias"
                  underlineColorAndroid="transparent"
                  underlineColor="transparent"
                  value={generalConfigs.limiteDiasVisualizaCritica ? generalConfigs.limiteDiasVisualizaCritica.toString() : '0'}
                  onChangeText={(dias: string) => handleChange('limiteDiasVisualizaCritica', parseInt(dias))}
                  maxLength={300}
                />
              </CardSwitch>
              <CardSwitch
                text='Bloquear acesso de clientes com faturamento em lote inativo?'
                value={generalConfigs.bloqueiaInativos}
                handleSwitch={() => handleChange('bloqueiaInativos', !generalConfigs.bloqueiaInativos)}
              />
            </>
        }
      </ScrollView>
      {hasChanged() && <ActionsButtons />}
    </Box>
  )
}
