import { UserModel } from '@/domain/models'
import { GetUserByDocument } from '@/domain/usecases'
import { RemoteGetUser } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'

export const makeGetUser = (): GetUserByDocument => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<UserModel[]>()

  return new RemoteGetUser(url, httpClient)
}
