import React, { useMemo } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import { NavigationRoute } from '@/main/config/router-navigation'
import { MenuAccessPermissionsConfigs } from '@/domain/models'
import { Badge, Box, HStack, Text, notificationsState } from '@/presentation/components'
import { useMediaQueries, useTheme } from '@/presentation/hooks'
import { useRecoilValue } from 'recoil'

type ItemMenuProps = {
  page: keyof MenuAccessPermissionsConfigs
  navigation: any
}

const colorsPage = {
  boleto: '#ed7809',
  os: '#14a10a',
  sac: '#cf0707',
  notaFiscal: '#d6ba06',
  monitoramento: '#08a8dd',
  cartao: '#5209fb',
  aceiteDigital: '#0a41ae',
  viagem: '#9b0aae',
  telefonesUteis: '#05d3b7',
  documentosUteis: '#d7fb09',
  rastreamento: '#0bfc03',
  contrato: '#fc4850'
}

export const ItemMenu: React.FC<ItemMenuProps> = ({ page, navigation }) => {
  const theme = useTheme()
  const { isMobile, width } = useMediaQueries()
  const localNotifications = useRecoilValue(notificationsState)

  const iconPage = useMemo(() => ({
    boleto: <MaterialIcons name="attach-money" size={isMobile ? 24 : 36} color={theme.colors.white} />,
    os: <MaterialIcons name="work-outline" size={isMobile ? 24 : 36} color={theme.colors.white} />,
    sac: <MaterialIcons name="support-agent" size={isMobile ? 24 : 36} color={theme.colors.white} />,
    notaFiscal: <MaterialCommunityIcons name="file-document-outline" size={isMobile ? 24 : 36} color={theme.colors.white} />,
    monitoramento: <MaterialCommunityIcons name="alarm-light-outline" size={isMobile ? 24 : 36} color={theme.colors.white} />,
    rastreamento: <MaterialIcons name="track-changes" size={isMobile ? 24 : 36} color={theme.colors.white} />,
    cartao: <MaterialCommunityIcons name="credit-card-outline" size={isMobile ? 24 : 36} color={theme.colors.white} />,
    aceiteDigital: <MaterialCommunityIcons name="file-document-edit-outline" size={isMobile ? 24 : 36} color={theme.colors.white} />,
    viagem: <MaterialCommunityIcons name="airplane-alert" size={isMobile ? 24 : 36} color={theme.colors.white} />,
    telefonesUteis: <MaterialCommunityIcons name="file-document-edit-outline" size={isMobile ? 24 : 36} color={theme.colors.white} />,
    documentosUteis: <MaterialCommunityIcons name="file-document-edit-outline" size={isMobile ? 24 : 36} color={theme.colors.white} />,
    contrato: <MaterialCommunityIcons name="handshake-outline" size={isMobile ? 24 : 36} color={theme.colors.white} />
  }[page]), [page])

  const iconButton = (): JSX.Element =>
    <Box style={styles.iconContainer}>
      <Box style={{
        ...styles.iconContent,
        backgroundColor: `${colorsPage[page]}CC`
      }}>
        {iconPage}
      </Box>
    </Box>

  const title = {
    boleto: 'Financeiro',
    os: isMobile ? 'Ordens' : 'Ordens de Serviço',
    sac: 'SAC',
    notaFiscal: isMobile ? 'Notas' : 'Notas Fiscais',
    monitoramento: 'Monitoramento',
    rastreamento: 'Rastreamento',
    cartao: 'Cartão',
    aceiteDigital: isMobile ? 'Aceite' : 'Aceite Digital',
    viagem: isMobile ? 'Viagem' : 'Aviso de Viagem',
    telefonesUteis: isMobile ? 'Telefones' : 'Telefones Úteis',
    documentosUteis: isMobile ? 'Arquivos' : 'Arquivos Úteis',
    contrato: isMobile ? 'Contrato' : 'Meu Contrato'
  }[page]

  const route = {
    boleto: NavigationRoute.Financial,
    os: NavigationRoute.ServiceOrders,
    sac: NavigationRoute.SAC,
    notaFiscal: NavigationRoute.Invoices,
    monitoramento: NavigationRoute.Monitoring,
    rastreamento: NavigationRoute.Track,
    cartao: NavigationRoute.CreditCard,
    aceiteDigital: NavigationRoute.DigitalAccepts,
    viagem: NavigationRoute.TravelAlert,
    telefonesUteis: 'Telefones Úteis',
    documentosUteis: 'Arquivos Úteis',
    contrato: NavigationRoute.Contract
  }[page]

  const containerStyles = {
    ...styles.container,
    ...(!isMobile
      ? {
          maxHeight: 180,
          maxWidth: 180,
          minHeight: 140
        }
      : {
          flex: 0.5,
          minHeight: 120
        }),
    minWidth: isMobile ? (width - 56) / 2 : '30%',
    backgroundColor: colorsPage[page]
  }

  return (
    <TouchableOpacity
      activeOpacity={0.8}
      style={containerStyles}
      onPress={() => navigation.navigate(route)}
      testID={`home-button-${title}`}
    >
      <HStack justify='between' fill>
        {iconButton()}
        <Badge
          label={localNotifications.filter((notification) => notification.screen === route).length}
          style={{ marginRight: 18, paddingHorizontal: 12, height: 32, borderRadius: 50 }}
          color={theme.colors.background}
          labelStyle={{ color: colorsPage[page], fontWeight: 'bold' }}
        />
      </HStack>
      <HStack fill items='end'>
        <Text
          color={theme.colors.white}
          style={{
            ...styles.title,
            fontSize: isMobile ? 16 : 18
          }}
        >
          {title}
        </Text>
      </HStack>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    paddingVertical: 18,
    borderRadius: 16,
    flexWrap: 'wrap'
  },
  title: {
    fontWeight: '500',
    paddingHorizontal: 18
  },
  iconContainer: {
    borderRadius: 9,
    backgroundColor: '#FFFFFF',
    marginLeft: 18
  },
  iconContent: {
    borderRadius: 8,
    paddingHorizontal: 12,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1
  }
})
