import React from 'react'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { FAB, HStack, Stack, Text, VStack } from '@/presentation/components'
import { useMediaQueries, useTheme, useThemeClient } from '@/presentation/hooks'
import { useRecoilValue } from 'recoil'
import { travelAlertVehiclesState } from '@/presentation/pages/TravelAlert/components'
import { IconButton, Surface } from 'react-native-paper'

type VehiclePanelProps = {
  openAddNew: () => void
  onRemove: (idVehicle: number) => void
}

export const VehiclePanel: React.FC<VehiclePanelProps> = ({ openAddNew, onRemove }) => {
  const theme = useTheme()
  const { isMobile } = useMediaQueries()
  const { getThemeClient } = useThemeClient()
  const vehicles = useRecoilValue(travelAlertVehiclesState)

  const themeClient = getThemeClient()

  const makeAction = (): JSX.Element =>
    <HStack fill mt={12}>
      <FAB
        variant='extended'
        label={isMobile ? 'Adicionar veículo' : 'Adicionar novo veículo'}
        onPress={openAddNew}
        size='mini'
        icon={props => <MaterialCommunityIcons name='plus' {...props} />} color={themeClient.mainColor}
      />
    </HStack>

  return (
    <>
      <Stack wrap spacing={12} direction={isMobile ? 'column' : 'row'}>
      {vehicles
        .map((vehicle, index) =>
          <Surface
            elevation={1}
            key={`responsible-${vehicle.placa}-${index}`}
            style={{
              flex: 1,
              maxWidth: 320,
              backgroundColor: theme.colors.backgroundInput,
              borderRadius: 4,
              marginTop: 8,
              padding: 12
            }}
          >
            <HStack items='center' spacing={12} fill>
              <MaterialCommunityIcons
                  name='car-back'
                  size={24}
                  color={themeClient.mainColor}
                />
                <VStack fill>
                  <Text
                    style={{
                      fontWeight: '600',
                      fontSize: isMobile ? 12 : 14
                    }}>
                      {vehicle.placa.toUpperCase()}
                  </Text>
                </VStack>
                <IconButton
                  size={18}
                  icon={'trash-can'}
                  iconColor={theme.colors.error}
                  onPress={() => onRemove(vehicle.id)}
                />
            </HStack>
          </Surface>
        )
      }
      </Stack>
      {vehicles?.length <= 4 && makeAction()}
    </>
  )
}
