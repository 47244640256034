import React from 'react'
import { Contract } from '@/presentation/pages/Contract'
import { NavigationPagesProps, TabBottomNavigator } from '@/presentation/components'
import { makeDateAdapterFactory } from '@/main/adapters'

export const makeContractFactory = ({ navigation }: NavigationPagesProps): JSX.Element => {
  return (
    <>
      <Contract dateAdapter={makeDateAdapterFactory()} />
      <TabBottomNavigator navigation={navigation} />
    </>
  )
}
