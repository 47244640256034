import { makeAxiosHttpClient } from '@/main/factories/http'
import { GetSigmaDeskContacts } from '@/domain/usecases'
import { SigmaDeskContactModel } from '@/domain/models'
import { RemoteGetSigmaDeskContacts } from '@/data/usecases/events'
import env from '@/main/config/env'

export const makeGetSigmaDeskContactsFactory = (): GetSigmaDeskContacts => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<SigmaDeskContactModel[] | string>()

  return new RemoteGetSigmaDeskContacts(url, httpClient)
}
