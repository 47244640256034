import React from 'react'
import { TimePickerModal } from 'react-native-paper-dates'

export type ReactNativePaperTimePros = {
  hours: number
  minutes: number
}

type TimePickerProps = {
  label: string
  time: ReactNativePaperTimePros
  onConfirm: (time: ReactNativePaperTimePros) => void
  onDimiss: () => void
  visible: boolean
}

export const TimePicker: React.FC<TimePickerProps> = ({
  label,
  time,
  onConfirm,
  onDimiss,
  visible
}) => {
  return (
    <>
      <TimePickerModal
        label={label}
        visible={visible}
        onDismiss={onDimiss}
        onConfirm={onConfirm}
        hours={time.hours}
        minutes={time.minutes}
        use24HourClock={true}
        animationType='slide'
        cancelLabel='Cancelar'
      />
    </>
  )
}
