import React, { useCallback, useRef } from 'react'
import { SafeAreaView } from 'react-native'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { ScrollView } from 'react-native-gesture-handler'
import { useFocusEffect } from '@react-navigation/native'
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import { AddNewOs, GetOsAttendance, GetServiceOrders } from '@/domain/usecases'
import { ServiceOrderStatus } from '@/domain/models'
import { DateAdapter } from '@/data/protocols'
import { ActionsButtons, Box, CardList, FAB, PageHeader } from '@/presentation/components'
import { useAuth, useErrorHandler, useMediaQueries, useNotify, useTheme, useThemeClient } from '@/presentation/hooks'
import { serviceOrdersConfigsState, SideFilters, ModalAddNewOs, ModalOsView, serviceOrderViewState, serviceOrdersFiltersState, CardOs, isLoadingServiceOrdersState, serviceOrdersState, isOpenServiceOrdersFiltersState, FiltersChipPanel, isOpenAddNewOS } from '@/presentation/pages/ServiceOrders/components'
import { styles } from './styles'

type ServiceOrdersProps = {
  getServiceOrders: GetServiceOrders
  dateAdapter: DateAdapter
  addNewOs: AddNewOs
  getOsAttendance: GetOsAttendance
}

export const ServiceOrders: React.FC<ServiceOrdersProps> = ({
  getServiceOrders,
  addNewOs,
  getOsAttendance,
  dateAdapter
}) => {
  const theme = useTheme()
  const notify = useNotify()
  const { isMobile } = useMediaQueries()
  const handleError = useErrorHandler()
  const { getClient } = useAuth()
  const { getThemeClient } = useThemeClient()
  const { currentOs } = useRecoilValue(serviceOrderViewState)
  const [serviceOrders, setServiceOrders] = useRecoilState(serviceOrdersState)
  const [serviceOrdersConfigs, setServiceOrdersConfig] = useRecoilState(serviceOrdersConfigsState)
  const [isLoading, setLoading] = useRecoilState(isLoadingServiceOrdersState)
  const setOpenAddNewOs = useSetRecoilState(isOpenAddNewOS)
  const filters = useRecoilValue(serviceOrdersFiltersState)
  const setOpen = useSetRecoilState(isOpenServiceOrdersFiltersState)
  const scrollViewRef = useRef(null)

  const client = getClient()
  const themeClient = getThemeClient()

  useFocusEffect(
    useCallback(() => {
      setLoading(true)

      const paramsGetOrders = {
        situacao: filters.situacao,
        codigoCliente: client.codCliente,
        codigoUnidade: client.unidade,
        dataInicial: filters.dataInicio
          ? dateAdapter.format(filters.dataInicio, 'YYYY-MM-DD')
          : dateAdapter.format(dateAdapter.monthAgo(GetServiceOrders.RN.DEFAULT_MONTH_AGO), 'YYYY-MM-DD'),
        dataFinal: filters.dataFim
          ? dateAdapter.format(filters.dataFim, 'YYYY-MM-DD')
          : dateAdapter.format(dateAdapter.today().toString(), 'YYYY-MM-DD')
      }

      getServiceOrders
        .get(paramsGetOrders)
        .then(serviceOrders => {
          const { listaOS, ...configs } = serviceOrders
          setServiceOrders(listaOS)
          setServiceOrdersConfig(configs)
        })
        .catch(handleError)
        .finally(() => setLoading(false))
    }, [])
  )

  const handleAddNewOs = useCallback((): void => {
    if (serviceOrdersConfigs.bloqueiaAberturaLimite) {
      const existWithOpenStatus = serviceOrders?.some(os => os.status === ServiceOrderStatus.Aberta)
      if (existWithOpenStatus) {
        notify.show('Já existe uma Ordem de Serviço aberta para você, não é possível abrir outra no momento.', { variant: 'error' })
        return
      }
    }

    setOpenAddNewOs(true)
  }, [serviceOrdersConfigs.bloqueiaAberturaLimite])

  return (
    <SafeAreaView style={styles.container}>
      <SideFilters getServiceOrders={getServiceOrders} dateAdapter={dateAdapter} />
      <ScrollView ref={scrollViewRef} contentContainerStyle={styles.scroll}>
        <Box style={styles.content}>
          <PageHeader
            text='Ordens de Serviço'
            icon={<MaterialIcons size={24} color={theme.colors.grey} name='work-outline' />}
            openFilters={() => setOpen(true)}
          />
          <FiltersChipPanel dateAdapter={dateAdapter} handleAddNewOs={handleAddNewOs} />
          <CardList
            isLoading={isLoading}
            isEmpty={!serviceOrders.length}
            textLoading='Buscando Ordens de Serviço'
          >
            {serviceOrders?.map((serviceOrder, index) =>
              <CardOs
                key={`card-server-order-${index}`}
                serviceOrder={serviceOrder}
                dateAdapter={dateAdapter}
              />
            )}
          </CardList>
        </Box>
      </ScrollView>
        {isMobile &&
          <ActionsButtons>
            <FAB
              label='Abrir OS'
              disabled={client.codTecnico === 0 && !client.osSemTecnico }
              testID='cadastrar-os'
              onPress={handleAddNewOs}
              variant='extended'
              icon={props => <MaterialCommunityIcons name="plus" {...props} />} color={themeClient.mainColor}
            />
          </ActionsButtons>
        }
      {!isLoading && <ModalAddNewOs addNewOs={addNewOs} dateAdapter={dateAdapter} />}
      {!!currentOs && <ModalOsView dateAdapter={dateAdapter} getOsAttendance={getOsAttendance} />}
    </SafeAreaView>
  )
}
