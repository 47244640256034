import { GenerateBankSlip, OpenBankSlip, OpenBankSlipParams, GenerateInsideBankSlip } from '@/domain/usecases'
import { BANK } from '@/domain/models'

export class RemoteOpenBankSlip implements OpenBankSlip {
  constructor (
    private readonly generateBankSlip: GenerateBankSlip,
    private readonly generateInsideBankSlip: GenerateInsideBankSlip
  ) {}

  async openPDF (params: OpenBankSlipParams): Promise<void> {
    let bankSlipUrl: string = ''

    if ([BANK.BoletoInside, BANK.CartaoInside].includes(params.codBanco)) {
      bankSlipUrl = await this.generateInsideBankSlip.generate(params)
    } else {
      if (params.registrado) {
        bankSlipUrl = {
          [BANK.Caixa]: 'https://bloquetoexpresso.caixa.gov.br/bloquetoexpresso/index.jsp',
          [BANK.Sicredi]: 'https://www.sicredi.com.br/segundaviaboleto/',
          [BANK.Itau]: 'https://www.itau.com.br/servicos/boletos/atualizar/',
          [BANK.Bradesco]: 'https://www.ib12.bradesco.com.br/ibpfsegundaviaboleto/segundaViaBoletoPesquisarLinhaDigitavel.do',
          [BANK.Santander]: 'https://www.santander.com.br/2-via-boleto',
          [BANK.Sicoob]: 'https://www.sicoob.com.br/web/sicoob/segunda-via-de-boleto',
          [BANK.Banestes]: 'https://wwws.banestes.com.br/formularios_consultas/servicos/serv_boletos.htm',
          [BANK.BoletoInside]: '',
          [BANK.CartaoInside]: ''
        }[params.codBanco]
      } else {
        bankSlipUrl = await this.generateBankSlip.generate(params)
      }
    }

    window.open(bankSlipUrl, '_blank')
  }
}
