import { atom } from 'recoil'
import { ServiceOrderDetailModel } from '@/domain/models'

export const isLoadingServiceOrderDetailState = atom({
  key: 'isLoadingServiceOrderDetailState',
  default: false
})

export const serviceOrderDetailState = atom<ServiceOrderDetailModel>({
  key: 'serviceOrderDetailState',
  default: null as unknown as ServiceOrderDetailModel
})
