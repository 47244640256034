import { AccessDeniedError, UnexpectedError } from '@/domain/errors'
import { GenericUserModel, SigmaCloudUserResult } from '@/domain/models'
import { GetSigmaCloudUsers, GetSigmaCloudUsersParams } from '@/domain/usecases'
import { HttpClient, HttpStatusCode } from '@/data/protocols/http'

export class RemoteGetSigmaCloudUsers implements GetSigmaCloudUsers {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<SigmaCloudUserResult>
  ) { }

  async getUsers (params: GetSigmaCloudUsersParams): Promise<GenericUserModel[]> {
    const { body, statusCode } = await this.httpClient.request({
      url: `${this.url}/api/sigmaCloud/listaUsuarios`,
      method: 'get',
      params
    })

    switch (statusCode) {
      case HttpStatusCode.ok:
        return this.mapToGeneric(body)
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }

  mapToGeneric (users: SigmaCloudUserResult): GenericUserModel[] {
    return users?.usuarios.map(user => ({
      nome: user.nome,
      codCliente: user.id.toString(),
      fone1: user.fone1,
      fone2: user.fone2
    }))
  }
}
