import React, { useCallback } from 'react'
import { FontAwesome5 as Icon } from '@expo/vector-icons'
import { useFocusEffect } from '@react-navigation/native'
import { useRecoilState } from 'recoil'
import { RedesSociaisModel } from '@/domain/models'
import { GetRedesSociais, SaveRedesSociais, SaveRedesSociaisParams } from '@/domain/usecases'
import { useErrorHandler, useNotify } from '@/presentation/hooks'
import { Box, Button, TextInput, Footer, socialMediaState } from '@/presentation/components'
import { styles } from './styles'

type RedesSociasProps = {
  saveRedesSociais: SaveRedesSociais
  getRedesSociais: GetRedesSociais
}

export const RedesSociais: React.FC<RedesSociasProps> = ({ saveRedesSociais, getRedesSociais }) => {
  const notify = useNotify()
  const handleError = useErrorHandler()
  const [state, setState] = useRecoilState(socialMediaState)

  useFocusEffect(
    useCallback(() => {
      void getRedesSociais.getRedesSociais()
        .then((res) => setState(currentState => ({ ...currentState, ...res })))
        .catch(handleError)
    }, [])
  )

  const handleChange = (name: keyof RedesSociaisModel, value: string): void => {
    setState((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleSave = (): void => {
    const saveParams: SaveRedesSociaisParams = {
      facebook: state.facebook,
      instagram: state.instagram,
      linkedin: state.linkedin,
      phone: state.phone,
      telegram: state.telegram,
      tiktok: state.tiktok,
      whatsapp: state.whatsapp,
      youtube: state.youtube
    }

    void saveRedesSociais.save(saveParams)
      .then(() => notify.show('Sucesso! Os links foram salvos!'))
      .catch(handleError)
  }

  return (
    <>
      <Box style={styles.container}>
        <TextInput
          value={state.phone}
          onChangeText={(text) => handleChange('phone', text)}
          leading={(props) => <Icon as Icon name='phone' {...props}/>}
          label='Número do Telefone'
        />
        <TextInput
          value={state.whatsapp}
          onChangeText={(text) => handleChange('whatsapp', text)}
          leading={(props) => <Icon as Icon name='whatsapp' {...props}/>}
          label='Whatsapp'
        />
        <TextInput
          value={state.facebook}
          onChangeText={(text) => handleChange('facebook', text)}
          leading={(props) => <Icon as Icon name='facebook' {...props}/>}
          label='Link do Facebook'
        />
        <TextInput
          value={state.linkedin}
          onChangeText={(text) => handleChange('linkedin', text)}
          leading={(props) => <Icon as Icon name='linkedin' {...props}/>}
          label='Link do Linkedin'
        />
        <TextInput
          value={state.instagram}
          onChangeText={(text) => handleChange('instagram', text)}
          leading={(props) => <Icon as Icon name='instagram' {...props}/>}
          label='Link do Instagram'
        />
        <TextInput
          value={state.tiktok}
          onChangeText={(text) => handleChange('tiktok', text)}
          leading={(props) => <Icon as Icon name='tiktok' {...props}/>}
          label='Link do Tiktok'
        />
        <TextInput
          value={state.telegram}
          onChangeText={(text) => handleChange('telegram', text)}
          leading={(props) => <Icon as Icon name='telegram' {...props}/>}
          label='Link do Telegram'
        />
      </Box>
      <Footer>
        <Box style={styles.footer}>
          <Button variant='text' title='Gravar' onPress={handleSave} />
        </Box>
      </Footer>
    </>
  )
}
