import React, { useEffect } from 'react'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { GestureResponderEvent } from 'react-native'
import { ForgotPassword, ForgotPasswordParams } from '@/domain/usecases'
import { ModalBase, Box, Button, Text, TextInput } from '@/presentation/components'
import { styles } from './styles'
import { useFormat, useNotify, useTheme } from '@/presentation/hooks'
import { isLoadingState, isOpenForgotPasswordState, loginState } from '../atom'

type ModalForgotPasswordProps = {
  forgotPassword: ForgotPassword
}

export const ModalForgotPassword: React.FC<ModalForgotPasswordProps> = ({ forgotPassword }) => {
  const theme = useTheme()
  const notify = useNotify()
  const { formatDocument } = useFormat()
  const [state, setState] = useRecoilState(loginState)
  const [isOpen, setOpen] = useRecoilState(isOpenForgotPasswordState)
  const [isLoading, setLoading] = useRecoilState(isLoadingState)
  const reset = useResetRecoilState(loginState)

  const handleOnRequestAccess = (event: GestureResponderEvent): void => {
    event.preventDefault()
    setLoading(true)

    const params: ForgotPasswordParams = {
      email: state.recoveryEmail,
      documento: state.document
    }

    forgotPassword
      .requestAccess(params)
      .then(message => {
        notify.show(message)
        reset()
      })
      .catch(error => notify.show(error.message, { variant: 'error' }))
      .finally(() => setLoading(false))

    handleCloseModal()
  }

  const handleCloseModal = (): void => setOpen(false)

  return (
    <ModalBase visible={isOpen} onClose={handleCloseModal}>
      <Box style={styles.container}>
        <Text variant="h4">Recuperar senha</Text>

        <TextInput
          maxLength={18}
          label="Documento*"
          placeholder='Informe o CPF/CNPJ'
          onChangeText={text => { 
            setState(currentValues => ({
              ...currentValues,
              document: formatDocument(text)
            }))
          }}
          value={state.document}
        />

        <TextInput
          label="E-mail*"
          onChangeText={text => { setState(currentValues => ({ ...currentValues, recoveryEmail: text })) }}
          value={state.recoveryEmail}
        />

        <Box style={styles.buttonArea}>
          <Button
            color={theme.colors.shadow}
            variant="text"
            title="Cancelar"
            onPress={handleCloseModal}
            disabled={isLoading}
          />
          <Button
            variant="text"
            title="Recuperar"
            onPress={handleOnRequestAccess}
            color={theme.colors.success}
            tintColor={theme.colors.white}
            disabled={isLoading || !(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.recoveryEmail)) || !state.document}
          />
        </Box>
      </Box>
    </ModalBase>
  )
}
