import React from 'react'
import { Cartao } from '@/presentation/pages/Cartao'
import { NavigationPagesProps, TabBottomNavigator } from '@/presentation/components'
import { makeGetCreditCard, makeSaveCreditCard } from '@/main/factories/usecases'

export const makeCardFactory: React.FC = ({ navigation }: NavigationPagesProps) => {
  return (
    <>
      <Cartao
        getCard={makeGetCreditCard()}
        saveCard={makeSaveCreditCard()}
      />
      <TabBottomNavigator navigation={navigation} />
    </>
  )
}
