import { StyleSheet } from 'react-native'
import { layoutStyles } from '@/presentation/styles/globals'

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom: 60
  },
  content: {
    ...layoutStyles.pageContent,
    marginVertical: 0,
    marginHorizontal: 'auto'
  },
  cardArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '100%',
    gap: 16
  },
  bold: {
    fontWeight: '600'
  },
  title: {
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, 0.12)',
    paddingBottom: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  emptyNf: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    gap: 16
  },
  scroll: {
    paddingBottom: 60
  }
})
