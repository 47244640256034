import { GetClientContract, GetClientContractParams } from '@/domain/usecases/'
import { HttpClient, HttpStatusCode } from '@/data/protocols/http'
import { AccessDeniedError, UnexpectedError, UnexpectedResultFormatError } from '@/domain/errors'
import { ClientContractModel } from '@/domain/models'

export class RemoteGetClientContract implements GetClientContract {
  constructor (
    private readonly url: string,
    private readonly httpCliente: HttpClient<ClientContractModel>
  ) {}

  async getContract (params: GetClientContractParams): Promise<ClientContractModel> {
    const { statusCode, body } = await this.httpCliente.request({
      method: 'get',
      url: `${this.url}/api/portal/dados-contrato`,
      params
    })

    switch (statusCode) {
      case HttpStatusCode.ok:
        if (!body) {
          throw new UnexpectedResultFormatError()
        }
        return body
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}
