import { HttpClient, HttpRequest, HttpStatusCode } from '@/data/protocols'
import { AccessDeniedError, ResultWithoutRecordsError, UnexpectedError, UnexpectedResultFormatError } from '@/domain/errors'
import { PrintEvents, PrintEventsParams } from '@/domain/usecases'

export class RemotePrintEvents implements PrintEvents {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<string>
  ) {}

  async print (params: PrintEventsParams): Promise<void> {
    const { tipoConexao, data } = params
    const request: HttpRequest = {
      url: `${this.url}/api/eventosmonitoramento`,
      method: 'post',
      params: { tipoConexao },
      body: data
    }

    const { statusCode, body } = await this.httpClient.request(request)

    const oldUrlIndex = this.url.toLowerCase().indexOf('/portalservice')
    const newUrl = oldUrlIndex === -1 ? this.url : this.url.substring(oldUrlIndex)

    switch (statusCode) {
      case HttpStatusCode.ok:
        if (!body) {
          throw new UnexpectedResultFormatError()
        }
        window.open(`${newUrl}/TempRelatorios/${body}`, '_blank')
        break
      case HttpStatusCode.noContent: throw new ResultWithoutRecordsError()
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}
