import React from 'react'
import { useRecoilState } from 'recoil'
import { ThemeColors, ThemeModel } from '@/domain/models'
import { TextInput } from '@/presentation/components'
import { useMediaQueries } from '@/presentation/hooks'
import { themeState } from '@/presentation/pages/Theme/components'
import { styles } from './styles'

type ColorPickerProps = {
  name: keyof ThemeColors
  label: string
}

export const ColorPicker: React.FC<ColorPickerProps> = ({ name, label }) => {
  const { isMobile } = useMediaQueries()
  const [themeClient, setThemeClient] = useRecoilState(themeState)

  const inputStyle = {
    ...styles.input,
    ...(isMobile
      ? { width: '100%' }
      : { flex: 1 }
    )
  }

  const handleSelect = (param: keyof ThemeColors, color: string): void => {
    setThemeClient(currentState => ({
      ...currentState,
      theme: ({ ...currentState.theme, [param]: color }) as ThemeModel
    }))
  }

  return (
    <TextInput
      variant='filled'
      label={label}
      style={inputStyle}
      value={themeClient.theme[name]}
      color={themeClient.theme.mainColor}
      editable={false}
      trailing={() =>
        <input
          type="color"
          style={styles.inputColor}
          value={themeClient.theme[name]}
          onChange={(event) => handleSelect(name, event.target.value)}
        />
      }
    />
  )
}
