import { atom } from 'recoil'

export type ImageProps = {
  uri: string
  description: string
}

export const selectedOsImageViewState = atom<ImageProps>({
  key: 'selectedOsImageViewState',
  default: null as unknown as ImageProps
})
