import { AccessDeniedError, UnexpectedError, UnexpectedResultFormatError } from '@/domain/errors'
import { OpenUrlDocumentAcceptParams, OpenUrlDocumentAccept } from '@/domain/usecases'
import { HttpClient, HttpRequest, HttpStatusCode } from '@/data/protocols/http'

export class RemoteOpenUrlDocAccept implements OpenUrlDocumentAccept {
  constructor (
    private readonly httpGetClient: HttpClient<string>
  ) { }

  async view (params: OpenUrlDocumentAcceptParams): Promise<void> {
    const { statusAceite, urlAceite } = params

    if (statusAceite === 'Pendente') {
      window.open(urlAceite, '_blank')
    } else {
      const hash = urlAceite.substring(urlAceite.indexOf('#/aceite/') + 9)
      const urlBaseAceite = urlAceite.substring(0, urlAceite.indexOf('AceiteDigital/#'))

      const request: HttpRequest = {
        url: `${urlBaseAceite}api/AceiteEletronico?link=${hash}`,
        method: 'post',
        params: {
          enviarEmail: false
        }
      }

      const { statusCode, body } = await this.httpGetClient.request(request)

      switch (statusCode) {
        case HttpStatusCode.ok:
          if (!body) {
            throw new UnexpectedResultFormatError()
          }

          const certificadoHtml = new Blob([body], { type: 'text/html;charset=utf-8' })
          const html = URL.createObjectURL(certificadoHtml)
          window.open(html, '_blank')

          break
        case HttpStatusCode.unauthorized: throw new AccessDeniedError()
        default: throw new UnexpectedError()
      }
    }
  }
}
