import React from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { SaveUnitConfig } from '@/domain/usecases'
import { Box, Button, Text, ModalBase, Loading } from '@/presentation/components'
import { useNotify, useSettings, useTheme } from '@/presentation/hooks'
import { configsState, initialConfigsState } from '@/presentation/pages/Settings/components'
import { isLoadingSaveOrDiscardConfigState, isOpenSaveOrDiscardConfigState } from './atom'
import { styles } from './styles'

type ModalOnSaveOrDiscardProps = {
  saveUnitConfig: SaveUnitConfig
}

export const ModalOnSaveOrDiscard: React.FC<ModalOnSaveOrDiscardProps> = ({ saveUnitConfig }) => {
  const theme = useTheme()
  const notify = useNotify()
  const { discardChanges } = useSettings()
  const currentConfig = useRecoilValue(configsState)
  const [isLoading, setLoading] = useRecoilState(isLoadingSaveOrDiscardConfigState)
  const [isOpen, setOpen] = useRecoilState(isOpenSaveOrDiscardConfigState)
  const setInitialConfigs = useSetRecoilState(initialConfigsState)

  const handleDiscardChanges = (): void => {
    discardChanges()
    handleClose()
    notify.show('As alterações foram descartadas')
  }

  const handleSaveChanges = (): void => {
    setLoading(true)
    handleClose()

    saveUnitConfig
      .save(currentConfig)
      .then(hasUpdated => {
        if (hasUpdated) {
          notify.show(`As configurações da unidade ${currentConfig.uniCodigo.toString().padStart(4, '0')} foram gravadas com sucesso`)
          setInitialConfigs(currentConfig)
        } else {
          notify.show(`Não foi possível gravar as configurações da unidade ${currentConfig.uniCodigo}, tente novamente`, { variant: 'error' })
        }
      })
      .catch(error => {
        notify.show(error.message, { variant: 'error' })
        handleClose()
      })
      .finally(() => setLoading(false))
  }

  const handleClose = (): void => setOpen(false)

  return (
    <ModalBase visible={isOpen} onClose={handleClose}>
      {isLoading
        ? <Loading text='Gravando configurações da unidade' />
        : <Box style={styles.container}>
            <Text variant="subtitle1">Existem alterações que ainda não foram gravadas na unidade. <br />Descarte ou grave as alterações para prosseguir</Text>

            <Box style={styles.buttonArea}>
              <Button
                title="DESFAZER"
                variant="text"
                color={theme.colors.shadow}
                disabled={isLoading}
                onPress={handleDiscardChanges}
              />
              <Button
                title="GRAVAR"
                color={theme.colors.success}
                tintColor={theme.colors.white}
                disabled={isLoading}
                onPress={handleSaveChanges}
              />
            </Box>
          </Box>
        }
    </ModalBase>
  )
}
