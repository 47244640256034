import { ConfigModel } from '@/domain/models'
import { GetUnitConfig } from '@/domain/usecases'
import { RemoteGetUnitConfig } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'

export const makeGetUnitConfig = (): GetUnitConfig => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<ConfigModel>()

  return new RemoteGetUnitConfig(url, httpClient)
}
