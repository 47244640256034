import React from 'react'
import { Divider } from 'react-native-paper'
import { Box, HStack, VStack, Text } from '@react-native-material/core'
import { GenericUserModel } from '@/domain/models'
import { theme } from '@/presentation/styles'
import { useMediaQueries } from '@/presentation/hooks'
import { styles } from './styles'

type CardUserProps = {
  user: GenericUserModel
}

export const CardUser: React.FC<CardUserProps> = ({ user }: CardUserProps) => {
  const { isMobile } = useMediaQueries()

  const containerStyles = {
    ...styles.container,
    ...(!isMobile && {
      flex: 1,
      maxWidth: 600
    }),
    backgroundColor: theme.colors.white,
    color: theme.colors.greyLighten
  }

  const textTitleStyles = {
    ...styles.contentText,
    color: theme.colors.texts
  }

  return (
    <Box style={containerStyles}>
      <HStack items='center' justify='between' spacing={8} mb={8} mt={8}>

        {user?.nome &&
          <Text variant='subtitle1' style={textTitleStyles} testID='user-nome'>
            <span style={styles.contentTitle}>
              Nome:
            </span>
            {user.nome}
          </Text>}

        {user.codCliente &&
          <Text variant='subtitle1' style={textTitleStyles} testID='user-codigo-cliente'>
            <span style={styles.contentTitle}>
              Codigo do cliente:
            </span>
            {user.codCliente}
          </Text>}
      </HStack>

      {user.fone1 &&
        <VStack spacing={8} style={{ flex: 1 }}>
          <Divider style={styles.divider} />
          <>
            <Text variant='subtitle1' style={textTitleStyles} testID='user-fone'>
              <span style={styles.contentTitle}>
                Fone:
              </span>
              {user.dddFone ? `(${user.dddFone})` : ''}
              {user.fone1}
            </Text>
            {user.fone2 &&
              <Text variant='subtitle1' style={textTitleStyles}>
                <span style={styles.contentTitle}>
                  Fone 2:
                </span>
                {user?.fone2}
              </Text>
            }
            {user.fone3 &&
              <Text variant='subtitle1' style={textTitleStyles}>
                <span style={styles.contentTitle}>
                  Fone 3:
                </span>
                {user?.fone3}
              </Text>
            }
          </>
        </VStack>}
    </Box>
  )
}
