import { HttpClient, HttpStatusCode } from '@/data/protocols/http'
import { AccessDeniedError, ForbiddenError, UnexpectedError, UnexpectedResultFormatError } from '@/domain/errors'
import { PayWithCreditCard, PayWithCreditCardParams } from '@/domain/usecases'

export class RemotePayWithCreditCard implements PayWithCreditCard {
  constructor (
    private readonly url: string,
    private readonly httpCliente: HttpClient<string>
  ) {}

  async pay (params: PayWithCreditCardParams): Promise<string> {
    const { statusCode, body } = await this.httpCliente.request({
      method: 'post',
      url: `${this.url}/api/pagamentocartao`,
      body: {
        duplicata: params.duplicata,
        cartao: {
          ...params.cartao,
          expiracao: this.formatExpiration(params.cartao.expiracao)
        }
      }
    })

    switch (statusCode) {
      case HttpStatusCode.ok:
        if (!body) {
          throw new UnexpectedResultFormatError()
        }
        return body
      case HttpStatusCode.notAcceptable: throw new ForbiddenError(body)
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }

  formatExpiration (expiration: string): string {
    const currentYear = new Date()
      .getFullYear()
      .toString()
      .substring(0, 2)

    return `${expiration.split('/')[0]}/${currentYear}${expiration.split('/')[1]}`
  }
}
