import React from 'react'
import { Box, InputField, Text, VStack, CardDetails, HStack } from '@/presentation/components'
import { useAuth } from '@/presentation/hooks'
import { styles } from '../../styles'

export const CompanyCard: React.FC = () => {
  const { getClient } = useAuth()

  const client = getClient()

  return (
    <HStack
      spacing={16}
      justify='between'
      style={{ width: '100%' }}
      wrap
      testID='contract-company-card'
    >
      <CardDetails maxWidth={'100%'}>
        <Box style={styles.titleBox}>
          <Text style={styles.bold}>Dados Gerais</Text>
        </Box>
        <InputField>
          <VStack style={{ flex: 1 }}>
            <Text style={styles.bold} testID='contract-company-nome-cliente'>Razão Social</Text>
            <Text>{ client.nomeCliente }</Text>
          </VStack>
          <VStack style={{ flex: 1 }}>
            <Text style={styles.bold} testID='contract-company-fantasia'>Fantasia</Text>
            <Text>{ client.fantasia ? client.fantasia : 'Não informado' }</Text>
          </VStack>
          <VStack style={{ flex: 1 }}>
            <Text style={styles.bold} testID='contract-company-cpf'>CPF/CNPJ</Text>
            <Text>{ client.cpfCnpj }</Text>
          </VStack>
        </InputField>
        <InputField>
          <VStack style={{ flex: 1 }}>
            <Text style={styles.bold} testID='contract-company-email'>E-mail</Text>
            <Text>{ client.email ? client.email : 'Não informado' }</Text>
          </VStack>
          <VStack style={{ flex: 1 }}>
            <Text style={styles.bold} testID='contract-company-phone'>Telefone</Text>
            <Text>{ client.telefone1 || client.telefone2 ? `${client.telefone1} ${client.telefone2}` : 'Não informado' }</Text>
          </VStack>
          <VStack style={{ flex: 1 }}>
            <Text style={styles.bold} testID='contract-company-address'>Endereço</Text>
            <Text>{
              `${client.enderecoGeral.endereco}, ${client.enderecoGeral.numero} - ` +
              `${client.enderecoGeral.bairro} - ${client.enderecoGeral.cidade}/${client.enderecoGeral.estado}`
            }</Text>
          </VStack>
        </InputField>
      </CardDetails>
    </HStack>
  )
}
