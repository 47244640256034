import React, { useCallback, useRef } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { SafeAreaView } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { ScrollView } from 'react-native-gesture-handler'
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import { CreateSac, GetSacList } from '@/domain/usecases'
import { DateAdapter } from '@/data/protocols'
import { ActionsButtons, Box, CardList, FAB, PageHeader } from '@/presentation/components'
import { sacState, ModalNewSac, CardSac, isLoadingState, isOpenNewSacState } from '@/presentation/pages/Sac/components'
import { useAuth, useErrorHandler, useTheme, useThemeClient } from '@/presentation/hooks'
import { styles } from './styles'

type SacProps = {
  dateAdapter: DateAdapter
  getSacList: GetSacList
  createSac: CreateSac
}

export const Sac: React.FC<SacProps> = ({ dateAdapter, getSacList, createSac }: SacProps) => {
  const theme = useTheme()
  const { getThemeClient } = useThemeClient()
  const handleError = useErrorHandler()
  const { getClient } = useAuth()
  const [sacs, setSacs] = useRecoilState(sacState)
  const [isLoading, setLoading] = useRecoilState(isLoadingState)
  const setOpenNewSac = useSetRecoilState(isOpenNewSacState)
  const scrollViewRef = useRef(null)

  const client = getClient()
  const themeClient = getThemeClient()

  useFocusEffect(
    useCallback(() => {
      setLoading(true)

      getSacList
        .get({ codigoCliente: client.codCliente })
        .then(setSacs)
        .catch(handleError)
        .finally(() => setLoading(false))
    }, [])
  )

  const handleOpenNewSac = (): void => {
    setOpenNewSac(true)
  }

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView ref={scrollViewRef} contentContainerStyle={styles.scroll}>
        <Box style={styles.content}>
          <PageHeader
            text='SAC'
            icon={<MaterialIcons size={24} color={theme.colors.grey} name='support-agent' />}
          />
          <CardList
            isLoading={isLoading}
            isEmpty={!sacs?.listaReclamacoes?.length}
            textLoading='Buscando SACs'
          >
            {sacs.listaReclamacoes?.map(item => (
              <CardSac
                key={item.codReclamacao}
                dateAdapter={dateAdapter}
                sac={item}
              />
            ))}
          </CardList>
        </Box>

      </ScrollView>
      <ActionsButtons>
        <FAB
          onPress={handleOpenNewSac}
          testID='sac-novo'
          icon={props => <MaterialCommunityIcons name="plus" {...props} />} color={themeClient.mainColor}
        />
      </ActionsButtons>
      <ModalNewSac createSac={createSac} dateAdapter={dateAdapter} />
    </SafeAreaView>
  )
}
