import React from 'react'
import { useSetRecoilState } from 'recoil'
import { ServiceOrderModel, ServiceOrderStatus } from '@/domain/models'
import { GetServiceOrders } from '@/domain/usecases'
import { DateAdapter } from '@/data/protocols'
import { useAuth, useFormat, useMediaQueries, useTheme } from '@/presentation/hooks'
import { Box, Chip, Divider, HStack, Text, VStack } from '@/presentation/components'
import { serviceOrderViewState } from '@/presentation/pages/ServiceOrders/components'
import { styles, CircleIcon, MarkerIcon } from './styles'

type CardOsProps = {
  serviceOrder: ServiceOrderModel
  dateAdapter: DateAdapter
}

export const CardOs: React.FC<CardOsProps> = ({
  serviceOrder,
  dateAdapter
}) => {
  const theme = useTheme()
  const { getAddress } = useAuth()
  const { removeNewLines } = useFormat()
  const { isMobile } = useMediaQueries()
  const setOsView = useSetRecoilState(serviceOrderViewState)

  const address = getAddress(serviceOrder.codEndereco)

  const containerStyles = {
    ...styles.container,
    ...(!isMobile && { flex: 1 }),
    backgroundColor: theme.colors.white,
    color: theme.colors.greyLighten
  }

  const textTitleStyles = {
    ...styles.title,
    color: theme.colors.texts
  }

  const colorStatusStyle = (status: ServiceOrderStatus): string => {
    return {
      A: theme.colors.success,
      B: theme.colors.error,
      F: theme.colors.info
    }[status]
  }

  const makeTextStatus = (status: ServiceOrderStatus): string => {
    return {
      A: 'Aberta',
      B: 'Fechada',
      F: 'Faturada'
    }[status]
  }

  const makeHeader = (): JSX.Element => {
    return (
      <HStack items='center' spacing={4} style={styles.headerStyles}>
        <Text
          style={{
            ...styles.title,
            color: theme.colors.textsDark,
            flex: 1,
            textTransform: 'uppercase'
          }}
          variant='h3'
          testID='os-card-nome'
        >
          {serviceOrder.solicitante
            ? serviceOrder.solicitante
            : 'Solicitante não informado'
          }
        </Text>
        <Text
          style={{
            ...styles.textOrderStyles,
            color: theme.colors.textsDark
          }}
          variant='h3'
        >
          Nº {serviceOrder.ordem.toString().padStart(6, '0')}
        </Text>
        <CircleIcon
          size={12}
          color={colorStatusStyle(serviceOrder.status)}
          name='circle'
        />
      </HStack>
    )
  }

  return (
    <Box style={containerStyles}>
      {makeHeader()}
      <VStack justify='between' spacing={6} style={{ flex: 1 }} fill>
        <Divider />
        <Text
          style={{
            ...textTitleStyles,
            ...(!serviceOrder.observacoes && { flex: 1 })
          }}
          variant='h4'
        >
          {'Data Abertura: '}
          <span style={styles.textStyles}>
            {dateAdapter.format(serviceOrder.dataAbertura, 'DD/MM/yyyy HH:mm')}
          </span>
        </Text>
        {!!serviceOrder.dataFechamento &&
          serviceOrder.status !== ServiceOrderStatus.Aberta &&
          <Text style={textTitleStyles} variant='h4'>
            {'Data Fechamento: '}
          <span style={styles.textStyles}>
            {dateAdapter.format(serviceOrder.dataFechamento, 'DD/MM/yyyy HH:mm')}
          </span>
          </Text>
        }
        {serviceOrder.observacoes &&
          <Text style={{ ...textTitleStyles, lineHeight: 20, flex: 1 }} variant='subtitle1'>
            {'Observações: '}
            <span style={{ ...styles.textStyles, flex: 1 }}>
              {removeNewLines(serviceOrder.observacoes)
                .substring(0, GetServiceOrders.RN.OBSERVATION_MAX_LENGTH)
              }
            </span>
          </Text>
        }
        <HStack spacing={6} justify='end' overflow='hidden' mv={6}>
        {address &&
          <Text style={{ ...styles.address, color: theme.colors.greyLight }}>
            {`${address.endereco.toUpperCase()}, ${address.numero.toUpperCase()} - ${address.bairro.toUpperCase()}`}
          </Text>
        }
          <MarkerIcon name='marker' style={{ fontSize: 18 }} color={theme.colors.greyLight} />
        </HStack>
        <HStack justify='between' mt={4}>
          <HStack testID='os-status'>
            <Text style={{ ...textTitleStyles }} variant='subtitle1'>{'Status: '}</Text>
            <Chip
              text={makeTextStatus(serviceOrder.status)}
              containerStyle={{ backgroundColor: colorStatusStyle(serviceOrder.status) }}
              textStyle={{ fontSize: 12 }}
            />
          </HStack>
          <Chip
            text='Ver Detalhes'
            textStyle={{ fontSize: 12, color: theme.colors.info }}
            containerStyle={{ borderColor: theme.colors.info }}
            variant='outlined'
            onClick={() => setOsView(currentState => ({ ...currentState, currentOs: serviceOrder }))}
          />
        </HStack>
      </VStack>
    </Box>
  )
}
