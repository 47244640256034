import { GetRemotyContacts } from '@/domain/usecases'
import { RemotyContactResult } from '@/domain/models'
import { RemoteGetRemotyContacts } from '@/data/usecases/events'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'

export const makeGetRemotyContactsFactory = (): GetRemotyContacts => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<RemotyContactResult | string>()

  return new RemoteGetRemotyContacts(url, httpClient)
}
