import { RemoteChangeTokenNotificationsStatus } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import { ChangeTokenNotificationsStatus } from '@/domain/usecases'
import env from '@/main/config/env'

export const makeChangeTokenNotificationsStatus = (): ChangeTokenNotificationsStatus => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<string>()

  return new RemoteChangeTokenNotificationsStatus(url, httpClient)
}
