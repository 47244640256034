import { ThemeModel } from '@/domain/models'
import { GetTheme, GetThemeParams } from '@/domain/usecases'
import { AccessDeniedError, NotFoundError, UnexpectedError, UnexpectedResultFormatError } from '@/domain/errors'
import { HttpClient, HttpRequest, HttpStatusCode } from '@/data/protocols'

export class RemoteGetTheme implements GetTheme {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<ThemeModel>
  ) { }

  async get (params: GetThemeParams): Promise<ThemeModel> {
    const request: HttpRequest = {
      url: `${params.enderecoExternoIntegra || this.url}/api/portal/tema`,
      method: 'get',
      params: { codigoUnidade: params.codigoUnidade }
    }

    const { statusCode, body } = await this.httpClient.request(request)

    switch (statusCode) {
      case HttpStatusCode.ok:
        if (!body) {
          throw new UnexpectedResultFormatError()
        }
        return body
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      case HttpStatusCode.notFound: throw new NotFoundError('Endereço de API não está respondendo')
      default: throw new UnexpectedError()
    }
  }
}
