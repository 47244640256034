import { UploadFile } from '@/domain/usecases'
import { RemoteUploadFile } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import { FileModel } from '@/domain/models'

export const makeUploadFile = (): UploadFile => {
  const httpClient = makeAxiosHttpClient<FileModel>()

  return new RemoteUploadFile(httpClient)
}
