import React from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { Box, Button, Text, ModalBase } from '@/presentation/components'
import { useNotify, useTheme } from '@/presentation/hooks'
import { initialThemeState, themeState } from '@/presentation/pages/Theme/components'
import { modalOnDiscardChangesThemeState } from './atom'
import { styles } from './styles'

export const ModalOnDiscardChanges: React.FC = () => {
  const theme = useTheme()
  const notify = useNotify()
  const setThemeClient = useSetRecoilState(themeState)
  const initialTheme = useRecoilValue(initialThemeState)
  const [isOpenDiscardChanges, setOpenDiscardChanges] = useRecoilState(modalOnDiscardChangesThemeState)

  const handleDiscardChanges = (): void => {
    setThemeClient(currentState => ({ ...currentState, theme: initialTheme }))
    setOpenDiscardChanges(false)
    notify.show('As alterações foram descartadas')
  }

  const handleClose = (): void => setOpenDiscardChanges(false)

  return (
    <ModalBase visible={isOpenDiscardChanges} onClose={handleClose}>
      <Box style={styles.container}>
        <Text variant="subtitle1">Deseja realmente desfazer as alterações?</Text>

        <Box style={styles.buttonArea}>
          <Button
            color={theme.colors.shadow}
            variant="text"
            title="CANCELAR"
            onPress={handleClose}
          />
          <Button
            title="DESFAZER"
            onPress={handleDiscardChanges}
            color={theme.colors.success}
            tintColor={theme.colors.white}
          />
        </Box>
      </Box>
    </ModalBase>
  )
}
