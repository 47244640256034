import React, { useState } from 'react'
import { Menu } from 'react-native-paper'
import { SigmaCloudKeyResponseModel } from '@/domain/models'
import { useMediaQueries, useThemeClient } from '@/presentation/hooks'
import { Box, FAB } from '@/presentation/components'
import { useRecoilState, useRecoilValue } from 'recoil'
import { selectSigmaCloudKeyData } from './atom'
import { sigmaKeysState } from '../atom'

export const SelectSigmaCloudKey: React.FC = () => {
  const { getThemeClient } = useThemeClient()
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [currentKey, setCurrentKey] = useRecoilState(selectSigmaCloudKeyData)
  const keys = useRecoilValue(sigmaKeysState)
  const themeClient = getThemeClient()
  const { isMobile } = useMediaQueries()

  const handleSelectCompany = (company: SigmaCloudKeyResponseModel): void => {
    setCurrentKey(company)
    setMenuOpen(false)
  }

  const customStyle = (): object => {
    if (isMobile) {
      return {
        margin: 'auto'
      }
    } else {
      return {
        marginLeft: 'auto'
      }
    }
  }

  return (
    <Box style={customStyle()}>
      <Menu
        contentStyle={{
          width: 300,
          marginTop: 4
        }}
        visible={isMenuOpen}
        onDismiss={() => setMenuOpen(false)}
        anchorPosition='bottom'
        anchor={
          <FAB
            color={themeClient.mainColor}
            variant='extended'
            label={currentKey.empresaSigma ? `Empresa: ${currentKey.empresaSigma.toString()} - Chave: ${currentKey.chave}/${currentKey.particao}` : 'Empresa - Chave/Partição'}
            onPress={() => setMenuOpen(true)}
            size='mini'
          />
        }
      >
        {keys.map((key) => (
          <Menu.Item
            key={key.idMonitoramento}
            onPress={() => handleSelectCompany(key)}
            title={`Empresa: ${key.empresaSigma.toString()} - Chave: ${key.chave}/${key.particao}`}
          />
        ))}
      </Menu>
    </Box>
  )
}
