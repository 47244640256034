import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'
import { SigmaEventModel } from '@/domain/models'
import { GetSigmaEvents } from '@/domain/usecases'
import { RemoteGetSigmaEvents } from '@/data/usecases/events'

export const makeGetSigmaEventsFactory = (): GetSigmaEvents => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<SigmaEventModel[]>()

  return new RemoteGetSigmaEvents(url, httpClient)
}
