
import { OpenDocumentAccept } from '@/domain/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'
import { RemoteOpenDocumentAccept } from '@/data/usecases'

export const makeOpenDocumentAcceptFactory = (): OpenDocumentAccept => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<string>()

  return new RemoteOpenDocumentAccept(url, httpClient)
}
