import React from 'react'
import { useRecoilValue } from 'recoil'
import { ScrollView } from 'react-native-gesture-handler'
import { DateAdapter } from '@/data/protocols'
import { useFormat } from '@/presentation/hooks'
import { VStack, HStack, Box, Text, Divider } from '@/presentation/components'
import { serviceOrderViewState } from '@/presentation/pages/ServiceOrders/components'
import { styles } from './styles'

type OsAttendandesProps = {
  dateAdapter: DateAdapter
}

export const OsAttendandes: React.FC<OsAttendandesProps> = ({ dateAdapter }) => {
  const serviceOrder = useRecoilValue(serviceOrderViewState)
  const { removeNewLines } = useFormat()

  return (
    <ScrollView style={styles.attendancesContainer}>
      {!!serviceOrder.attendances.length && <Text style={{ ...styles.title, marginBottom: 16 }}>Atendimentos</Text>}
        {serviceOrder.attendances?.map((attendance, index) => (
          <VStack key={`attendances-vstack-${index}`}>
            <HStack>
              <Text style={styles.titleAttendances}>{'Técnico: '}</Text>
              <Text>{attendance.nomeTecnico}</Text>
            </HStack>
            <HStack justify='between'>
              <HStack>
                <Text style={styles.titleAttendances}>{'Início: '}</Text>
                <Text style={styles.attendancesText}>{dateAdapter.format(attendance.dataInicio, 'DD/MM/yyyy HH:mm')}</Text>
              </HStack>
              <HStack>
                <Text style={styles.titleAttendances}>{'Término: '}</Text>
                <Text style={styles.attendancesText}>{dateAdapter.format(attendance.dataTermino, 'DD/MM/yyyy HH:mm')}</Text>
              </HStack>
            </HStack>
            <Box style={{ maxWidth: 520 }}>
              <Text style={styles.titleAttendances}>Observações</Text>
              <Text style={styles.attendancesText}>{removeNewLines(attendance.observacoes)}</Text>
            </Box>
            <Divider style={{ marginTop: 12, marginBottom: 12 }} />
          </VStack>
        ))}
    </ScrollView>
  )
}
