import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  cardArea: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '100%',
    gap: 16
  },
  titleBox: {
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, 0.12)',
    paddingBottom: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  pageInfo: {
    fontWeight: '600',
    fontSize: 20,
    marginBottom: 20,
    marginTop: 20
  },
  bold: {
    fontWeight: '600'
  },
  additionalText: {
    fontSize: 13,
    fontStyle: 'italic'
  },
  additionalTextBox: {
    borderTopWidth: 1,
    borderTopColor: 'rgba(0, 0, 0, 0.12)',
    paddingTop: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  totalizerTextBox: {
    marginTop: 10,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  cardBase: {
    display: 'flex',
    borderRadius: 4,
    minWidth: 280,
    maxWidth: 600
  },
  cardContent: {
    display: 'flex',
    gap: 8
  },
  imageContainer: {
    maxWidth: 280,
    width: '100%',
    height: 280,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
  }
})
