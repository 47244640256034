export type ServiceOrderDetailModel = {
  planilha: number
  codInterno: number
  unidade: number
  numOS: number
  tipoOSDescricao: string
  status: ServiceOrderDetailStatus
  executar: string
  obsFechamento: string

  listaProdutos: ServiceOrderProductModel[]
  listaOsServicos: ServiceOrderServiceModel[]
  listaAtendimentos: ServiceOrderTreatmentModel[]

  totalProdutos: number
  descontoProd: number
  pDesconto: number
  totalServicos: number
  descontoServ: number
  pDescontoServico: number

  logoEmpresa: string
  exibeValoresProdutosServicos: boolean
  assinaturasFechamento: ServiceOrderImageModel[]
  imagensGerais: ServiceOrderImageModel[]
  checklist: Checklist
} & ServiceOrderClientModel

type Checklist = {
  titulo: string
  perguntas: ChecklistPergunta[]
}

export type ChecklistPergunta = {
  pergunta: string
  codInterno: number
  descritiva: boolean
  oSCheckListsRespostas: {
    resposta: boolean
    detalhes: string
  }
}

export type ServiceOrderProductModel = {
  codInterno: number
  codProduto: number
  descricao: string
  quantidade: number
  unitario: number
  total: number
  cobraCliente: boolean
  motivo: string
  entregue: number
  unidadeMedida: string
}

export type ServiceOrderServiceModel = {
  codInterno: number
  codServico: number
  descricao: string
  quantidade: number
  total: number
  unitario: number
  cobraCliente: boolean
}

export type ServiceOrderTreatmentModel = {
  codTecnico: number
  tecnico: string
  data: string
  hrInicio: string
  hrFim: string
  hrInicioDeslocamento: string
  hrFimDeslocamento: string
  responsavel: string
  observacoes: string
  tipoAtendimento: string
}

export enum ServiceOrderDetailStatus {
  Aberta = 'A',
  Fechada = 'B',
  Faturada = 'F'
}

export type ServiceOrderImageModel = {
  nomeArquivo: string
  dataModificacao: string
  data64: string
}

export type ServiceOrderClientModel = {
  codCliente: number
  cliente: string
  fantasia: string
  cpf: string
  fone1: string
  endereco: string
  bairro: string
  cidade: string
  estado: string
  emailCliente: string
}
