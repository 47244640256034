import { GetProspectConfigs } from '@/domain/usecases'
import { ProspectConfigsModel } from '@/domain/models'
import { AccessDeniedError, UnexpectedError, UnexpectedResultFormatError } from '@/domain/errors'
import { HttpClient, HttpRequest, HttpStatusCode } from '@/data/protocols'

export class RemoteGetProspectConfigs implements GetProspectConfigs {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<ProspectConfigsModel>
  ) { }

  async getAll (): Promise<ProspectConfigsModel> {
    const request: HttpRequest = {
      url: `${this.url}/api/portal/configuracoes-prospect`,
      method: 'get'
    }

    const { statusCode, body } = await this.httpClient.request(request)

    switch (statusCode) {
      case HttpStatusCode.ok:
        if (!body) {
          throw new UnexpectedResultFormatError()
        }
        return body
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}
