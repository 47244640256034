import { HttpClient, HttpStatusCode } from '@/data/protocols/http'
import { AccessDeniedError, ForbiddenError, UnexpectedError } from '@/domain/errors'
import { SaveNotificationToken, SaveNotificationTokenParams } from '@/domain/usecases'

export class RemoteSaveNotificationToken implements SaveNotificationToken {
  constructor (
    private readonly url: string,
    private readonly httpGetClient: HttpClient<string>
  ) {}

  async save (params: SaveNotificationTokenParams): Promise<void> {
    const { body, statusCode } = await this.httpGetClient.request({
      url: `${this.url}/api/portal/salvar-token-notificacao-cliente`,
      method: 'post',
      body: params
    })

    switch (statusCode) {
      case HttpStatusCode.ok:
        break
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      case HttpStatusCode.forbidden: throw new ForbiddenError(body)
      default: throw new UnexpectedError()
    }
  }
}
