import { atom } from 'recoil'

export const statusTicketState = atom({
  key: 'selectStatusTicketState',
  default: [
    { _id: 'Todas', value: 'Todas' },
    { _id: 'Faturas Pagas', value: 'Pagas' },
    { _id: 'Faturas em Aberto', value: 'Em Aberto' }
  ]
})
