import { ProspectRegistration, ProspectRegistrationParams, ProspectRegistrationResult } from '@/domain/usecases'
import { ForbiddenError, UnexpectedError, UnexpectedResultFormatError } from '@/domain/errors'
import { HttpClient, HttpRequest, HttpStatusCode } from '@/data/protocols'

export class RemoteProspectRegistration implements ProspectRegistration {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<ProspectRegistrationResult | string>
  ) { }

  async register (params: ProspectRegistrationParams): Promise<number> {
    const { email, ...bodyParams } = params

    const request: HttpRequest = {
      url: `${this.url}/api/portal/gravar-prospect`,
      method: 'post',
      params: {
        email,
        origemSistema: 'P'
      },
      body: {
        ...bodyParams,
        fichaCadastral: 'Prospect cadastrado através do Portal do Cliente.',
        status: 'A',
        usuario: 'PortalService'
      }
    }

    const { statusCode, body } = await this.httpClient.request(request)

    switch (statusCode) {
      case HttpStatusCode.ok:
        if (!body) {
          throw new UnexpectedResultFormatError()
        }
        return (body as ProspectRegistrationResult).codProspect
      case HttpStatusCode.forbidden: throw new ForbiddenError(body as string)
      default: throw new UnexpectedError()
    }
  }
}
