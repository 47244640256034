import { ServiceOrderResult } from '@/domain/models'
import { GetServiceOrders, GetServiceOrdersParams } from '@/domain/usecases'
import { AccessDeniedError, ForbiddenError, UnexpectedError, UnexpectedResultFormatError } from '@/domain/errors'
import { HttpClient, HttpRequest, HttpStatusCode } from '@/data/protocols'

export class RemoteGetServiceOrders implements GetServiceOrders {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<ServiceOrderResult | string>
  ) {}

  async get (params: GetServiceOrdersParams): Promise<ServiceOrderResult> {
    const request: HttpRequest = {
      url: `${this.url}/api/portal/lista-oss`,
      method: 'get',
      params
    }

    const { statusCode, body } = await this.httpClient.request(request)

    switch (statusCode) {
      case HttpStatusCode.ok:
        if (!body) {
          throw new UnexpectedResultFormatError()
        }

        return body as ServiceOrderResult
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      case HttpStatusCode.forbidden: throw new ForbiddenError(body as string)
      default: throw new UnexpectedError()
    }
  }
}
