import * as React from 'react'
import { GestureResponderEvent, Animated, StyleSheet } from 'react-native'
import { useTheme, useNotify, useMediaQueries } from '@/presentation/hooks'
import { Button, Snackbar } from '@/presentation/components'

export type SnackBarProps = {
  id: string
  message: string
  variant?: 'success' | 'error' | 'info' | 'warning'
  duration?: number
  delay?: number
}

const animateView = (toValue: number, animatedRef: Animated.Value, duration: number, delay?: number): Animated.CompositeAnimation => {
  return Animated.timing(
    animatedRef,
    {
      toValue,
      duration,
      delay,
      useNativeDriver: true
    }
  )
}

export function SnackBar ({
  id,
  message,
  variant = 'info'
}: SnackBarProps): JSX.Element {
  const theme = useTheme()
  const { isMobile } = useMediaQueries()
  const notify = useNotify()
  const fadeAnime = React.useRef(new Animated.Value(0)).current
  const [isOpen, setOpen] = React.useState(true)

  React.useEffect(() => {
    const initAnimations = [animateView(1, fadeAnime, 500)]
    Animated.sequence(initAnimations).start()
  }, [])

  const handleClose = (event: GestureResponderEvent): void => {
    event.preventDefault()
    Animated.sequence([
      animateView(0, fadeAnime, 300)
    ]).start(() => {
      setOpen(false)
      notify.dimiss(id)
    })
  }

  const containerStyles = {
    info: {
      backgroundColor: theme.colors.info
    },
    success: {
      backgroundColor: theme.colors.success
    },
    error: {
      backgroundColor: theme.colors.error
    },
    warning: {
      backgroundColor: theme.colors.secondary
    }
  }[variant]

  return (
    <Snackbar
      message={message}
      testID={`notificacao-${variant}`}
      action={
        <Button
          variant="text"
          title="FECHAR"
          color={theme.colors.greyLight}
          onPress={handleClose}
          compact
        />
      }
      style={{
        ...containerStyles,
        ...styles.container,
        width: isMobile ? '100%' : '500px',
        opacity: fadeAnime,
        display: isOpen ? 'flex' : 'none'
      }}
    />
  )
}

const styles = StyleSheet.create({
  container: {
    zIndex: 2,
    marginTop: 8
  }
})
