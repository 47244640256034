import { StyleSheet } from 'react-native'

export const layoutStyles = StyleSheet.create({
  pageContent: {
    flex: 1,
    padding: 16,
    maxWidth: 1280,
    width: '100%',
    marginHorizontal: 'auto'
  }
})
