import { ShowEvents } from '@/domain/usecases'
import { RemoteShowEvents } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'

export const makeShowEvents = (): ShowEvents => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<string>()

  return new RemoteShowEvents(url, httpClient)
}
