import { atom } from 'recoil'

export const isOpenSaveSettingsState = atom({
  key: 'isOpenSaveEventsSettingsState',
  default: false
})

export const isLoadingSaveSettingsState = atom({
  key: 'isLoadingSaveEventsSettingsState',
  default: false
})
