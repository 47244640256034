export type ServiceOrderModel = {
  codInterno: number
  empresa: string
  status: ServiceOrderStatus
  tipo: ServiceOrderModality
  prioridade: ServiceOrderPriority
  ordem: number
  cliente: number
  nomeCliente: string
  dataAbertura: string
  dataFechamento: string
  observacoes: string
  modalidade: string
  solicitante: string
  parecerFinal: string
  codEndereco: number
}

export type ServiceOrderResult = {
  listaOS: ServiceOrderModel[]
  bloqueiaAberturaLimite: boolean
  portalExibeMensagem: boolean
  exibirOsFechadas: boolean
  portalMensagem: string
}

export enum ServiceOrderStatus {
  Aberta = 'A',
  Fechada = 'B',
  Faturada = 'F'
}

export enum ServiceOrderPriority {
  Alta = 'A',
  Normal = 'N',
  Baixa = 'B'
}

export enum ServiceOrderModality {
  ManutencaoCorretiva = 'M',
  VendasInstalacao = 'V',
  ManutenCaoPreventiva = 'P',
  VendasAmpliacao = 'A',
  RetiradaEquipamento = 'R',
  Interna = 'I'
}
