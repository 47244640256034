import React, { useState } from 'react'
import { Menu } from 'react-native-paper'
import { useRecoilState, useRecoilValue } from 'recoil'
import { SowillKeyResponseModel } from '@/domain/models'
import { useMediaQueries, useThemeClient } from '@/presentation/hooks'
import { Box, FAB } from '@/presentation/components'
import { selectSowillKeyState } from './atom'
import { sowillKeysState } from '../atom'

export const SelectSowillKey: React.FC = () => {
  const keys = useRecoilValue(sowillKeysState)
  const { getThemeClient } = useThemeClient()
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [key, setKey] = useRecoilState(selectSowillKeyState)
  const { isMobile } = useMediaQueries()

  const themeClient = getThemeClient()

  const handleSelectCompany = (identifier: SowillKeyResponseModel): void => {
    const { codCliente, ...key } = identifier
    setKey(key)
  }

  const customStyle = (): object => {
    if (isMobile) {
      return {
        margin: 'auto'
      }
    } else {
      return {
        marginLeft: 'auto'
      }
    }
  }

  return (
    <Box style={customStyle()}>
      <Menu
        contentStyle={{
          width: 300,
          marginTop: 4
        }}
        visible={isMenuOpen}
        onDismiss={() => setMenuOpen(false)}
        anchorPosition='bottom'
        anchor={
          <FAB
            color={themeClient.mainColor}
            variant='extended'
            label={key.chave ? `Empresa ${key.empresaMonitoramento} - Chave ${key.chave}/${key.particao}` : 'Selecione o identificador'}
            onPress={() => setMenuOpen(true)}
            size='mini'
          />
        }
      >
        {keys.map(identifier => (
          <Menu.Item
            key={`${identifier.codCliente}/${identifier.particao}/${identifier.empresaMonitoramento}`}
            onPress={() => handleSelectCompany(identifier)}
            title={`Empresa ${identifier.empresaMonitoramento} - Chave ${identifier.chave}/ ${identifier.particao}`}
          />
        ))}
      </Menu>
    </Box>
  )
}
