import { GetRemotySectors } from '@/domain/usecases'
import { RemotySectorModel } from '@/domain/models'
import { RemoteGetRemotySectors } from '@/data/usecases/events'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'

export const makeGetRemotySectorsFactory = (): GetRemotySectors => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<RemotySectorModel[] | string>()

  return new RemoteGetRemotySectors(url, httpClient)
}
