import React, { useState } from 'react'
import { Menu } from 'react-native-paper'
import { CentralMonitoring } from '@/domain/models'
import { useAuth, useMediaQueries, useThemeClient } from '@/presentation/hooks'
import { Box, FAB } from '@/presentation/components'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { contactListHasError, monitoringListHasError, remotyFilterState, sectorListHasError, userListHasError } from '@/presentation/pages/Events/components'

export const SelectRemotyEventCompany: React.FC = () => {
  const { getClient } = useAuth()
  const { getThemeClient } = useThemeClient()
  const { isMobile } = useMediaQueries()

  const [isMenuOpen, setMenuOpen] = useState(false)
  const [currentCompany, setCompany] = useRecoilState(remotyFilterState)
  const setEventError = useSetRecoilState(monitoringListHasError)
  const setSectorError = useSetRecoilState(sectorListHasError)
  const setUserError = useSetRecoilState(userListHasError)
  const setContactError = useSetRecoilState(contactListHasError)

  const client = getClient()
  const themeClient = getThemeClient()

  const companies = client?.centraisMonitoramento

  const onSelectCompany = (company: CentralMonitoring): void => {
    setCompany({ remotyIdEquipamento: company.idEquipment, remotyNmEquip: company.nmEquipment })
  }

  const handleSelectCompany = (company: CentralMonitoring): void => {
    onSelectCompany(company)
    setMenuOpen(false)

    setEventError(false)
    setSectorError(false)
    setUserError(false)
    setContactError(false)
  }

  const customStyle = (): object => {
    if (isMobile) {
      return {
        margin: 'auto'
      }
    } else {
      return {
        marginLeft: 'auto'
      }
    }
  }

  return (
    <Box style={customStyle()}>
      <Menu
        contentStyle={{
          width: 300,
          marginTop: 4
        }}
        visible={isMenuOpen}
        onDismiss={() => setMenuOpen(false)}
        anchorPosition='bottom'
        anchor={
          <FAB
            color={themeClient.mainColor}
            variant='extended'
            label={currentCompany.remotyNmEquip ? `${currentCompany.remotyNmEquip}` : 'Selecione o Equipamento'}
            onPress={() => setMenuOpen(true)}
            size='mini'
          />
        }
      >
        {companies.map((company) => (
          <Menu.Item
            key={company.idEquipment}
            onPress={() => handleSelectCompany(company)}
            title={company.nmEquipment}
          />
        ))}
      </Menu>
    </Box>
  )
}
