import { MetricsLogin } from '@/domain/usecases'
import { RemoteMetricsLogin } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'

export const makeMetricsLogin = (): MetricsLogin => {
  const url = env.METRICS_LOG_IN_OUT
  const httpClient = makeAxiosHttpClient<null>()

  return new RemoteMetricsLogin(url, httpClient)
}
