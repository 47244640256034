import React from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { AddTravelAlertOsResponsible, AddTravelAlertResponsibleType } from '@/domain/usecases'
import { Box, Button, Text, TextInput, ModalBase, Stack, VStack, HStack } from '@/presentation/components'
import { useMediaQueries, useNotify, useTheme, useThemeClient } from '@/presentation/hooks'
import { ResponsiblesPanel, addVehicleState, isOpenTravelAlertAddVehicle, travelAlertAddResponsibleState, travelAlertVehiclesState } from '@/presentation/pages/TravelAlert/components'
import { styles } from './styles'

export type AddVehicleParams = {
  placa: string
  possuiAutorizados: boolean
  possuiResponsaveis: boolean
}

export const ModalAddVehicle: React.FC = () => {
  const theme = useTheme()
  const notify = useNotify()
  const { isMobile } = useMediaQueries()
  const { getThemeClient } = useThemeClient()
  const [addVehicle, setAddVehicle] = useRecoilState(addVehicleState)
  const [isOpen, setOpen] = useRecoilState(isOpenTravelAlertAddVehicle)
  const setOpenAddResponsible = useSetRecoilState(travelAlertAddResponsibleState)
  const setTravelAlertAddVehicle = useSetRecoilState(travelAlertVehiclesState)

  const themeClient = getThemeClient()

  const handleChangeInput = (name: keyof AddVehicleParams, value: string | boolean): void => {
    setAddVehicle(currentValues => ({ ...currentValues, [name]: value }))
  }

  const handleAddVehicle = (): void => {
    const vehicle = {
      id: new Date().getMilliseconds(),
      placa: addVehicle.placa,
      responsaveis: addVehicle.responsaveis,
      autorizados: addVehicle.autorizados,
      possuiAutorizados: !!addVehicle.autorizados.length,
      possuiResponsaveis: !!addVehicle.responsaveis.length
    }

    setTravelAlertAddVehicle(currentState => [...currentState, vehicle])
    handleClose()
    notify.show(`Veículo placa ${vehicle.placa} adicionado com sucesso!`)
  }

  const handleReset = (): void => {
    setAddVehicle({
      id: 0,
      placa: '',
      responsaveis: [] as AddTravelAlertOsResponsible[],
      autorizados: [] as AddTravelAlertOsResponsible[],
      possuiResponsaveis: false,
      possuiAutorizados: false
    })
  }

  const handleClose = (): void => {
    setOpen(false)
    handleReset()
  }

  return (
    <ModalBase visible={isOpen} onClose={handleClose}>
      <Box style={styles.container}>
        <HStack spacing={6}>
          <MaterialCommunityIcons name='car-back' size={24} />
          <Text variant="subtitle1">Adicionar veículo</Text>
        </HStack>

        <TextInput
          label="Placa*"
          onChangeText={text => handleChangeInput('placa', text)}
          value={addVehicle.placa}
          maxLength={30}
        />

        <VStack>
          <Stack spacing={6} direction='row' justify='between'>
            <Text variant='subtitle1' style={{ fontWeight: '700' }}>Responsáveis da chave do veículo</Text>
          </Stack>
          <ResponsiblesPanel
            variant='tiny'
            disabled={!addVehicle.placa}
            responsibles={addVehicle.responsaveis}
            onAddNew={() => {
              setOpen(false)
              setOpenAddResponsible({
                isOpen: true,
                typeCurrent: AddTravelAlertResponsibleType.VEHICLE_KEYS
              })
            }}
            onRemove={(idResp) => {
              setAddVehicle(currentState => ({
                ...currentState,
                responsaveis: currentState.responsaveis.filter(resp => resp.id !== idResp)
              }))
            }}
          />
        </VStack>

        <VStack>
          <Stack spacing={6} direction='row' justify='between'>
            <Text variant='subtitle1' style={{ fontWeight: '700' }}>Autorizados a utilizar o veículo</Text>
          </Stack>
          <ResponsiblesPanel
            variant='tiny'
            disabled={!addVehicle.placa}
            responsibles={addVehicle.autorizados}
            onAddNew={() => {
              setOpen(false)
              setOpenAddResponsible({
                isOpen: true,
                typeCurrent: AddTravelAlertResponsibleType.VEHICLE_AUTHORIZED
              })
            }}
            onRemove={(idResp) => {
              setAddVehicle(currentState => ({
                ...currentState,
                autorizados: currentState.autorizados.filter(vehicle => vehicle.id !== idResp)
              }))
            }}
          />
        </VStack>

        <Box style={styles.buttonArea}>
          {!isMobile &&
            <Button
              color={theme.colors.shadow}
              variant="text"
              title="Cancelar"
              onPress={handleClose}
            />
          }
          <Button
            color={theme.colors.shadow}
            variant="text"
            title="Limpar"
            onPress={handleReset}
          />
          <Button
            title="Adicionar"
            onPress={handleAddVehicle}
            disabled={!addVehicle.placa}
            color={themeClient.mainColor}
          />
        </Box>
      </Box>
    </ModalBase>
  )
}
