import jwt_decode from 'jwt-decode'
import { Decoder } from '@/data/protocols/'

export class JwtAdapter implements Decoder {
  decode (token: string): any {
    try {
      const plaintext: any = jwt_decode(token)
      return plaintext
    } catch (error) {
      return null
    }
  }
}
