import { atom } from 'recoil'

export const isOpenSaveConfigState = atom({
  key: 'isOpenSaveConfigState',
  default: false
})

export const isLoadingSaveConfigState = atom({
  key: 'isLoadingSaveConfigState',
  default: false
})
