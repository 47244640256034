import React from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { TrackDetailsModel, FuelType } from '@/domain/models'
import { useMediaQueries, useTheme } from '@/presentation/hooks'
import { ModalBase, Box, Text, VStack, Loading, Divider, Stack, Button } from '@/presentation/components'
import { isLoadingTrackDetailsState, isOpenTrackDetailsState, trackDetailsState } from '@/presentation/pages/Track/components'
import { styles } from './styles'

export const TrackDetails: React.FC = () => {
  const [details, setDetails] = useRecoilState(trackDetailsState)
  const isLoading = useRecoilValue(isLoadingTrackDetailsState)
  const [isOpen, setOpen] = useRecoilState(isOpenTrackDetailsState)
  const theme = useTheme()

  const { isMobile } = useMediaQueries()

  const { combustivel, cor, id, modelo, placa, ano } = details

  const handleFuelName = {
    [FuelType.Biocombustivel]: 'Biocombustivel',
    [FuelType.Diesel]: 'Diesel',
    [FuelType.Eletrico]: 'Eletrico',
    [FuelType.Etanol]: 'Etanol',
    [FuelType.GNV]: 'GNV',
    [FuelType.Gasolina]: 'Gasolina',
    [FuelType.Hibrido]: 'Hibrido'
  }[combustivel]

  const handleClose = (): void => {
    setOpen(false)
    setDetails({} as TrackDetailsModel)
  }

  return (
    <ModalBase visible={isOpen} onClose={handleClose}>
      <Box style={styles.container}>
        {isLoading
          ? <Loading />
          : <Box testID='track-detalhes-modal'>
            <Text variant='h6' style={{ justifyContent: 'center', alignItems: 'center' }}>
              Detalhes do track
            </Text>

            <Divider color={theme.colors.divider} style={{ margin: '8px' }} />

            <Stack direction={isMobile ? 'column' : 'row'} justify='between'>
              {placa &&
                <VStack>
                  <Text style={styles.title} variant='subtitle1'>Placa:</Text>
                  <Text variant="subtitle1" testID='track-placa'>
                    {placa}
                  </Text>
                </VStack>}
              {id &&
                <VStack style={{ alignItems: isMobile ? 'flex-start' : 'flex-end' }}>
                  <Text style={styles.title} testID='track-id' variant='subtitle1'>Id:</Text>
                  <Text variant="subtitle1">{id}</Text>
                </VStack>}
            </Stack>

            <Stack direction={isMobile ? 'column' : 'row'} justify='between'>
              {cor &&
                <VStack>
                  <Text style={styles.title} testID='track-cor' variant='subtitle1'>Cor:</Text>
                  <Text variant="subtitle1">
                    {cor}
                  </Text>
                </VStack>}
              {ano &&
                <VStack style={{ alignItems: isMobile ? 'flex-start' : 'flex-end' }}>
                  <Text style={styles.title} testID='track-ano' variant='subtitle1'>Ano:</Text>
                  <Text variant="subtitle1">{ano}</Text>
                </VStack>}
            </Stack>

            {modelo &&
              <VStack>
                <Text style={styles.title} testID='track-modelo' variant='subtitle1'>Modelo:</Text>
                <Text variant="subtitle1">
                  {modelo}
                </Text>
              </VStack>}
            {combustivel &&
              <VStack>
                <Text style={styles.title} testID='track-combustivel' variant='subtitle1'>Combustivel:</Text>
                <Text variant="subtitle1">
                  {handleFuelName}
                </Text>
              </VStack>
            }
          </Box>
        }
        <Button
          color={theme.colors.greyDark}
          variant="text"
          title="Fechar"
          onPress={handleClose}
          style={{ marginLeft: 'auto' }}
        />
      </Box>
    </ModalBase>
  )
}
