import * as React from 'react'
import { StyleSheet } from 'react-native'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Octicons } from '@expo/vector-icons'
import { Box, Button, currentUnitState, HStack, ModalBase, Text, unitsState, VStack } from '@/presentation/components'
import { useMediaQueries, useTheme, useThemeClient } from '@/presentation/hooks'
import { currentListServerUrlState } from '@/presentation/pages/Theme/components'
import { isOpenMenuServersUrlState } from './atom'

type ServerUrlSelectorProps = {
  disabled: boolean
}

export const ServerUrlSelector: React.FC<ServerUrlSelectorProps> = ({ disabled }) => {
  const theme = useTheme()
  const { isMobile } = useMediaQueries()
  const { getThemeClient } = useThemeClient()
  const [isMenuOpen, setMenuOpen] = useRecoilState(isOpenMenuServersUrlState)
  const [currentUnit, setCurrentUnit] = useRecoilState(currentUnitState)
  const units = useRecoilValue(unitsState)
  const currentListServerUrl = useRecoilValue(currentListServerUrlState)

  const themeClient = getThemeClient()
  const selectedServerUrl = units?.find(unit => unit.codigoUnidade === currentUnit?.codigoUnidade)?.enderecoExterno ?? ''
  const sameUrl = window.location.href.substring(0, window.location.href.lastIndexOf('/')) === selectedServerUrl

  const handleSelectUnit = (selectedUnit: number): void => {
    const unit = units.find(unit => unit.codigoUnidade === selectedUnit)
    unit && setCurrentUnit(unit)
    setMenuOpen(false)
  }

  const formatLabelServer = (externalAddress: string): string => {
    if (!externalAddress) { return '' }
    const unitsLabel = units
      .filter(unit => unit.enderecoExterno === externalAddress)
      .map(unit => unit.codigoUnidade.toString().padStart(4, '0'))

    const externalServerLabel = externalAddress?.replace('https://', '')?.replace('http://', '')
    const unitsDescription = isMobile ? '' : ` | Unidades ${unitsLabel.join(', ')}`
    return `${externalServerLabel}${unitsDescription}`
  }

  return (
    <Box style={styles.container}>
      <Button
        disabled={disabled}
        color={themeClient.buttonSave}
        style={{ margin: 12, borderRadius: 15 }}
        tintColor={theme.colors.white}
        onPress={() => setMenuOpen(true)}
        loading={disabled}
        loadingIndicatorPosition='overlay'
        title={formatLabelServer(selectedServerUrl)}
      />

      {!sameUrl &&
        <Button
          title={isMobile ? '' : 'Abrir em nova aba'}
          color={theme.colors.grey}
          leading={props => <Octicons name='link-external' {...props} size={24} />}
          style={{ margin: 12, borderRadius: 15 }}
          onPress={() => { window.open(`${selectedServerUrl}/portalservice`, '_blank') }}
        />
      }

      <ModalBase visible={isMenuOpen} onClose={() => setMenuOpen(false)}>
        <Text variant="subtitle1" style={{ marginBottom: 18, fontWeight: '500' }}>Selecione o Endereço</Text>

        <VStack
          spacing={8}
          style={{
            maxHeight: 400,
            overflowY: 'scroll'
          }}>
          {currentListServerUrl?.map(serverUrl => (
            <Button
              key={serverUrl.enderecoExterno}
              variant={serverUrl.enderecoExterno === currentUnit.enderecoExterno ? 'contained' : 'outlined'}
              title={
                <VStack justify='start' fill>
                  <Text variant='body1' style={{ fontWeight: '500' }} color={serverUrl.enderecoExterno === currentUnit.enderecoExterno ? theme.colors.white : theme.colors.texts}>
                    {serverUrl.enderecoExterno.replace('https://', '').replace('http://', '')}
                  </Text>
                  <Text variant='body2' color={serverUrl.enderecoExterno === currentUnit.enderecoExterno ? theme.colors.white : theme.colors.texts}>
                    Unidade{serverUrl.unidades.length > 1 ? 's' : ''}: {serverUrl.unidades.map(unit => unit.toString().padStart(4, '0')).join(', ')}
                  </Text>
                </VStack>
              }
              color={themeClient.mainColor}
              tintColor={theme.colors.white}
              onPress={() => handleSelectUnit(serverUrl.unidades.at(0))}
              style={{
                width: '100%',
                marginBottom: 10,
                padding: 3
              }}
            />
          ))}
        </VStack>
        <HStack justify='end'>
          <Button
            color={theme.colors.shadow}
            variant="text"
            title="CANCELAR"
            onPress={() => setMenuOpen(false)}
          />
        </HStack>
      </ModalBase>
    </Box>
  )
}

export const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4
  }
})
