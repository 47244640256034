import React from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { Box, Text, CardRow, CardDetails } from '@/presentation/components'
import { serviceOrderDetailState, selectedOsImageViewState } from '@/presentation/pages/ServiceOrderDetail/components'
import { styles } from '../../styles'

export const OsSignatures: React.FC = () => {
  const serviceOrderDetails = useRecoilValue(serviceOrderDetailState)
  const setImageView = useSetRecoilState(selectedOsImageViewState)

  return (
    <>
      {!!serviceOrderDetails.assinaturasFechamento?.length &&
        <>
          <Text style={styles.pageInfo}>Assinaturas</Text>
          <CardRow>
            {serviceOrderDetails.assinaturasFechamento.map((sign, index) => (
              <TouchableOpacity key={index} onPress={() => { setImageView({ uri: sign.data64, description: sign.nomeArquivo }) }}>
                <CardDetails>
                  <img src={sign.data64} style={{ maxWidth: 280, maxHeight: 280 }} />
                  <Box style={styles.additionalTextBox}>
                    <Text style={styles.additionalText}>{sign.nomeArquivo}</Text>
                  </Box>
                </CardDetails>
              </TouchableOpacity>
            ))}
          </CardRow>
        </>
      }
    </>
  )
}
