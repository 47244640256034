import { DateAdapter } from '@/data/protocols'
import { HttpClient, HttpStatusCode } from '@/data/protocols/http'
import { AccessDeniedError, ForbiddenError, UnexpectedError } from '@/domain/errors'
import { GenericEventModel, MoniEventModel } from '@/domain/models'
import { GetMoniEvents, GetMoniEventsParams } from '@/domain/usecases'

export class RemoteGetMoniEvents implements GetMoniEvents {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<string | MoniEventModel[]>,
    private readonly dateAdapter: DateAdapter

  ) {}

  async getEvents (params: GetMoniEventsParams): Promise<GenericEventModel[]> {
    const { body, statusCode } = await this.httpClient.request({
      url: `${this.url}/api/portal/moni/lista-eventos-central`,
      method: 'get',
      params
    })

    switch (statusCode) {
      case HttpStatusCode.ok: return this.mapToGeneric(body as MoniEventModel[])
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      case HttpStatusCode.forbidden: throw new ForbiddenError(body as string)
      default: throw new UnexpectedError()
    }
  }

  mapToGeneric (events: MoniEventModel[]): GenericEventModel[] {
    return events?.map(event => ({
      evento: event?.codigoOcorrencia,
      descricao: event?.ocorrencia,
      dataEvento: this.dateAdapter.format(event?.dataHora)
    }))
  }
}
