import { BANK } from '@/domain/models'
import { GetTicketPix, GetTicketPixParams } from '@/domain/usecases'
import { AccessDeniedError, ForbiddenError, UnexpectedError, UnexpectedResultFormatError } from '@/domain/errors'
import { HttpClient, HttpRequest, HttpStatusCode } from '@/data/protocols'

export class RemoteGetTicketPix implements GetTicketPix {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<string>
  ) {}

  async getPix (params: GetTicketPixParams): Promise<string> {
    if (params.codigoBanco === BANK.BoletoInside) {
      const request: HttpRequest = {
        url: `${this.url}/api/boleto/pix`,
        method: 'get',
        params
      }

      const { statusCode, body } = await this.httpClient.request(request)

      switch (statusCode) {
        case HttpStatusCode.ok:
          if (!body) {
            throw new UnexpectedResultFormatError()
          }
          return body
        case HttpStatusCode.forbidden: throw new ForbiddenError(body)
        case HttpStatusCode.unauthorized: throw new AccessDeniedError()
        default: throw new UnexpectedError()
      }
    }

    return params?.pixUrl ?? ''
  }
}
