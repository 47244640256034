import { TestEmail, TestEmailParams } from '@/domain/usecases'
import { AccessDeniedError, UnexpectedError } from '@/domain/errors'
import { HttpClient, HttpStatusCode } from '@/data/protocols/http'

export class RemoteTestEmail implements TestEmail {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<boolean>
  ) { }

  async test (params: TestEmailParams): Promise<boolean> {
    const { statusCode, body } = await this.httpClient.request({
      method: 'post',
      url: `${this.url}/api/portal/testaemail`,
      body: params
    })

    switch (statusCode) {
      case HttpStatusCode.ok: return body
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}
