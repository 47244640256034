import { atom } from 'recoil'

export const isOpenNfsFiltersState = atom({
  key: 'isOpenNfsFiltersState',
  default: false
})

export const nfsFiltersState = atom({
  key: 'nfsFiltersState',
  default: {
    dataInicio: '',
    dataFim: ''
  }
})
