import { GetSowillUsers } from '@/domain/usecases'
import { SowillUserResult } from '@/domain/models'
import { makeAxiosHttpClient } from '@/main/factories/http'
import { RemoteGetSowillUsers } from '@/data/usecases'
import env from '@/main/config/env'

export const makeGetSowillUsersFactory = (): GetSowillUsers => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<SowillUserResult | string>()

  return new RemoteGetSowillUsers(url, httpClient)
}
