import { PortalLogout } from '@/domain/usecases'
import { RemotePortalLogout } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'

export const makePortalLogout = (): PortalLogout => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<null>()

  return new RemotePortalLogout(url, httpClient)
}
