import { HttpClient, HttpStatusCode } from '@/data/protocols/http'
import { AccessDeniedError, ForbiddenError, UnexpectedError, UnexpectedResultFormatError } from '@/domain/errors'
import { NotificationOnDBModel } from '@/domain/models'
import { GetClientNotifications, GetClientNotificationsParams } from '@/domain/usecases'

export class RemoteGetClientNotifications implements GetClientNotifications {
  constructor (
    private readonly url: string,
    private readonly httpGetClient: HttpClient<string | NotificationOnDBModel[]>
  ) {}

  async get (params: GetClientNotificationsParams): Promise<NotificationOnDBModel[]> {
    const { body, statusCode } = await this.httpGetClient.request({
      url: `${this.url}/api/portal/buscar-notificacoes-cliente`,
      method: 'get',
      params
    })

    switch (statusCode) {
      case HttpStatusCode.ok:
        if (!body) {
          throw new UnexpectedResultFormatError()
        }
        return body as NotificationOnDBModel[]
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      case HttpStatusCode.forbidden: throw new ForbiddenError(body as string)
      default: throw new UnexpectedError()
    }
  }
}
