import React from 'react'
import { StyleSheet } from 'react-native'
import { useMediaQueries } from '@/presentation/hooks'
import { Box, NoResultMessage, Loading } from '@/presentation/components'

type CardListProps = {
  children: React.ReactNode
  isLoading: boolean
  isEmpty: boolean
  textLoading: string
  textEmpty?: string
}

export const CardList: React.FC<CardListProps> = (props) => {
  const { isMobile } = useMediaQueries()

  const makeComponent = (): React.ReactNode => {
    if (props.isLoading) {
      return <Loading text={props.textLoading} />
    }

    return props.isEmpty ? <NoResultMessage text={props.textEmpty} /> : props.children
  }

  return (
    <Box style={isMobile ? styles.containerMobile : styles.container}>
      {makeComponent()}
    </Box>
  )
}

const styles = StyleSheet.create({
  container: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    flexGrow: 1,
    gap: 16
  },
  containerMobile: {
    flexWrap: 'nowrap',
    flexGrow: 1,
    gap: 16
  }
})
