import React from 'react'
import { StyleSheet } from 'react-native'
import { Stack } from '@/presentation/components'
import { useMediaQueries } from '@/presentation/hooks'

type CardRowProps = {
  children: React.ReactNode
}

export const CardRow: React.FC<CardRowProps> = ({ children }) => {
  const { isMobile } = useMediaQueries()

  return (
    <Stack
      spacing={12}
      style={styles.container}
      direction={isMobile ? 'column' : 'row'}
    >
      {children}
    </Stack>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '100%',
    gap: 16,
    marginBottom: 10
  }
})
