import { atom } from 'recoil'
import { CreditCardModel } from '@/domain/models'

export const isLoadingCreditCardsState = atom({
  key: 'isLoadingCreditCardsState',
  default: false
})

export const isOpenCreditCardsState = atom({
  key: 'isOpenCreditCardsState',
  default: false
})

export const creditCardsState = atom({
  key: 'creditCardsState',
  default: [] as CreditCardModel[]
})

export const creditCardSelectedState = atom({
  key: 'creditCardSelectedState',
  default: null as unknown as CreditCardModel
})
