import { MetricsLogoff, MetricsParams } from '@/domain/usecases'
import { HttpClient, HttpRequest } from '@/data/protocols/http'

export class RemoteMetricsLogoff implements MetricsLogoff {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<null>
  ) {}

  async register (params: MetricsParams): Promise<void> {
    const authParams: HttpRequest = {
      url: `${this.url}/v2/acessos/logInOut`,
      method: 'post',
      body: {
        ...params,
        acao: 'LOGOFF',
        plataforma: 'Portal Service'
      }
    }

    await this.httpClient.request(authParams)
  }
}
