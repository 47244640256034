import { atom } from 'recoil'

export const phoneModalState = atom({
  key: 'phoneModalState',
  default: false
})

export const phoneModalNumberState = atom({
  key: 'phoneModalNumberState',
  default: ''
})
