import { StyleSheet } from 'react-native'
export { default as CopyIcon } from '@expo/vector-icons/MaterialCommunityIcons'
export { default as MaterialIcons } from '@expo/vector-icons/MaterialIcons'

export const styles = StyleSheet.create({
  container: {
    padding: 16,
    margin: 0,
    width: '100%',
    minWidth: 280,
    height: 'fit-content',
    fontSize: 14,
    overflow: 'hidden',
    borderRadius: 14,
    shadowColor: 'rgba(0, 0, 0, 0.04)',
    shadowOffset: {
      width: 4,
      height: 8
    },
    shadowRadius: 4,
    backgroundPosition: '100%',
    backgroundSize: '8px 100%',
    backgroundRepeat: 'no-repeat'
  },
  statusIcon: {
    height: 20,
    width: 20
  },
  title: {
    fontWeight: '500',
    fontSize: 24
  },
  textStatus: {
    marginTop: 4,
    fontSize: 14
  }
})
