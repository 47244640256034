import { TrackModel } from '@/domain/models'
import { atom } from 'recoil'

export const isLoadingTrackState = atom({
  key: 'isLoadingTrackState',
  default: true
})

export const trackState = atom({
  key: 'trackState',
  default: [] as TrackModel[]
})
