import { ClientModel } from '@/domain/models'
import { makeLocalStorageAdapter } from '@/main/factories/cache'
import { KEY_ACCESS_TOKEN } from '@/presentation/hooks'
import { atom } from 'recoil'

export const authenticationState = atom({
  key: 'autenticationState',
  default: {
    client: null as unknown as ClientModel,
    registerLogin: false,
    accessToken: makeLocalStorageAdapter().get(KEY_ACCESS_TOKEN)
  }
})
