import { HttpClient, HttpStatusCode } from '@/data/protocols/http'
import { AccessDeniedError, ForbiddenError, UnexpectedError } from '@/domain/errors'
import { GenericEventModel, RemotyEventModel } from '@/domain/models'
import { GetRemotyEvents, GetRemotyEventsParams } from '@/domain/usecases'

export class RemoteGetRemotyEvents implements GetRemotyEvents {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<string>
  ) {}

  async getEvents (params: GetRemotyEventsParams): Promise<GenericEventModel[]> {
    const { body, statusCode } = await this.httpClient.request({
      url: `${this.url}/api/portal/remoty/eventos`,
      method: 'get',
      params
    })

    switch (statusCode) {
      case HttpStatusCode.ok: return this.mapToGeneric(body)
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      case HttpStatusCode.forbidden: throw new ForbiddenError(body)

      default: throw new UnexpectedError()
    }
  }

  mapToGeneric (eventsRaw: string): GenericEventModel[] {
    const events = eventsRaw
      ? JSON.parse(eventsRaw) as RemotyEventModel[]
      : []

    return events?.map(remotyEvent => ({
      evento: remotyEvent?.event?.codEvent,
      descricao: remotyEvent?.event?.nmEvent,
      dataEvento: remotyEvent?.dtEventFormatted,
      aux: remotyEvent?.event?.idEvent.toString(),
      usuario: remotyEvent?.nmPanelUser
    }))
  }
}
