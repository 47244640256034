import React from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { RadioButton } from 'react-native-paper'
import { statusTicketState, ticketsFiltersState } from '@/presentation/pages/Financial/components'
import { useMediaQueries, useThemeClient } from '@/presentation/hooks'
import { GetTicketSituation } from '@/domain/usecases'

export const SelectStatusTicketFilter = (): JSX.Element => {
  const { getThemeClient } = useThemeClient()
  const { isMobile } = useMediaQueries()
  const [filters, setFilters] = useRecoilState(ticketsFiltersState)
  const statusTicket = useRecoilValue(statusTicketState)

  const themeClient = getThemeClient()

  const handleOnSelect = (selectedStatus: GetTicketSituation): void => {
    setFilters((currentFilters) => ({ ...currentFilters, situacao: selectedStatus }))
  }

  return (
    <>
      {
        statusTicket.map((statusOption) => (
          <RadioButton.Item
            key={statusOption._id}
            label={statusOption.value}
            value={statusOption.value}
            status={filters.situacao === statusOption.value ? 'checked' : 'unchecked'}
            onPress={() => handleOnSelect(statusOption.value as GetTicketSituation)}
            labelStyle={{ fontSize: isMobile ? 14 : 16 }}
            style={{
              borderRadius: 4,
              borderWidth: 1,
              borderColor: `${themeClient.mainColor}26`,
              marginBottom: 8,
              paddingVertical: isMobile ? 0 : 16
            }}
            color={themeClient.mainColor}
          />
        ))
      }
    </>
  )
}
