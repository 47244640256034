export type SaveNotificationTokenParams = {
  codigoCliente: number
  tokenDispositivo: string
}

export interface SaveNotificationToken {
  save: (params: SaveNotificationTokenParams) => Promise<void>
}
export namespace SaveNotificationToken {
  export const RN = {
    LOCAL_STORAGE_TOKEN: 'firebase_device_token'
  }
}
