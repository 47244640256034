import { NFResultModel } from '@/domain/models'

export type GetNFsParams = {
  cliCodigo: number
  dtInicial?: string
  dtFinal?: string
}

export interface GetNFs {
  get: (params: GetNFsParams) => Promise<NFResultModel>
}

export namespace GetNFs {
  export const RN = {
    DEFAULT_MONTHS_AGO: 6
  }
}
