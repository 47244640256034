import React from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { BANK, TicketModel } from '@/domain/models'
import { GenerateInsideBankSlip, OpenBankSlip, PayWithCreditCard } from '@/domain/usecases'
import { DateAdapter } from '@/data/protocols'
import { useFormat, useTheme, useThemeClient } from '@/presentation/hooks'
import { ModalBase, Box, Button, Text, Chip, HStack, VStack, Loading, MessageBlock, MessageBlockStatus } from '@/presentation/components'
import { PayCreditCards, TicketActions, isLoadingTicketViewState, isOpenCreditCardsState, isOpenTicketViewState, ticketViewState } from '@/presentation/pages/Financial/components'
import { styles } from './styles'

type TicketViewViewProps = {
  openBankSlip: OpenBankSlip
  payWithCreditCard: PayWithCreditCard
  dateAdapter: DateAdapter
}

const MARGIN_ITEMS = 8
const INFO_INSIDE_SLIP_BANK = `O boleto só será atualizado ${GenerateInsideBankSlip.RN.TOTAL_DAYS_BEFORE_NEW_UPDATE} dias após o vencimento. Você pode realizar o pagamento dele, mesmo estando vencido, em qualquer agência bancária em até 58 dias`

export const TicketView: React.FC<TicketViewViewProps> = ({
  dateAdapter,
  payWithCreditCard,
  openBankSlip
}) => {
  const theme = useTheme()
  const { getThemeClient } = useThemeClient()
  const { formatCurrency } = useFormat()
  const [ticketView, setTicketView] = useRecoilState(ticketViewState)
  const isLoading = useRecoilValue(isLoadingTicketViewState)
  const [isOpen, setOpen] = useRecoilState(isOpenTicketViewState)
  const [isPayCreditCard, setPayCreditCard] = useRecoilState(isOpenCreditCardsState)

  const themeClient = getThemeClient()
  const daysOfArrears = dateAdapter.diffInDays({ startDate: dateAdapter.today(), endDate: dateAdapter.parse(ticketView?.vencimento) })

  const handleClose = (): void => {
    setOpen(false)
    setTicketView(null as unknown as TicketModel)
    setPayCreditCard(false)
  }

  const colorSituationStyle = {
    paga: theme.colors.success,
    vencida: theme.colors.error,
    venceHoje: theme.colors.secondary,
    'em Aberto': theme.colors.info
  }[ticketView?.situacao]

  const titleSituation = {
    paga: 'Fatura paga',
    vencida: 'Fatura vencida',
    venceHoje: 'Vencendo hoje',
    'em Aberto': 'Fatura em Aberto'
  }[ticketView?.situacao]

  const styleStatusSituation = {
    paga: 'success',
    vencida: 'error',
    venceHoje: 'warning',
    'em Aberto': 'info'
  }[ticketView?.situacao] as MessageBlockStatus

  const descriptionSituation = {
    paga: `Você pagou em ${dateAdapter.format(ticketView?.dataPagamento, 'DD/MM/yyyy')} o valor ${formatCurrency(ticketView?.valorPago)}`,
    vencida: `Atraso de ${daysOfArrears} dia${daysOfArrears > 1 ? 's' : ''} valor atualizado ${formatCurrency(ticketView?.valorAtualizado)}`,
    venceHoje: 'Sua fatura vence hoje efetue o pagamento para evitar juros',
    'em Aberto': `Fatura em aberto vence em ${daysOfArrears} dia${daysOfArrears > 1 ? 's' : ''}`
  }

  const descriptionInsideSlipBank = {
    paga: descriptionSituation.paga,
    vencida: INFO_INSIDE_SLIP_BANK,
    venceHoje: descriptionSituation.venceHoje,
    'em Aberto': descriptionSituation['em Aberto']
  }[ticketView?.situacao]

  return (
    <ModalBase visible={isOpen} onClose={handleClose}>
      <Box style={styles.container}>
        {!ticketView || isLoading
          ? <Loading />
          : <Box>
              <HStack mv={MARGIN_ITEMS} justify='between' wrap>
                <Chip
                  text={titleSituation}
                  containerStyle={{ backgroundColor: colorSituationStyle }}
                />
                <Chip
                  text={`Nº ${ticketView?.numero.toString().padStart(6, '0')}`}
                  color={themeClient.mainColor}
                  variant='outlined'
                />
              </HStack>
              <HStack mb={MARGIN_ITEMS} justify='between' wrap>
                <VStack>
                  <Text style={styles.title} variant='subtitle1'>Emissão</Text>
                  <Text variant="subtitle1">
                    {dateAdapter.format(ticketView?.dataEmissao, 'DD/MM/yyyy')}
                  </Text>
                </VStack>
                <VStack>
                  <Text variant="subtitle1" style={styles.title}>Vencimento</Text>
                  <Text variant="subtitle1">
                    {dateAdapter.format(ticketView?.vencimento, 'DD/MM/yyyy')}
                  </Text>
                </VStack>
              </HStack>
              <HStack mb={MARGIN_ITEMS} justify='between' wrap>
                <VStack>
                  <Text variant="subtitle1" style={styles.title}>Valor Duplicata</Text>
                  {ticketView?.saldo &&
                    <Text variant="subtitle1">
                      {formatCurrency(ticketView?.saldo) }
                    </Text>
                  }
                </VStack>
                <VStack>
                  <Text variant="subtitle1" style={styles.title}>Valor Atualizado</Text>
                    {ticketView?.valorAtualizado !== ticketView?.saldo
                      ? <Text variant="subtitle1">{formatCurrency(ticketView?.valorAtualizado)}</Text>
                      : <Text variant="subtitle1">{formatCurrency(ticketView?.saldo)}</Text>
                    }
                </VStack>
              </HStack>
              <HStack style={{ marginBottom: MARGIN_ITEMS }}>
                <VStack>
                  <Text variant="subtitle1" style={styles.title}>Forma de Pagamento Faturada</Text>
                  <Text variant="subtitle1">
                    {ticketView?.nomeFormaPagto?.split('-')?.[1]?.trim()?.toUpperCase()}
                  </Text>
                </VStack>
              </HStack>
              {!isPayCreditCard &&
                <MessageBlock
                  status={styleStatusSituation}
                  message={ticketView?.codBanco === BANK.BoletoInside
                    ? descriptionInsideSlipBank
                    : ticketView?.codBanco === BANK.CartaoInside
                      ? ticketView?.dupProtestada
                        ? 'Duplicata protestada ou em assessoria. Entre em contanto com o financeiro.'
                        : descriptionSituation[ticketView?.situacao]
                      : descriptionSituation[ticketView?.situacao]
                  }
                />
              }
              {isPayCreditCard
                ? <PayCreditCards payWithCreditCard={payWithCreditCard} />
                : ticketView?.situacao !== 'paga' &&
                  <TicketActions dateAdapter={dateAdapter} openBankSlip={openBankSlip} />
              }
            </Box>
        }
        <HStack>
          <Button
            color={theme.colors.greyDark}
            variant="text"
            title="Fechar"
            onPress={handleClose}
            style={{ marginLeft: 'auto' }}
          />
        </HStack>
      </Box>
    </ModalBase>
  )
}
