type PermissionsProps = {
  requestNotification: () => Promise<NotificationPermissionReturn>
}

enum PermissionsStatus {
  Granted = 'granted',
  Denied = 'denied',
  Default = 'default'
}

type NotificationPermissionReturn = {
  isGranted: boolean
  errorMessage?: string
}

export const usePermissions = (): PermissionsProps => {
  const requestNotification = async (): Promise<NotificationPermissionReturn> => {
    const permission = await Notification.requestPermission()

    const returnObject: NotificationPermissionReturn = {
      isGranted: permission === PermissionsStatus.Granted
    }

    if (!returnObject.isGranted) {
      returnObject.errorMessage = 'Notificações negadas. Elas podem ser necessárias para algumas funcionalidades.'
    }

    return returnObject
  }

  return {
    requestNotification
  }
}
