import { TicketModel } from '@/domain/models'
import { GetTickets } from '@/domain/usecases'
import { RemoteGetTickets } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'

export const makeGetTickets = (): GetTickets => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<TicketModel[] | string>()

  return new RemoteGetTickets(url, httpClient)
}
