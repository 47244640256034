import React, { useCallback } from 'react'
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil'
import { GestureResponderEvent } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useFocusEffect, useRoute } from '@react-navigation/native'
import { NavigationRoute } from '@/main/config/router-navigation'
import { UpdateDefaultPassword, UpdateDefaultPasswordParams, ValidatePasswordRecovery } from '@/domain/usecases'
import { useAuth, useErrorHandler, useNotify, useTheme } from '@/presentation/hooks'
import { Box, Button, IconButton, Loading, ModalBase, SecurityPassword, showPasswordState, Text, TextInput } from '@/presentation/components'
import { isLoadingRecoveryPasswordState, isOpenRecoveryPasswordState, recoverySettingsState } from './atom'
import { loginState } from '@/presentation/pages/Login/components'
import { styles } from './styles'

type RecoveryPasswordProps = {
  navigation: any
  validatePasswordRecovery: ValidatePasswordRecovery
  updateDefaultPassword: UpdateDefaultPassword
}

export const RecoveryPasswordC: React.FC<RecoveryPasswordProps> = ({ navigation, validatePasswordRecovery, updateDefaultPassword }) => {
  const theme = useTheme()
  const notify = useNotify()
  const { isPasswordSecure } = useAuth()
  const [state, setState] = useRecoilState(recoverySettingsState)
  const [isLoading, setLoading] = useRecoilState(isLoadingRecoveryPasswordState)
  const [isOpen, setOpen] = useRecoilState(isOpenRecoveryPasswordState)
  const [showPassword, setShowPassword] = useRecoilState(showPasswordState)
  const setLogin = useSetRecoilState(loginState)
  const params = useRoute().params as { idRecuperacao: string }
  const resetRecoverySettings = useResetRecoilState(recoverySettingsState)
  const resetLogin = useResetRecoilState(loginState)
  const handleError = useErrorHandler(() => { setOpen(false) })

  useFocusEffect(
    useCallback(() => {
      if (params?.idRecuperacao) {
        setLoading(true)
        validatePasswordRecovery
          .validate({ idRecuperacao: params?.idRecuperacao })
          .then(clientCode => {
            setState(currentState => ({ ...currentState, cliente: clientCode }))
            setLogin({
              username: clientCode.toString(),
              password: '',
              recoveryEmail: '',
              document: ''
            })
          })
          .catch(handleError)
          .finally(() => setLoading(false))
      }
    }, [params?.idRecuperacao])
  )

  const handleUpdatePassword = useCallback((event: GestureResponderEvent): void => {
    event.preventDefault()
    setLoading(true)

    const updatePasswordParams: UpdateDefaultPasswordParams = {
      novaSenha: state.novaSenha,
      idRecuperacao: params?.idRecuperacao
    }

    updateDefaultPassword
      .changePassword(updatePasswordParams)
      .then(changed => {
        if (changed) {
          notify.show('Senha alterada, a nova senha foi gravada com sucesso')
          resetLogin()
          resetRecoverySettings()
          navigation.navigate(NavigationRoute.Login)
        } else {
          notify.error('Não foi possível alterar a senha, tente novamente')
        }
      })
      .catch(handleError)
      .finally(() => {
        handleClose()
        setLoading(false)
      })
  }, [params?.idRecuperacao, state, navigation])

  const handleTogglePasswordVisibility = (): void => {
    setShowPassword(currentState => !currentState)
  }

  const handleChangeInput = (name: string, value: string): void => {
    setState(currentValues => ({ ...currentValues, [name]: value }))
  }

  const handleClose = (): void => {
    setOpen(false)
    navigation.navigate(NavigationRoute.Login)
  }

  return (
    <ModalBase visible={!!params?.idRecuperacao && isOpen} onClose={handleClose}>
      <Box style={styles.container}>
        {isLoading
          ? <Loading text='Validando recuperação da senha, aguarde...' />
          : <>
          <Text variant="h5" testID='update-alterar-senha'>Alterar senha</Text>

          <TextInput
            label="Nova Senha"
            testID='update-nova-senha'
            secureTextEntry={!showPassword}
            onChangeText={text => handleChangeInput('novaSenha', text)}
            value={state.novaSenha}
            maxLength={30}
            trailing={
              <IconButton
                icon={<MaterialCommunityIcons
                  name={showPassword ? 'eye-off' : 'eye'}
                  color={theme.colors.greyLight}
                  size={18}
                />}
                onPress={handleTogglePasswordVisibility}
              />
            }
          />

          <SecurityPassword
            minLenght={state.novaSenha.length >= 8}
            hasLowerCase={/[a-z]/.test(state.novaSenha)}
            hasUpperCase={/[A-Z]/.test(state.novaSenha)}
            hasNumber={/\d/.test(state.novaSenha)}
            hasSpecialChar={/[!@#$%^&*(),.?":{}|<>]/.test(state.novaSenha)}
          />

          <TextInput
            label="Confirmar Nova Senha"
            testID='update-confirmar-senha'
            secureTextEntry
            onChangeText={text => handleChangeInput('confirmacaoSenha', text)}
            value={state.confirmacaoSenha}
            maxLength={30}
          />

          <Box style={styles.buttonArea}>
            <Button
              color={theme.colors.shadow}
              tintColor={theme.colors.white}
              variant="text"
              testID='update-cancelar-senha'
              title="CANCELAR"
              onPress={handleClose}
              disabled={isLoading}
              />
            <Button
              title="GRAVAR"
              testID='update-gravar-senha'
              onPress={handleUpdatePassword}
              tintColor={theme.colors.white}
              color={theme.colors.success}
              disabled={
                isLoading ||
                !state.novaSenha ||
                state.novaSenha !== state.confirmacaoSenha ||
                !isPasswordSecure(state.novaSenha)
              }
            />
          </Box>
        </>
        }
      </Box>
    </ModalBase>
  )
}
