import React, { useCallback } from 'react'
import { ScrollView } from 'react-native'
import { useRecoilState, useRecoilValue } from 'recoil'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useFocusEffect } from '@react-navigation/native'
import { ClientMonitoringType } from '@/domain/models'
import { GetRemotyContacts, GetRemotyContactsParams, GetSigmaDeskContacts, GetSigmaDeskContactsParams } from '@/domain/usecases'
import { Box, CardList, PageHeader, Stack } from '@/presentation/components'
import { layoutStyles } from '@/presentation/styles/globals'
import { theme } from '@/presentation/styles'
import { useAuth, useErrorHandler, useMediaQueries } from '@/presentation/hooks'
import { CardContact, contactListState, isLoadingContactEventState, SelectRemotyEventCompany, SelectSigmaCloudKey, selectSigmaCloudKeyData, remotyFilterState, contactListHasError } from '@/presentation/pages/Events/components'

type ContactEventListProps = {
  getRemotyContacts: GetRemotyContacts
  getSigmaDeskContacts: GetSigmaDeskContacts
}

export const ContactEventList: React.FC<ContactEventListProps> = ({
  getRemotyContacts,
  getSigmaDeskContacts
}: ContactEventListProps) => {
  const { getClient } = useAuth()
  const handleError = useErrorHandler()
  const { isMobile } = useMediaQueries()
  const client = getClient()

  const [hasError, setHasError] = useRecoilState(contactListHasError)
  const currentSigmaKey = useRecoilValue(selectSigmaCloudKeyData)
  const { remotyIdEquipamento } = useRecoilValue(remotyFilterState)
  const [contactList, setContactList] = useRecoilState(contactListState)
  const [isLoading, setLoading] = useRecoilState(isLoadingContactEventState)

  useFocusEffect(
    useCallback(() => {
      const conditions = {
        [ClientMonitoringType.REMOTY]: remotyIdEquipamento && remotyIdEquipamento !== 0,
        [ClientMonitoringType.MONI]: false,
        [ClientMonitoringType.SIGMA_CLOUD]: !!(currentSigmaKey.codCliente),
        [ClientMonitoringType.SIGMA_DESK]: !!(currentSigmaKey.codCliente),
        [ClientMonitoringType.SO_WILL]: false
      }[client.tipoConexao]

      if (conditions && !hasError) { getEventContacts() }
    }, [client.empresa, remotyIdEquipamento, currentSigmaKey.codCliente, currentSigmaKey.empresaSigma])
  )

  const errorHandler = (error: Error): void => {
    handleError(error)
    setHasError(true)
  }

  const handleGetRemotyContactList = (): void => {
    const params: GetRemotyContactsParams = {
      idEquipamento: remotyIdEquipamento,
      empresaMonitoramento: client.empresaMonitoramento
    }

    setLoading(true)

    getRemotyContacts
      .getContacts(params)
      .then(setContactList)
      .catch(errorHandler)
      .finally(() => setLoading(false))
  }

  const handleGetSigmaDeskContactList = (): void => {
    const params: GetSigmaDeskContactsParams = {
      empresaSigma: currentSigmaKey.empresaSigma,
      idCentral: currentSigmaKey.codInterno,
      unidade: currentSigmaKey.empresaSigma
    }

    setLoading(true)

    getSigmaDeskContacts
      .getContacts(params)
      .then(setContactList)
      .catch(errorHandler)
      .finally(() => setLoading(false))
  }

  const getEventContacts = {
    [ClientMonitoringType.MONI]: () => { },
    [ClientMonitoringType.REMOTY]: handleGetRemotyContactList,
    [ClientMonitoringType.SIGMA_CLOUD]: () => { },
    [ClientMonitoringType.SIGMA_DESK]: handleGetSigmaDeskContactList,
    [ClientMonitoringType.SO_WILL]: () => { }
  }[client.tipoConexao]

  const marginRight = window.innerWidth <= 1380 ? 36 : isMobile ? 'auto' : 0
  const marginLeft = window.innerWidth <= 1380 ? 36 : isMobile ? 'auto' : 0

  return (
    <Box style={{ ...layoutStyles.pageContent }}>
      <PageHeader
        text='Contatos'
        icon={<MaterialCommunityIcons name="alarm-light-outline" size={24} color={theme.colors.grey} />}
      />
      <Stack
        direction={isMobile ? 'column-reverse' : 'row'}
        mr={marginRight}
        ml={marginLeft}
        justify='between'
        items='center'
        spacing={8}
      >
        {[ClientMonitoringType.REMOTY].includes(client.tipoConexao) && <SelectRemotyEventCompany />}
        {[ClientMonitoringType.SIGMA_DESK].includes(client.tipoConexao) && <SelectSigmaCloudKey />}
      </Stack>
      <ScrollView style={{ padding: '1rem' }}>
        <CardList
          isEmpty={!contactList.length}
          isLoading={isLoading}
          textLoading='Buscando Eventos de Contatos'
        >
          {contactList?.map(contact => (
            <CardContact
              key={`cardContact-${contact.idDispositivo}`}
              contact={contact}
            />
          ))}
        </CardList>
      </ScrollView>
    </Box>
  )
}
