import React, { useCallback, useEffect } from 'react'
import { SafeAreaView } from 'react-native'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { ScrollView } from 'react-native-gesture-handler'
import { useFocusEffect } from '@react-navigation/native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useErrorHandler, useEventsSettings, useMediaQueries, useTheme } from '@/presentation/hooks'
import { ShowEvents, GetEventsSettings, GetSigmaDeskConnections } from '@/domain/usecases'
import { Box, PageHeader, Loading, VStack, Text, Select, NoResultMessage, InputField, Divider } from '@/presentation/components'
import { ActionsButtons, ModalOnDiscardChanges, ModalOnSaveChanges, eventsSettingsState, isLoadingState, isOpenConnectionMenuState, sigmaDeskConnectionsState } from '@/presentation/pages/EventsSettings/components'
import { TiposEventosModel } from '@/domain/models'
import { styles } from './styles'

type EventsSettingsProps = {
  getSigmaDeskConnections: GetSigmaDeskConnections
  getEventsSettings: GetEventsSettings
  showEvents: ShowEvents
}

export const EventsSettings: React.FC<EventsSettingsProps> = ({ getSigmaDeskConnections, getEventsSettings, showEvents }) => {
  const theme = useTheme()
  const { isMobile } = useMediaQueries()
  const [isLoading, setLoading] = useRecoilState(isLoadingState)
  const [connections, setConnections] = useRecoilState(sigmaDeskConnectionsState)
  const [isOpenConnectionMenu, setOpenConnectionMenu] = useRecoilState(isOpenConnectionMenuState)
  const { startConfig, hasChanged } = useEventsSettings()
  const [events, setEvents] = useRecoilState(eventsSettingsState)
  const resetEvents = useResetRecoilState(eventsSettingsState)
  const handleError = useErrorHandler()

  const handleBounceEvent = (codEvento: string, show: boolean): void => {
    let eventosTodos: TiposEventosModel[]
    let eventosExibidos: TiposEventosModel[]

    if (show) {
      const selectedEvent = events.listaTipos
        .find(event => event.codigo === codEvento) as TiposEventosModel

      eventosTodos = events.listaTipos
        .filter(event => event.codigo !== selectedEvent.codigo)

      eventosExibidos = [...events.listaExibidos, selectedEvent]
    } else {
      const selectedEvent = events.listaExibidos
        .find(event => event.codigo === codEvento) as TiposEventosModel

      eventosExibidos = events.listaExibidos
        .filter(event => event.codigo !== selectedEvent.codigo)

      eventosTodos = [...events.listaTipos, selectedEvent]
    }

    const newConfig = Object.assign({}, events)
    newConfig.listaTipos = eventosTodos
    newConfig.listaExibidos = eventosExibidos

    setEvents(newConfig)
  }

  const onDrag = (event: React.DragEvent<HTMLDivElement>, codEvento: string): void => {
    event.dataTransfer.setData('codEvento', codEvento)
  }

  const onDrop = (event: React.DragEvent<HTMLDivElement>, show: boolean): void => {
    const codEvento = event.dataTransfer.getData('codEvento')
    handleBounceEvent(codEvento, show)
  }

  const handleClick = (codEvento: string, show: boolean): void => {
    handleBounceEvent(codEvento, show)
  }

  const acceptDrop = (event: React.DragEvent<HTMLDivElement>): void => {
    event.preventDefault()
  }

  const handleGetEvents = (): void => {
    resetEvents()
    setLoading(true)

    const params = {
      codInterno: getSelectedConnection() as number
    }

    getEventsSettings
      .getAll(params)
      .then(startConfig)
      .catch(handleError)
      .finally(() => setLoading(false))
  }

  const handleSelectConnection = (id: number): void => {
    const updatedConnections = connections.map((connection) => {
      if (connection.id === id) return { ...connection, isSelected: true }
      else if (connection.isSelected) return { ...connection, isSelected: false }

      return connection
    })

    resetEvents()
    setConnections(updatedConnections)
  }

  const getSelectedConnection = (type: 'description' | 'id' = 'id'): number | string => {
    const selectedConnection = connections?.find((connection) => connection.isSelected)

    if (type === 'description') {
      return selectedConnection ? selectedConnection.descricao : ''
    } else {
      return selectedConnection ? selectedConnection.id : 0
    }
  }

  useEffect(() => { getSelectedConnection() !== 0 && handleGetEvents() }, [connections])

  useFocusEffect(
    useCallback(() => {
      getSigmaDeskConnections
        .getAll()
        .then(setConnections)
        .catch(handleError)
    }, [])
  )

  return (
    <SafeAreaView style={styles.container}>
      <Box style={styles.content}>
        <InputField justify='between'>
          <PageHeader
            text='Ocultar Eventos de Alarme'
            icon={<MaterialCommunityIcons size={24} color={theme.colors.grey} name='alarm-light-outline' />}
          />
          <Select.Trigger
            label={'Conexão monitoramento'}
            description={getSelectedConnection('description') as string}
            onOpen={() => setOpenConnectionMenu(true)}
            style={{ marginBottom: 8 }}
          />
        </InputField>
        {(getSelectedConnection() !== 0)
          ? isLoading
            ? <Loading text='Buscando configurações' />
            : <>
              <VStack mb={16}>
                <Text variant='caption' style={{ fontStyle: 'italic' }}>
                  * Caso nenhum evento seja selecionado para ser exibido, todos os eventos serão listados.
                </Text>
                { isMobile &&
                  <Text variant='caption' style={{ fontStyle: 'italic' }}>
                    ** Clique sobre o item para muda-lo de listagem.
                  </Text>
                }
              </VStack>

              <ScrollView>
                <Box style={isMobile ? styles.selectorContainerMobile : styles.selectorContainer}>
                  <div
                    style={styles.selectBox}
                    onDrop={(event) => onDrop(event, true)}
                    onDragOver={(event) => acceptDrop(event)}
                  >
                    <Text style={styles.titleBox}>Eventos Exibidos</Text>
                    <div style={styles.optionBox}>
                      {events?.listaExibidos?.map((eventoExibido) => (
                        <div
                          draggable
                          key={eventoExibido.codigo}
                          onDragStart={(event) => onDrag(event, eventoExibido.codigo)}
                          onClick={() => handleClick(eventoExibido.codigo, false)}
                          style={styles.optionSelectorChip}
                        >
                          <MaterialCommunityIcons name="drag" size={24} style={styles.optionIcon} />
                          <Text style={styles.optionText}>{`${eventoExibido.codigoExibicao} - ${eventoExibido.descricao}`}</Text>
                        </div>
                      ))}
                    </div>
                  </div>
                  <Divider style={{ marginTop: isMobile ? 16 : 0, marginBottom: isMobile ? 16 : 0 }} />
                  <div
                    style={styles.selectBox}
                    onDrop={(event) => onDrop(event, false)}
                    onDragOver={(event) => acceptDrop(event)}
                  >
                    <Text style={styles.titleBox}>Eventos Ocultos</Text>
                    <div style={styles.optionBox}>
                      {events?.listaTipos?.map((eventoComum) =>
                        <div
                          draggable
                          key={eventoComum.codigo}
                          onDragStart={(event) => onDrag(event, eventoComum.codigo)}
                          style={styles.optionSelectorChip}
                          onClick={() => handleClick(eventoComum.codigo, true)}
                        >
                          <MaterialCommunityIcons name="drag" size={24} style={styles.optionIcon} />
                          <Text style={styles.optionText}>{`${eventoComum.codigoExibicao} - ${eventoComum.descricao}`}</Text>
                        </div>
                      )}
                    </div>
                  </div>
                </Box>
              </ScrollView>
              {hasChanged() && <ActionsButtons />}
            </>
          : (connections.length > 0)
              ? <NoResultMessage text='Selecione uma conexão de monitoramento para buscar' />
              : <NoResultMessage text='Sem conexões de monitoramento Sigma Desk' />
        }
      </Box>
      <ModalOnSaveChanges showEvents={showEvents} />
      <ModalOnDiscardChanges />
      <Select.Modal
        isOpen={isOpenConnectionMenu}
        onClose={() => setOpenConnectionMenu(false)}
        onSelect={(id) => handleSelectConnection(id)}
        title={'Selecione a conexão de monitoramento'}
        items={connections}
      />
    </SafeAreaView>
  )
}
