import React, { useCallback } from 'react'
import { SafeAreaView } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { ScrollView } from 'react-native-gesture-handler'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useErrorHandler, useMediaQueries, useProspectSettings, useTheme, useThemeClient } from '@/presentation/hooks'
import { GetProspectConfigs, SaveProspectConfigs, SaveProspectConfigsParams } from '@/domain/usecases'
import { Box, PageHeader, Loading, HStack, Checkbox, Text, TextInput, Button, VStack } from '@/presentation/components'
import { ActionsButtons, ModalAddConfigUnit, ModalDeleteOption, ModalOnDiscardChanges, ModalOnSaveChanges, TableAreaOfInterest, isLoadingState, isOpenAddConfigUnitState, prospectConfigsState } from '@/presentation/pages/ProspectSettings/components'
import { styles } from './styles'

type ProspectSettingsProps = {
  getProspectConfigs: GetProspectConfigs
  saveProspectConfigs: SaveProspectConfigs
}

export const ProspectSettings: React.FC<ProspectSettingsProps> = ({
  getProspectConfigs,
  saveProspectConfigs
}) => {
  const theme = useTheme()
  const { getThemeClient } = useThemeClient()
  const { isMobile } = useMediaQueries()
  const { startConfig, hasChanged } = useProspectSettings()
  const [isLoading, setLoading] = useRecoilState(isLoadingState)
  const [prospectConfigs, setProspectConfigs] = useRecoilState(prospectConfigsState)
  const setAddOptionOpen = useSetRecoilState(isOpenAddConfigUnitState)
  const handleError = useErrorHandler()

  const themeClient = getThemeClient()

  useFocusEffect(
    useCallback(() => {
      setLoading(true)
      getProspectConfigs
        .getAll()
        .then(startConfig)
        .catch(handleError)
        .finally(() => setLoading(false))
    }, [])
  )

  const handleChange = (param: keyof SaveProspectConfigsParams, value: boolean | string): void => {
    setProspectConfigs(currentState => ({ ...currentState, [param]: value }))
  }

  return (
    <SafeAreaView style={styles.container}>
      <Box style={styles.content}>
        {isLoading
          ? <Loading text='Buscando configurações' />
          : <>
              <PageHeader
                text='Prospects'
                icon={<MaterialCommunityIcons size={24} color={theme.colors.grey} name='account-group' />}
              />
              <ScrollView>
                <HStack items='center' mb={3}>
                  <Checkbox
                    status={prospectConfigs.ativo ? 'checked' : 'unchecked'}
                    onPress={() => handleChange('ativo', !prospectConfigs.ativo)}
                    color={themeClient.mainColor}
                  />
                  <Text>Ativar cadastro de Prospect</Text>
                </HStack>
                {prospectConfigs.ativo &&
                  <VStack spacing={3}>
                    <Box style={{
                      justifyContent: isMobile ? 'center' : 'space-between',
                      flexDirection: isMobile ? 'column' : 'row',
                      alignItems: 'baseline'
                    }}>
                      <TextInput
                        label="Descrição da seleção de interesse de serviços"
                        value={prospectConfigs?.descricao || 'Selecione o serviço que tem interesse'}
                        onChangeText={(text: string) => handleChange('descricao', text)}
                        maxLength={50}
                        style={{
                          width: isMobile ? '100%' : 500,
                          marginBottom: isMobile ? 10 : 0
                        }}
                      />
                      <Button
                        title='adicionar config unidade'
                        trailing={props => <MaterialCommunityIcons name="plus" {...props} />}
                        color={themeClient.mainColor}
                        tintColor={theme.colors.white}
                        onPress={() => setAddOptionOpen(true)}
                        style={{ width: isMobile ? '100%' : 'fit-content' }}
                      />
                    </Box>
                    <TableAreaOfInterest />
                  </VStack>
                }
              </ScrollView >
              {hasChanged() && <ActionsButtons />}
            </>
        }
      </Box>
      <ModalOnSaveChanges saveProspectConfigs={saveProspectConfigs} />
      <ModalOnDiscardChanges />
      <ModalAddConfigUnit />
      <ModalDeleteOption />
    </SafeAreaView>
  )
}
