export type GenerateInsideBankSlipParams = {
  codigoCliente: number
  nossoNumero: string
  registrado: boolean
  linkBoleto: string
  dtVencimento: string
  codBanco: number
  novaVersao: boolean
  original?: boolean
}

export interface GenerateInsideBankSlip {
  generate: (params: GenerateInsideBankSlipParams) => Promise<string>
}

export namespace GenerateInsideBankSlip {
  export const RN = {
    TOTAL_DAYS_BEFORE_NEW_UPDATE: 21
  }
}
