import { ClientModality } from '@/domain/models'

export type AddTravelAlertOsParams = {
  cliente: number
  empresa: number
  modalidade: ClientModality
  solicitante: string
  recomendacoes: string
  dataSaida: string
  horaSaida: string
  dataRetorno: string
  horaRetorno: string
  email: string
  telefone1: string
  telefone2: string
  chavesSomenteComCentral: boolean
  possuiAutorizados: boolean
  possuiVeiculosCuidadosCentral: boolean
  responsaveisChave: AddTravelAlertOsResponsible[]
  autorizados: AddTravelAlertOsResponsible[]
  veiculos: AddTravelAlertOsVehicle[]
}

export type AddTravelAlertOsResponsible = {
  id: number
  nome: string
  endereco: string
  telefone1: string
  telefone2: string
  observacao: string
}

export type AddTravelAlertOsVehicle = {
  id: number
  placa: string
  responsaveis: AddTravelAlertOsResponsible[]
  autorizados: AddTravelAlertOsResponsible[]
  possuiResponsaveis: boolean
  possuiAutorizados: boolean
}

export enum AddTravelAlertResponsibleType {
  KEYS = 0,
  AUTHORIZED = 1,
  VEHICLE_KEYS = 2,
  VEHICLE_AUTHORIZED = 3
}

export interface AddTravelAlertOs {
  add: (params: AddTravelAlertOsParams) => Promise<number>
}
