import { atom } from 'recoil'

export const sigmaTypeEventState = atom({
  key: 'typeSigmaEventState',
  default: [
    { _id: 'Todos', value: 'rbTodos' },
    { _id: 'Alarme', value: 'rbAlarme' },
    { _id: 'Teste Automático', value: 'rbTstAuto' },
    { _id: 'Armado', value: 'rbArmado' },
    { _id: 'Desarmado', value: 'rbDesarmado' },
    { _id: 'Sem Controle', value: 'rbSControle' },
    { _id: 'Desarmado ronda', value: 'rbRonda' }
  ]
})

export type SigmaTypes = 'rbTodos' | 'rbAlarme' | 'rbTstAuto' | 'rbArmado' | 'rbDesarmado' | 'rbSControle' | 'rbRonda'
