import { atom } from 'recoil'

export const isOpenDebtsYearMenuState = atom({
  key: 'isOpenDebtsYearMenuState',
  default: false
})

export type DebtYear = {
  id: number
  descricao: string
  isSelected: boolean
}

export const debtsYearState = atom<DebtYear[]>({
  key: 'debtsYearState',
  default: null as unknown as DebtYear[]
})
