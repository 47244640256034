import { OpenStatementOfDebts } from '@/domain/usecases'
import { RemoteOpenStatementOfDebts } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'

export const makeOpenStatementOfDebts = (): OpenStatementOfDebts => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<string>()

  return new RemoteOpenStatementOfDebts(url, httpClient)
}
