import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    gap: 16
  },
  footer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    height: '100%',
    padding: 16
  }
})
