import React from 'react'
import { Box, Divider, HStack } from '@react-native-material/core'
import { ClientMonitoringType, GenericEventModel } from '@/domain/models'
import { DateAdapter } from '@/data/protocols'
import { Text, VStack } from '@/presentation/components'
import { useAuth, useMediaQueries } from '@/presentation/hooks'
import { theme } from '@/presentation/styles'
import { styles } from './styles'

type CardMonitoringEventProps = {
  dateAdapter: DateAdapter
  event: GenericEventModel
}

export const CardMonitoringEvent: React.FC<CardMonitoringEventProps> = ({ event, dateAdapter }: CardMonitoringEventProps) => {
  const { isMobile } = useMediaQueries()
  const { getClient } = useAuth()
  const client = getClient()

  const containerStyles = {
    ...styles.container,
    ...(!isMobile && {
      flex: 1,
      maxWidth: 600
    }),
    backgroundColor: theme.colors.white,
    color: theme.colors.greyLighten
  }

  const textTitleStyles = {
    ...styles.contentText,
    color: theme.colors.texts
  }

  return (
    <Box style={containerStyles}>
      <HStack items='center' spacing={8} mb={8} mt={8} >
        {event?.evento &&
          <Text variant='subtitle1' style={textTitleStyles} testID={`evento-${event.evento}`}>
            <span style={styles.contentTitle}>
              Evento:
            </span>
            {event.evento}
          </Text>}
      </HStack>

      <VStack spacing={8} style={{ flex: 1 }}>
        <Divider style={styles.divider} />
        {
          event.aux &&
          <Text variant='subtitle1' style={textTitleStyles} testID={`evento-${event.evento}`}>
            <span style={styles.contentTitle}>
              {[ClientMonitoringType.SIGMA_CLOUD, ClientMonitoringType.SIGMA_DESK].includes(client.tipoConexao) && 'Empresa:'}
              {[ClientMonitoringType.MONI].includes(client.tipoConexao) && 'AUX:'}
            </span>
            {event.aux}
          </Text>
        }
        <Text variant='subtitle1' style={textTitleStyles} testID={`evento-data-${event.dataEvento}`}>
          <span style={styles.contentTitle}>
            Data:
          </span>
          {
            client.tipoConexao === ClientMonitoringType.REMOTY
              ? event.dataEvento
              : dateAdapter.format(event.dataEvento, 'DD/MM/YYYY HH:mm:ss')
          }
        </Text>
        <Text variant='subtitle1' style={textTitleStyles} testID={`evento-desc-${event.descricao}`}>
          <span style={styles.contentTitle}>
            Descrição:
          </span>
          {event.descricao}
        </Text>
        {
          [ClientMonitoringType.REMOTY].includes(client.tipoConexao) && event.usuario &&
          <Text variant='subtitle1' style={textTitleStyles} testID={`evento-usuario-${event.usuario}`}>
            <span style={styles.contentTitle}>
              Usuario:
            </span>
            {event.usuario}
          </Text>
        }
      </VStack>

    </Box>
  )
}
