import { GetSowillIdentifier } from '@/domain/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import { RemoteGetSowillIdentifier } from '@/data/usecases/events'
import { SowillIdentifierResult } from '@/domain/models'
import env from '@/main/config/env'

export const makeGetSowillIdentifierFactory = (): GetSowillIdentifier => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<SowillIdentifierResult>()

  return new RemoteGetSowillIdentifier(url, httpClient)
}
