import { GenericSectorModel, SigmaSectorModel } from '@/domain/models'
import { GetSigmaCloudSectors, GetSigmaCloudSectorsParams } from '@/domain/usecases'
import { AccessDeniedError, UnexpectedError } from '@/domain/errors'
import { HttpClient, HttpStatusCode } from '@/data/protocols/http'

export class RemoteGetSigmaCloudSectors implements GetSigmaCloudSectors {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<SigmaSectorModel[]>
  ) { }

  async getZones (params: GetSigmaCloudSectorsParams): Promise<GenericSectorModel[]> {
    const { body, statusCode } = await this.httpClient.request({
      url: `${this.url}/api/sigmaCloudZona`,
      method: 'get',
      params
    })

    switch (statusCode) {
      case HttpStatusCode.ok:
        return this.mapToGeneric(body)
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }

  mapToGeneric (sectors: SigmaSectorModel[]): GenericSectorModel[] {
    return sectors?.map(sector => ({
      nome: sector.descProduto,
      idEquipamento: sector.idEvento,
      codigoCliente: sector.cdCliente.toString(),
      codigoZona: sector.cdEvento.toString(),
      observacao: sector.obs
    }))
  }
}
