import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  content: {
    flex: 1
  },

  cardContainerMobile: {
    marginBottom: '8px',
    marginTop: '8px',
    marginRight: '16px',
    marginLeft: '16px',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1
  },

  headerText: {
    padding: 12,
    fontWeight: '500',
    textTransform: 'uppercase'
  }
})
