import React from 'react'
import { AcceptDigital } from '@/presentation/pages/AceiteDigital'
import { NavigationPagesProps, TabBottomNavigator } from '@/presentation/components'
import { makeGetAcceptDigital, makeOpenDocumentAcceptFactory, makeOpenUrlDocAcceptFactory } from '@/main/factories/usecases'
import { makeDateAdapterFactory } from '@/main/adapters'

export const makeAcceptDigitalFactory = ({ navigation }: NavigationPagesProps): JSX.Element => {
  return (
    <>
      <AcceptDigital
        dateAdapter={makeDateAdapterFactory()}
        getAcceptDigital={makeGetAcceptDigital()}
        getDocAcceptDigital={makeOpenUrlDocAcceptFactory()}
        openDocumentAccept={makeOpenDocumentAcceptFactory()}
      />
      <TabBottomNavigator navigation={navigation} />
    </>
  )
}
