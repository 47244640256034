import { makeAxiosHttpClient } from '@/main/factories/http'
import { RemoteCreateSac } from '@/data/usecases/sac'
import { CreateSac } from '@/domain/usecases'
import env from '@/main/config/env'

export const makeCreateSac = (): CreateSac => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<number | string>()

  return new RemoteCreateSac(url, httpClient)
}
