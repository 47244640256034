import React from 'react'
import { ServiceOrders } from '@/presentation/pages/ServiceOrders'
import { NavigationPagesProps, TabBottomNavigator } from '@/presentation/components'
import { makeAddNewOs, makeGetOsAttendance, makeGetServiceOrders } from '@/main/factories/usecases'
import { makeDateAdapterFactory } from '@/main/adapters'

export const makeServiceOrdersFactory = ({ navigation }: NavigationPagesProps): JSX.Element => {
  return (
    <>
      <ServiceOrders
        getServiceOrders={makeGetServiceOrders()}
        dateAdapter={ makeDateAdapterFactory()}
        addNewOs={makeAddNewOs()}
        getOsAttendance={makeGetOsAttendance()}
      />
      <TabBottomNavigator navigation={navigation} />
    </>
  )
}
