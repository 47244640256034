import { AddTravelAlertOsResponsible, AddTravelAlertOsVehicle } from '@/domain/usecases'
import { atom } from 'recoil'

export const isOpenTravelAlertAddVehicle = atom({
  key: 'isOpenTravelAlertAddVehicle',
  default: false
})

export const addVehicleState = atom<AddTravelAlertOsVehicle>({
  key: 'addVehicleState',
  default: {
    id: 0,
    placa: '',
    responsaveis: [] as AddTravelAlertOsResponsible[],
    autorizados: [] as AddTravelAlertOsResponsible[],
    possuiResponsaveis: false,
    possuiAutorizados: false
  }
})
