import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'
import { RemoteGetSowillEvents } from '@/data/usecases/events'
import { GetSowillEvents } from '@/domain/usecases/events/get-sowill-events'
import { SoWillEventModel } from '@/domain/models'

export const makeGetSowillEventsFactory = (): GetSowillEvents => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<SoWillEventModel[]>()

  return new RemoteGetSowillEvents(url, httpClient)
}
