
import { palette } from './palette'
import { MD3LightTheme as DefaultTheme } from 'react-native-paper'

export const theme = {
  name: 'default',
  colors: {
    ...DefaultTheme.colors,
    ...palette.common,
    ...palette.primary,
    ...palette.secondary,
    ...palette.info,
    ...palette.error,
    ...palette.success,
    ...palette.divider,
    ...palette.background,
    ...palette.grey,
    ...palette.texts,
    ...palette.other
  }
}
