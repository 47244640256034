import { CardFlagsTypes } from '@/domain/models'

type CreditCardResult = {
  getCreditCardType: (creditCardNumber: string) => CardFlagsTypes
  maskCreditCardNumber: (creditCardNumber: string) => string
  maskCreditCardValidity: (value: string) => string
  isValidExpiration: (exp: string) => boolean
}

export const useCreditCard = (): CreditCardResult => {
  const getCreditCardType = (creditCardNumber: string): CardFlagsTypes => {
    let result: CardFlagsTypes | 'unknown' = 'unknown'

    if (/^5[1-5]/.test(creditCardNumber)) {
      result = 'mastercard'
    } else if (/^4/.test(creditCardNumber)) {
      result = 'visa'
    } else if (/^3[47]/.test(creditCardNumber)) {
      result = 'amex'
    } else if (/3(?:0[0-5]|[68][0-9])[0-9]{11}/.test(creditCardNumber)) {
      result = 'diners'
    } else if (/6(?:011|5[0-9]{2})[0-9]{12}/.test(creditCardNumber)) {
      result = 'discover'
    }

    return result
  }

  const maskCreditCardNumber = (creditCardNumber: string): string => {
    const trimmedNumber = creditCardNumber.replace(/\s/g, '')

    const regex = /(\d{4})(?=\d)/g

    return trimmedNumber.replace(regex, '$1 ')
  }

  const maskCreditCardValidity = (value: string): string => {
    const cleanedText = value.replace(/[^0-9]/g, '')
    let formattedText = cleanedText

    if (cleanedText.length > 2) {
      formattedText = cleanedText.slice(0, 2) + '/' + cleanedText.slice(2)
    }

    return formattedText
  }

  const isValidExpiration = (exp: string): boolean => {
    const regex = /^(0[1-9]|1[0-2])\/\d{2}$/
    return regex.test(exp)
  }

  return {
    getCreditCardType,
    maskCreditCardNumber,
    maskCreditCardValidity,
    isValidExpiration
  }
}
