import { VersionsModel } from '@/domain/models'
import { GetVersions } from '@/domain/usecases'
import { RemoteGetVersions } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'

export const makeGetVersions = (): GetVersions => {
  const httpClient = makeAxiosHttpClient<VersionsModel>()

  return new RemoteGetVersions(httpClient)
}
