import React from 'react'
import { TravelAlert } from '@/presentation/pages/TravelAlert'
import { NavigationPagesProps, TabBottomNavigator } from '@/presentation/components'
import { makeAddTravelAlertOs } from '@/main/factories/usecases'
import { makeDateAdapterFactory } from '@/main/adapters'

export const makeTravelAlertFactory: React.FC = ({ navigation }: NavigationPagesProps) => {
  return (
    <>
      <TravelAlert
        navigation={navigation}
        dateAdapter={makeDateAdapterFactory()}
        addTravelAlertOs={makeAddTravelAlertOs()}
      />
      <TabBottomNavigator navigation={navigation} />
    </>
  )
}
