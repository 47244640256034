import { ServiceOrderModality, ServiceOrderPriority, ServiceOrderStatus } from '@/domain/models'
import { AddNewOs, AddNewOsParams } from '@/domain/usecases'
import { ForbiddenError, AccessDeniedError, UnexpectedError, UnexpectedResultFormatError } from '@/domain/errors'
import { HttpClient, HttpRequest, HttpStatusCode } from '@/data/protocols'

export class RemoteAddNewOs implements AddNewOs {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<number | string>
  ) {}

  async add (params: AddNewOsParams): Promise<number> {
    const bodyParams = {
      ...params,
      avisoViagem: false,
      status: ServiceOrderStatus.Aberta,
      tipo: ServiceOrderModality.ManutencaoCorretiva,
      prioridade: ServiceOrderPriority.Normal
    }

    const request: HttpRequest = {
      url: `${this.url}/api/os`,
      method: 'post',
      body: bodyParams
    }

    const { statusCode, body } = await this.httpClient.request(request)

    switch (statusCode) {
      case HttpStatusCode.ok:
        if (!body) {
          throw new UnexpectedResultFormatError()
        }
        return body as number
      case HttpStatusCode.forbidden: throw new ForbiddenError(body as string)
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}
