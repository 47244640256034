import { AccessDeniedError, ForbiddenError, UnexpectedError } from '@/domain/errors'
import { GetSigmaDeskContacts, GetSigmaDeskContactsParams } from '@/domain/usecases'
import { GenericContactModel, SigmaDeskContactModel } from '@/domain/models'
import { HttpClient, HttpStatusCode } from '@/data/protocols/http'

export class RemoteGetSigmaDeskContacts implements GetSigmaDeskContacts {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<SigmaDeskContactModel[] | string>
  ) { }

  async getContacts (params: GetSigmaDeskContactsParams): Promise<GenericContactModel[]> {
    const { body, statusCode } = await this.httpClient.request({
      url: `${this.url}/api/contatoSigma`,
      method: 'get',
      params
    })

    switch (statusCode) {
      case HttpStatusCode.ok: return this.mapToGeneric(body as SigmaDeskContactModel[])
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      case HttpStatusCode.forbidden: throw new ForbiddenError(body as string)
      default: throw new UnexpectedError()
    }
  }

  mapToGeneric (contacts: SigmaDeskContactModel[]): GenericContactModel[] {
    return contacts?.map(contact => ({
      idUsuario: contact.cdCliente.toString(),
      nomeUsuario: contact.nome,
      fone1: contact.fone1,
      fone2: contact.fone2,
      idDispositivo: contact.idFuncao.toString(),
      nomeDispositivo: contact.descFuncao
    }))
  }
}
