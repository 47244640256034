import { AccessDeniedError, ForbiddenError, UnexpectedError } from '@/domain/errors'
import { GenericSectorModel, RemotySectorResult } from '@/domain/models'
import { GetRemotySectors, GetRemotySectorsParams } from '@/domain/usecases'
import { HttpClient, HttpStatusCode } from '@/data/protocols/http'

export class RemoteGetRemotySectors implements GetRemotySectors {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<RemotySectorResult | string>
  ) { }

  async getSectors (params: GetRemotySectorsParams): Promise<GenericSectorModel[]> {
    const { body, statusCode } = await this.httpClient.request({
      url: `${this.url}/api/portal/remoty/lista-setores`,
      method: 'get',
      params
    })

    switch (statusCode) {
      case HttpStatusCode.ok:
        return this.mapToGeneric(body as RemotySectorResult)
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      case HttpStatusCode.forbidden: throw new ForbiddenError(body as string)
      default: throw new UnexpectedError()
    }
  }

  mapToGeneric (sectors: RemotySectorResult): GenericSectorModel[] {
    return sectors?.map(sector => ({
      nome: sector.nmZone,
      idEquipamento: sector.idEquipmentZone.toString(),
      codigoCliente: sector.codZone.toString(),
      ativo: sector.flActive
    }))
  }
}
