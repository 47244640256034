import React from 'react'
import { StyleSheet } from 'react-native'
import { AntDesign } from '@expo/vector-icons'
import { NavigationRoute } from '@/main/config/router-navigation'
import { useMediaQueries, useTheme, useThemeClient } from '@/presentation/hooks'
import { Box, HStack, IconButton, NavigationPagesProps } from '@/presentation/components'

type TabNavigatorProps = NavigationPagesProps & {
  variant?: 'light' | 'theme'
}

export const TAB_BOTTOM_CONTAINER_HEIGTH = 64

export const TabBottomNavigator: React.FC<TabNavigatorProps> = ({ navigation, variant = 'light' }) => {
  const theme = useTheme()
  const { isMobile } = useMediaQueries()
  const { getThemeClient } = useThemeClient()

  const themeClient = getThemeClient()
  const handleGoHome = (): void => navigation.navigate(NavigationRoute.Home)

  const colorsVariant = {
    light: {
      containerColor: theme.colors.white,
      containerIconColor: themeClient.mainColor,
      iconColor: themeClient.menuColor
    },
    theme: {
      containerColor: themeClient.mainColor,
      containerIconColor: 'transparent',
      iconColor: themeClient.menuColor
    }
  }[variant]

  return (
    <Box style={{
      ...styles.cardContainer,
      display: isMobile ? 'flex' : 'none',
      backgroundColor: colorsVariant.containerColor
    }}>
      <HStack items='center' justify='around' style={{ flex: 1 }} spacing={8}>
        <IconButton
          onPress={handleGoHome}
          color={colorsVariant.iconColor }
          style={{ backgroundColor: colorsVariant.containerIconColor }}
          icon={(props) => <AntDesign name='appstore-o' {...props} size={20} />}
        />
      </HStack>
    </Box>
  )
}

const styles = StyleSheet.create({
  cardContainer: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    padding: 8,
    width: '100%'
  }
})
