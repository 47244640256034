import React from 'react'
import { StyleSheet } from 'react-native'
import { Box, Text, ActivityIndicator } from '@/presentation/components'
import { useThemeClient } from '@/presentation/hooks'

type LoadingProps = {
  text?: string
}

export const Loading: React.FC<LoadingProps> = ({ text }) => {
  const { getThemeClient } = useThemeClient()

  const themeClient = getThemeClient()

  return (
    <Box style={styles.container}>
      <ActivityIndicator size='large' color={themeClient.mainColor} />
      {!!text &&
        <Text style={{ fontWeight: '600', marginTop: 12 }}>{text}</Text>
      }
    </Box>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '50vh'
  }
})
