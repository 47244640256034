import React from 'react'
import { ProspectSettings } from '@/presentation/pages/ProspectSettings'
import { makeGetProspectConfigs, makeSaveProspectConfigs } from '@/main/factories/usecases'

export const makeProspectSettingsFactory = (): JSX.Element => {
  return (
    <ProspectSettings
      getProspectConfigs={makeGetProspectConfigs()}
      saveProspectConfigs={makeSaveProspectConfigs()}
    />
  )
}
