import { atom } from 'recoil'
import { TicketModel } from '@/domain/models'

export const isLoadingTicketViewState = atom({
  key: 'isLoadingTicketViewState',
  default: false
})

export const ticketViewState = atom({
  key: 'ticketViewState',
  default: null as unknown as TicketModel
})

export const isOpenTicketViewState = atom({
  key: 'isOpenTicketViewState',
  default: false
})
