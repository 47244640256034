import React from 'react'
import { StyleSheet } from 'react-native'
import { Card } from 'react-native-paper'
import { useSetRecoilState } from 'recoil'
import { useTheme } from '@/presentation/hooks'
import { Button, HStack } from '@/presentation/components'
import { isOpenDiscardSettingsState, isOpenSaveSettingsState } from '@/presentation/pages/EventsSettings/components'

export const ActionsButtons: React.FC = () => {
  const theme = useTheme()
  const setOpenSaveConfig = useSetRecoilState(isOpenSaveSettingsState)
  const setOpenDiscardConfig = useSetRecoilState(isOpenDiscardSettingsState)

  const handleSubmit = (): void => {
    setOpenSaveConfig(true)
  }

  const handleDiscardChanges = (): void => {
    setOpenDiscardConfig(true)
  }

  return (
    <Card style={styles.cardContainer} elevation={2}>
      <HStack reverse spacing={4}>
        <Button
          title='Gravar'
          color={theme.colors.success}
          tintColor={theme.colors.white}
          onPress={handleSubmit}
        />
        <Button
          variant='text'
          title='Desfazer alterações'
          color={theme.colors.grey}
          onPress={handleDiscardChanges}
        />
      </HStack>
    </Card>
  )
}

const styles = StyleSheet.create({
  cardContainer: {
    margin: 12,
    padding: 8,
    borderRadius: 4,
    flexDirection: 'row',
    justifyContent: 'flex-end'
  }
})
