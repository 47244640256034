import { ServiceOrderResult } from '@/domain/models'
import { GetServiceOrders } from '@/domain/usecases'
import { RemoteGetServiceOrders } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'

export const makeGetServiceOrders = (): GetServiceOrders => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<ServiceOrderResult | string>()

  return new RemoteGetServiceOrders(url, httpClient)
}
