import React, { useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Box, Text, logoEmpresaState } from '@/presentation/components'
import { isPrintingServiceOrderDetailState, serviceOrderDetailState } from '@/presentation/pages/ServiceOrderDetail/components'
import { useErrorHandler, useImage, useMediaQueries } from '@/presentation/hooks'
import { styles } from './styles'

export const PageHeader: React.FC = () => {
  const { getImage } = useImage()
  const { isMobile } = useMediaQueries()
  const handleError = useErrorHandler()
  const serviceOrderDetail = useRecoilValue(serviceOrderDetailState)
  const isPrinting = useRecoilValue(isPrintingServiceOrderDetailState)
  const [logoEmpresa, setLogoEmpresa] = useRecoilState(logoEmpresaState)

  const textStatus = {
    A: 'Aberta',
    B: 'Fechada',
    F: 'Faturada'
  }[serviceOrderDetail.status]

  useEffect(() => {
    getImage(serviceOrderDetail.logoEmpresa, 'logo')
      .then(setLogoEmpresa)
      .catch(handleError)
  }, [])

  return (
    <Box style={{ ...styles.headerBox, flexDirection: isPrinting || !isMobile ? 'row' : 'column' }}>
      <Box mb={isPrinting || !isMobile ? 0 : 8}>
        <img style={{
          width: 'auto',
          maxHeight: '80px'
        }} src={logoEmpresa} alt='Logo Empresa' />
      </Box>
      <Box>
        <Text style={styles.headerTitle}>OS {serviceOrderDetail.numOS}</Text>
        <Text>{serviceOrderDetail.tipoOSDescricao}</Text>
        <Text>{textStatus}</Text>
      </Box>
    </Box>
  )
}
