import React, { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import { GetRedesSociais, GetTheme, PortalLogout, GetProspectConfigs, UpdatePassword, GetClientNotifications, SaveNotificationToken } from '@/domain/usecases'
import { useProspectSettings, useThemeClient, useFirebaseNotifications } from '@/presentation/hooks'
import { InstallationSuggestion, Logout, NavigationDrawer, PhoneModal, SnackBarProvider, UpdatePasswordC, phoneModalNumberState, socialMediaState } from '@/presentation/components'

type RouterProps = {
  getTheme: GetTheme
  getRedesSociais: GetRedesSociais
  getProspectConfigs: GetProspectConfigs
  portalLogout: PortalLogout
  updatePassword: UpdatePassword
  saveNotificationToken: SaveNotificationToken
  getNotifications: GetClientNotifications
}

const Router: React.FC<RouterProps> = ({
  portalLogout,
  getTheme,
  getRedesSociais,
  getProspectConfigs,
  updatePassword,
  saveNotificationToken,
  getNotifications
}) => {
  const { setThemeClient } = useThemeClient()
  const { startConfig } = useProspectSettings()
  const firebaseNotifications = useFirebaseNotifications()
  const setSocialMedia = useSetRecoilState(socialMediaState)
  const setPhoneModal = useSetRecoilState(phoneModalNumberState)

  useEffect(() => {
    void (!__DEV__ && window.location.protocol === 'https' && firebaseNotifications.serviceWorkerRegistration())

    getTheme
      .get({ codigoUnidade: 0, enderecoExternoIntegra: '' })
      .then(setThemeClient)
      .catch(console.error)

    getRedesSociais
      .getRedesSociais()
      .then((socialMedias) => {
        setSocialMedia(socialMedias)
        setPhoneModal(socialMedias.phone)
      })
      .catch(console.error)

    getProspectConfigs
      .getAll()
      .then(startConfig)
      .catch(console.error)
  }, [])

  return (
    <>
      <SnackBarProvider />
      <Logout portalLogout={portalLogout} />
      <UpdatePasswordC updatePassword={updatePassword} />
      <InstallationSuggestion />
      <NavigationDrawer
        getNotifications={getNotifications}
        saveNotificationToken={saveNotificationToken}
      />
      <PhoneModal/>
    </>
  )
}

export default Router
