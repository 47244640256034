import React, { useCallback, useEffect } from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { ClientMonitoringType } from '@/domain/models'
import { PrintEvents, GetMoniEvents, GetRemotyEvents, GetSowillEvents, GetSigmaEvents, GetRemotySectors, GetMoniSectors, GetRemotyUsers, GetSowillUsers, GetRemotyContacts, GetSowillSectors, GetMoniUsers, GetSigmaCloudSectors, GetSigmaCloudUsers, GetSigmaCloudKeys, GetSowillKeys, GetSowillIdentifier, GetSigmaDeskSectors, GetSigmaDeskContacts } from '@/domain/usecases'
import { DateAdapter } from '@/data/protocols'
import { NoResultMessage } from '@/presentation/components'
import { useAuth, useTheme, useThemeClient, useMediaQueries, useNotify } from '@/presentation/hooks'
import { SideFilters, SectorList, UserEventList, ContactEventList, MonitoringEventList, sowillKeysState, sowillIdentifierState, sigmaKeysState } from '@/presentation/pages/Events/components'
import { styles } from './styles'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useFocusEffect } from '@react-navigation/native'
import { AccessDeniedError } from '@/domain/errors'
import { selectSowillKeyState } from './components/SelectSowillKey/atom'

const Tab = createMaterialTopTabNavigator()

type EventsProps = {
  dateAdapter: DateAdapter
  printEvents: PrintEvents
  getRemotyEvents: GetRemotyEvents
  getRemotySectors: GetRemotySectors
  getRemotyUsers: GetRemotyUsers
  getRemotyContacts: GetRemotyContacts
  getMoniEvents: GetMoniEvents
  getMoniSectors: GetMoniSectors
  getMoniUsers: GetMoniUsers
  getSowillEvents: GetSowillEvents
  getSowillSectors: GetSowillSectors
  getSowillUsers: GetSowillUsers
  getSowillKeys: GetSowillKeys
  getSowillIdentifier: GetSowillIdentifier
  getSigmaEvents: GetSigmaEvents
  getSigmaCloudSectors: GetSigmaCloudSectors
  getSigmaCloudUsers: GetSigmaCloudUsers
  getSigmaCloudKeys: GetSigmaCloudKeys
  getSigmaDeskSectors: GetSigmaDeskSectors
  getSigmaDeskContacts: GetSigmaDeskContacts
}

export const Events: React.FC<EventsProps> = ({
  dateAdapter,
  printEvents,
  getSigmaEvents,
  getRemotyEvents,
  getRemotySectors,
  getRemotyUsers,
  getRemotyContacts,
  getMoniEvents,
  getMoniSectors,
  getMoniUsers,
  getSowillEvents,
  getSowillSectors,
  getSowillUsers,
  getSowillKeys,
  getSowillIdentifier,
  getSigmaCloudSectors,
  getSigmaCloudUsers,
  getSigmaCloudKeys,
  getSigmaDeskSectors,
  getSigmaDeskContacts
}) => {
  const theme = useTheme()
  const { isMobile } = useMediaQueries()
  const { getThemeClient } = useThemeClient()
  const { getClient, logout } = useAuth()
  const [sigmaKeys, setSigmaKeys] = useRecoilState(sigmaKeysState)

  const setSowillKeysList = useSetRecoilState(sowillKeysState)
  const selectedSowillKey = useRecoilValue(selectSowillKeyState)
  const setSowillIdentifier = useSetRecoilState(sowillIdentifierState)

  const themeClient = getThemeClient()
  const client = getClient()
  const notify = useNotify()

  const errorHandler = (error: Error): void => {
    notify.show(error.message, { variant: 'error' })

    if (error instanceof AccessDeniedError) {
      logout()
    }
  }

  useFocusEffect(
    useCallback(() => {
      if (
        [ClientMonitoringType.SIGMA_CLOUD, ClientMonitoringType.SIGMA_DESK]
          .includes(client.tipoConexao) &&
        !sigmaKeys.length) {
        getSigmaCloudKeys
          .getKeys({ codCliente: client.codCliente })
          .then(setSigmaKeys)
          .catch(errorHandler)
      } else
        if ([ClientMonitoringType.SO_WILL].includes(client.tipoConexao)) {
          getSowillKeys
            .getKeys({ codCliente: client.codCliente, unidade: client.unidade })
            .then(setSowillKeysList)
            .catch(errorHandler)
        }
    }, [])
  )

  useEffect(() => {
    if ([ClientMonitoringType.SO_WILL].includes(client.tipoConexao) &&
      selectedSowillKey.chave
    ) {
      getSowillIdentifier
        .getIdentifier(selectedSowillKey)
        .then(setSowillIdentifier)
        .catch(errorHandler)
    }
  }, [selectedSowillKey.chave])

  return (
    <SafeAreaView style={styles.container}>
      <SideFilters
        dateAdapter={dateAdapter}
      />
      {client.tipoConexao != null
        ? <Tab.Navigator
          screenOptions={{
            tabBarItemStyle: { paddingHorizontal: 40 },
            tabBarLabelStyle: { color: theme.colors.background },
            tabBarIndicatorStyle: { backgroundColor: theme.colors.white },
            tabBarStyle: { backgroundColor: themeClient.mainColor }
          }}
        >
          <Tab.Screen
            name={isMobile ? 'Eventos' : 'Eventos do alarme'}
            component={() => MonitoringEventList({
              dateAdapter,
              printEvents,
              getMoniEvents,
              getSigmaEvents,
              getRemotyEvents,
              getSowillEvents,
              getSowillIdentifier
            })}
          />

          {
            [
              ClientMonitoringType.REMOTY,
              ClientMonitoringType.MONI,
              ClientMonitoringType.SO_WILL,
              ClientMonitoringType.SIGMA_CLOUD,
              ClientMonitoringType.SIGMA_DESK
            ]
              .includes(client.tipoConexao) &&
            <Tab.Screen
              name={[ClientMonitoringType.SO_WILL].includes(client.tipoConexao)
                ? 'Zonas'
                : 'Setores'}
              component={() => SectorList({
                getRemotySectors,
                getMoniSectors,
                getSowillSectors,
                getSigmaCloudSectors,
                getSigmaDeskSectors
              })}
            />
          }
          {
            [
              ClientMonitoringType.REMOTY,
              ClientMonitoringType.MONI,
              ClientMonitoringType.SO_WILL,
              ClientMonitoringType.SIGMA_CLOUD
            ]
              .includes(client.tipoConexao) &&
            <Tab.Screen
              name={'Usuarios'}
              component={() => UserEventList({
                getRemotyUsers,
                getMoniUsers,
                getSowillUsers,
                getSigmaCloudUsers
              })}
            />
          }
          {
            [
              ClientMonitoringType.REMOTY,
              ClientMonitoringType.SIGMA_DESK
            ]
              .includes(client.tipoConexao) &&
            <Tab.Screen
              name={'Contatos'}
              component={() => ContactEventList({
                getRemotyContacts,
                getSigmaDeskContacts
              })}
            />
          }

        </Tab.Navigator>
        : <NoResultMessage text='Cliente não possui tipo de integração de monitoramento' />
      }
    </SafeAreaView>
  )
}
