import { ProspectConfigsModel } from '@/domain/models'
import { GetProspectConfigs } from '@/domain/usecases'
import { RemoteGetProspectConfigs } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'

export const makeGetProspectConfigs = (): GetProspectConfigs => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<ProspectConfigsModel>()

  return new RemoteGetProspectConfigs(url, httpClient)
}
