import { HttpClient, HttpStatusCode } from '@/data/protocols/http'
import { AccessDeniedError, ForbiddenError, UnexpectedError } from '@/domain/errors'
import { ChangeTokenNotificationsStatus, ChangeTokenNotificationsStatusParams } from '@/domain/usecases'

export class RemoteChangeTokenNotificationsStatus implements ChangeTokenNotificationsStatus {
  constructor (
    private readonly url: string,
    private readonly httpGetClient: HttpClient<string>
  ) {}

  async change (params: ChangeTokenNotificationsStatusParams): Promise<void> {
    const { body, statusCode } = await this.httpGetClient.request({
      url: `${this.url}/api/portal/trocar-status-notificacoes-token`,
      method: 'put',
      body: params
    })

    switch (statusCode) {
      case HttpStatusCode.ok:
        break
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      case HttpStatusCode.forbidden: throw new ForbiddenError(body)
      default: throw new UnexpectedError()
    }
  }
}
