import React, { useState } from 'react'
import { Image, StyleSheet } from 'react-native'
import { useSetRecoilState } from 'recoil'
import { TouchableOpacity } from 'react-native-gesture-handler'
import * as ImagePicker from 'expo-image-picker'
import { ThemeModel } from '@/domain/models'
import { HStack, Text, VStack } from '@/presentation/components'
import { useImage, useMediaQueries, useTheme, useThemeClient } from '@/presentation/hooks'
import { selectedBackgroundState, themeState } from '@/presentation/pages/Theme/components'

type LoginBackgroundSelectorProps = {
  fileName: string
}

export const LoginBackgroundSelector: React.FC<LoginBackgroundSelectorProps> = ({ fileName }) => {
  const theme = useTheme()
  const { base64toFile } = useImage()
  const { isMobile } = useMediaQueries()
  const { getThemeClient } = useThemeClient()
  const setTheme = useSetRecoilState(themeState)
  const setSelectedBackground = useSetRecoilState(selectedBackgroundState)
  const [background, setBackground] = useState<string>()

  const themeClient = getThemeClient()

  const backgroundImage = !__DEV__ && fileName
    ? { uri: fileName }
    : require('../../../../../../assets/hexagon-bg.svg')

  const handlePickImage = async (): Promise<void> => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1
    })

    if (!result.canceled) {
      const base64Background = result.assets?.[0].uri?.split(',')[1]
      const file = base64toFile(base64Background, `Background_${new Date().getMilliseconds()}.jpeg`)
      setSelectedBackground(file)
      setTheme(currentState => ({ ...currentState, theme: ({ ...currentState.theme, bgLogin: file.name }) as ThemeModel }))
      setBackground(result.assets?.[0].uri)
    }
  }

  return (
    <TouchableOpacity
      activeOpacity={0.8}
      onPress={handlePickImage}
      style={{
        marginBottom: 12
      }}
    >
      <HStack center>
        <Text style={{ fontWeight: '500' }} variant='subtitle1' color={theme.colors.grey}>
          Imagem de Fundo do Login
        </Text>
      </HStack>
      <VStack spacing={24} mb={24} p={12} items='center' fill
        style={{
          backgroundColor: themeClient.mainColor,
          borderRadius: 4
        }}
      >
        <Image
          resizeMode='cover'
          style={{
            ...styles.backgroundImage,
            height: isMobile ? 180 : 400,
            backgroundColor: themeClient.mainColor
          }}
          source={background ? { uri: background } : backgroundImage}
        />
      </VStack>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  backgroundImage: {
    width: '100%',
    maxWidth: 720,
    borderRadius: 4
  }
})
