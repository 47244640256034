import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  loginContent: {
    width: '100%',
    position: 'absolute',
    paddingHorizontal: 40,
    paddingVertical: 20,
    maxWidth: 428,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: 4,
    shadowColor: 'rgba(0, 0, 0, 0.04)',
    shadowOffset: {
      width: 4,
      height: 8
    }
  },
  image: {
    flex: 1,
    justifyContent: 'center',
    alignSelf: 'stretch'
  },
  input: {
    width: '100%',
    outlineWidth: 0,
    backgroundColor: 'transparent'
  },
  form: {
    width: '100%',
    gap: 16
  },
  checkboxArea: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 32,
    color: '#FFF',
    height: 'min-content'
  },
  buttonArea: {
    width: '100%',
    gap: 16
  },
  snackbar: {
    width: 428,
    maxWidth: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    bottom: 32
  },
  center: {
    textAlign: 'center'
  },
  footer: {
    fontSize: 10,
    position: 'absolute',
    width: '100%',
    bottom: 8,
    left: 0,
    textAlign: 'center'
  }
})
