import React from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { phoneModalNumberState, phoneModalState } from './atom'
import { ModalBase, Text } from '@/presentation/components'

export const PhoneModal: React.FC = () => {
  const [modalIsOpen, setModalOpen] = useRecoilState(phoneModalState)
  const phoneNumber = useRecoilValue(phoneModalNumberState)

  const treatedPhoneNumber = phoneNumber.replace(/[^0-9.-]/g, '')

  const handleCloseModal = (): void => {
    setModalOpen(false)
  }

  return (
    <ModalBase
      visible={modalIsOpen}
      onClose={handleCloseModal}
      key={`id-${phoneNumber}`}
    >
      <Text variant='h6'>Contato</Text>
      <Text variant='body1'>{`Fale conosco através do fone: ${treatedPhoneNumber}`}</Text>
    </ModalBase>
  )
}
