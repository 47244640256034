import { layoutStyles } from '@/presentation/styles/globals'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'stretch'
  },
  content: {
    ...layoutStyles.pageContent
  },
  scroll: {
    paddingBottom: 60
  },
  buttonArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 8
  }
})
