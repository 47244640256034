import { ForgotPassword } from '@/domain/usecases'
import { RemoteForgotPassword } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'

export const makeForgotPassword = (): ForgotPassword => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<string>()

  return new RemoteForgotPassword(url, httpClient)
}
