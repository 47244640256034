import React, { useEffect } from 'react'
import { StyleSheet } from 'react-native'
import { AntDesign } from '@expo/vector-icons'
import { IconButton } from 'react-native-paper'
import { useThemeClient } from '@/presentation/hooks'

type ScrollToTopButtonProps = {
  scrollToTop: () => void
  targetRef: any
}

export const ScrollToTopButton: React.FC<ScrollToTopButtonProps> = ({ scrollToTop, targetRef }) => {
  const { getThemeClient } = useThemeClient()

  const themeClient = getThemeClient()

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '60px',
      threshold: 0.25
    }

    const observer = new IntersectionObserver((): void => {}, options)

    if (targetRef.current) {
      observer.observe(targetRef.current)
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current)
      }
    }
  }, [])

  return (
    <IconButton
      style={styles.container}
      mode='contained'
      onPress={scrollToTop}
      iconColor={themeClient.mainColor}
      icon={(props) => <AntDesign name='up' {...props} size={24} />}
    />
  )
}

const styles = StyleSheet.create({
  container: {
    shadowColor: 'rgba(0, 0, 0, 0.04)',
    shadowOffset: {
      width: 4,
      height: 8
    },
    shadowRadius: 4
  }
})
