import { useCallback } from 'react'
import { useRecoilState } from 'recoil'
import { EventsSettingsModel } from '@/domain/models'
import { initialEventsSettingsState, eventsSettingsState } from '@/presentation/pages/EventsSettings/components'

type EventsSettingsProps = {
  startConfig: (config: EventsSettingsModel) => void
  discardChanges: () => void
  hasChanged: () => boolean
}

export const useEventsSettings = (): EventsSettingsProps => {
  const [initialConfigs, setInitialConfigs] = useRecoilState(initialEventsSettingsState)
  const [EventsSettings, setEventsSettings] = useRecoilState(eventsSettingsState)

  const discardChanges = useCallback((): void => {
    startConfig(initialConfigs)
  }, [initialConfigs])

  const startConfig = (initialEventsSettings: EventsSettingsModel): void => {
    const eventosExibidos = initialEventsSettings.listaTipos.filter((evento) => {
      const hasMatchingCode = initialEventsSettings.listaExibidos.some((oculto) => oculto.codigo === evento.codigo)
      return !hasMatchingCode
    })

    const treatedBody: EventsSettingsModel = {
      listaExibidos: initialEventsSettings.listaExibidos,
      listaTipos: eventosExibidos
    }

    setInitialConfigs(treatedBody)
    setEventsSettings(treatedBody)
  }

  const hasChanged = useCallback((): boolean => {
    return JSON.stringify(initialConfigs) !== JSON.stringify(EventsSettings)
  }, [EventsSettings])

  return {
    startConfig,
    discardChanges,
    hasChanged
  }
}
