import React from 'react'
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import { Box, Button, Text, ModalBase } from '@/presentation/components'
import { useTheme } from '@/presentation/hooks'
import { deleteProspectOptionState, isOpenDeleteProspectOptionState, prospectInterestAreaState } from '@/presentation/pages/ProspectSettings/components'
import { styles } from './styles'

export const ModalDeleteOption: React.FC = () => {
  const theme = useTheme()
  const [isOpen, setOpen] = useRecoilState(isOpenDeleteProspectOptionState)
  const deleteOption = useRecoilValue(deleteProspectOptionState)
  const setAreaInterests = useSetRecoilState(prospectInterestAreaState)
  const resetDeleteOption = useResetRecoilState(deleteProspectOptionState)

  const handleDeleteOption = (): void => {
    setAreaInterests((prevInterests) => {
      const updatedInterests = prevInterests.filter(
        (interest) => (interest.unidade !== deleteOption.unidade) || (interest.interesse.descreve !== deleteOption.interesse)
      )
      return updatedInterests
    })
    resetDeleteOption()
    setOpen(false)
  }

  const handleClose = (): void => setOpen(false)

  return (
    <ModalBase visible={isOpen} onClose={handleClose}>
      <Box style={styles.container}>
        <Text variant="subtitle1">Deseja realmente remover a opção de interesse "{deleteOption.interesse.replace(/\s+(?!\b)/g, '')}" da unidade {deleteOption.unidade}?</Text>

        <Box style={styles.buttonArea}>
          <Button
            color={theme.colors.shadow}
            variant="text"
            title="CANCELAR"
            onPress={handleClose}
          />
          <Button
            title="remover"
            onPress={handleDeleteOption}
            color={theme.colors.success}
            tintColor={theme.colors.white}
          />
        </Box>
      </Box>
    </ModalBase>
  )
}
