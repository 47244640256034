import { GenericUserModel } from '@/domain/models'
import { atom } from 'recoil'

export const isLoadingUserEventsState = atom({
  key: 'isLoadingUserEventsState',
  default: false
})

export const userListState = atom({
  key: 'useListState',
  default: [] as GenericUserModel[]
})

export const userListHasError = atom({
  key: 'userListHasError',
  default: false
})
