import React from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { ScrollView } from 'react-native-gesture-handler'
import { MaterialIcons } from '@expo/vector-icons'
import { CardFlagsTypes } from '@/domain/models'
import { Button, HStack, ModalBase, Text, VStack } from '@/presentation/components'
import { AddNewCreditCard, CreditCard } from '@/presentation/pages/Cartao/components'
import { useMediaQueries, useTheme, useThemeClient } from '@/presentation/hooks'
import { creditCardsState, isOpenTicketViewState, errorAddOtherCreditCardState, isOpenAddOtherCreditCardState, otherCreditCardState } from '@/presentation/pages/Financial/components'

export const AddOtherCreditCard: React.FC = () => {
  const theme = useTheme()
  const { isMobile } = useMediaQueries()
  const { getThemeClient } = useThemeClient()
  const [isOpen, setOpen] = useRecoilState(isOpenAddOtherCreditCardState)
  const [otherCreditCard, setOtherCreditCard] = useRecoilState(otherCreditCardState)
  const [error, setError] = useRecoilState(errorAddOtherCreditCardState)
  const setOpenTicketView = useSetRecoilState(isOpenTicketViewState)
  const setCreditCards = useSetRecoilState(creditCardsState)

  const themeClient = getThemeClient()

  const handleResetCard = (): void => {
    setOtherCreditCard(currentState => ({
      ...currentState,
      numero: '',
      nome: '',
      ccv: '',
      expiracao: '',
      mask: '9999 9999 9999 9999',
      type: 'unknown' as CardFlagsTypes,
      vencimento: ''
    }))
  }

  const handleAddCreditCard = (): void => {
    if (isValidDataCard()) {
      handleClose()
      setCreditCards(currentState => [...currentState, otherCreditCard])
      handleResetCard()
    } else {
      setError('Todos os dados do cartão devem ser informados')
    }
  }

  const handleClose = (): void => {
    setOpen(false)
    setOpenTicketView(true)
    setError('')
  }

  const isValidDataCard = (): boolean => {
    if (
      !otherCreditCard.nome ||
      !otherCreditCard.numero ||
      !otherCreditCard.expiracao ||
      otherCreditCard.expiracao.length < 4 ||
      !otherCreditCard.expiracao.includes('/') ||
      !otherCreditCard.ccv ||
      otherCreditCard.numero.includes('*')
    ) {
      return false
    } else {
      return true
    }
  }

  return (
    <ModalBase visible={isOpen} onClose={handleClose}>
      <Text variant='subtitle1' style={{ fontWeight: '500' }}>
        Adicionar Cartão {isMobile ? '' : 'para pagamento'}
      </Text>
      <ScrollView>
        <VStack justify='center' items='center'>
          <AddNewCreditCard isEditable={true} isLoading={false} />
          {error &&
            <HStack mv={8} p={12} justify='center' items='center' spacing={12}
              style={{
                backgroundColor: theme.colors.errorContainer,
                borderLeftWidth: 4,
                borderLeftColor: theme.colors.error
              }}>
              <MaterialIcons name='info' size={24} color={theme.colors.error} />
              <Text color={theme.colors.grey}>Todos os dados do cartão devem ser informados</Text>
            </HStack>
          }
          <CreditCard creditCard={otherCreditCard} />
        </VStack>
      </ScrollView>
      <HStack
        items='center'
        justify='end'
        mt={12}
        pr={16}
        spacing={8}
      >
        <Button
          title='LIMPAR'
          variant='text'
          color={theme.colors.greyLighten}
          onPress={handleResetCard}
        />
        <Button
          title='SALVAR'
          variant='outlined'
          color={themeClient.mainColor}
          onPress={handleAddCreditCard}
          disabled={false}
        />
      </HStack>
    </ModalBase>
  )
}
