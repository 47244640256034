import { AccessDeniedError, ForbiddenError, UnexpectedError } from '@/domain/errors'
import { GenericContactModel, RemotyContactModel } from '@/domain/models'
import { GetRemotyContacts, GetRemotyContactsParams } from '@/domain/usecases'
import { HttpClient, HttpStatusCode } from '@/data/protocols/http'

export class RemoteGetRemotyContacts implements GetRemotyContacts {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<RemotyContactModel[] | string>
  ) { }

  async getContacts (params: GetRemotyContactsParams): Promise<GenericContactModel[]> {
    const { body, statusCode } = await this.httpClient.request({
      url: `${this.url}/api/portal/remoty/listaContato`,
      method: 'get',
      params
    })

    switch (statusCode) {
      case HttpStatusCode.ok: return this.mapToGeneric(body as RemotyContactModel[])
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      case HttpStatusCode.forbidden: throw new ForbiddenError(body as string)
      default: throw new UnexpectedError()
    }
  }

  mapToGeneric (contacts: RemotyContactModel[]): GenericContactModel[] {
    return contacts?.map(contact => ({
      idUsuario: contact.idDevice.toString(),
      nomeUsuario: contact.nmDeviceUser,
      fone1: contact.nmDevicePhone,
      fone2: contact.nmDevicePhone2,
      idDispositivo: contact.deviceGroup.idDeviceGroup.toString(),
      nomeDispositivo: contact.deviceGroup.nmDeviceGroup
    }))
  }
}
