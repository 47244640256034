import { GenericSectorModel, SowillSectorResult } from '@/domain/models'
import { AccessDeniedError, ForbiddenError, UnexpectedError } from '@/domain/errors'
import { GetSowillSectors, GetSowillSectorsParams } from '@/domain/usecases'
import { HttpClient, HttpStatusCode } from '@/data/protocols/http'

export class RemoteGetSowillSectors implements GetSowillSectors {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<SowillSectorResult | string>
  ) { }

  async getZones (params: GetSowillSectorsParams): Promise<GenericSectorModel[]> {
    const bodyParams = {
      IdCliente: params.idCliente,
      CodInternoChave: params.codInternoChave
    }

    const { body, statusCode } = await this.httpClient.request({
      url: `${this.url}/api/sowill/listazonas`,
      method: 'post',
      body: bodyParams
    })

    switch (statusCode) {
      case HttpStatusCode.ok:
        return this.mapToGeneric(body as SowillSectorResult)
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      case HttpStatusCode.forbidden: throw new ForbiddenError(body as string)
      default: throw new UnexpectedError()
    }
  }

  mapToGeneric (sectors: SowillSectorResult): GenericSectorModel[] {
    return sectors?.map(sector => ({
      nome: sector.descricao,
      idEquipamento: sector.numeroSerie,
      codigoCliente: sector.id,
      observacao: sector.auxiliar
    }))
  }
}
