import { useTheme } from '@/presentation/hooks'
import * as React from 'react'
import { Menu } from 'react-native-paper'

type MenuActionsProps = {
  children: JSX.Element | JSX.Element[]
  onDismiss: () => void
  anchor: React.ReactNode
  visible: boolean
}

export const MenuActions: React.FC<MenuActionsProps> = ({ children, onDismiss, anchor, visible }) => {
  const theme = useTheme()

  return (
    <Menu
      contentStyle={{ backgroundColor: theme.colors.background, minWidth: 240 }}
      visible={visible}
      onDismiss={onDismiss}
      anchor={anchor}
      anchorPosition='bottom'
      >
        {children}
    </Menu>
  )
}
