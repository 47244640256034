import { atom } from 'recoil'

export const isOpenServiceOrdersFiltersState = atom({
  key: 'isOpenServiceOrdersFiltersState',
  default: false
})

export const serviceOrdersFiltersState = atom({
  key: 'serviceOrdersFiltersState',
  default: {
    situacao: 'Em Aberto' as 'Em Aberto' | 'Fechadas' | 'Todas',
    dataInicio: '',
    dataFim: ''
  }
})
