import { atom } from 'recoil'
import { Claim, ClaimsResult, ServiceType } from '@/domain/models'

export const isLoadingState = atom({
  key: 'isLoadingSacState',
  default: true
})

export const sacState = atom({
  key: 'sacState',
  default: {
    listaReclamacoes: [] as Claim[],
    listaTipoAtendimento: [] as ServiceType[]
  } as ClaimsResult
})
