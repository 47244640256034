import { atom } from 'recoil'
import { GetTickets } from '@/domain/usecases'

export const isOpenFinancialFiltersState = atom({
  key: 'isOpenFinancialFiltersState',
  default: false
})

export const ticketsFiltersState = atom({
  key: 'ticketsFiltersState',
  default: {
    situacao: GetTickets.RN.DEFAULT_STATUS,
    dataInicio: '',
    dataFim: ''
  }
})
