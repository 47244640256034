import { HttpClient, HttpStatusCode } from '@/data/protocols/http'
import { AccessDeniedError, UnexpectedError, UnexpectedResultFormatError } from '@/domain/errors'
import { NFResultModel } from '@/domain/models'
import { GetNFs, GetNFsParams } from '@/domain/usecases'

export class RemoteGetNFs implements GetNFs {
  constructor (
    private readonly url: string,
    private readonly httpGetClient: HttpClient<NFResultModel>
  ) {}

  async get (params: GetNFsParams): Promise<NFResultModel> {
    const { body, statusCode } = await this.httpGetClient.request({
      url: `${this.url}/api/NotasFiscais`,
      method: 'get',
      params,
      headers:
      {
        'content-type': 'application/json',
        'cache-control': 'no-cache'
      }
    })

    switch (statusCode) {
      case HttpStatusCode.ok:
        if (!body) {
          throw new UnexpectedResultFormatError()
        }

        return body
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}
