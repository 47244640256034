import { layoutStyles } from '@/presentation/styles/globals'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  content: {
    ...layoutStyles.pageContent
  }
})
