import { StyleSheet } from 'react-native'
export { default as CircleIcon } from '@expo/vector-icons/FontAwesome'
export { default as MoreIcon } from '@expo/vector-icons/Feather'
export { default as MarkerIcon } from '@expo/vector-icons/Foundation'

export const styles = StyleSheet.create({
  container: {
    padding: 16,
    minWidth: 320,
    maxWidth: 600,
    fontSize: 14,
    display: 'flex',
    alignItems: 'stretch',
    overflow: 'hidden',
    borderRadius: 4,
    shadowColor: 'rgba(0, 0, 0, 0.04)',
    shadowOffset: {
      width: 4,
      height: 8
    },
    shadowRadius: 4
  },
  address: {
    height: 18,
    lineHeight: 18,
    fontSize: 10
  },
  headerStyles: {
    width: '100%',
    marginBottom: 4
  },
  textStyles: {
    fontSize: 12,
    fontWeight: '400'
  },
  textOrderStyles: {
    paddingHorizontal: 4,
    fontSize: 12,
    fontWeight: '700'
  },
  title: {
    fontWeight: '700',
    fontSize: 12
  }
})
