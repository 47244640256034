import * as React from 'react'
import { StyleSheet } from 'react-native'
import { useRecoilValue } from 'recoil'
import { SnackBar, snackBarProviderState, Box } from '@/presentation/components'
import { useMediaQueries } from '@/presentation/hooks'

export function SnackBarProvider (): JSX.Element {
  const messages = useRecoilValue(snackBarProviderState)
  const { isMobile } = useMediaQueries()

  return (
    <Box
      style={{
        ...styles.container,
        display: messages?.length ? 'flex' : 'none',
        alignItems: isMobile ? 'center' : 'flex-end'
      }}
    >
    {messages?.map(notify => (
      <SnackBar
        id={notify.id}
        key={notify.id}
        message={notify.message}
        delay={notify.delay}
        variant={notify.variant}
        duration={notify.duration}
      />
    ))}
    </Box>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: '100%',
    height: 'fit-content',
    zIndex: 5,
    top: 60,
    paddingTop: 12,
    paddingHorizontal: 24
  }
})
