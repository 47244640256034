import { ServiceOrderResult } from '@/domain/models'

export type GetServiceOrdersParams = {
  codigoCliente: number
  codigoUnidade: number
  situacao: 'Em Aberto' | 'Fechadas' | 'Todas'
  dataInicial?: string
  dataFinal?: string
}

export interface GetServiceOrders {
  get: (params: GetServiceOrdersParams) => Promise<ServiceOrderResult>
}

export namespace GetServiceOrders {
  export const RN = {
    OBSERVATION_MAX_LENGTH: 180,
    LIMIT_SHOW_ITEMS: 12,
    DEFAULT_MONTH_AGO: 8,
    DEFAULT_STATUS: 'Em Aberto' as 'Em Aberto' | 'Fechadas' | 'Todas'
  }
}
