import React from 'react'
import { StyleSheet } from 'react-native'
import { Divider } from '@react-native-material/core'
import { Box } from '@/presentation/components'

type FooterProps = {
  children: JSX.Element
}

export const Footer: React.FC<FooterProps> = ({ children }) => {
  return (
    <Box style={styles.container}>
      <Divider />
      {children}
    </Box>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 60,
    backgroundColor: '#FFF',
    marginRight: 16,
    marginLeft: 16
  }
})
