import { atom } from 'recoil'

export const isLoadingNewSacState = atom({
  key: 'isLoadingNewSacState',
  default: true
})

export const isOpenNewSacState = atom({
  key: 'isOpenNewSacState',
  default: false
})

export const sacNewState = atom({
  key: 'newSacForm',
  default: {
    isLoading: false,
    tipo: 'E' as 'E' | 'R' | 'S',
    tipoDescricao: 'ELOGIO' as 'ELOGIO' | 'RECLAMAÇÃO' | 'SOLICITAÇÃO',
    reclamante: '',
    reclamacao: ''
  }
})
