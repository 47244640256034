import { GetCookie, SetCookie } from '@/data/protocols/cache'
import Cookies from 'universal-cookie'

const universalCookies = new Cookies()

export class UniversalCookieAdapter implements SetCookie, GetCookie {
  set (key: string, value: any): void {
    if (value) {
      universalCookies.set(key, value, { path: '/' })
    } else {
      universalCookies.remove(key, { path: '/' })
    }
  }

  get (key: string): any {
    return universalCookies.get(key)
  }
}
