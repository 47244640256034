import { RemotyUserResult } from '@/domain/models'
import { GetRemotyUsers } from '@/domain/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import { RemoteGetRemotyUserEvents } from '@/data/usecases/events'
import env from '@/main/config/env'

export const makeGetRemotyUsersFactory = (): GetRemotyUsers => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<RemotyUserResult | string>()

  return new RemoteGetRemotyUserEvents(url, httpClient)
}
