import { PayWithCreditCard } from '@/domain/usecases'
import { RemotePayWithCreditCard } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'

export const makePayWithCreditCard = (): PayWithCreditCard => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<string>()

  return new RemotePayWithCreditCard(url, httpClient)
}
