import { HttpClient, HttpRequest, HttpStatusCode } from '@/data/protocols'
import { AccessDeniedError, UnexpectedError, UnexpectedResultFormatError } from '@/domain/errors'
import { RedesSociaisModel } from '@/domain/models'
import { GetRedesSociais } from '@/domain/usecases/redes-sociais/get-redes-sociais'

export class RemoteGetRedesSociais implements GetRedesSociais {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<RedesSociaisModel>
  ) {}

  async getRedesSociais (): Promise<RedesSociaisModel> {
    const authParams: HttpRequest = {
      url: `${this.url}/api/Portal/LinksRedesSociais`,
      method: 'get',
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      }
    }

    const { statusCode, body } = await this.httpClient.request(authParams)

    switch (statusCode) {
      case HttpStatusCode.ok:
        if (!body) {
          throw new UnexpectedResultFormatError()
        }
        return body
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}
