import { GenerateInsideBankSlip } from '@/domain/usecases'
import { RemoteGenerateInsideBankSlip } from '@/data/usecases'
import { makeDateAdapterFactory } from '@/main/adapters'
import { makeGenerateBankSlip } from '@/main/factories/usecases'

export const makeGenerateInsideBankSlip = (): GenerateInsideBankSlip => {
  const dateAdapter = makeDateAdapterFactory()
  const generateBankSlip = makeGenerateBankSlip()

  return new RemoteGenerateInsideBankSlip(dateAdapter, generateBankSlip)
}
