import React from 'react'
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import { AreaInterest } from '@/domain/models'
import { Box, Button, Text, ModalBase, TextInput, HStack, Stack, Select, MessageBlock } from '@/presentation/components'
import { useMediaQueries, useTheme } from '@/presentation/hooks'
import { addConfigUnitState, isDuplicatedOptionSate, isOpenAddConfigUnitState, isOpenSelectInterestState, isOpenSelectUnitState, prospectConfigsState, prospectInterestAreaState, prospectUnitsState } from '@/presentation/pages/ProspectSettings/components'
import { styles } from './styles'

export const ModalAddConfigUnit: React.FC = () => {
  const theme = useTheme()
  const { isMobile } = useMediaQueries()
  const [isOpen, setOpen] = useRecoilState(isOpenAddConfigUnitState)
  const [areaInterest, setAreaInterest] = useRecoilState(addConfigUnitState)
  const [isDuplicated, setIsDuplicated] = useRecoilState(isDuplicatedOptionSate)
  const units = useRecoilValue(prospectUnitsState)
  const [isOpenSelectUnit, setOpenSelectUnit] = useRecoilState(isOpenSelectUnitState)
  const [isOpenSelectInterest, setOpenSelectInterest] = useRecoilState(isOpenSelectInterestState)
  const areaInterestsList = useRecoilValue(prospectInterestAreaState)
  const prospectConfigs = useRecoilValue(prospectConfigsState)
  const setProspectInterestAreaList = useSetRecoilState(prospectInterestAreaState)
  const reset = useResetRecoilState(addConfigUnitState)

  const handleSave = (): void => {
    if (existUnit()) {
      setIsDuplicated(true)
    } else {
      const newInterestArea: AreaInterest = {
        descricao: areaInterest?.descricao,
        email: areaInterest?.email,
        interesse: areaInterest?.interesse,
        unidade: areaInterest?.unidade
      }

      setProspectInterestAreaList((currentInterestAreaList) => ([...currentInterestAreaList, newInterestArea]))
      setOpen(false)
      reset()
    }
  }

  const existUnit = (): boolean => {
    return areaInterestsList.some(
      (interest) => interest.unidade === areaInterest.unidade && interest.interesse.descreve === areaInterest.interesse.descreve
    )
  }

  const handleInputChange = (param: keyof AreaInterest, value: number | string): void => {
    setAreaInterest(currentState => ({ ...currentState, [param]: value }))
  }

  const validValues = (): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const isValidEmail = emailRegex.test(areaInterest.email)
    return !!(areaInterest.descricao && areaInterest.unidade && areaInterest.interesse?.codInterno !== 0 && isValidEmail && !isDuplicated)
  }

  const handleClose = (): void => {
    setOpen(false)
    reset()
  }

  return (
    <ModalBase visible={isOpen} onClose={handleClose}>
      <Box style={styles.container}>
        <Text variant="subtitle1">Adicionar nova opção para cliente</Text>

        {isDuplicated &&
          <MessageBlock
            status='error'
            message='Já existe uma opção com esse interesse na mesma unidade!'
          />
        }

        <HStack style={styles.content} spacing={10}>
          <Select.Trigger
            label='Unidade*'
            description={areaInterest.unidade ? areaInterest.unidade.toString() : ''}
            onOpen={() => setOpenSelectUnit(true)}
          />
          <TextInput
            style={{ flex: 1 }}
            label="Descrição*"
            value={areaInterest.descricao}
            onChangeText={(text: string) => handleInputChange('descricao', text)}
            maxLength={50}
          />
        </HStack>
        <Stack
          style={styles.content}
          direction={isMobile ? 'column' : 'row'}
          spacing={isMobile ? 0 : 10}
        >
          <Select.Trigger
            label='Interesse*'
            description={areaInterest.interesse?.descreve || ''}
            onOpen={() => setOpenSelectInterest(true)}
            style={{ minWidth: '100%' }}
          />
        </Stack>
        <TextInput
          label="E-mail*"
          value={areaInterest?.email}
          onChangeText={(text: string) => handleInputChange('email', text)}
          textContentType='emailAddress'
        />

        <Box style={styles.buttonArea}>
          <Button
            color={theme.colors.shadow}
            variant="text"
            title="CANCELAR"
            onPress={handleClose}
          />
          <Button
            title="SALVAR"
            disabled={!validValues()}
            onPress={handleSave}
            color={theme.colors.success}
            tintColor={theme.colors.white}
          />
        </Box>
      </Box>
      <Select.Modal
        title='Selecione a unidade'
        isOpen={isOpenSelectUnit}
        onClose={() => setOpenSelectUnit(false)}
        items={units.map(unit => ({
          id: unit.codigoUnidade,
          descricao: unit.codigoUnidade.toString(),
          data: unit,
          isSelected: unit.codigoUnidade === areaInterest.unidade
        }))}
        onSelect={(unitCode, _) => {
          setAreaInterest(currentState => ({ ...currentState, unidade: unitCode }))
          isDuplicated && setIsDuplicated(false)
        }}
      />
      <Select.Modal
        title='Selecione a área de interesse'
        isOpen={isOpenSelectInterest}
        onClose={() => setOpenSelectInterest(false)}
        items={prospectConfigs.listaInteresse?.map(interest => ({
          id: interest.codInterno,
          descricao: interest.descreve,
          data: interest,
          isSelected: interest.codInterno === areaInterest.interesse?.codInterno
        }))}
        onSelect={(_, data) => {
          setAreaInterest(currentState => ({ ...currentState, interesse: data }))
          isDuplicated && setIsDuplicated(false)
        }}
      />
    </ModalBase>
  )
}
