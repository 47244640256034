import React, { useCallback } from 'react'
import { SafeAreaView } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { GetClientByCode, MetricsLogin, SaveNotificationToken } from '@/domain/usecases'
import { useAuth, useErrorHandler } from '@/presentation/hooks'
import { isLoadingState, Menu } from '@/presentation/pages/Home/components'
import { authenticationState, localStorageState, PhoneModal } from '@/presentation/components'
import { DateAdapter } from '@/data/protocols'
import { version } from '../../../../package.json'

type HomeProps = {
  getClientByCode: GetClientByCode
  navigation: any
  metricsLogin: MetricsLogin
  dateAdapter: DateAdapter
}

export const Home: React.FC<HomeProps> = ({
  getClientByCode,
  navigation,
  metricsLogin,
  dateAdapter
}) => {
  const handleError = useErrorHandler()
  const { setClient, getCurrentClient } = useAuth()
  const setLoading = useSetRecoilState(isLoadingState)
  const [autentication, setAuthentication] = useRecoilState(authenticationState)
  const localStorageAdapter = useRecoilValue(localStorageState)

  const currentClient = getCurrentClient()

  useFocusEffect(
    useCallback(() => {
      const tokenCliente = localStorageAdapter.get(SaveNotificationToken.RN.LOCAL_STORAGE_TOKEN)

      if (parseInt(currentClient, 10) > 0) {
        setLoading(true)
        getClientByCode
          .getByCode({ codigoCliente: parseInt(currentClient, 10), tokenDispositivo: tokenCliente })
          .then(client => {
            setClient(client)

            if (autentication.registerLogin) {
              void metricsLogin
                .register({
                  dataOcorrencia: dateAdapter.format(dateAdapter.today(), 'YYYY-MM-DDTHH:mm:ssZ'),
                  email: client.email,
                  unidade: client.unidade,
                  usuario: client.codCliente.toString(),
                  versao: version
                })
                .then(() => setAuthentication(currentState => ({ ...currentState, registerLogin: false })))
                .catch(console.error)
            }
          })
          .catch(handleError)
          .finally(() => setLoading(false))
      }
    }, [currentClient])
  )

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <Menu navigation={navigation} />
      <PhoneModal/>
    </SafeAreaView>
  )
}
