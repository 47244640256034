import React from 'react'
import { useRecoilValue } from 'recoil'
import { Box, Text, CardDetails, CardRow } from '@/presentation/components'
import { serviceOrderDetailState } from '@/presentation/pages/ServiceOrderDetail/components'
import { useFormat } from '@/presentation/hooks'
import { styles } from '../../styles'

export const OsTotalizer: React.FC = () => {
  const serviceOrderDetail = useRecoilValue(serviceOrderDetailState)
  const { formatCurrency } = useFormat()

  return (
    <>
      <Text style={styles.pageInfo}>Valores Gerais</Text>
      <CardRow>
        <CardDetails minWidth='100%'>
          {serviceOrderDetail?.exibeValoresProdutosServicos &&
            <>
              <Text><Text style={styles.bold}>Total Líquido de Produtos: </Text>{formatCurrency(serviceOrderDetail.totalProdutos)}</Text>
              <Text><Text style={styles.bold}>Total Líquido de Serviços: </Text>{formatCurrency(serviceOrderDetail.totalServicos)}</Text>
              <Text><Text style={styles.bold}>Total de Descontos: </Text>{formatCurrency(serviceOrderDetail.descontoProd + serviceOrderDetail.descontoServ)}</Text>
              <Box style={styles.additionalTextBox}>
                <Text><Text style={styles.pageInfo}>Valor da OS: {formatCurrency(serviceOrderDetail.totalProdutos + serviceOrderDetail.totalServicos)}</Text></Text>
              </Box>
            </>
          }
        </CardDetails>
      </CardRow>
    </>
  )
}
