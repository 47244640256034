import { atom } from 'recoil'
import { TravelAlertParams } from '@/presentation/pages/TravelAlert'

export const travelAlertTimePickerState = atom({
  key: 'travelAlertTimePickerState',
  default: {
    isOpen: false,
    currentTime: '' as keyof TravelAlertParams
  }
})
