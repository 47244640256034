import { atom, selector } from 'recoil'
import { makeLocalStorageAdapter } from '@/main/factories/cache'
import { ThemeColors, ThemeModel } from '@/domain/models'
import { theme } from '@/presentation/styles'
import { unitsState } from '@/presentation/components'

export const isLoadingState = atom({
  key: 'isLoadingThemeState',
  default: true
})

export const errorThemeState = atom({
  key: 'errorThemeState',
  default: ''
})

type ThemeProps = {
  theme: ThemeModel
  colorSelect: keyof ThemeColors
}

const initialTheme: ThemeModel = makeLocalStorageAdapter().get('portalTheme') || {
  ...null as unknown as ThemeModel,
  mainColor: theme.colors.primary,
  menuColor: theme.colors.white,
  loginColor: theme.colors.white,
  buttonSave: theme.colors.secondary
}

export const initialThemeState = atom({
  key: 'initialThemeState',
  default: initialTheme
})

export const colorSelectState = atom({
  key: 'colorSelectState',
  default: 'mainColor'
})

export const themeState = atom<ThemeProps>({
  key: 'themeClient',
  default: {
    theme: initialTheme,
    colorSelect: 'mainColor'
  }
})

type ImageProps = {
  file: File
  scope: 'logo' | 'loginBackground'
}

export const imagesState = atom<ImageProps[]>({
  key: 'imagesState',
  default: []
})

export const selectedLogoState = atom({
  key: 'selectedLogoState',
  default: null as unknown as File
})

export const selectedBackgroundState = atom({
  key: 'selectedBackgroundState',
  default: null as unknown as File
})

export type ExternalAddress = {
  enderecoExterno: string
  unidades: number[]
}

export const currentListServerUrlState = selector<ExternalAddress[]>({
  key: 'currentListServerUrlState',
  get: ({ get }) => {
    const units = get(unitsState)

    const serverList = units.reduce<any>((acc, unit) => {
      const { enderecoExterno, ...dadosUnidade } = unit
      if (!acc[enderecoExterno]) {
        acc[enderecoExterno] = {
          enderecoExterno,
          unidades: []
        }
      }
      acc[enderecoExterno].unidades.push(dadosUnidade.codigoUnidade)
      return acc
    }, [])

    return Object.values(serverList)
  }
})
