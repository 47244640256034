import React, { useEffect, useCallback } from 'react'
import { GestureResponderEvent } from 'react-native'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { GetNFs, GetNFsParams } from '@/domain/usecases'
import { DateAdapter } from '@/data/protocols'
import { useAuth, useErrorHandler } from '@/presentation/hooks'
import { Box, DatePicker, SideFilter, InputField, Divider } from '@/presentation/components'
import { nfsState, nfsFiltersState, nfsConfigState, nfState, leaseReceiptState, isLoadingNfState, isOpenNfsFiltersState } from '@/presentation/pages/NotaFiscal/components'

type DateParams = {
  dataInicio: string
  dataFim: string
}

type SideDrawerProps = {
  getNFs: GetNFs
  dateAdapter: DateAdapter
}

export const MARGIN_MODALS = 30

export const SideFilters: React.FC<SideDrawerProps> = ({ getNFs, dateAdapter }) => {
  const { getClient } = useAuth()
  const handleError = useErrorHandler()
  const [filters, setFilters] = useRecoilState(nfsFiltersState)
  const [isLoading, setLoading] = useRecoilState(isLoadingNfState)
  const [isOpen, setOpen] = useRecoilState(isOpenNfsFiltersState)
  const setNFsConfigs = useSetRecoilState(nfsConfigState)
  const setNFs = useSetRecoilState(nfState)
  const setNFSs = useSetRecoilState(nfsState)
  const setLeaseReceipts = useSetRecoilState(leaseReceiptState)

  const client = getClient()

  useEffect(() => {
    const initFilters = {
      isOpen: false,
      dataInicio: dateAdapter.monthAgo(GetNFs.RN.DEFAULT_MONTHS_AGO).toString(),
      dataFim: dateAdapter.today().toString()
    }

    if (!filters.dataInicio || !filters.dataFim) {
      setFilters(initFilters)
    }
  }, [])

  const handleResetState = (): void => {
    setFilters(currentState => ({
      ...currentState,
      dataInicio: dateAdapter.format(dateAdapter.monthAgo(GetNFs.RN.DEFAULT_MONTHS_AGO), 'YYYY-MM-DD'),
      dataFim: dateAdapter.format(dateAdapter.today(), 'YYYY-MM-DD')
    }))
  }

  const onSubmit = (event: GestureResponderEvent): void => {
    event.preventDefault()
    setLoading(true)

    const paramsGetTicket: GetNFsParams = {
      cliCodigo: client.codCliente,
      dtInicial: dateAdapter.format(filters.dataInicio, 'YYYY-MM-DD'),
      dtFinal: dateAdapter.format(filters.dataFim, 'YYYY-MM-DD')
    }

    getNFs
      .get(paramsGetTicket)
      .then(nfResult => {
        setNFsConfigs({
          ocultaInfos: nfResult.ocultaInfos,
          ocultaLink: nfResult.ocultaLink
        })
        setLeaseReceipts(nfResult.arrRecibos)
        setNFs(nfResult.arrNF)
        setNFSs(nfResult.arrNFS)
      })
      .catch(handleError)
      .finally(() => setLoading(false))

    setOpen(false)
  }

  const handleChangeDate = useCallback((field: keyof DateParams, date: Date): void => {
    setFilters(currentFilters => ({
      ...currentFilters,
      [field]: date.toString()
    }))
  }, [])

  const handleClose = (): void => setOpen(false)

  return (
    <SideFilter.Drawer
      open={isOpen}
      onClose={handleClose}
      loading={isLoading}
      onSubmit={onSubmit}
      resetState={handleResetState}
    >
      <Box>
        <SideFilter.Title title='Data de Emissão' />
        <InputField>
          <DatePicker
            label='De'
            value={filters.dataInicio ? dateAdapter.parse(filters.dataInicio) : undefined}
            disabled={isLoading}
            dateAdapter={dateAdapter}
            onChange={(date) => handleChangeDate('dataInicio', date ?? dateAdapter.monthAgo(GetNFs.RN.DEFAULT_MONTHS_AGO))}
          />
          <Divider />
          <DatePicker
            label='Até'
            value={filters.dataFim ? dateAdapter.parse(filters.dataFim) : undefined}
            disabled={isLoading}
            dateAdapter={dateAdapter}
            onChange={(date) => handleChangeDate('dataFim', date ?? dateAdapter.today())}
          />
        </InputField>
      </Box>
    </SideFilter.Drawer>
  )
}
