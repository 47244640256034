import React from 'react'
import { SafeAreaView } from 'react-native'
import { UpdateDefaultPassword, ValidatePasswordRecovery } from '@/domain/usecases'
import { useTheme, useThemeClient } from '@/presentation/hooks'
import { BackgroundTheme, Box, RecoveryPasswordC, Text } from '@/presentation/components'
import { LogoTheme } from '@/presentation/components/LogoTheme'
import { styles } from './styles'
import { version } from '../../../../package.json'

type RecoveryPasswordProps = {
  navigation: any
  validatePasswordRecovery: ValidatePasswordRecovery
  updateDefaultPassword: UpdateDefaultPassword
}

export const RecoveryPassword: React.FC<RecoveryPasswordProps> = ({ navigation, validatePasswordRecovery, updateDefaultPassword }) => {
  const theme = useTheme()
  const { getThemeClient } = useThemeClient()
  const themeClient = getThemeClient()

  return (
    <SafeAreaView style={{ ...styles.container, backgroundColor: themeClient.mainColor }}>
      <BackgroundTheme />

      <Box
        style={{
          ...styles.loginContent,
          backgroundColor: themeClient.mainColor,
          borderColor: theme.colors.greyDarken2
        }}
      >
        <LogoTheme height={100}/>
        <RecoveryPasswordC
          navigation={navigation}
          validatePasswordRecovery={validatePasswordRecovery}
          updateDefaultPassword={updateDefaultPassword}
        />
          <Text style={styles.footer} color={theme.colors.white} variant="subtitle1">Recuperação de senha{version}</Text>
        </Box>
      <Text style={styles.footer} color={theme.colors.white} variant="subtitle1">Desenvolvido por Inside Sistemas | v{version}</Text>
    </SafeAreaView>
  )
}
