import React, { useCallback } from 'react'
import { GestureResponderEvent, SafeAreaView } from 'react-native'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { ScrollView } from 'react-native-gesture-handler'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useFocusEffect } from '@react-navigation/native'
import { SaveCreditCard, GetCreditCard, GetCreditCardParams, SaveCreditCardParams } from '@/domain/usecases'
import { CardFlagsTypes, cardMaskByFlag } from '@/domain/models'
import { useAuth, useCreditCard, useErrorHandler, useNotify, useTheme, useThemeClient } from '@/presentation/hooks'
import { Box, Button, HStack, PageHeader, TAB_BOTTOM_CONTAINER_HEIGTH, VStack } from '@/presentation/components'
import { AddNewCreditCard, creditCardState, CreditCard, isEditableState, isLoadingState, isOpenEditConfirmationState, ModalModalEditConfirmation } from '@/presentation/pages/Cartao/components'
import { styles } from './styles'

type CartaoProps = {
  getCard: GetCreditCard
  saveCard: SaveCreditCard
}

export const Cartao: React.FC<CartaoProps> = ({ getCard, saveCard }) => {
  const theme = useTheme()
  const notify = useNotify()
  const { getClient } = useAuth()
  const { getThemeClient } = useThemeClient()
  const { getCreditCardType } = useCreditCard()
  const [creditCard, setCreditCard] = useRecoilState(creditCardState)
  const [isLoading, setLoading] = useRecoilState(isLoadingState)
  const [isEditable, setEditable] = useRecoilState(isEditableState)
  const setOpenEditConfirmation = useSetRecoilState(isOpenEditConfirmationState)
  const handleError = useErrorHandler()

  const client = getClient()
  const themeClient = getThemeClient()

  useFocusEffect(
    useCallback(() => {
      setLoading(true)
      setEditable(false)

      const getCardParams: GetCreditCardParams = {
        codCliente: client.codCliente
      }

      getCard
        .getByClient(getCardParams)
        .then((card) => {
          if (card) {
            setCreditCard(card)
            setEditable(false)
          } else {
            setEditable(true)
          }
        })
        .catch(handleError)
        .finally(() => setLoading(false))
    }, [])
  )

  const isValidDataCard = useCallback((): boolean => {
    if (
      !creditCard.nome ||
      !creditCard.numero ||
      !creditCard.expiracao ||
      creditCard.expiracao.length < 4 ||
      !creditCard.expiracao.includes('/') ||
      !creditCard.ccv ||
      creditCard.numero.includes('*')
    ) {
      return false
    } else {
      return true
    }
  }, [creditCard])

  const handleSaveCreditCard = (event: GestureResponderEvent): void => {
    event.preventDefault()

    const saveCardParams: SaveCreditCardParams = {
      codCliente: client.codCliente,
      email: client.email,
      unidade: client.unidade,
      type: getCreditCardType(creditCard.numero),
      mask: cardMaskByFlag[creditCard.type],
      ccv: creditCard.ccv,
      expiracao: creditCard.expiracao,
      nome: creditCard.nome,
      numero: creditCard.numero
    }

    if (isValidDataCard()) {
      setLoading(true)

      saveCard
        .save(saveCardParams)
        .then(notify.show)
        .catch(handleError)
        .finally(() => setLoading(false))
    } else {
      handleError(new Error('Todos os dados do cartão devem ser informados'))
    }
  }

  const handleResetCard = (event: GestureResponderEvent): void => {
    event.preventDefault()

    setCreditCard(currentState => ({
      ...currentState,
      numero: '',
      nome: '',
      ccv: '',
      expiracao: '',
      mask: '9999 9999 9999 9999',
      type: 'unknown' as CardFlagsTypes,
      vencimento: ''
    }))
  }

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView>
        <Box style={styles.content}>
          <PageHeader
            text='Cartão de crédito'
            icon={<MaterialCommunityIcons size={24} color={theme.colors.grey} name='credit-card-outline' />}
          />
          <VStack justify='center' items='center'>
            <AddNewCreditCard isEditable={isEditable} isLoading={isLoading} />
            <CreditCard creditCard={creditCard} />
          </VStack>
          <HStack
            items='center'
            justify='end'
            mt={18}
            pr={16}
            center
            mb={TAB_BOTTOM_CONTAINER_HEIGTH + 12}
            spacing={8}
          >
            {(isLoading || !isEditable) &&
              <Button
                title='ALTERAR'
                testID='cartao-alterar'
                variant='text'
                color={theme.colors.greyLighten}
                onPress={() => { setOpenEditConfirmation(true) }}
              />
            }
            {(!isLoading && isEditable) &&
              <Button
                title='LIMPAR'
                testID='cartao-limpar'
                variant='text'
                color={theme.colors.greyLighten}
                onPress={handleResetCard}
              />
            }
            <Button
              title='SALVAR'
              testID='cartao-salvar'
              variant='outlined'
              color={themeClient.mainColor}
              onPress={handleSaveCreditCard}
              disabled={isLoading || !isEditable}
            />
          </HStack>
        </Box>
      </ScrollView>
      <ModalModalEditConfirmation />
    </SafeAreaView>
  )
}
