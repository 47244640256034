import { StyleSheet } from 'react-native'
export { default as MarkerIcon } from '@expo/vector-icons/Foundation'

export const styles = StyleSheet.create({
  container: {
    display: 'flex'
  },
  title: {
    fontWeight: '700',
    marginBottom: 8
  }
})
