import { HttpClient, HttpStatusCode } from '@/data/protocols/http'
import { AccessDeniedError, ResultWithoutRecordsError, UnexpectedError } from '@/domain/errors'
import { OpenDanfe, OpenDanfeParams } from '@/domain/usecases'

export class RemoteOpenDanfe implements OpenDanfe {
  constructor (
    private readonly url: string,
    private readonly httpGetClient: HttpClient<string>
  ) {}

  async open (params: OpenDanfeParams): Promise<void> {
    const { body, statusCode } = await this.httpGetClient.request({
      url: `${this.url}/api/portal/gerar-danfe`,
      method: 'post',
      body: params
    })

    switch (statusCode) {
      case HttpStatusCode.ok:
        if (!body) {
          throw new ResultWithoutRecordsError()
        }
        window.open(`/${OpenDanfe.RN.FOLDER_DEFAULT_PDF_DANFE}/${params.cliCodigo}/${body}`, '_blank')
        break
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}
