import { atom } from 'recoil'
import { NFModel } from '@/domain/models'

export const isLoadingNfState = atom({
  key: 'isLoadingNfState',
  default: true
})

export const isLoadingOpenNfState = atom({
  key: 'isLoadingOpenNfState',
  default: false
})

export const nfsConfigState = atom({
  key: 'nfsConfigState',
  default: {
    ocultaInfos: false,
    ocultaLink: false
  }
})

export const nfsState = atom({
  key: 'nfsState',
  default: [] as NFModel[]
})

export const nfState = atom({
  key: 'nfState',
  default: [] as NFModel[]
})

export const leaseReceiptState = atom({
  key: 'leaseReceiptState',
  default: [] as NFModel[]
})
