import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { TextInput } from 'react-native-paper'
import { MaterialIcons } from '@expo/vector-icons'
import { TestEmail } from '@/domain/usecases'
import { CardSwitch, Button, Stack, Text } from '@/presentation/components'
import { useTheme, useThemeClient } from '@/presentation/hooks'
import { initialGeneralConfigsState, generalConfigsState, isLoadingTestMailState, hasErrorGeneralConfigState } from '@/presentation/pages/Settings/components'

type EmailSendingTesterProps = {
  testEmail: TestEmail
}

export const EmailSendingTester: React.FC<EmailSendingTesterProps> = ({ testEmail }) => {
  const theme = useTheme()
  const { getThemeClient } = useThemeClient()
  const [generalConfigs, setGeneralConfig] = useRecoilState(generalConfigsState)
  const [initialGeneralConfigs, setInitialGeneralConfigs] = useRecoilState(initialGeneralConfigsState)
  const [mailSended, setMailSended] = useState(false)
  const [responseMail, setResponseMail] = useState('')
  const [isLoadingTestMail, setLoadingTestMail] = useRecoilState(isLoadingTestMailState)
  const [hasError, setError] = useRecoilState(hasErrorGeneralConfigState)

  const themeClient = getThemeClient()

  useEffect(() => {
    setInitialGeneralConfigs({ ...generalConfigs })
  }, [initialGeneralConfigs])

  const handleChange = (param: 'email' | 'nome', value: string | boolean): void => {
    setGeneralConfig(currentState => ({ ...currentState, [param]: value }))
    setMailSended(false)
    setError([])
  }

  const handleSendTestMail = (): void => {
    setLoadingTestMail(true)

    const testEmailParams = {
      email: generalConfigs.email,
      copia: generalConfigs.copia,
      nome: generalConfigs.nome
    }

    testEmail
      .test(testEmailParams)
      .then(isValid => {
        setMailSended(isValid)
        if (isValid) {
          setResponseMail('O e-mail de teste foi enviado com sucesso! Verifique sua caixa de entrada e lixo eletrônico')
        } else {
          throw new Error('O e-mail de teste não pode ser enviado, verifique os dados de acesso e tente novamente')
        }
      })
      .catch(error => {
        setMailSended(false)
        setResponseMail(error.message)
      })
      .finally(() => setLoadingTestMail(false))
  }

  return (
    <CardSwitch
      text='Configurações de Envio de E-mail'
      value={true}
      handleSwitch={() => {}}
      readonly={true}
    >
      <Stack direction='row' spacing={8} justify='between' items='end'>
        <TextInput
          mode="flat"
          activeUnderlineColor={themeClient.mainColor}
          label="Nome exibição"
          underlineColorAndroid="transparent"
          underlineColor="transparent"
          value={generalConfigs.nome}
          onChangeText={(text: string) => handleChange('nome', text)}
          maxLength={50}
          style={{
            flex: 0.3
          }}
        />
        <TextInput
          mode="flat"
          activeUnderlineColor={themeClient.mainColor}
          label="E-mail Remetente"
          underlineColorAndroid="transparent"
          underlineColor="transparent"
          value={generalConfigs.email}
          error={hasError.includes('email')}
          onChangeText={(text: string) => handleChange('email', text)}
          maxLength={300}
          style={{
            flex: 0.7
          }}
        />
        <Button
          title={isLoadingTestMail
            ? 'Enviando e-mail...'
            : mailSended
              ? 'Enviado com sucesso'
              : 'Enviar e-mail teste'
          }
          color={themeClient.mainColor}
          tintColor={theme.colors.white}
          trailing={props => mailSended
            ? <MaterialIcons name="check" {...props} />
            : <MaterialIcons name="send" {...props} />
          }
          onPress={handleSendTestMail}
          loading={isLoadingTestMail}
          disabled={mailSended}
        />
      </Stack>
      <>
        {mailSended &&
          <Stack direction='row' items='center' justify='center' spacing={8} pt={8}>
            {responseMail &&
              <>
                <MaterialIcons name="info" color={theme.colors.primary} size={18} />
                <Text style={{ marginLeft: 8 }} color={theme.colors.grey}>{responseMail}</Text>
              </>
            }
          </Stack>
        }
      </>
    </CardSwitch>
  )
}
