import React, { useCallback } from 'react'
import { StyleSheet, GestureResponderEvent } from 'react-native'
import { Box, Button, Text, TextInput } from '@react-native-material/core'
import { RadioButton } from 'react-native-paper'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { Claim } from '@/domain/models'
import { CreateSac, CreateSacParams } from '@/domain/usecases'
import { DateAdapter } from '@/data/protocols'
import { useAuth, useErrorHandler, useNotify, useTheme } from '@/presentation/hooks'
import { ModalBase } from '@/presentation/components'
import { isLoadingNewSacState, isOpenNewSacState, sacNewState, sacState } from '@/presentation/pages/Sac/components'

type ModalNewSacParams = {
  tipo: 'E' | 'R' | 'S'
  tipoDescricao: 'ELOGIO' | 'RECLAMAÇÃO' | 'SUGESTÃO'
  reclamante: string
  reclamacao: string
}

type ModalNewSacProps = {
  createSac: CreateSac
  dateAdapter: DateAdapter
}

export const ModalNewSac: React.FC<ModalNewSacProps> = ({ createSac, dateAdapter }: ModalNewSacProps) => {
  const theme = useTheme()
  const notify = useNotify()
  const { getClient } = useAuth()
  const handleError = useErrorHandler()
  const [newSac, setNewSac] = useRecoilState(sacNewState)
  const setSacs = useSetRecoilState(sacState)
  const setLoading = useSetRecoilState(isLoadingNewSacState)
  const [isOpen, setOpen] = useRecoilState(isOpenNewSacState)

  const client = getClient()

  const handleChangeInput = (name: keyof ModalNewSacParams, value: string): void => {
    setNewSac(currentValues => ({ ...currentValues, [name]: value }))
  }

  const handleSubmit = (event: GestureResponderEvent): void => {
    event.preventDefault()
    const typeDescription = {
      E: 'ELOGIO',
      S: 'SOLICITAÇÃO',
      R: 'RECLAMAÇÃO'
    }[newSac.tipo]

    const dateParam = dateAdapter.today().toString()

    const createSacParams: CreateSacParams = {
      reclamante: newSac.reclamante,
      reclamacao: newSac.reclamacao,
      codCliente: client.codCliente,
      tipo: newSac.tipo,
      data: dateParam,
      hora: dateParam,
      dataAbertura: dateParam,
      nomeCliente: client.nomeCliente,
      dataAcaoImediata: dateParam,
      dataAcaoCorretiva: dateParam,
      unidade: client.unidade,
      tipoDescricao: typeDescription
    }

    createSac
      .create(createSacParams)
      .then(codigo => {
        if (codigo) {
          successNewSac(createSacParams, codigo as unknown as string)
          resetNewSac()
        } else {
          throw new Error('Não foi possível gravar o SAC!')
        }
      })
      .catch(handleError)
      .finally(handleCloseModal)
  }

  const successNewSac = (createSacParams: CreateSacParams, codigo: string): void => {
    setSacs(currentState => ({
      ...currentState,
      listaReclamacoes: [
        ...currentState.listaReclamacoes,
        {
          ...createSacParams as unknown as Claim,
          codReclamacao: parseInt(codigo)
        }
      ]
    }))

    notify.success('SAC gravado com sucesso')
  }

  const resetNewSac = (): void => {
    setLoading(false)
    setOpen(false)
    setNewSac(currentState => ({
      ...currentState,
      reclamacao: '',
      reclamante: '',
      tipo: 'E',
      tipoDescricao: 'ELOGIO'
    }))
  }

  const handleCloseModal = useCallback((): void => {
    setLoading(false)
    setOpen(false)
  }, [])

  return (
    <ModalBase visible={isOpen} onClose={handleCloseModal}>
      <Box style={styles.container}>
        <Text variant="subtitle1">Preencha os dados para criar um SAC</Text>

        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <RadioButton
              testID='sac-elogio'
              value="E"
              status={newSac.tipo === 'E' ? 'checked' : 'unchecked'}
              onPress={() => handleChangeInput('tipo', 'E')}
            />
            <Text
              style={{
                ...styles.textOrderStyles,
                color: theme.colors.texts
              }}
              variant='h3'
            >
              Elogio
            </Text>
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <RadioButton
            testID='sac-solicitacao'
              value="S"
              status={newSac.tipo === 'S' ? 'checked' : 'unchecked'}
              onPress={() => handleChangeInput('tipo', 'S')}
            />
            <Text
              style={{
                ...styles.textOrderStyles,
                color: theme.colors.texts
              }}
              variant='h3'
            >
              Solicitação
            </Text>
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <RadioButton
              value="R"
              testID='sac-reclamacao'
              status={newSac.tipo === 'R' ? 'checked' : 'unchecked'}
              onPress={() => handleChangeInput('tipo', 'R')}
            />
            <Text
              style={{
                ...styles.textOrderStyles,
                color: theme.colors.texts
              }}
              variant='h3'
            >
              Reclamação
            </Text>
          </Box>
        </Box>

        <TextInput
          label="Seu Nome*"
          onChangeText={text => handleChangeInput('reclamante', text)}
          testID='sac-nome'
          value={newSac.reclamante}
        />
        <TextInput
          label="O que você tem a dizer?*"
          testID='sac-texto'
          onChangeText={text => handleChangeInput('reclamacao', text)}
          value={newSac.reclamacao}
          multiline
        />

        <Box style={styles.buttonArea}>
          <Button
            color={theme.colors.shadow}
            variant="text"
            testID='sac-cancelar'
            title="Cancelar"
            onPress={handleCloseModal}
            disabled={newSac.isLoading}
          />
          <Button
            variant="text"
            testID='sac-confirmar'
            title="Confirmar"
            onPress={handleSubmit}
            disabled={newSac.isLoading || !newSac.reclamante.length || !newSac.reclamacao.length}
          />
        </Box>
      </Box>
    </ModalBase>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    gap: 18
  },
  buttonArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 8
  },
  textOrderStyles: {
    fontSize: 14,
    fontWeight: '400'
  }
})
