import { CreditCardModel } from '@/domain/models'
import { GetCreditCard } from '@/domain/usecases'
import { RemoteGetCreditCard } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'

export const makeGetCreditCard = (): GetCreditCard => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<CreditCardModel>()

  return new RemoteGetCreditCard(url, httpClient)
}
