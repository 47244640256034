import React, { useMemo } from 'react'
import { DatePickerInput } from 'react-native-paper-dates'
import { DateAdapter } from '@/data/protocols'
import { useTheme } from '@/presentation/hooks'
import { styles } from './styles'

type DatePickerProps = {
  dateAdapter: DateAdapter
  label: string
  value: Date | undefined
  onChange: (date: Date | undefined) => void
  disabled?: boolean
  initYear?: number
  finalYear?: number
}

export const DatePicker: React.FC<DatePickerProps> = ({
  label,
  value,
  onChange,
  disabled,
  initYear,
  finalYear,
  dateAdapter
}) => {
  const theme = useTheme()

  const INITIAL_YEAR = useMemo(() => initYear ?? dateAdapter.today().getFullYear() - 5, [])
  const FINAL_YEAR = useMemo(() => finalYear ?? dateAdapter.today().getFullYear() + 12, [])

  const inputStyle = {
    ...styles.container,
    backgroundColor: theme.colors.backgroundInput,
    color: theme.colors.greyDark
  }

  return (
    <DatePickerInput
      inputMode="start"
      locale="pt-BR"
      value={value}
      label={label}
      disabled={disabled}
      style={inputStyle}
      startYear={INITIAL_YEAR}
      endYear={FINAL_YEAR}
      withDateFormatInLabel={false}
      inputEnabled={false}
      onChange={onChange}
    />
  )
}
