import { UpdatePassword } from '@/domain/usecases'
import { RemoteUpdatePassword } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'

export const makeUpdatePassword = (): UpdatePassword => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<boolean | string>()

  return new RemoteUpdatePassword(url, httpClient)
}
