import { HttpClient, HttpStatusCode } from '@/data/protocols/http'
import { AccessDeniedError, UnexpectedError } from '@/domain/errors'
import { GenericEventModel, SigmaEventModel } from '@/domain/models'
import { GetSigmaEvents, GetSigmaEventsParams } from '@/domain/usecases'

export class RemoteGetSigmaEvents implements GetSigmaEvents {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<SigmaEventModel[]>
  ) {}

  async getEvents (params: GetSigmaEventsParams): Promise<GenericEventModel[]> {
    const { body, statusCode } = await this.httpClient.request({
      url: `${this.url}/api/EventosSigma`,
      method: 'get',
      params
    })

    switch (statusCode) {
      case HttpStatusCode.ok: return this.mapToGeneric(body)
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }

  mapToGeneric (events: SigmaEventModel[]): GenericEventModel[] {
    return events?.map(sigmaEvent => ({
      evento: sigmaEvent?.evento,
      descricao: sigmaEvent?.descricao,
      dataEvento: sigmaEvent?.dataEvento,
      aux: sigmaEvent?.aux
    }))
  }
}
