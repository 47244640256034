import React from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { DateAdapter } from '@/data/protocols'
import { useAuth, useMediaQueries, useNotify } from '@/presentation/hooks'
import { ChipFilter, HStack } from '@/presentation/components'
import { isOpenMonitoringFiltersState, monitoringDateFiltersState, remotyFilterState, selectSigmaCloudKeyData, sowillIdentifierState } from '@/presentation/pages/Events/components'
import { ClientMonitoringType } from '@/domain/models'

type FiltersChipPanelProps = {
  dateAdapter: DateAdapter
}

export const FiltersChipPanel: React.FC<FiltersChipPanelProps> = ({ dateAdapter }) => {
  const setOpen = useSetRecoilState(isOpenMonitoringFiltersState)
  const filters = useRecoilValue(monitoringDateFiltersState)
  const { getClient } = useAuth()
  const { isMobile } = useMediaQueries()
  const notify = useNotify()

  const { remotyIdEquipamento } = useRecoilValue(remotyFilterState)
  const sowillIdentifier = useRecoilValue(sowillIdentifierState)
  const currentSigmaKey = useRecoilValue(selectSigmaCloudKeyData)

  const client = getClient()

  const period = filters.dataInicial && filters.dataFinal
    ? `${dateAdapter.format(filters.dataInicial, 'DD/MM/YYYY')} até ${dateAdapter.format(filters.dataFinal, 'DD/MM/YYYY')}`
    : filters.dataFinal
      ? `${dateAdapter.format(filters.dataFinal, 'DD/MM/YYYY')}`
      : `${dateAdapter.format(filters.dataInicial, 'DD/MM/YYYY')}`

  const handleOpen = (): void => {
    const conditions = {
      [ClientMonitoringType.REMOTY]: remotyIdEquipamento !== 0,
      [ClientMonitoringType.MONI]: client.tipoConexao === 1,
      [ClientMonitoringType.SIGMA_CLOUD]: !!(currentSigmaKey.codCliente && currentSigmaKey.codCliente !== 0),
      [ClientMonitoringType.SIGMA_DESK]: !!(currentSigmaKey.codCliente && currentSigmaKey.codCliente !== 0),
      [ClientMonitoringType.SO_WILL]: !!(sowillIdentifier.codInternoChave && sowillIdentifier.codInternoChave !== 0)
    }[client.tipoConexao]

    if (conditions) {
      setOpen(true)
    } else {
      notify.show('Selecione uma empresa antes de filtrar a data', { variant: 'error' })
    }
  }

  return (
    <HStack mt={isMobile ? 8 : 0} wrap>
      <ChipFilter
        title='Período'
        subtitle={period}
        onPress={handleOpen}
      />
    </HStack>
  )
}
