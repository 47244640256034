import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    padding: 16,
    minWidth: 320,
    fontSize: 14,
    display: 'flex',
    alignItems: 'stretch',
    overflow: 'hidden',
    borderRadius: 4,
    shadowColor: 'rgba(0, 0, 0, 0.04)',
    shadowOffset: {
      width: 4,
      height: 8
    },
    shadowRadius: 4
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 0,
    paddingRight: 0
  },
  headerTitle: {
    color: 'rgba(0, 0, 0, 0.77)',
    fontWeight: '500',
    fontSize: 14,
    overflow: 'hidden',
    whiteSpace: 'noWrap',
    textOverflow: 'ellipsis'
  },

  divider: {
    backgroundColor: 'rgba(0, 0, 0, 0.21)'
  },

  cardContent: {
    flexDirection: 'column',
    paddingLeft: 0,
    paddingRight: 0,
    display: 'flex',
    gap: 8
  },
  contentText: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  contentTextWithChip: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    overflow: 'hidden',
    whiteSpace: 'noWrap',
    textOverflow: 'ellipsis'
  },
  contentTitle: {
    color: 'rgba(0, 0, 0, 0.77)',
    fontWeight: '500',
    marginRight: 4
  },
  docButtonBox: {
    justifyContent: 'space-between'
  }
})
