import { HttpClient, HttpStatusCode } from '@/data/protocols/http'
import { AccessDeniedError, UnexpectedError } from '@/domain/errors'
import { GetSowillIdentifier, GetSowillIdentifierParams } from '@/domain/usecases'
import { SowillIdentifier, SowillIdentifierResult } from '@/domain/models'

export class RemoteGetSowillIdentifier implements GetSowillIdentifier {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<SowillIdentifierResult>
  ) { }

  async getIdentifier (params: GetSowillIdentifierParams): Promise<SowillIdentifier> {
    const { body, statusCode } = await this.httpClient.request({
      url: `${this.url}/api/sowill/dados`,
      method: 'get',
      params
    })

    switch (statusCode) {
      case HttpStatusCode.ok: return {
        codInternoChave: body.CodInternoChave,
        idCliente: body.IdCliente
      }
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}
