import { RemoteDeleteNotification } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import { DeleteNotification } from '@/domain/usecases'
import env from '@/main/config/env'

export const makeDeleteNotification = (): DeleteNotification => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<string>()

  return new RemoteDeleteNotification(url, httpClient)
}
