import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'
import { GetMoniEvents } from '@/domain/usecases'
import { RemoteGetMoniEvents } from '@/data/usecases/events'
import { makeDateAdapterFactory } from '@/main/adapters'
import { MoniEventModel } from '@/domain/models'

export const makeGetMoniEventsFactory = (): GetMoniEvents => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<string | MoniEventModel[]>()
  const dateAdapter = makeDateAdapterFactory()

  return new RemoteGetMoniEvents(url, httpClient, dateAdapter)
}
