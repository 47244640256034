import React, { useCallback } from 'react'
import { ScrollView } from 'react-native-gesture-handler'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { OpenNfse } from '@/domain/usecases'
import { DateAdapter } from '@/data/protocols'
import { Box, CardList, PageHeader } from '@/presentation/components'
import { nfsState, isLoadingNfState, FiltersChipPanel, CardNfse, isOpenNfsFiltersState } from '@/presentation/pages/NotaFiscal/components'
import { useTheme } from '@/presentation/hooks'
import { styles } from '../../styles'

type NFServiceProps = {
  openNfse: OpenNfse
  dateAdapter: DateAdapter
}

export const NFService: React.FC<NFServiceProps> = ({ openNfse, dateAdapter }) => {
  const theme = useTheme()
  const isLoading = useRecoilValue(isLoadingNfState)
  const nfs = useRecoilValue(nfsState)
  const setOpenFilters = useSetRecoilState(isOpenNfsFiltersState)

  const handleOnFilter = useCallback((): void => {
    setOpenFilters(true)
  }, [])

  return (
    <ScrollView style={styles.scroll}>
      <Box style={styles.content}>
        <PageHeader
          icon={<MaterialCommunityIcons size={24} color={theme.colors.grey} name='file-document-outline' />}
          text='Notas Fiscais de Serviço'
          openFilters={handleOnFilter}
        />
        <FiltersChipPanel dateAdapter={dateAdapter} />
        <CardList
          isEmpty={!nfs?.length}
          isLoading={isLoading}
          textLoading='Buscando Notas Fiscais Serviço'
        >
          {nfs?.map((nfse, index) => 
            <CardNfse
              nfse={nfse} 
              key={nfse.notaNumero}
              index={index}
              openNfse={openNfse}
            />
          )}
        </CardList>
      </Box>
    </ScrollView>
  )
}
