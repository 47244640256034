import React from 'react'
import { useSetRecoilState } from 'recoil'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Box, Button, HStack, InputField, Text, VStack, CardRow, CardDetails } from '@/presentation/components'
import { useAuth, useTheme, useThemeClient } from '@/presentation/hooks'
import { isOpenContractDetailsState } from '@/presentation/pages/Contract/components'
import { DateAdapter } from '@/data/protocols'
import { styles } from '../../styles'

type ContractCardProps = {
  dateAdapter: DateAdapter
}

export const ContractCard: React.FC<ContractCardProps> = ({ dateAdapter }) => {
  const theme = useTheme()
  const { getClient } = useAuth()
  const { getThemeClient } = useThemeClient()
  const setOpen = useSetRecoilState(isOpenContractDetailsState)

  const client = getClient()
  const themeClient = getThemeClient()
  const numContrato = client.numContrato || ''

  return (
    <CardRow >
      <CardDetails maxWidth={'100%'}>
        <Box style={styles.titleBox}>
          <Text style={styles.bold} testID='contract-card-number'>Contrato {numContrato}</Text>
        </Box>
        <InputField>
          <VStack style={{ flex: 1 }}>
            <Text style={styles.bold} testID='contract-card-faturamento'>Início</Text>
            <Text>{dateAdapter.format(client.primeiroFaturamento, 'DD/MM/YYYY')}</Text>
          </VStack>
          <VStack style={{ flex: 1 }}>
            <Text style={styles.bold} testID='contract-card-cobranca'>Dia da Cobrança</Text>
            <Text>{client.diaVencimento}</Text>
          </VStack>
        </InputField>
        {client.visualizaDetalhesContrato &&
          <HStack justify='end'>
            <Button
              leading={<MaterialCommunityIcons color={theme.colors.white} size={18} name='eye' />}
              title='Ver Detalhes'
              testID='contract-card-detalhes'
              color={themeClient.mainColor}
              tintColor={theme.colors.white}
              onPress={() => setOpen(true)}
            />
        </HStack>
        }
      </CardDetails>
    </CardRow>
  )
}
