import { TrackDetailsResult } from '@/domain/models'
import { GetTrackDetails } from '@/domain/usecases'
import { RemoteGetDetailTrack } from '@/data/usecases/track'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'

export const makeGetTrackDetailsFactory = (): GetTrackDetails => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<TrackDetailsResult>()

  return new RemoteGetDetailTrack(url, httpClient)
}
