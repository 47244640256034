import { layoutStyles } from '@/presentation/styles/globals'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  content: {
    ...layoutStyles.pageContent
  },
  formArea: {
    marginTop: 12,
    width: '100%',
    padding: 24,
    marginHorizontal: 'auto',
    gap: 12
  },
  input: {
    flex: 1,
    marginTop: 12
  },
  insideLogo: {
    height: 38,
    width: 102,
    alignSelf: 'flex-end'
  },
  chipCard: {
    height: 38,
    width: 52,
    marginLeft: 16
  },
  cardInformation: {
    display: 'flex',
    gap: 8
  },
  text: {
    fontSize: 18,
    letterSpacing: 4
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 16
  },
  inlineInputs: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16
  }
})
