import { SaveUnitConfig, SaveUnitConfigParams } from '@/domain/usecases/'
import { AccessDeniedError, InvalidRelationCompany, UnexpectedError } from '@/domain/errors'
import { HttpClient, HttpStatusCode } from '@/data/protocols/http'

export class RemoteSaveUnitConfig implements SaveUnitConfig {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<boolean | string>
  ) { }

  async save (config: SaveUnitConfigParams): Promise<boolean> {
    const { statusCode, body } = await this.httpClient.request({
      method: 'put',
      url: `${this.url}/api/portal/config`,
      body: config
    })

    switch (statusCode) {
      case HttpStatusCode.ok: return body as boolean
      case HttpStatusCode.forbidden: throw new InvalidRelationCompany(body as string)
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}
