import React from 'react'
import { HStack } from '@react-native-material/core'
import { useMediaQueries } from '@/presentation/hooks'

type ActionsButtonsProps = {
  children: React.ReactNode
}

export const ActionsButtons: React.FC<ActionsButtonsProps> = ({ children }) => {
  const { isMobile } = useMediaQueries()

  return (
    <HStack
      fill
      position='absolute'
      bottom={isMobile ? 74 : 24}
      right={isMobile ? 8 : 24}
      justify='end'
    >
      {children}
    </HStack>
  )
}
