export type TrackModel = {
  codInterno: number
  id: string
  placa: string
}

export type TrackResult = {
  CodInterno: number
  Id: string
  Placa: string
}

export type TrackDetailsModel = {
  id: string
  cor: string
  ano: string
  placa: string
  modelo: string
  combustivel: FuelType
}

export enum FuelType {
  Gasolina = 'G',
  Etanol = 'A',
  Biocombustivel = 'B',
  Diesel = 'D',
  GNV = 'N',
  Eletrico = 'E',
  Hibrido = 'H'
}

export type TrackDetailsResult = {
  Id: string
  Cor: string
  Ano: string
  Placa: string
  Modelo: string
  Combustivel: string
}
