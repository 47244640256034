import React from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { TouchableOpacity } from 'react-native'
import { BANK, TicketModel } from '@/domain/models'
import { GenerateInsideBankSlip, GetTicketPix, OpenBankSlip, OpenBankSlipParams } from '@/domain/usecases'
import { DateAdapter } from '@/data/protocols'
import { useAuth, useFormat, useNotify, useTheme } from '@/presentation/hooks'
import { Box, Text, IconButton, HStack, Clipboard, Chip, PixIcon } from '@/presentation/components'
import { isLoadingOpenBankSlip, isOpenTicketPixState, isOpenTicketViewState, selectedTicketPixState, ticketViewState } from '@/presentation/pages/Financial/components'
import { styles, CopyIcon, MaterialIcons } from './styles'

const FORMAT_DATE_DESCRIPTION = 'DD/MM/YY'

type CardTicketProps = {
  ticket: TicketModel
  openBankSlip: OpenBankSlip
  getTicketPix: GetTicketPix
  dateAdapter: DateAdapter
  current?: boolean
}

export const INFO_INSIDE_SLIP_BANK = `O boleto só será atualizado ${GenerateInsideBankSlip.RN.TOTAL_DAYS_BEFORE_NEW_UPDATE} dias após o vencimento. Você pode realizar o pagamento dele, mesmo estando vencido, em qualquer agência bancária em até 58 dias`

export const CardTicket: React.FC<CardTicketProps> = ({
  ticket,
  openBankSlip,
  dateAdapter,
  current = false
}) => {
  const theme = useTheme()
  const notify = useNotify()
  const { getClient } = useAuth()
  const { formatCurrency } = useFormat()
  const setCurrentTicketPix = useSetRecoilState(selectedTicketPixState)
  const setOpenPix = useSetRecoilState(isOpenTicketPixState)
  const setOpen = useSetRecoilState(isOpenTicketViewState)
  const setTicketView = useSetRecoilState(ticketViewState)
  const [isLoading, setLoading] = useRecoilState(isLoadingOpenBankSlip)

  const client = getClient()

  const handleCopyBarCode = (): void => {
    Clipboard.setString(ticket.linhaDigitavel)
    notify.show('Código de barras copiado com sucesso')
  }

  const handleOpenPDFBankSlip = (): void => {
    setLoading(true)

    const params: OpenBankSlipParams = {
      codigoCliente: ticket.cliente,
      nossoNumero: ticket.nossoNumero,
      codBanco: ticket.codBanco,
      dtVencimento: ticket.vencimento,
      registrado: ticket.registrado,
      linkBoleto: ticket.linkBoleto,
      novaVersao: client.novaVersao
    }

    openBankSlip
      .openPDF(params)
      .then(() => notify.show(`A fatura Nº ${ticket.numero} e vencimento ${dateAdapter.format(ticket.vencimento)} foi aberta em uma nova aba`))
      .catch(error => notify.show(error, { variant: 'error' }))
      .finally(() => setLoading(false))
  }

  const handleOpenPix = (ticket: TicketModel): void => {
    setOpenPix(true)
    setCurrentTicketPix(ticket)
  }

  const handleOpenTicketView = (ticket: TicketModel): void => {
    setTicketView(ticket)
    setOpen(true)
  }

  const statusColor = {
    paga: theme.colors.success,
    'em Aberto': theme.colors.info,
    vencida: theme.colors.error,
    venceHoje: theme.colors.secondary
  }[ticket.situacao]

  const containerStyles = {
    ...styles.container,
    backgroundColor: theme.colors.white,
    color: theme.colors.greyLighten,
    backgroundImage: `linear-gradient(to right, ${statusColor}, ${statusColor})`
  }

  const textStatusStyles = {
    ...styles.textStatus,
    color: theme.colors.textsLight
  }

  const amountStyles = {
    ...styles.title,
    color: ticket.situacao === 'paga' ? theme.colors.textsLighten : theme.colors.texts
  }

  const makeTags = (): JSX.Element =>
    <HStack spacing={8} mt={8} wrap>
      {current && <Chip color={theme.colors.info} containerStyle={{ marginRight: 4 }} variant='outlined' text='Fatura atual' />}
      {ticket.situacao === 'venceHoje' && <Chip color={theme.colors.secondary} variant='outlined' text='Vencendo hoje'/>}
      {ticket.saldo > 0 && ticket.situacao === 'vencida' && ticket.atraso > 0 &&
        <Chip variant='outlined' text={`${ticket.atraso} dia${ticket.atraso > 1 ? 's' : ''} de atraso`} />
      }
    </HStack>

  const makePaid = (): JSX.Element => {
    return (
      <>
        <HStack>
          <Box style={{ flex: 1 }}>
            <Text style={textStatusStyles} variant='h3'>
              Fatura {ticket.numero.toLowerCase().padStart(6, '0')}
            </Text>
            <Text style={amountStyles} variant='subtitle1' testID='valor-duplicata'>
              {formatCurrency(ticket.valorDuplicata)}
            </Text>
          </Box>
          <HStack items='center'>
          </HStack>
        </HStack>
        <HStack spacing={8} items='center' testID='vencimento-duplicata'>
          <Text style={{ ...textStatusStyles, fontWeight: '400' }} variant='subtitle2'>
            Você pagou em {dateAdapter.format(ticket.vencimento, FORMAT_DATE_DESCRIPTION)}
          </Text>
          <Text style={{ ...textStatusStyles, fontWeight: '400' }} variant='subtitle2'>
            vencimento {dateAdapter.format(ticket.vencimento)}
          </Text>
        </HStack>
      </>
    )
  }

  const makeExpired = (): JSX.Element => {
    return (
      <>
        <HStack>
          <Box style={{ flex: 1 }}>
            <Text style={textStatusStyles} variant='h3'>
              Fatura Vencida {ticket.numero.toLowerCase().padStart(6, '0')}
            </Text>
            <Text style={amountStyles} variant='subtitle1'>
              {formatCurrency(ticket.saldo)}
            </Text>
          </Box>
          <HStack items='center'>
            {([BANK.BoletoInside].includes(ticket.codBanco) || ticket.pixUrl) &&
              <IconButton
                color={theme.colors.grey}
                icon={<PixIcon color={theme.colors.grey} />}
                onPress={() => handleOpenPix(ticket)}
                testID='fatura-pix'
                disabled={isLoading}
              />
            }
            {!!ticket.linhaDigitavel &&
              !!ticket.nossoNumero &&
                <IconButton
                  color={theme.colors.grey}
                  icon={<CopyIcon color={theme.colors.grey} size={18} name="content-copy"/>}
                  onPress={handleCopyBarCode}
                  disabled={isLoading}
                />
            }
            {(!!ticket.nossoNumero || !!ticket.linkBoleto) &&
                <IconButton
                  color={theme.colors.grey}
                  icon={<MaterialIcons color={theme.colors.grey} size={18} name="arrow-downward" />}
                  onPress={handleOpenPDFBankSlip}
                  disabled={isLoading}
                />
            }
          </HStack>
        </HStack>
        <HStack spacing={8} items='center'>
          <Text style={{ ...textStatusStyles, fontWeight: '400' }} variant='subtitle2'>
            venceu em {dateAdapter.format(ticket.vencimento)}
          </Text>
        </HStack>
        {makeTags()}
      </>
    )
  }

  const makeOpened = (): JSX.Element => {
    return (
      <>
        <HStack>
          <Box style={{ flex: 1 }}>
            <Text style={textStatusStyles} variant='h3'>
              Fatura {current ? 'Atual' : ''} {ticket.numero.toLowerCase().padStart(6, '0')}
            </Text>
            <Text style={amountStyles} variant='subtitle1'>
              {formatCurrency(ticket.saldo)}
            </Text>
          </Box>
          <HStack items='center'>
            {([BANK.BoletoInside].includes(ticket.codBanco) || ticket.pixUrl) &&
              <IconButton
                color={theme.colors.grey}
                icon={<PixIcon color={theme.colors.grey} />}
                onPress={() => handleOpenPix(ticket)}
                disabled={isLoading}
              />
            }
            {!!ticket.linhaDigitavel &&
              !!ticket.nossoNumero &&
                <IconButton
                  color={theme.colors.grey}
                  icon={<CopyIcon color={theme.colors.grey} size={18} name="content-copy"/>}
                  onPress={handleCopyBarCode}
                  disabled={isLoading}
                />
            }
            {(!!ticket.nossoNumero || !!ticket.linkBoleto) &&
              <IconButton
                color={theme.colors.grey}
                icon={<MaterialIcons color={theme.colors.grey} size={18} name="arrow-downward" />}
                onPress={handleOpenPDFBankSlip}
                disabled={isLoading}
              />
            }
          </HStack>
        </HStack>
        <HStack spacing={8} items='center'>
          <Text style={{ ...textStatusStyles, fontWeight: '400' }} variant='subtitle2'>
            vencimento {dateAdapter.format(ticket.vencimento)}
          </Text>
        </HStack>
        {makeTags()}
      </>
    )
  }

  return (
    <TouchableOpacity
      style={containerStyles}
      testID={`status-fatura-${ticket.situacao}`}
      onPress={() => handleOpenTicketView(ticket)}
    >
      {{
        paga: makePaid(),
        vencida: makeExpired(),
        'em Aberto': makeOpened(),
        venceHoje: makeOpened()
      }[ticket.situacao]}
    </TouchableOpacity>
  )
}
