import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  loginContent: {
    width: '100%',
    position: 'absolute',
    paddingHorizontal: 40,
    paddingVertical: 20,
    maxWidth: 428,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: 4,
    shadowColor: 'rgba(0, 0, 0, 0.04)',
    shadowOffset: {
      width: 4,
      height: 8
    }
  },
  footer: {
    fontSize: 10,
    position: 'absolute',
    width: '100%',
    bottom: 8,
    left: 0,
    textAlign: 'center'
  }
})
