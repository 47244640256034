import React, { useEffect, useCallback } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { GestureResponderEvent } from 'react-native'
import { GetDigitalAccepts } from '@/domain/usecases'
import { DateAdapter } from '@/data/protocols'
import { useAuth, useErrorHandler } from '@/presentation/hooks'
import { Box, Divider, DatePicker, InputField, SideFilter } from '@/presentation/components'
import { SelectStatusAcceptFilter, acceptDigitalFiltersState, acceptDigitalState, isLoadingAcceptDigitalState, isOpenAcceptDigitalFiltersState } from '@/presentation/pages/AceiteDigital/components'

type DateParams = {
  dtInicial: string
  dtFinal: string
}

export type AcceptFilters = {
  status: 'Pendentes' | 'Autorizados' | 'Ambos'
  dtInicial: string
  dtFinal: string
}

type SideFiltersProps = {
  getAcceptDigital: GetDigitalAccepts
  dateAdapter: DateAdapter
}

export const MARGIN_MODALS = 30

export const SideFilters: React.FC<SideFiltersProps> = ({ getAcceptDigital, dateAdapter }: SideFiltersProps) => {
  const { getClient } = useAuth()
  const [isOpen, setOpen] = useRecoilState(isOpenAcceptDigitalFiltersState)
  const [isLoading, setLoading] = useRecoilState(isLoadingAcceptDigitalState)
  const [filters, setFilters] = useRecoilState(acceptDigitalFiltersState)
  const setAccepts = useSetRecoilState(acceptDigitalState)
  const handlerError = useErrorHandler()

  const client = getClient()

  useEffect(() => {
    setFilters({
      status: GetDigitalAccepts.RN.DEFAULT_STATUS,
      dtInicial: dateAdapter.daysAgo(GetDigitalAccepts.RN.DEFAULT_DAYS_AGO).toString(),
      dtFinal: dateAdapter.daysLater(GetDigitalAccepts.RN.DEFAULT_DAYS_LATER).toString()
    })
  }, [])

  const handleResetState = (): void => {
    setFilters({
      status: GetDigitalAccepts.RN.DEFAULT_STATUS,
      dtInicial: dateAdapter.daysAgo(GetDigitalAccepts.RN.DEFAULT_DAYS_AGO).toString(),
      dtFinal: dateAdapter.daysLater(GetDigitalAccepts.RN.DEFAULT_DAYS_LATER).toString()
    })
  }

  const onSubmit = (event: GestureResponderEvent): void => {
    event.preventDefault()

    const paramsGetAcceptDigital = {
      codCliente: client.codCliente,
      dtInicial: dateAdapter.format(filters.dtInicial, 'YYYY-MM-DD'),
      dtFinal: dateAdapter.format(filters.dtFinal, 'YYYY-MM-DD'),
      status: filters.status
    }

    setLoading(true)

    getAcceptDigital
      .get(paramsGetAcceptDigital)
      .then(setAccepts)
      .catch(handlerError)
      .finally(() => setLoading(false))

    setOpen(false)
  }

  const handleChangeDate = useCallback((field: keyof DateParams, date: Date): void => {
    setFilters(currentFilters => ({
      ...currentFilters,
      [field]: date.toString()
    }))
  }, [])

  const handleClose = (): void => setOpen(false)

  return (
    <SideFilter.Drawer
      open={isOpen}
      onClose={handleClose}
      loading={isLoading}
      onSubmit={onSubmit}
      resetState={handleResetState}
    >
      <Box>
        <SideFilter.Title title='Data de Abertura' />
        <InputField>
          <DatePicker
            label='De'
            disabled={isLoading}
            value={filters.dtInicial ? dateAdapter.parse(filters.dtInicial) : undefined}
            onChange={(date) => handleChangeDate('dtInicial', date ?? dateAdapter.daysAgo(GetDigitalAccepts.RN.DEFAULT_DAYS_AGO))}
            dateAdapter={dateAdapter}
          />
          <Divider />
          <DatePicker
            label='Até'
            disabled={isLoading}
            value={filters.dtFinal ? dateAdapter.parse(filters.dtFinal) : undefined}
            onChange={(date) => handleChangeDate('dtFinal', date ?? dateAdapter.daysLater(GetDigitalAccepts.RN.DEFAULT_DAYS_LATER))}
            dateAdapter={dateAdapter}
          />
        </InputField>
      </Box>
      <Box>
        <SideFilter.Title title='Status' />
        <SelectStatusAcceptFilter />
      </Box>
    </SideFilter.Drawer>
  )
}
