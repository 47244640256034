import React from 'react'
import { RecoilRoot } from 'recoil'
import { Provider as PaperProvider } from 'react-native-paper'
import { pt, registerTranslation } from 'react-native-paper-dates'
import { theme } from '@/presentation/styles'
import { Router, InstallButton, UserMenu, NotificationsModal } from '@/presentation/components'
import { makeGetRedesSociais, makeGetTheme, makePortalLogout, makeGetProspectConfigs, makeUpdatePassword, makeSaveNotificationToken, makeGetClientNotifications, makeDeleteNotification, makeChangeTokenNotificationsStatus } from '@/main/factories/usecases'
import clarityStart from '@/main/config/clarity'
import env from '@/main/config/env'
registerTranslation('pt-BR', pt)

!__DEV__ && clarityStart(window, document, 'clarity', 'script', env.CLARITY_KEY)

export default function App (): JSX.Element {
  const isHttps = window.location.protocol === 'https:'

  return (
    <RecoilRoot>
      <PaperProvider theme={theme}>
        <Router
          getRedesSociais={makeGetRedesSociais()}
          getTheme={makeGetTheme()}
          portalLogout={makePortalLogout()}
          getProspectConfigs={makeGetProspectConfigs()}
          updatePassword={makeUpdatePassword()}
          saveNotificationToken={makeSaveNotificationToken()}
          getNotifications={makeGetClientNotifications()}
        />
        {isHttps && <InstallButton />}
        <UserMenu />
        <NotificationsModal
          deleteNotification={makeDeleteNotification()}
          changeNotificationStatus={makeChangeTokenNotificationsStatus()}
        />
      </PaperProvider>
    </RecoilRoot>
  )
}
