import React from 'react'
import { makeGetTheme, makeGetUnits, makeGetVersions, makeSaveTheme, makeUploadFile } from '@/main/factories/usecases'
import { Theme } from '@/presentation/pages/Theme'

export const makeThemeFactory = (): JSX.Element => {
  return (
    <Theme
      getVersions={makeGetVersions()}
      saveTheme={makeSaveTheme()}
      getTheme={makeGetTheme()}
      uploadFile={makeUploadFile()}
      getUnits={makeGetUnits()}
    />
  )
}
