const common = {
  white: 'rgba(250, 250, 250, 1)',
  black: 'rgba(0, 0, 0, 0.65)'
}

const primary = {
  primaryLighten: 'rgba(185, 176, 191, 1)',
  primaryLight: 'rgba(112, 84, 140, 1)',
  primary: 'rgba(91, 42, 140, 1)',
  primaryDark: 'rgba(80, 45, 115, 1)',
  primaryDarken: 'rgba(43, 29, 64, 1)'
}

const secondary = {
  secondaryLight: 'rgba(242, 209, 109, 1)',
  secondary: 'rgba(242, 183, 5, 1)',
  secondaryDark: 'rgba(217, 173, 41, 1)',
  secondaryContainer: 'rgba(242, 183, 5, .2)'
}

const info = {
  info: 'rgba(45, 156, 219, 1)',
  infoContainer: 'rgba(45, 156, 219, .2)'
}

const error = {
  error: 'rgba(255, 96, 96, 1)'
}

const success = {
  success: 'rgba(0, 153, 52, 1)',
  successContainer: 'rgba(0, 153, 52, .2)'
}

const divider = {
  divider: 'rgba(214, 214, 214, 1)'
}

const background = {
  background: 'rgba(250, 250, 250, 1)',
  backgroundCard: 'rgba(255, 255, 255, 1)',
  backgroundSearch: 'rgba(255, 255, 255, 0.12)',
  backgroundMenu: 'rgb(255, 255, 255)',
  backgroundInput: 'rgba(33, 33, 33, 0.08)',
  backgroundPortal: 'rgba(33, 33, 33, 0.08)',
  backgroundNotification: 'rgba(33, 33, 33, 0.04)'
}

const grey = {
  greyLighten: 'rgb(51, 51, 51)',
  greyLight: 'rgba(0, 0, 0, 0.32)',
  grey: 'rgba(0, 0, 0, 0.7)',
  greyDark: 'rgba(0, 0, 0, 0.8)',
  greyDarken: 'rgba(0, 0, 0, 0.12)',
  greyDarken2: 'rgba(0, 0, 0, 0.04)'
}

const texts = {
  textsLighten: 'rgba(0, 0, 0, 0.32)',
  textsLight: 'rgba(0, 0, 0, 0.62)',
  texts: 'rgba(0, 0, 0, 0.77)',
  textsDark: 'rgba(0, 0, 0, 0.8)'
}

const other = {
  pink: '#FF508F'
}

export const palette = {
  common,
  primary,
  secondary,
  error,
  success,
  divider,
  background,
  grey,
  texts,
  info,
  other
}
