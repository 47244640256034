import * as React from 'react'
import { useTheme, useThemeClient } from '@/presentation/hooks'
import { Image, ImageBackground, StyleSheet } from 'react-native'
import { Box, Text } from '@react-native-material/core'
import Slider from '@react-native-community/slider'

type ConfigSliderProps = {
  maximunValue: number
  minimunValue: number
  isBackgroundImage?: boolean
}

const image = { uri: require('../../../../assets/fundo.png') }

export function ConfigSlider ({ minimunValue, maximunValue, isBackgroundImage = false }: ConfigSliderProps): JSX.Element {
  const { getThemeClient } = useThemeClient()
  const [logoHeight, setLogoHeight] = React.useState(isBackgroundImage ? 0.12 : minimunValue)
  const theme = useTheme()
  const themeClient = getThemeClient()

  return (
    <Box style={styles.container}>
        <Box style={styles.titleArea}>
            <Text style={styles.text} variant="subtitle2">{isBackgroundImage ? 'Opacidade:' : 'Tamanho:'}</Text>
            <Text style={styles.text} variant="subtitle2">{logoHeight.toFixed(2)}</Text>
        </Box>
        <Box style={{ ...styles.themeContainer, backgroundColor: themeClient.mainColor }}>
            {
            isBackgroundImage
              ? <ImageBackground source={{ uri: themeClient?.bgLogin ?? image.uri }} resizeMode="cover" style={{ ...styles.image, opacity: logoHeight }} />
              : <Image style={{ height: logoHeight, width: logoHeight }} source={require('../../../../assets/logo_24dp.svg')} />
            }
        </Box>
        <Slider
        style={{ height: 40 }}
        minimumValue={isBackgroundImage ? 0.12 : minimunValue}
        maximumValue={isBackgroundImage ? 0.32 : maximunValue}
        onValueChange={(event) => setLogoHeight(event)}
        minimumTrackTintColor={theme.colors.primary}
        maximumTrackTintColor={theme.colors.primaryLighten}
        thumbTintColor={theme.colors.primary}
        />
    </Box>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'relative'
  },
  themeContainer: {
    height: 200,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4
  },
  image: {
    flex: 1,
    justifyContent: 'center',
    alignSelf: 'stretch'
  },
  text: {
    color: '#FFFF'
  },
  titleArea: {
    width: 'calc(100% - 16px)',
    position: 'absolute',
    top: 8,
    right: 8,
    left: 8,
    zIndex: 1000,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
})
