import React from 'react'
import { Home } from '@/presentation/pages/Home'
import { makeGetClientByCode, makeMetricsLogin } from '@/main/factories/usecases'
import { NavigationPagesProps } from '@/presentation/components'
import { makeDateAdapterFactory } from '@/main/adapters'

export const makeHomeFactory = ({ navigation }: NavigationPagesProps): JSX.Element => {
  return (
    <Home
      navigation={navigation}
      getClientByCode={makeGetClientByCode()}
      dateAdapter={makeDateAdapterFactory()}
      metricsLogin={makeMetricsLogin()}
    />
  )
}
