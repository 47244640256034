import { AccessDeniedError, ForbiddenError, UnexpectedError, UnexpectedResultFormatError } from '@/domain/errors'
import { OpenDocumentAccept, OpenDocumentAcceptParams } from '@/domain/usecases'
import { HttpClient, HttpStatusCode } from '@/data/protocols/http'

export class RemoteOpenDocumentAccept implements OpenDocumentAccept {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<string>
  ) { }

  async open (params: OpenDocumentAcceptParams): Promise<void> {
    const { codigoAceite, statusAceite } = params

    if (statusAceite === 'Autorizado') {
      const { statusCode, body } = await this.httpClient.request({
        url: `${this.url}/api/AceiteEletronico/ObterDocumentoAceite`,
        method: 'get',
        params: { codigoAceite }
      })

      switch (statusCode) {
        case HttpStatusCode.ok:
          if (!body) {
            throw new UnexpectedResultFormatError()
          }

          window.open(`${this.url}/${body}`, '_blank')
          break
        case HttpStatusCode.forbidden: throw new ForbiddenError(body)
        case HttpStatusCode.unauthorized: throw new AccessDeniedError()
        default: throw new UnexpectedError()
      }
    }
  }
}
