import React, { useState, useCallback, useEffect } from 'react'
import { StyleSheet } from 'react-native'
import { Card } from 'react-native-paper'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { Entypo, MaterialCommunityIcons } from '@expo/vector-icons'
import { useMediaQueries, useTheme, useThemeClient } from '@/presentation/hooks'
import { Button, HStack, IconButton, isOpenInstallationState, localStorageState } from '@/presentation/components'

export const TIME_IN_EXPIRATION_HOURS = 6
export const KEY_TIME_EXPIRATION = 'decline_install'

export const InstallButton: React.FC = () => {
  const theme = useTheme()
  const localStorageAdapter = useRecoilValue(localStorageState)
  const { isMobile, openInIOS, openInPWA } = useMediaQueries()
  const { getThemeClient } = useThemeClient()
  const [isOpen, setOpen] = useState(false)
  const setOpenModal = useSetRecoilState(isOpenInstallationState)

  const themeClient = getThemeClient()
  let deferredPrompt: any = null

  useEffect(() => {
    if (openInIOS) {
      setOpen(true)
    } else {
      window.addEventListener('beforeinstallprompt', (event) => {
        event.preventDefault()
        deferredPrompt = event

        if (window.location.protocol === 'https:' && !openInPWA) {
          const hasDeclineInstall: any = localStorageAdapter.get(KEY_TIME_EXPIRATION)
          if (hasDeclineInstall?.timestamp) {
            const today = new Date().getTime()
            const isExpiredDecline: number = hasDeclineInstall.timestamp
            const diferrenceMilissegundos = Math.abs(isExpiredDecline - today)
            const hoursDifference = diferrenceMilissegundos / (1000 * 60 * 60)

            setOpen(hoursDifference > TIME_IN_EXPIRATION_HOURS)
          } else {
            setOpen(true)
          }
        }
      })
    }
  }, [])

  const handleInstall = useCallback((): void => {
    if (openInIOS) {
      setOpenModal(true)
    } else {
      deferredPrompt?.prompt()
      setOpen(false)
    }
  }, [deferredPrompt])

  const handleCloseButton = (): void => {
    localStorageAdapter.set(KEY_TIME_EXPIRATION, { timestamp: new Date().getTime() })
    setOpen(false)
  }

  const containerMobile = {
    right: 60,
    top: 12,
    minWidth: '70%'
  }

  const container = {
    right: 60,
    top: 24
  }

  return (
    <Card style={{
      ...styles.cardContainer,
      display: isOpen ? 'flex' : 'none',
      ...(isMobile ? containerMobile : container)
    }} elevation={2}>
      <HStack items='center' justify='around' style={{ flex: 1 }} spacing={8}>
        <Entypo name='install' size={24} color={themeClient.mainColor} />
        <Button
          title='Instalar APP'
          color={themeClient.mainColor}
          tintColor={theme.colors.white}
          onPress={handleInstall}
        />
        <IconButton
          onPress={handleCloseButton}
          icon={<MaterialCommunityIcons name='close' size={18} color={theme.colors.greyLight} />}
        />
      </HStack>
    </Card>
  )
}

const styles = StyleSheet.create({
  cardContainer: {
    position: 'absolute',
    padding: 8,
    borderRadius: 4
  }
})
