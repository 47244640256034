import { TicketModel } from '@/domain/models'

export type GetTicketSituation = 'Pagas' | 'Em Aberto' | 'Todas'

export type GetTicketsParams = {
  codigoCliente: number
  situacao?: GetTicketSituation
  dataInicial?: string
  dataFinal?: string
}

export interface GetTickets {
  get: (params: GetTicketsParams) => Promise<TicketModel[]>
}

export namespace GetTickets {
  export const RN = {
    DEFAULT_DAYS_LATER: 90,
    DEFAULT_DAYS_AGO: 120,
    DEFAULT_YEARS_AGO: 5,
    DEFAULT_STATUS: 'Em Aberto' as GetTicketSituation,
    LIMIT_SHOW_ITEMS: 4,
    SHOW_ONLY_PENDING: 'false'
  }
}
