import React, { useCallback } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { GestureResponderEvent } from 'react-native'
import { AddTravelAlertOsResponsible, AddTravelAlertResponsibleType } from '@/domain/usecases'
import { Box, Button, Text, TextInput, ModalBase, Stack } from '@/presentation/components'
import { useMediaQueries, useTheme, useThemeClient } from '@/presentation/hooks'
import { addResponsibleState, addVehicleState, isOpenTravelAlertAddVehicle, travelAlertResponsiblesKeysState, travelAlertAuthorizedsState, travelAlertAddResponsibleState } from '@/presentation/pages/TravelAlert/components'
import { styles } from './styles'

export const ModalAddResponsible: React.FC = () => {
  const theme = useTheme()
  const { getThemeClient } = useThemeClient()
  const { isMobile } = useMediaQueries()
  const [addResponsible, setState] = useRecoilState(addResponsibleState)
  const [addVehicle, setAddVehicle] = useRecoilState(addVehicleState)
  const [modalState, setModalState] = useRecoilState(travelAlertAddResponsibleState)
  const setModalVehicle = useSetRecoilState(isOpenTravelAlertAddVehicle)
  const setTravelAlertResponsibles = useSetRecoilState(travelAlertAuthorizedsState)
  const setTravelAlertResponsiblesKeys = useSetRecoilState(travelAlertResponsiblesKeysState)

  const themeClient = getThemeClient()
  const addNewVehicle = !!addVehicle.placa

  const handleChangeInput = (name: keyof AddTravelAlertOsResponsible, value: string): void => {
    setState(currentValues => ({ ...currentValues, [name]: value }))
  }

  const handleAddResponsible = useCallback((event: GestureResponderEvent): void => {
    event.preventDefault()

    const responsible: AddTravelAlertOsResponsible = {
      id: new Date().getMilliseconds(),
      nome: addResponsible.nome,
      endereco: addResponsible.endereco,
      telefone1: addResponsible.telefone1,
      telefone2: addResponsible.telefone2,
      observacao: addResponsible.observacao
    }

    switch (modalState.typeCurrent) {
      case AddTravelAlertResponsibleType.AUTHORIZED:
        setTravelAlertResponsibles(currentState => [
          ...currentState,
          responsible
        ])
        break
      case AddTravelAlertResponsibleType.KEYS:
        setTravelAlertResponsiblesKeys(currentState => [
          ...currentState,
          responsible
        ])
        break
      case AddTravelAlertResponsibleType.VEHICLE_KEYS:
        setAddVehicle(currentState => ({
          ...currentState,
          responsaveis: [
            ...currentState.responsaveis,
            responsible
          ]
        }))
        break
      case AddTravelAlertResponsibleType.VEHICLE_AUTHORIZED:
        setAddVehicle(currentState => ({
          ...currentState,
          autorizados: [
            ...currentState.autorizados,
            responsible
          ]
        }))
        break
      default:
        break
    }

    handleClose()
    handleReset()
  }, [addResponsible, modalState.typeCurrent])

  const handleReset = (): void => {
    setState({
      id: 0,
      nome: '',
      endereco: '',
      telefone1: '',
      telefone2: '',
      observacao: ''
    })
  }

  const handleClose = (): void => {
    setModalState(currentState => ({ ...currentState, isOpen: false }))
    addNewVehicle && setModalVehicle(true)
  }

  return (
    <ModalBase visible={modalState.isOpen} onClose={handleClose}>
      <Box style={styles.container}>
        <Text variant="subtitle1">Adicionar responsável
        {[AddTravelAlertResponsibleType.VEHICLE_KEYS, AddTravelAlertResponsibleType.VEHICLE_AUTHORIZED].includes(modalState.typeCurrent) ? 'do veículo' : ''}
        </Text>

        <TextInput
          label="Nome*"
          onChangeText={text => handleChangeInput('nome', text)}
          value={addResponsible?.nome}
          maxLength={30}
        />

        <Stack
          spacing={12}
          style={{ width: '100%' }}
          direction={isMobile ? 'column' : 'row'}
        >
          <TextInput
            value={addResponsible?.telefone1}
            keyboardType='phone-pad'
            label="Telefone 1*"
            onChangeText={(text) => handleChangeInput('telefone1', text)}
            style={{ flex: 1 }}
          />

          <TextInput
            value={addResponsible?.telefone2}
            keyboardType='phone-pad'
            label="Telefone 2"
            onChangeText={(text) => handleChangeInput('telefone2', text)}
            style={{ flex: 1 }}
          />
        </Stack>

        <TextInput
          label="Endereço"
          onChangeText={text => handleChangeInput('endereco', text)}
          value={addResponsible?.endereco}
          maxLength={100}
        />

        <TextInput
          label="Observações"
          onChangeText={text => handleChangeInput('observacao', text)}
          value={addResponsible?.observacao}
          multiline
          numberOfLines={4}
        />

        <Box style={styles.buttonArea}>
          {!isMobile &&
            <Button
              color={theme.colors.shadow}
              variant="text"
              title="Cancelar"
              onPress={handleClose}
            />
          }
          <Button
            color={theme.colors.shadow}
            variant="text"
            title="Limpar"
            onPress={handleReset}
          />
          <Button
            title="GRAVAR"
            onPress={handleAddResponsible}
            disabled={!addResponsible?.nome || !addResponsible?.telefone1}
            color={themeClient.mainColor}
          />
        </Box>
      </Box>
    </ModalBase>
  )
}
