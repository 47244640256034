import { AddNewOs } from '@/domain/usecases'
import { RemoteAddNewOs } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'

export const makeAddNewOs = (): AddNewOs => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<number | string>()

  return new RemoteAddNewOs(url, httpClient)
}
