import { RemoteTestEmail } from '@/data/usecases'
import { TestEmail } from '@/domain/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'

export const makeTestEmail = (): TestEmail => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<boolean>()

  return new RemoteTestEmail(url, httpClient)
}
