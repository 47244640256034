import React, { useEffect } from 'react'
import { SafeAreaView } from 'react-native'
import { useRecoilState } from 'recoil'
import { MaterialIcons } from '@expo/vector-icons'
import { OpenStatementOfDebts, OpenStatementOfDebtsParams } from '@/domain/usecases'
import { useAuth, useErrorHandler, useTheme } from '@/presentation/hooks'
import { Box, Button, PageHeader, Select } from '@/presentation/components'
import { DebtYear, debtsYearState, isOpenDebtsYearMenuState } from '@/presentation/pages/StatementOfDebts/components'
import { DateAdapter } from '@/data/protocols'
import { styles } from './styles'

type StatementOfDebtsProps = {
  openStatementOfDebts: OpenStatementOfDebts
  dateAdapter: DateAdapter
}

export const StatementOfDebts: React.FC<StatementOfDebtsProps> = ({ openStatementOfDebts, dateAdapter }) => {
  const theme = useTheme()
  const { getClient } = useAuth()
  const handleError = useErrorHandler()
  const [isOpenDebtsYearMenu, setOpenDebtsYearMenu] = useRecoilState(isOpenDebtsYearMenuState)
  const [debtsYear, setDebtsYear] = useRecoilState(debtsYearState)

  const client = getClient()

  const getPossibleDebtsYears = (): void => {
    const currentYear = dateAdapter.today().getFullYear()
    const possibleDebtsYears = []

    for (let i = 0; i < 5; i++) {
      const debtYear: DebtYear = {
        id: currentYear - i,
        descricao: `${currentYear - i}`,
        isSelected: false
      }

      possibleDebtsYears.push(debtYear)
    }

    setDebtsYear(possibleDebtsYears)
  }

  const getSelectedDebtYear = (): number => {
    const selectedDebtYear = debtsYear?.find((debt: DebtYear) => debt.isSelected)

    return selectedDebtYear ? selectedDebtYear.id : 0
  }

  const handleSelectDebtYear = (id: number): void => {
    const updatedDebtsYear = debtsYear.map((debtYear: DebtYear) => {
      if (debtYear.id === id) return { ...debtYear, isSelected: true }
      else if (debtYear.isSelected) return { ...debtYear, isSelected: false }

      return debtYear
    })

    setDebtsYear(updatedDebtsYear)
  }

  const handleOpenStatementOfDebts = (): void => {
    const openParams: OpenStatementOfDebtsParams = {
      codCliente: client.codCliente,
      ano: getSelectedDebtYear()
    }

    openStatementOfDebts
      .openPaymentReport(openParams)
      .catch(handleError)
  }

  useEffect(() => getPossibleDebtsYears(), [])

  return (
    <SafeAreaView style={styles.container}>
      <Box style={styles.content}>
        <PageHeader
          text='Declaração de Débitos'
          icon={<MaterialIcons size={24} color={theme.colors.grey} name='receipt-long' />}
        />
        <Select.Trigger
          label={'Ano da declaração'}
          description={getSelectedDebtYear() === 0 ? '' : getSelectedDebtYear().toString()}
          onOpen={() => setOpenDebtsYearMenu(true)}
          style={{ marginBottom: 8 }}
        />
        <Box style={styles.buttonArea}>
          <Button
            disabled={getSelectedDebtYear() === 0}
            title="Buscar"
            onPress={handleOpenStatementOfDebts}
            tintColor={theme.colors.white}
            color={theme.colors.success}
          />
        </Box>
      </Box>
      <Select.Modal
        isOpen={isOpenDebtsYearMenu}
        onClose={() => setOpenDebtsYearMenu(false)}
        onSelect={(id) => handleSelectDebtYear(id)}
        title={'Selecione o ano da declaração'}
        items={debtsYear}
      />
    </SafeAreaView>
  )
}
