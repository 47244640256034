import { AccessDeniedError, UnexpectedError } from '@/domain/errors'
import { ClaimsResult } from '@/domain/models'
import { GetSacList, GetSacListParams } from '@/domain/usecases'
import { HttpClient, HttpStatusCode } from '@/data/protocols/http'

export class RemoteGetSacList implements GetSacList {
  constructor (
    private readonly url: string,
    private readonly httpGetClient: HttpClient<ClaimsResult>
  ) { }

  async get (params: GetSacListParams): Promise<ClaimsResult> {
    const { body, statusCode } = await this.httpGetClient.request({
      url: `${this.url}/api/portal/lista-sacs`,
      method: 'get',
      params
    })

    switch (statusCode) {
      case HttpStatusCode.ok: return body
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}
