import { HttpClient, HttpRequest, HttpStatusCode } from '@/data/protocols'
import { AccessDeniedError, ResultWithoutRecordsError, UnexpectedError, UnexpectedResultFormatError } from '@/domain/errors'
import { OpenStatementOfDebts, OpenStatementOfDebtsParams } from '@/domain/usecases'

export class RemoteOpenStatementOfDebts implements OpenStatementOfDebts {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<string>
  ) {}

  async openPaymentReport (params: OpenStatementOfDebtsParams): Promise<void> {
    const request: HttpRequest = {
      url: `${this.url}/api/portal/imprimir-quitacao`,
      method: 'post',
      params
    }

    const { statusCode, body } = await this.httpClient.request(request)

    switch (statusCode) {
      case HttpStatusCode.ok:
        if (!body) {
          throw new UnexpectedResultFormatError()
        }
        window.open?.(`/${OpenStatementOfDebts.RN.FOLDER_DEFAULT_PDF}/${body}`, '_blank')
        break
      case HttpStatusCode.noContent: throw new ResultWithoutRecordsError()
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}
