import React from 'react'
import { StyleSheet } from 'react-native'
import { Switch, Card, Text, Chip, HStack } from '@/presentation/components'
import { useThemeClient } from '@/presentation/hooks'

type CardSWitchProps = {
  text: string
  value: boolean
  handleSwitch: () => void
  children?: JSX.Element[] | JSX.Element | boolean
  readonly?: boolean
  boletoInside?: boolean
  showIfValueFalsy?: boolean
}

export const CardSwitch: React.FC<CardSWitchProps> = ({
  text,
  children,
  value,
  handleSwitch,
  readonly,
  boletoInside,
  showIfValueFalsy = false
}) => {
  const { getThemeClient } = useThemeClient()

  const themeCliente = getThemeClient()
  const showChildren = showIfValueFalsy ? !value : !!value

  return (
    <Card style={styles.cardContainer}>
      <Card.Content style={styles.cardContent}>
        <Card.Content style={styles.cardContentHeader}>
          <HStack items='start' spacing={8}>
            <Text variant='subtitle1'>{text}</Text>
            {boletoInside && <Chip variant='outlined' text='Cartão Inside' color={themeCliente.mainColor} />}
          </HStack>
          {!readonly && <Switch color={themeCliente.mainColor} value={value} onValueChange={handleSwitch} />}
        </Card.Content>
        {children && showChildren &&
          <Card.Content style={styles.cardChildren}>
            {children}
          </Card.Content>
        }
      </Card.Content>
    </Card>
  )
}

const styles = StyleSheet.create({
  cardContainer: {
    marginVertical: 8,
    marginHorizontal: 16,
    borderRadius: 4
  },
  cardContent: {
    justifyContent: 'space-between',
    flex: 1
  },
  cardContentHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
    paddingLeft: 0,
    paddingRight: 0
  },
  cardChildren: {
    marginBottom: 8,
    marginTop: 16
  }
})
