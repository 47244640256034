import { SigmaCloudKeyResult, SowillKeyResult, SowillIdentifier } from '@/domain/models'
import { atom } from 'recoil'

export const sigmaKeysState = atom({
  key: 'sigmaKeyState',
  default: [] as SigmaCloudKeyResult
})

export const sowillKeysState = atom({
  key: 'sowillKeysState',
  default: [] as SowillKeyResult
})

export const sowillIdentifierState = atom<SowillIdentifier>({
  key: 'sowillIdentifierState',
  default: {
    codInternoChave: 0,
    idCliente: ''
  }
})
