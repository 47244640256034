import { NFResultModel } from '@/domain/models'
import { RemoteGetNFs } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'
import { GetNFs } from '@/domain/usecases'

export const makeGetRemoteGetNFs = (): GetNFs => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<NFResultModel>()

  return new RemoteGetNFs(url, httpClient)
}
