import { GenericEventModel } from '@/domain/models'

export type SigmaFilterType = 'rbTodos' | 'rbAlarme' | 'rbTstAuto' | 'rbArmado' | 'rbDesarmado' | 'rbSControle' | 'rbRonda'
export type SigmaFilterTitle = 'Todos' | 'Alarme' | 'Teste Automático' | 'Armado' | 'Desarmado' | 'Sem Controle' | 'Desarmado ronda'

export type GetSigmaEventsParams = {
  empresa: number
  cliente: number
  dataIni: string
  dataFinal: string
  filtro: SigmaFilterType
  idSigma: number
}

export interface GetSigmaEvents {
  getEvents: (params: GetSigmaEventsParams) => Promise<GenericEventModel[]>
}

export namespace GetSigmaEvents {
  export const RN = {
    DEFAULT_DAYS: 30,
    DEFAULT_FILTER_TYPE: 'rbTodos' as SigmaFilterType,
    DEFAULT_FILTER_TITLE: 'Todos'
  }
}
