import { atom } from 'recoil'

export const selectSowillKeyState = atom({
  key: 'selectSowillKeyState',
  default: {
    empresaMonitoramento: 0,
    chave: '',
    particao: ''
  }
})
