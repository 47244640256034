import { AuthenticationAdmin, AuthenticationAdminResult } from '@/domain/usecases'
import { RemoteAuthenticationAdmin } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'

export const makeAuthenticationAdmin = (): AuthenticationAdmin => {
  const url = env.API_LOGIN_URL
  const identificadorAplicação = env.API_LOGIN_IDENTIFICADOR_APP
  const httpClient = makeAxiosHttpClient<AuthenticationAdminResult>()

  return new RemoteAuthenticationAdmin(url, identificadorAplicação, httpClient)
}
