import React from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { ScrollView } from 'react-native-gesture-handler'
import { MaterialIcons } from '@expo/vector-icons'
import { Box, Button, ModalBase, Text, isOpenNotificationsModalState, notificationsState, NotificationCard, VStack, HStack, authenticationState, localStorageState } from '@/presentation/components'
import { useErrorHandler, useTheme, useThemeClient } from '@/presentation/hooks'
import { ChangeTokenNotificationsStatus, DeleteNotification, SaveNotificationToken } from '@/domain/usecases'
import { styles } from './styles'

type NotificationsModalProps = {
  deleteNotification: DeleteNotification
  changeNotificationStatus: ChangeTokenNotificationsStatus
}

export const NotificationsModal: React.FC<NotificationsModalProps> = ({ deleteNotification, changeNotificationStatus }) => {
  const theme = useTheme()
  const { getThemeClient } = useThemeClient()
  const handleError = useErrorHandler()
  const [isOpen, setIsOpen] = useRecoilState(isOpenNotificationsModalState)
  const [notifications, setNotifications] = useRecoilState(notificationsState)
  const [autentication, setAuthentication] = useRecoilState(authenticationState)
  const localStorageAdapter = useRecoilValue(localStorageState)
  const tokenCliente = localStorageAdapter.get(SaveNotificationToken.RN.LOCAL_STORAGE_TOKEN)

  const themeClient = getThemeClient()

  const handleMarkAsRead = (notificationKey: number): void => {
    deleteNotification
      .delete({ codigo: notificationKey })
      .then(() => {
        setNotifications((currentState) =>
          currentState.filter((notification) => notification.codInterno !== notificationKey)
        )
      })
      .catch(handleError)
  }

  const handleChangeNotificationsStatus = (): void => {
    changeNotificationStatus
      .change({ tokenDispositivo: tokenCliente })
      .then(() => {
        setAuthentication((currentState) => ({
          ...currentState, client: { ...currentState.client, recebeNotificacoes: !currentState.client?.recebeNotificacoes }
        }))
      })
      .catch(handleError)
  }

  return (
    <ModalBase visible={isOpen} onClose={() => { setIsOpen(false) }}>
      <HStack justify='between' items='center' mb={24} mr={8}>
        <Text variant='subtitle1' style={{ fontWeight: '500' }}>Notificações</Text>
        {tokenCliente &&
          <Button
            leading={props => <MaterialIcons name={autentication.client?.recebeNotificacoes ? 'notifications-off' : 'notifications'} {...props} />}
            color={autentication.client?.recebeNotificacoes ? theme.colors.greyLight : themeClient.mainColor}
            variant={autentication.client?.recebeNotificacoes ? 'outlined' : 'contained'}
            title={autentication.client?.recebeNotificacoes ? 'DESATIVAR' : 'ATIVAR'}
            onPress={() => { handleChangeNotificationsStatus() }}
          />
        }
      </HStack>

      <ScrollView style={styles.container}>
        {notifications.length
          ? notifications?.map((notification) => (
            <NotificationCard
              key={notification.codInterno}
              notification={notification}
              onMarkAsRead={handleMarkAsRead}
            />
          ))
          : <VStack items='center'>
            <MaterialIcons name='mood' style={{ color: theme.colors.textsLight, fontSize: 64 }} />
            <Text variant='subtitle1' style={{ color: theme.colors.textsLight }}>Você está atualizado com as últimas notificações!</Text>
          </VStack>
        }
      </ScrollView>
      <Box style={styles.buttonArea}>
        <Button
          color={theme.colors.shadow}
          variant='text'
          title='FECHAR'
          onPress={() => { setIsOpen(false) }}
        />
      </Box>
    </ModalBase>
  )
}
