import React from 'react'
import { GestureResponderEvent, TouchableOpacity } from 'react-native'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { ScrollView } from 'react-native-gesture-handler'
import { UserModel } from '@/domain/models'
import { Authentication } from '@/domain/usecases'
import { useAuth, useNotify, useTheme } from '@/presentation/hooks'
import { ModalBase, Box, Button, Text, List, Loading } from '@/presentation/components'
import { isLoadingState, isOpenUserSelect, loginState, usersSelectState } from '@/presentation/pages/Login/components'
import { styles } from './styles'

type ModalUserSelectProps = {
  authentication: Authentication
}

export const ModalUserSelect: React.FC<ModalUserSelectProps> = ({ authentication }) => {
  const theme = useTheme()
  const notify = useNotify()
  const { login } = useAuth()
  const { password } = useRecoilValue(loginState)
  const [isLoading, setLoading] = useRecoilState(isLoadingState)
  const [users, setUsersSelect] = useRecoilState(usersSelectState)
  const [isOpen, setOpen] = useRecoilState(isOpenUserSelect)
  const reset = useResetRecoilState(loginState)

  const handleLogin = (event: GestureResponderEvent, client: UserModel): void => {
    event.preventDefault()
    setLoading(true)

    authentication
      .auth({
        username: client.codCliente.toString(),
        password
      })
      .then(authToken => {
        login(authToken)
        reset()
      })
      .catch(error => notify.show(error.message, { variant: 'error' }))
      .finally(() => {
        setLoading(false)
        handleCancel()
      })
  }

  const handleCancel = (): void => {
    setOpen(false)
    setUsersSelect([])
  }

  return (
    <ModalBase visible={isOpen} onClose={handleCancel}>
      <Box style={styles.container}>
        {isLoading
          ? <Loading text='Realizando login' />
          : <>
              <Text variant='subtitle1' style={{ fontWeight: '600' }}>Selecione o usuário para Login</Text>
              <ScrollView style={{ maxHeight: '50vh' }}>
                <Box style={styles.content}>
                  {users?.map(client =>
                    <TouchableOpacity
                      key={client.codCliente}
                      style={{ backgroundColor: theme.colors.divider, borderRadius: 4 }}
                      onPress={(event) => handleLogin(event, client)}
                    >
                      <List.Item
                        title={client.nomeCliente}
                        description={`Código ${client.codCliente.toString().padStart(6, '0')}  - Empresa Faturamento:  ${client.empresa.toString().padStart(6, '0')}`}
                        left={props => <List.Icon {...props} icon='account' />}
                        titleStyle={{ fontWeight: '600' }}
                      />
                    </TouchableOpacity>
                  )}
                </Box>
              </ScrollView>
            </>
        }

        <Box style={styles.buttonArea}>
          <Button
            color={theme.colors.shadow}
            variant="text"
            title="Cancelar"
            onPress={handleCancel}
            disabled={isLoading}
          />
        </Box>
      </Box>
    </ModalBase>
  )
}
