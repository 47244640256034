export type OpenLeaseReceiptParams = {
  codigoRecibo: string
}

export interface OpenLeaseReceipt {
  open: (params: OpenLeaseReceiptParams) => Promise<void>
}

export namespace OpenLeaseReceipt {
  export const RN = {
    FOLDER_DEFAULT_LEASE_RECEIPT: 'TempRelatorios'
  }
}
