import React, { useCallback } from 'react'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import env from '@/main/config/env'
import { SaveTheme, SaveThemeParams, UploadFile } from '@/domain/usecases'
import { Box, Button, Text, ModalBase, unitsState, currentUnitState } from '@/presentation/components'
import { useErrorHandler, useNotify, useTheme, useThemeClient } from '@/presentation/hooks'
import { isLoadingState, selectedBackgroundState, selectedLogoState, themeState } from '@/presentation/pages/Theme/components'
import { modalOnSaveChangeThemeState } from './atom'
import { styles } from './styles'

type ModalOnSaveChangesProps = {
  saveTheme: SaveTheme
  uploadFile: UploadFile
}

export const ModalOnSaveChanges: React.FC<ModalOnSaveChangesProps> = ({ saveTheme, uploadFile }) => {
  const theme = useTheme()
  const notify = useNotify()
  const [themeClient] = useRecoilState(themeState)
  const selectedLogo = useRecoilValue(selectedLogoState)
  const selectedBackgroundLogo = useRecoilValue(selectedBackgroundState)
  const { setThemeClient } = useThemeClient()
  const [isLoading, setLoading] = useRecoilState(isLoadingState)
  const [isOpenSaveChanges, setOpenSaveChanges] = useRecoilState(modalOnSaveChangeThemeState)
  const resetSelectedLogo = useResetRecoilState(selectedLogoState)
  const resetSelectedBackgroundLogo = useResetRecoilState(selectedBackgroundState)
  const units = useRecoilValue(unitsState)
  const currentUnit = useRecoilValue(currentUnitState)
  const handleError = useErrorHandler(() => {
    setLoading(false)
    handleClose()
  })

  const handleSaveChanges = async (): Promise<void> => {
    setLoading(true)

    try {
      const unit = units.find(unitData => unitData.codigoUnidade === currentUnit?.codigoUnidade)
      const externalServer = unit?.enderecoExterno ?? ''
      selectedLogo && await uploadFile.upload({
        enderecoExternoIntegra: externalServer,
        file: selectedLogo,
        scope: 'logo'
      })

      selectedBackgroundLogo && await uploadFile.upload({
        enderecoExternoIntegra: externalServer,
        file: selectedBackgroundLogo,
        scope: 'loginBackground'
      })

      const saveThemeParams: SaveThemeParams = {
        ...themeClient.theme,
        enderecoExternoIntegra: __DEV__ ? env.API_URL : externalServer
      }

      const themeResult = await saveTheme.save(saveThemeParams, currentUnit?.codigoUnidade)
      notify.show(themeResult)

      setThemeClient(themeClient.theme)
      resetSelectedLogo()
      resetSelectedBackgroundLogo()
      setLoading(false)
      handleClose()
    } catch (error) {
      handleError(error)
    }
  }

  const handleClose = useCallback(() => setOpenSaveChanges(false), [])

  return (
    <ModalBase visible={isOpenSaveChanges} onClose={handleClose}>
      <Box style={styles.container}>
        <Text variant="subtitle1">Deseja realmente gravar as alterações?</Text>

        <Box style={styles.buttonArea}>
          <Button
            title="GRAVAR"
            color={theme.colors.success}
            tintColor={theme.colors.white}
            onPress={handleSaveChanges}
            disabled={isLoading}
          />
        </Box>
      </Box>
    </ModalBase>
  )
}
