import React from 'react'
import { useRecoilState } from 'recoil'
import { ScrollView } from 'react-native-gesture-handler'
import { Box, Text, ModalBase, VStack, Divider, HStack, Button, CardDetails, CardRow } from '@/presentation/components'
import { isOpenContractDetailsState } from '@/presentation/pages/Contract/components'
import { useAuth, useFormat, useTheme } from '@/presentation/hooks'
import { DateAdapter } from '@/data/protocols'
import { styles } from '../../styles'

type ModalContractDetailsProps = {
  dateAdapter: DateAdapter
}

export const ModalContractDetails: React.FC<ModalContractDetailsProps> = ({ dateAdapter }) => {
  const theme = useTheme()
  const { formatCurrency } = useFormat()
  const [isOpen, setOpen] = useRecoilState(isOpenContractDetailsState)
  const { getClient } = useAuth()

  const client = getClient()

  const handleClose = (): void => setOpen(false)

  const btnLimparStyles = {
    ...styles.btnAction,
    color: theme.colors.textsLight
  }

  return (
    <ModalBase visible={isOpen} onClose={handleClose} >
      <ScrollView style={styles.scroll}>
        <VStack style={styles.container} spacing={10}>
          <Box style={styles.titleBox}>
            <Text variant='h6' style={styles.bold}>{client.numContrato ? `Detalhes do contrato ${client.numContrato}` : 'Detalhes da mensalidade' }</Text>
          </Box>
          <Box>
            <Text style={styles.bold}>Início da Vigência</Text>
            <Text>{dateAdapter.format(client.primeiroFaturamento, 'DD/MM/YYYY')}</Text>
          </Box>
          <Box>
            <Text style={styles.bold}>Término da Vigência</Text>
            <Text>
              {dateAdapter.format(client.terminoContrato, 'DD/MM/YYYY') === '01/01/0001'
                ? 'Não definido'
                : dateAdapter.format(client.terminoContrato, 'DD/MM/YYYY')
              }
            </Text>
          </Box>
          <Box>
            <Text style={styles.bold}>Dia de Cobrança</Text>
            <Text>{client.diaVencimento}</Text>
          </Box>
          <Box>
            <Text style={styles.bold}>Valor Mensal</Text>
            <Text>{formatCurrency(client.valorMensal)}</Text>
          </Box>
          <Box>
            <Text style={styles.bold}>Serviços</Text>
          </Box>
          <VStack spacing={5} ph={2}>
            {client.servicosContratadosPortal?.map(servico => (
              <CardRow key={`servico-contratado-${servico.serCodigo}`}>
                <CardDetails minWidth={'100%'}>
                  <Box style={styles.titleBox}>
                    <Text variant='subtitle2' style={styles.bold}>{servico.cliSerContDescricao}</Text>
                  </Box>
                  <Box>
                    <Text variant='subtitle2' style={styles.bold}>Quantidade</Text>
                    <Text variant='subtitle2' style={{ fontWeight: 'normal' }}>{servico.cliSerContQuantidade}</Text>
                  </Box>
                  <Box>
                    <Text variant='subtitle2' style={styles.bold}>Valor Total</Text>
                    <Text variant='subtitle2' style={{ fontWeight: 'normal' }}>{formatCurrency(servico.cliSerContValor)}</Text>
                  </Box>
                  <Box>
                    <Text variant='subtitle2' style={styles.bold}>Início da Prestação</Text>
                    <Text variant='subtitle2' style={{ fontWeight: 'normal' }}>
                      {dateAdapter.format(servico.dataCadastro, 'DD/MM/YYYY') !== '01/01/0001'
                        ? dateAdapter.format(servico.dataCadastro, 'DD/MM/YYYY')
                        : dateAdapter.format(client.primeiroFaturamento, 'DD/MM/YYYY')
                      }
                    </Text>
                  </Box>
                </CardDetails>
              </CardRow>
            ))}
          </VStack>
        </VStack>
      </ScrollView>
      <Box>
        <Divider color={theme.colors.greyDarken} />
        <HStack style={styles.actionsContent} spacing={8}>
          <Divider color={theme.colors.greyDarken} />
          <Button
            variant='text'
            title='Fechar'
            titleStyle={btnLimparStyles}
            contentContainerStyle={btnLimparStyles}
            onPress={handleClose}
          />
        </HStack>
      </Box>
    </ModalBase>
  )
}
