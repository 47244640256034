
import { atom } from 'recoil'
import { AddTravelAlertOsResponsible, AddTravelAlertOsVehicle } from '@/domain/usecases'

export const isLoadingState = atom({
  key: 'travelAlertLoadingState',
  default: false
})

export const travelAlertState = atom({
  key: 'travelAlertState',
  default: {
    solicitante: '',
    email: '',
    telefone1: '',
    telefone2: '',
    dataSaida: '',
    horaSaida: '',
    dataRetorno: '',
    horaRetorno: '',
    recomendacoes: '',
    chavesSomenteComCentral: true,
    possuiAutorizados: false,
    possuiVeiculosCuidadosCentral: false
  }
})

export const travelAlertResponsiblesKeysState = atom({
  key: 'travelAlertResponsiblesKeysState',
  default: [] as AddTravelAlertOsResponsible[]
})

export const travelAlertAuthorizedsState = atom({
  key: 'travelAlertAuthorizedsState',
  default: [] as AddTravelAlertOsResponsible[]
})

export const travelAlertVehiclesState = atom({
  key: 'travelAlertVehiclesState',
  default: [] as AddTravelAlertOsVehicle[]
})
