import React from 'react'
import { Stack } from '@/presentation/components'
import { useMediaQueries } from '@/presentation/hooks'

type InputFieldProps = {
  children: React.ReactNode
  justify?: 'around' | 'between' | 'center' | 'start' | 'end' | 'evenly'
}

export const InputField: React.FC<InputFieldProps> = ({ children, justify }) => {
  const { isMobile } = useMediaQueries()

  return (
    <Stack
      spacing={12}
      style={{ width: '100%' }}
      direction={isMobile ? 'column' : 'row'}
      justify={justify}
    >
      {children}
    </Stack>
  )
}
