import { Dimensions } from 'react-native'
import { APP_BAR_HEIGTH, PADDING_STATUS_BAR_IOS } from '@/presentation/components'

const width = Dimensions.get('window').width
const height = Dimensions.get('window').height

type DimensionsProps = {
  width: number
  height: number
  appBarHeight: number
  isMobile: boolean
  openInIOS: boolean
  openInPWA: boolean
}

const useMediaQueries = (): DimensionsProps => {
  const isMobile = width < 600

  const openInPWA = window.window.matchMedia('(display-mode: standalone)').matches

  const openInIOS = /iphone|ipad|ipod/.test(window.window.navigator.userAgent.toLowerCase())

  const appBarHeight = APP_BAR_HEIGTH + (openInPWA && openInIOS ? PADDING_STATUS_BAR_IOS : 0)

  return {
    width,
    height,
    isMobile,
    openInIOS,
    appBarHeight,
    openInPWA
  }
}

export default useMediaQueries
