import React, { useCallback } from 'react'
import { GestureResponderEvent } from 'react-native'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useFocusEffect } from '@react-navigation/native'
import { DateAdapter } from '@/data/protocols'
import { ClientMonitoringType } from '@/domain/models'
import { GetSigmaEvents, SigmaFilterType } from '@/domain/usecases'
import { Box, DatePicker, SideFilter, Text } from '@/presentation/components'
import { useAuth } from '@/presentation/hooks'
import { monitoringDateFiltersState, isLoadingMonitoringEventState, isOpenMonitoringFiltersState, SelectTypeSigmaEvent, remotyFilterState, sigmaFilterState, monitoringListHasError } from '@/presentation/pages/Events/components'

type SideFiltersProps = {
  dateAdapter: DateAdapter
}

export const SideFilters: React.FC<SideFiltersProps> = ({
  dateAdapter
}) => {
  const { getClient } = useAuth()
  const client = getClient()

  const [monitoringFilterDate, setMonitoringFilterDate] = useRecoilState(monitoringDateFiltersState)
  const setRemotyFilter = useSetRecoilState(remotyFilterState)
  const setSigmaFilter = useSetRecoilState(sigmaFilterState)
  const [isOpen, setOpen] = useRecoilState(isOpenMonitoringFiltersState)
  const setNoMonitoringError = useSetRecoilState(monitoringListHasError)

  const [localFilterDate, setLocalFilterDate] = React.useState({
    dataInicial: '',
    dataFinal: ''
  })

  const isLoading = useRecoilValue(isLoadingMonitoringEventState)

  const handleResetState = (): void => {
    setMonitoringFilterDate(currentState => ({
      ...currentState,
      dataInicial: '',
      dataFinal: ''
    })
    )

    setRemotyFilter({ remotyIdEquipamento: 0, remotyNmEquip: '' })

    setSigmaFilter({ sigmaTipo: null as unknown as SigmaFilterType, sigmaTituloChip: '' })
  }

  const handleClose = (): void => setOpen(false)

  useFocusEffect(
    useCallback(() => {
      handleResetState()
    }, [])
  )

  const onSubmit = (event: GestureResponderEvent): void => {
    event.preventDefault()
    setOpen(false)
    setNoMonitoringError(false)

    setMonitoringFilterDate(currentState => ({
      ...currentState,
      dataInicial: localFilterDate.dataInicial,
      dataFinal: localFilterDate.dataFinal
    }))
  }

  const handleChangeDate = useCallback((date: Date): void => {
    const dataInicial = dateAdapter.format(dateAdapter.daysAgo(GetSigmaEvents.RN.DEFAULT_DAYS, date).toString(), 'YYYY-MM-DD')
    const dataFinal = dateAdapter.format(date.toString(), 'YYYY-MM-DD')

    setLocalFilterDate({ dataInicial, dataFinal })
  }, [])

  return (
    <SideFilter.Drawer
      open={isOpen}
      onClose={handleClose}
      loading={isLoading}
      onSubmit={onSubmit}
      resetState={handleResetState}
    >
      <Box>
        <SideFilter.Title title='Período dos eventos' />
        <Text style={{ paddingRight: '4px', paddingLeft: '4px', paddingBottom: '4px' }}>Referente aos 30 dias anteriores à data selecionada.</Text>
        <DatePicker
          label={localFilterDate.dataFinal ? dateAdapter.format(localFilterDate.dataFinal, 'DD-MM-YYYY') : 'Selecione uma data.'}
          disabled={isLoading}
          value={
            [ClientMonitoringType.SIGMA_CLOUD, ClientMonitoringType.SIGMA_CLOUD].includes(client.tipoConexao) &&
              monitoringFilterDate.dataFinal
              ? dateAdapter.parse(monitoringFilterDate.dataFinal)
              : undefined
          }
          onChange={(date) => handleChangeDate(date ?? dateAdapter.today())}
          dateAdapter={dateAdapter}
        />

      </Box>
      {([ClientMonitoringType.SIGMA_CLOUD].includes(client.tipoConexao) && (
        <Box>
          <SideFilter.Title title='Filtro de eventos' />
          <SelectTypeSigmaEvent />
        </Box>
      )) as React.ReactElement || undefined}
    </SideFilter.Drawer >
  )
}
