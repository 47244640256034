import React, { useState } from 'react'
import { ScrollView } from 'react-native'
import { useRecoilState } from 'recoil'
import { InvoiceConfigs } from '@/domain/models'
import { Box, CardSwitch, Loading } from '@/presentation/components'
import { ActionsButtons, invoiceConfigsState } from '@/presentation/pages/Settings/components'
import { styles } from './styles'

export const InvoicesSettings: React.FC = () => {
  const [invoiceConfigs, setInvoiceConfig] = useRecoilState(invoiceConfigsState)
  const [initialInvoiceConfigs] = useState(invoiceConfigs)

  const handleChangeConfig = (param: keyof InvoiceConfigs, value: boolean): void => {
    setInvoiceConfig(currentState => ({ ...currentState, [param]: value }))
  }

  const hasChanged = (): boolean => {
    return JSON.stringify(initialInvoiceConfigs) !== JSON.stringify(invoiceConfigs)
  }

  return (
    <Box style={styles.container}>
      {!invoiceConfigs
        ? <Loading />
        : <>
          <ScrollView>
            <CardSwitch
              text='Ocultar informações adicionais da nota fiscal de serviço?'
              value={invoiceConfigs.nfsOcultarInfos}
              handleSwitch={() => handleChangeConfig('nfsOcultarInfos', !invoiceConfigs.nfsOcultarInfos)}
            />
            <CardSwitch
              text='Ocultar link para visualização da nota fiscal de serviço'
              value={invoiceConfigs.nfsOcultarLink}
              handleSwitch={() => handleChangeConfig('nfsOcultarLink', !invoiceConfigs.nfsOcultarLink)}
            />
          </ScrollView>
          {hasChanged() && <ActionsButtons />}
        </>
      }
    </Box>
  )
}
