import React, { useCallback, useEffect } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { ScrollView } from 'react-native-gesture-handler'
import { DateAdapter } from '@/data/protocols'
import { ClientMonitoringType, GenericEventModel } from '@/domain/models'
import { GetMoniEvents, GetMoniEventsParams, GetRemotyEvents, GetRemotyEventsParams, GetSigmaEvents, GetSigmaEventsParams, PrintEvents, GetSowillEvents, GetSowillEventsParams, GetSowillIdentifier } from '@/domain/usecases'
import { useAuth, useErrorHandler, useMediaQueries, useNotify, useThemeClient } from '@/presentation/hooks'
import { Box, FAB, CardList, PageHeader, ActionsButtons, Stack } from '@/presentation/components'
import { FiltersChipPanel, CardMonitoringEvent, monitoringDateFiltersState, isOpenMonitoringFiltersState, SelectRemotyEventCompany, SelectSigmaCloudKey, SelectSowillKey, monitoringEventListState, isLoadingMonitoringEventState, sowillIdentifierState, selectSigmaCloudKeyData, remotyFilterState, monitoringListHasError, sigmaFilterState } from '@/presentation/pages/Events/components'
import { layoutStyles } from '@/presentation/styles/globals'
import { theme } from '@/presentation/styles'
import { useFocusEffect } from '@react-navigation/native'

type MonitoringListEventsProps = {
  dateAdapter: DateAdapter
  printEvents: PrintEvents
  getMoniEvents: GetMoniEvents
  getSigmaEvents: GetSigmaEvents
  getRemotyEvents: GetRemotyEvents
  getSowillEvents: GetSowillEvents
  getSowillIdentifier: GetSowillIdentifier
}

export const MonitoringEventList: React.FC<MonitoringListEventsProps> = ({
  dateAdapter,
  printEvents,
  getMoniEvents,
  getSigmaEvents,
  getRemotyEvents,
  getSowillEvents
}) => {
  const notify = useNotify()
  const { getClient } = useAuth()
  const { getThemeClient } = useThemeClient()
  const { isMobile } = useMediaQueries()
  const handleError = useErrorHandler()

  const [isLoading, setLoading] = useRecoilState(isLoadingMonitoringEventState)
  const [monitoringEventList, setMonitoringEventList] = useRecoilState(monitoringEventListState)
  const [hasError, setHasError] = useRecoilState(monitoringListHasError)
  const setOpen = useSetRecoilState(isOpenMonitoringFiltersState)
  const [filters, setFilters] = useRecoilState(monitoringDateFiltersState)
  const { remotyIdEquipamento } = useRecoilValue(remotyFilterState)
  const sowillIdentifier = useRecoilValue(sowillIdentifierState)
  const currentSigmaKey = useRecoilValue(selectSigmaCloudKeyData)
  const sigmaFilterType = useRecoilValue(sigmaFilterState)

  const client = getClient()
  const themeClient = getThemeClient()

  useEffect(() => {
    const dataInicial = dateAdapter.format(dateAdapter.daysAgo(GetSigmaEvents.RN.DEFAULT_DAYS).toString(), 'YYYY-MM-DD')
    const dataFinal = dateAdapter.format(dateAdapter.today(), 'YYYY-MM-DD')
    setFilters({ dataInicial, dataFinal })
  }, [])

  useFocusEffect(
    useCallback(() => {
      const conditions = {
        [ClientMonitoringType.REMOTY]: remotyIdEquipamento && remotyIdEquipamento !== 0,
        [ClientMonitoringType.MONI]: client.tipoConexao === 1,
        [ClientMonitoringType.SIGMA_CLOUD]: !!(currentSigmaKey.codCliente),
        [ClientMonitoringType.SIGMA_DESK]: !!(currentSigmaKey.codCliente),
        [ClientMonitoringType.SO_WILL]: !!(sowillIdentifier.codInternoChave)
      }[client.tipoConexao]
      if (conditions && !hasError) { getEventsMonitoring() }
    },
    [
      client.empresa,
      remotyIdEquipamento,
      currentSigmaKey.codCliente,
      currentSigmaKey.empresaSigma,
      sowillIdentifier.codInternoChave,
      filters.dataInicial,
      sigmaFilterType.sigmaTipo,
      hasError
    ])
  )

  const errorHandler = (error: Error): void => {
    handleError(error)
    setHasError(true)
  }

  const handleGetRemotyEvent = (): void => {
    const initialDefaultDate = dateAdapter.format(dateAdapter.daysAgo(GetSigmaEvents.RN.DEFAULT_DAYS).toString(), 'YYYY-MM-DD')
    const finalDefaultDate = dateAdapter.format(dateAdapter.today(), 'YYYY-MM-DD')

    const params: GetRemotyEventsParams = {
      idEquipamento: remotyIdEquipamento,
      empresaMonitoramento: client.empresaMonitoramento,
      dtInicio: filters.dataInicial && filters.dataInicial !== '' ? filters.dataInicial : initialDefaultDate,
      dtFim: filters.dataFinal && filters.dataFinal !== '' ? filters.dataFinal : finalDefaultDate
    }

    setLoading(true)

    getRemotyEvents
      .getEvents(params)
      .then(setMonitoringEventList)
      .catch(errorHandler)
      .finally(() => setLoading(false))
  }

  const handleGetMoniEvent = (): void => {
    const defaultDate = dateAdapter.format(dateAdapter.daysAgo(GetSigmaEvents.RN.DEFAULT_DAYS).toString(), 'YYYY-MM-DD')

    const params: GetMoniEventsParams = {
      unidade: client.unidade.toString(),
      empresaMoni: client.empresaMonitoramento.toString(),
      codCliente: client.codCliente,
      dataInicial: filters.dataInicial && filters.dataInicial !== '' ? filters.dataInicial : defaultDate
    }

    setLoading(true)

    getMoniEvents
      .getEvents(params)
      .then(setMonitoringEventList)
      .catch(errorHandler)
      .finally(() => setLoading(false))
  }

  const handleGetSigmaEvent = (): void => {
    const initialDefaultDate = dateAdapter.format(dateAdapter.daysAgo(GetSigmaEvents.RN.DEFAULT_DAYS).toString(), 'YYYY-MM-DD')
    const finalDefaultDate = dateAdapter.format(dateAdapter.today(), 'YYYY-MM-DD')

    const params: GetSigmaEventsParams = {
      empresa: client.empresa,
      cliente: currentSigmaKey.codCliente,
      dataIni: filters.dataInicial && filters.dataInicial !== '' ? filters.dataInicial : initialDefaultDate,
      dataFinal: filters.dataFinal && filters.dataFinal !== '' ? filters.dataFinal : finalDefaultDate,
      filtro: sigmaFilterType.sigmaTipo ? sigmaFilterType.sigmaTipo : GetSigmaEvents.RN.DEFAULT_FILTER_TYPE,
      idSigma: currentSigmaKey.empresaSigma
    }

    getSigmaEvents
      .getEvents(params)
      .then(setMonitoringEventList)
      .catch(errorHandler)
      .finally(() => setLoading(false))
  }

  const handleGetSowillEvent = (): void => {
    const defaultDate = dateAdapter.format(dateAdapter.daysAgo(GetSigmaEvents.RN.DEFAULT_DAYS).toString(), 'YYYY-MM-DD')

    const params: GetSowillEventsParams = {
      dateTimeInicioManutencao: filters.dataInicial && filters.dataInicial !== '' ? filters.dataInicial : defaultDate,
      codInternoChave: sowillIdentifier.codInternoChave,
      idCliente: sowillIdentifier.idCliente
    }

    getSowillEvents
      .getEvents(params)
      .then(setMonitoringEventList)
      .catch(errorHandler)
      .finally(() => setLoading(false))
  }

  const getEventsMonitoring = {
    [ClientMonitoringType.MONI]: handleGetMoniEvent,
    [ClientMonitoringType.SIGMA_CLOUD]: handleGetSigmaEvent,
    [ClientMonitoringType.SIGMA_DESK]: handleGetSigmaEvent,
    [ClientMonitoringType.REMOTY]: handleGetRemotyEvent,
    [ClientMonitoringType.SO_WILL]: handleGetSowillEvent
  }[client.tipoConexao]

  const handlePrint = (): void => {
    let listaEventos: GenericEventModel[]

    if (client.tipoConexao === ClientMonitoringType.MONI) {
      listaEventos = monitoringEventList.map(event => ({
        evento: event.evento,
        dataEvento: dateAdapter.format(event.dataEvento, 'DD/MM/YYYY HH:mm:ss'),
        descricao: event.descricao,
        empresaSigma: client.empresaMonitoramento
      }))
    } else {
      listaEventos = monitoringEventList.map(event => ({
        ...event,
        dataEvento: client.tipoConexao === ClientMonitoringType.REMOTY
          ? event.dataEvento
          : dateAdapter.format(event.dataEvento, 'DD/MM/YYYY HH:mm:ss')
      }))
    }

    const printParams = {
      tipoConexao: client.tipoConexao,
      data: listaEventos
    }

    printEvents.print(printParams)
      .then(res => res)
      .catch(error => {
        notify.show(error.message, { variant: 'error' })
        console.error({ printError: error })
      })
  }

  const handleFilter = (): void => {
    const conditions = {
      [ClientMonitoringType.REMOTY]: remotyIdEquipamento !== 0,
      [ClientMonitoringType.MONI]: client.tipoConexao === 1,
      [ClientMonitoringType.SIGMA_CLOUD]: !!(currentSigmaKey.codCliente && currentSigmaKey.codCliente !== 0),
      [ClientMonitoringType.SIGMA_DESK]: !!(currentSigmaKey.codCliente && currentSigmaKey.codCliente !== 0),
      [ClientMonitoringType.SO_WILL]: !!(sowillIdentifier.codInternoChave && sowillIdentifier.codInternoChave !== 0)
    }[client.tipoConexao]

    if (conditions) {
      setOpen(true)
    } else {
      notify.show('Selecione uma empresa antes de filtrar a data', { variant: 'error' })
    }
  }

  return (
    <Box style={{ ...layoutStyles.pageContent }}>
      <PageHeader
        text={isMobile ? 'Eventos' : 'Eventos do alarme'}
        icon={<MaterialCommunityIcons name="alarm-light-outline" size={24} color={theme.colors.grey} />}
        openFilters={handleFilter}
      />

      <Stack
        direction={isMobile ? 'column-reverse' : 'row'}
        mr={window.innerWidth <= 1380 ? 36 : isMobile ? 'auto' : 0}
        ml={window.innerWidth <= 1380 ? 36 : isMobile ? 'auto' : 0}
        justify={filters.dataInicial ? 'between' : 'end'}
        items='center'
        spacing={8}
      >
        {filters.dataInicial && <FiltersChipPanel dateAdapter={dateAdapter} />}
        {[ClientMonitoringType.REMOTY].includes(client.tipoConexao) && <SelectRemotyEventCompany />}
        {[ClientMonitoringType.SIGMA_CLOUD, ClientMonitoringType.SIGMA_DESK].includes(client.tipoConexao) && <SelectSigmaCloudKey />}
        {[ClientMonitoringType.SO_WILL].includes(client.tipoConexao) && <SelectSowillKey />}
      </Stack>

      <ScrollView style={{ padding: '1rem' }}>
        <CardList
          isLoading={isLoading}
          isEmpty={!monitoringEventList.length}
          textLoading={'Buscando eventos'}
          textEmpty='Não há eventos a serem apresentados'
        >
          {monitoringEventList?.map(event => (
            <CardMonitoringEvent
              key={`cardEvents-${event.dataEvento.replaceAll(' ', '')}`}
              event={event}
              dateAdapter={dateAdapter}
            />
          ))}
        </CardList>
      </ScrollView>
      {
        !!monitoringEventList.length &&
        <ActionsButtons>
          <FAB
            testID='imprimir-eventos'
            onPress={handlePrint}
            icon={props => <MaterialCommunityIcons name="printer-outline" {...props} />} color={themeClient.mainColor}
          />
        </ActionsButtons>
      }
    </Box>
  )
}
