import { GetSigmaCloudUsers } from '@/domain/usecases'
import { SigmaCloudUserResult } from '@/domain/models'
import { makeAxiosHttpClient } from '@/main/factories/http'
import { RemoteGetSigmaCloudUsers } from '@/data/usecases'
import env from '@/main/config/env'

export const makeGetSigmaCloudUsersFactory = (): GetSigmaCloudUsers => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<SigmaCloudUserResult>()

  return new RemoteGetSigmaCloudUsers(url, httpClient)
}
