import React from 'react'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { IconButton, Surface } from 'react-native-paper'
import { AddTravelAlertOsResponsible } from '@/domain/usecases'
import { FAB, HStack, Stack, Text, VStack } from '@/presentation/components'
import { useMediaQueries, useTheme, useThemeClient } from '@/presentation/hooks'

type ResponsiblesPanelProps = {
  responsibles: AddTravelAlertOsResponsible[]
  onAddNew: () => void
  onRemove: (idResp: number) => void
  variant?: 'tiny' | 'regular'
  disabled?: boolean
}

export const ResponsiblesPanel: React.FC<ResponsiblesPanelProps> = ({
  responsibles,
  onAddNew,
  onRemove,
  variant,
  disabled = false
}) => {
  const theme = useTheme()
  const { isMobile } = useMediaQueries()
  const { getThemeClient } = useThemeClient()

  const themeClient = getThemeClient()

  const makeAction = (): JSX.Element =>
    <HStack fill mt={12}>
      <FAB
        variant='extended'
        label='Adicionar novo'
        onPress={onAddNew}
        size='mini'
        disabled={disabled}
        icon={props => <MaterialCommunityIcons name='plus' {...props} />}
        color={disabled ? theme.colors.greyLight : themeClient.mainColor}
      />
    </HStack>

  return (
    <>
      <Stack wrap spacing={12} direction={isMobile ? 'column' : 'row'}>
      {responsibles
        .map((resp, index) => {
          let description = `Telefone 1: ${resp.telefone1}\r\n`
          description += `${resp.endereco}`

          return (
            <Surface
              elevation={1}
              key={`responsible-${resp.nome.replace(' ', '-')}-${index}`}
              style={{
                flex: 1,
                maxWidth: 320,
                backgroundColor: theme.colors.backgroundInput,
                borderRadius: 4,
                marginTop: 8,
                padding: 12
              }}
            >
              <HStack items='center' spacing={12} fill>
                <MaterialCommunityIcons name='account' size={24} />
                <VStack fill>
                  <Text
                    style={{
                      fontWeight: '600',
                      fontSize: variant === 'tiny' ? 12 : 14
                    }}>
                      {resp.nome.toUpperCase()}
                  </Text>
                  {variant === 'tiny' &&
                    <Text style={{ fontSize: 14, color: theme.colors.grey }}>{description}</Text>
                  }
                </VStack>
                <IconButton
                  size={18}
                  icon={'trash-can'}
                  iconColor={theme.colors.error}
                  onPress={() => onRemove(resp.id)}
                />
              </HStack>
            </Surface>
          )
        })
      }
      </Stack>
      {responsibles?.length < 4 && makeAction()}
    </>
  )
}
