import React, { useEffect, useCallback } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { GestureResponderEvent } from 'react-native'
import { GetServiceOrders } from '@/domain/usecases'
import { DateAdapter } from '@/data/protocols'
import { Box, Divider, DatePicker, InputField, SideFilter } from '@/presentation/components'
import { useAuth, useErrorHandler } from '@/presentation/hooks'
import { SelectStatusOsFilter, serviceOrdersFiltersState, serviceOrdersConfigsState, isLoadingServiceOrdersState, isOpenServiceOrdersFiltersState, serviceOrdersState } from '@/presentation/pages/ServiceOrders/components'

type DateParams = {
  dataInicio: string
  dataFim: string
}

type SideFiltersProps = {
  getServiceOrders: GetServiceOrders
  dateAdapter: DateAdapter
}

export const MARGIN_MODALS = 30

export function SideFilters ({ dateAdapter, getServiceOrders }: SideFiltersProps): JSX.Element {
  const { getClient } = useAuth()
  const handleError = useErrorHandler()
  const [serviceOrdersConfigs, setServiceOrdersConfig] = useRecoilState(serviceOrdersConfigsState)
  const [isLoading, setLoading] = useRecoilState(isLoadingServiceOrdersState)
  const [filters, setFilters] = useRecoilState(serviceOrdersFiltersState)
  const [isOpen, setOpen] = useRecoilState(isOpenServiceOrdersFiltersState)
  const setServiceOrders = useSetRecoilState(serviceOrdersState)

  const client = getClient()

  useEffect(() => {
    setFilters({
      situacao: GetServiceOrders.RN.DEFAULT_STATUS,
      dataInicio: dateAdapter.format(dateAdapter.monthAgo(GetServiceOrders.RN.DEFAULT_MONTH_AGO), 'YYYY-MM-DD'),
      dataFim: dateAdapter.format(dateAdapter.today().toString(), 'YYYY-MM-DD')
    })
  }, [])

  const onSubmit = (event: GestureResponderEvent): void => {
    event.preventDefault()
    setLoading(true)

    const paramsGetOrders = {
      situacao: filters.situacao,
      codigoCliente: client.codCliente,
      codigoUnidade: client.unidade,
      dataInicial: dateAdapter.format(filters.dataInicio, 'YYYY-MM-DD'),
      dataFinal: dateAdapter.format(filters.dataFim, 'YYYY-MM-DD')
    }

    getServiceOrders
      .get(paramsGetOrders)
      .then(serviceOrders => {
        const { listaOS, ...configs } = serviceOrders
        setServiceOrders(listaOS)
        setServiceOrdersConfig(configs)
      })
      .catch(handleError)
      .finally(() => setLoading(false))

    setOpen(false)
  }

  const handleChangeDate = useCallback((field: keyof DateParams, date: Date): void => {
    setFilters(currentFilters => ({
      ...currentFilters,
      [field]: date.toString()
    }))
  }, [])

  const handleResetState = (): void => {
    setFilters({
      situacao: GetServiceOrders.RN.DEFAULT_STATUS,
      dataInicio: dateAdapter.format(dateAdapter.monthAgo(GetServiceOrders.RN.DEFAULT_MONTH_AGO), 'YYYY-MM-DD'),
      dataFim: dateAdapter.format(dateAdapter.today().toString(), 'YYYY-MM-DD')
    })
  }

  const handleClose = (): void => setOpen(false)

  return (
    <SideFilter.Drawer
      open={isOpen}
      onClose={handleClose}
      loading={isLoading}
      onSubmit={onSubmit}
      resetState={handleResetState}
    >
      <Box>
        <SideFilter.Title title='Data de Abertura' />
        <InputField>
          <DatePicker
            label='De'
            value={filters.dataInicio ? dateAdapter.parse(filters.dataInicio) : undefined}
            disabled={isLoading}
            onChange={(date) => handleChangeDate('dataInicio', date ?? dateAdapter.monthAgo(GetServiceOrders.RN.DEFAULT_MONTH_AGO))}
            dateAdapter={dateAdapter}
          />
          <Divider />
          <DatePicker
            label='Até'
            value={filters.dataFim ? dateAdapter.parse(filters.dataFim) : undefined}
            disabled={isLoading}
            onChange={(date) => handleChangeDate('dataFim', date ?? dateAdapter.today())}
            dateAdapter={dateAdapter}
          />
        </InputField>
      </Box>
      <Box>
        {serviceOrdersConfigs.exibirOsFechadas &&
          <>
            <SideFilter.Title title='Status da OS' />
            <SelectStatusOsFilter />
          </>
        }
      </Box>
    </SideFilter.Drawer>
  )
}
