import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    flex: 1
  },

  containerHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  headerText: {
    padding: 16
  },
  headerChip: {
    margin: 16,
    backgroundColor: 'rgba(91, 42, 140, 1)',
    borderRadius: 15
  },

  cardContainer: {
    marginBottom: '8px',
    marginTop: '8px',
    marginRight: '16px',
    marginLeft: '16px',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  cardContainerMobile: {
    marginBottom: '8px',
    marginTop: '8px',
    marginRight: '16px',
    marginLeft: '16px',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },

  cardContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1
  }
})
