import React from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { Box, Text, CardRow, Card } from '@/presentation/components'
import { serviceOrderDetailState } from '@/presentation/pages/ServiceOrderDetail/components'
import { theme } from '@/presentation/styles'
import { useMediaQueries } from '@/presentation/hooks'
import { styles } from '../../styles'
import { selectedOsImageViewState } from '../OsImageView/atom'

export const OsImages: React.FC = () => {
  const { isMobile } = useMediaQueries()
  const serviceOrderDetails = useRecoilValue(serviceOrderDetailState)
  const setImageView = useSetRecoilState(selectedOsImageViewState)

  return (
    <>
      {!!serviceOrderDetails.imagensGerais?.length &&
        <>
          <Text style={styles.pageInfo}>Anexos</Text>
          <CardRow>
            {serviceOrderDetails
              .imagensGerais
              .filter(image => !(/PERGUNTA\d+\.png$/.test(image.nomeArquivo)))
              .map((image, index) => (
              <Card
                key={index}
                onPress={() => { setImageView({ uri: image.data64, description: image.nomeArquivo }) }}
                style={{
                  ...styles.cardBase,
                  backgroundColor: theme.colors.backgroundCard,
                  marginBottom: isMobile ? 12 : 24,
                  padding: isMobile ? 4 : 8
                }}
              >
                <Card.Content style={styles.cardContent}>
                  <Box style={styles.imageContainer}>
                    <img style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      objectFit: 'contain'
                    }} src={image.data64} alt={image.nomeArquivo} />
                  </Box>
                  <Box style={styles.additionalTextBox}>
                    <Text style={styles.additionalText}>{image.nomeArquivo}</Text>
                  </Box>
                </Card.Content>
              </Card>
            ))}
          </CardRow>
        </>
      }
    </>
  )
}
