import React from 'react'
import { useRecoilValue } from 'recoil'
import { Box, Text, CardDetails, CardRow } from '@/presentation/components'
import { serviceOrderDetailState } from '@/presentation/pages/ServiceOrderDetail/components'
import { styles } from '../../styles'

export const OsInfo: React.FC = () => {
  const serviceOrderDetail = useRecoilValue(serviceOrderDetailState)

  return (
  <>
    <Text style={styles.pageInfo}>Informações da OS</Text>
    <CardRow>
      <CardDetails maxWidth={'100%'}>
        <Box style={styles.titleBox}>
          <Text style={styles.bold}>Observações de Abertura</Text>
        </Box>
        <Text>{serviceOrderDetail.executar}</Text>
      </CardDetails>
      <CardDetails maxWidth={'100%'}>
        <Box style={styles.titleBox}>
          <Text style={styles.bold}>Observações de Fechamento</Text>
        </Box>
        <Text>{serviceOrderDetail.obsFechamento}</Text>
      </CardDetails>
    </CardRow>
  </>
  )
}
