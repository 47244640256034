import { AddTravelAlertOsResponsible, AddTravelAlertOsVehicle } from '@/domain/usecases'
import { DateAdapter } from '@/data/protocols'

export class TravelAlertBuilder {
  constructor (private readonly dateAdapter: DateAdapter) {}

  private observation = ''

  private addLine (line: string): TravelAlertBuilder {
    this.observation += line
    this.observation += '\r\n'
    return this
  }

  setDates (
    dataSaida: string,
    horaMinutoSaida: string,
    dataRetorno: string,
    horaMinutoRetorno: string
  ): TravelAlertBuilder {
    const [horaSaida, minutoSaida] = horaMinutoSaida.split(':')
    const [horaRetorno, minutoRetorno] = horaMinutoRetorno.split(':')

    const dateStart = this.dateAdapter.parse(dataSaida)
    dateStart.setHours(parseInt(horaSaida))
    dateStart.setMinutes(parseInt(minutoSaida))

    const dateEnd = this.dateAdapter.parse(dataRetorno)
    dateEnd.setHours(parseInt(horaRetorno))
    dateEnd.setMinutes(parseInt(minutoRetorno))

    const dataSaidaFormatada = this.dateAdapter.format(dateStart, 'DD/MM/YYYY HH:mm')
    const dataRetornoFormatada = this.dateAdapter.format(dateEnd, 'DD/MM/YYYY HH:mm')

    return this.addLine(`Aviso de Viagem de ${dataSaidaFormatada} á ${dataRetornoFormatada}`)
  }

  requester (requester: string): TravelAlertBuilder {
    return this.addLine(`Responsável pelo Aviso: ${requester}`)
  }

  emailRequester (email: string): TravelAlertBuilder {
    return this.addLine(`E-mail do responsável: ${email}`)
  }

  phone1Requester (phone: string): TravelAlertBuilder {
    return this.addLine(`Telefone 1: ${phone}`)
  }

  phone2Requester (phone: string): TravelAlertBuilder {
    if (phone) {
      return this.addLine(`Telefone 2: ${phone}`)
    }
    return this
  }

  addResponsible (responsible: AddTravelAlertOsResponsible): TravelAlertBuilder {
    this.addLine(`Nome: ${responsible.nome}`)
    responsible.endereco && this.addLine(`Endereço do responsável: ${responsible.endereco}`)
    responsible.telefone1 && this.addLine(`Telefone1 do responsável: ${responsible.telefone1}`)
    responsible.telefone2 && this.addLine(`Telefone2 do responsável: ${responsible.telefone2}`)
    responsible.observacao && this.addLine(`Observações do responsável: ${responsible.observacao}`)
    return this
  }

  addResponsibles (chavesSomenteComCentralMonitoramento: boolean, responsibles: AddTravelAlertOsResponsible[]): TravelAlertBuilder {
    if (chavesSomenteComCentralMonitoramento) {
      return this
        .addLine('As chaves da residência ficarão na Central de Monitoramento!')
        .addLine('')
    } else {
      if (responsibles.length) {
        this.addLine(`Responsáve${responsibles.length > 1 ? 'is' : 'l'} das Chaves da residência:`)
        responsibles.forEach(resp => { this.addResponsible(resp) })
        return this.addLine('')
      } else {
        return this
          .addLine('As chaves da residência ficarão na Central de Monitoramento!')
          .addLine('')
      }
    }
  }

  addAuthorized (possuiAutorizados: boolean, responsibles: AddTravelAlertOsResponsible[]): TravelAlertBuilder {
    if (!possuiAutorizados) {
      return this
        .addLine('Ninguém está autorizado a entrar na Residência!')
        .addLine('')
    } else {
      if (responsibles.length) {
        this.addLine(`Autorizado${responsibles.length > 1 ? 's' : ''} a entrar na Residência:`)
        responsibles.forEach(resp => { this.addResponsible(resp) })
        return this.addLine('')
      } else {
        return this
          .addLine('Ninguém está autorizado a entrar na Residência!')
          .addLine('')
      }
    }
  }

  addVehicle (vehicle: AddTravelAlertOsVehicle): TravelAlertBuilder {
    this.addLine(`Veículo Placa: ${vehicle.placa}`)

    if (vehicle.possuiResponsaveis && vehicle.responsaveis.length) {
      this.addLine('Estará com a chave do veículo o seguinte responsável: ')
      vehicle.responsaveis.forEach(resp => { this.addResponsible(resp) })
    } else {
      this
        .addLine('Somente a central de monitoramento estará com as chaves do veículo.')
    }

    if (vehicle.possuiAutorizados && vehicle.autorizados.length) {
      this.addLine('Estará autorizado a utilizar o veículo o seguinte responsável: ')
      vehicle.autorizados.forEach(authorized => { this.addResponsible(authorized) })
    } else {
      this
        .addLine('Ninguém está autorizado a utilizar o veículo.')
    }

    return this
  }

  addVehicles (possuiVeiculosCuidadosCentral: boolean, vehicles: AddTravelAlertOsVehicle[]): TravelAlertBuilder {
    if (!possuiVeiculosCuidadosCentral || !vehicles.length) {
      return this
        .addLine('Não ficarão veículos sobre cuidados da empresa.')
        .addLine('')
    } else {
      this
        .addLine(`Ficará sobre cuidados da empresa o${vehicles.length > 1 ? 's' : ''} seguinte${vehicles.length > 1 ? 's' : ''} veículo${vehicles.length > 1 ? 's' : ''}: `)
        .addLine('')

      vehicles.forEach(vehicle => { this.addVehicle(vehicle) })

      return this.addLine('')
    }
  }

  addRecommendations (recommendations: string): TravelAlertBuilder {
    if (recommendations) {
      return this
        .addLine('')
        .addLine(`Algumas Recomendações: ${recommendations}`)
    }
    return this
  }

  build (): string {
    return this.observation
  }
}
