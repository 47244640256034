import React from 'react'
import { TextInput as Input, TextInputProps } from '@react-native-material/core'
import { useTheme } from '@/presentation/hooks'
import { styles } from './styles'

export const TextInput: React.FC<TextInputProps> = (props: TextInputProps) => {
  const theme = useTheme()

  return (
    <Input
      color={theme.colors.primary}
      inputStyle={{ ...styles.input }}
      {...props}
    />
  )
}
