import { atom } from 'recoil'

export const isOpenDeleteProspectOptionState = atom({
  key: 'isOpenDeleteProspectOptionState',
  default: false
})

export type deleteProspectOptionProps = {
  unidade: number
  interesse: string
}

export const deleteProspectOptionState = atom<deleteProspectOptionProps>({
  key: 'deleteProspectOptionState',
  default: {
    unidade: 0,
    interesse: ''
  }
})
