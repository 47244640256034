export default {
  /*
    Em **desenvolvimento** preencher API_URL com endereço do integra exemplo http://localhost:5002.
    Antes de gerar o build o valor da API_URL deve ser removido e mantido em branco para que o build
    ocorra de forma correta.
  */
  API_URL: __DEV__ ? 'http://localhost:5002' : '',
  API_PROJETOS: 'https://f002.backblazeb2.com/file/ftp-projetos-insidesistemas/',
  API_LOGIN_URL: 'https://login.insidesistemas.com.br',
  API_LOGIN_IDENTIFICADOR_APP: '226f8ce0-d48e-4af7-8f54-2d02cfdbe587',
  CLARITY_KEY: 'hr379143ky',
  METRICS_LOG_IN_OUT: 'https://servicewebmetrics.azurewebsites.net',
  FIREBASE_PUBLIC_KEY_VAPID: 'BBV7B9l_nBEBZbR4KvX67cD9eVUcELSOsbd3OOa5MuLgHWFFedo7cLHqiDSyDPK9G-7yTyLu79KV77o_0M7wJ7M',

  FIREBASE_API_KEY: 'QUl6YVN5RGRDQ041RXFrNVhZSWZHUHM2OWRjMVJMTmVibmdEWGE0',
  FIREBASE_AUTH_DOMAIN: 'cG9ydGFsLWRvLWNsaWVudGUtZWE3MjEuZmlyZWJhc2VhcHAuY29t',
  FIREBASE_PROJECT_ID: 'cG9ydGFsLWRvLWNsaWVudGUtZWE3MjE=',
  FIREBASE_STORAGE_BUCKET: 'cG9ydGFsLWRvLWNsaWVudGUtZWE3MjEuYXBwc3BvdC5jb20=',
  FIREBASE_MESSAGING_SENDER_ID: 'OTY5MTUxMzUwODI1',
  FIREBASE_APP_ID: 'MTo5NjkxNTEzNTA4MjU6d2ViOjMzOGNkOWQ0N2ZmMWZmNTExNmY0Mzg=',
  FIREBASE_VAPID_KEY: 'Qkp3aldVcVpmS1lXWDZITElKS2dsUzFzTEllYUVaYTZJQVlTVUVOTVd6OW43TGgxVkZ4aC13Q2tja2NOdmkyZ0FoUUxsSXNwNmJfaE5mTGNMenZXaDlv'
}
