import React, { useCallback, useState } from 'react'
import { GestureResponderEvent } from 'react-native'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import { ScrollView } from 'react-native-gesture-handler'
import { NFModel } from '@/domain/models'
import { OpenDanfe, OpenDanfeParams } from '@/domain/usecases'
import { DateAdapter } from '@/data/protocols'
import { useAuth, useErrorHandler, useFormat, useNotify } from '@/presentation/hooks'
import { theme } from '@/presentation/styles'
import { MenuActions, Menu, Box, HStack, IconButton, Text, CardList, PageHeader, CardBase } from '@/presentation/components'
import { nfState, isLoadingNfState, isOpenNfsFiltersState, FiltersChipPanel } from '@/presentation/pages/NotaFiscal/components'
import { styles } from '../../styles'

type NFProductProps = {
  openDanfe: OpenDanfe
  dateAdapter: DateAdapter
}

export const NFProduct: React.FC<NFProductProps> = ({ openDanfe, dateAdapter }) => {
  const notify = useNotify()
  const { formatCurrency } = useFormat()
  const { getClient } = useAuth()
  const handleError = useErrorHandler()
  const [currentIndex, setCurrentIndex] = useState(-1)
  const [nfs] = useRecoilState(nfState)
  const [isLoading, setLoading] = useRecoilState(isLoadingNfState)
  const setOpenFilters = useSetRecoilState(isOpenNfsFiltersState)

  const client = getClient()

  const openMenu = (index: number): void => setCurrentIndex(index)
  const closeMenu = (): void => setCurrentIndex(-1)

  const handleOpenDanfe = useCallback((event: GestureResponderEvent, nf: NFModel) => {
    event.preventDefault()
    setLoading(true)

    const openDanfeParams: OpenDanfeParams = {
      cliCodigo: client.codCliente,
      notaFiscal: nf
    }

    openDanfe
      .open(openDanfeParams)
      .then(() => notify.show(`A DANFE da NFº ${nf.notaNumero}/${nf.notaSerie} foi aberta em uma nova aba`))
      .catch(handleError)
      .finally(() => setLoading(false))
  }, [])

  const handleOnFilter = useCallback((): void => {
    setOpenFilters(true)
  }, [])

  return (
    <ScrollView style={styles.scroll}>
      <Box style={styles.content}>
        <PageHeader
          icon={<MaterialCommunityIcons size={24} color={theme.colors.grey} name='file-document-outline' />}
          text='Notas Fiscais de Produto'
          openFilters={handleOnFilter}
        />
        <FiltersChipPanel dateAdapter={dateAdapter} />
        <CardList
          isEmpty={!nfs?.length}
          isLoading={isLoading}
          textLoading='Buscando Notas Fiscais Produtos'
        >
          {nfs?.map((nf, index) => (
            <CardBase key={nf.notaNumero}>
              <Box style={styles.title}>
                <Text style={styles.bold}>{nf.notaNumero.toString().padStart(6, '0')}/{nf.notaSerie}</Text>
                {!!nf.notaProcNFE &&
                  <MenuActions
                    key={`menu-${nf.notaNumero}`}
                    visible={currentIndex >= 0 && currentIndex === index}
                    onDismiss={closeMenu}
                    anchor={<IconButton
                      onPress={() => openMenu(index)}
                      style={{ width: 32, height: 32 }}
                      testID='notas-produto-opcoes'
                      icon={(props: any) => <MaterialCommunityIcons name="dots-vertical" {...props} />}></IconButton>
                    }>
                      <Menu.Item
                        onPress={(event) => handleOpenDanfe(event, nf)}
                        title="Visualizar Danfe"
                        testID='notas-produto-visualizar-danfe'
                        leadingIcon={props => <MaterialCommunityIcons name="link" {...props} />}
                      />
                  </MenuActions>
                }
              </Box>
              <Text><span style={styles.bold}>Valor: </span>{formatCurrency(nf.notaValor)}</Text>
              <Text><span style={styles.bold}>Emissão: </span>{new Date(nf.notaData).toLocaleDateString('pt-BR')}</Text>
                {!nf.notaProcNFE &&
                  <HStack items='center' spacing={8}>
                    <MaterialIcons color={theme.colors.greyLight} style={{ fontSize: 18 }} name="info-outline" />
                    <Text style={{ color: theme.colors.greyLight, fontSize: 12 }}>
                      Não existe xml para a NF
                    </Text>
                  </HStack>
                }
            </CardBase>
          ))}
        </CardList>
      </Box>
    </ScrollView>
  )
}
