import { atom } from 'recoil'
import { TrackDetailsModel } from '@/domain/models'

export const isLoadingTrackDetailsState = atom({
  key: 'isLoadingTrackDetailsState',
  default: false
})

export const trackDetailsState = atom({
  key: 'trackDetailsState',
  default: {} as TrackDetailsModel
})

export const isOpenTrackDetailsState = atom({
  key: 'isOpenTrackDetailsState',
  default: false
})
