import React from 'react'
import { Events } from '@/presentation/pages/Events'
import {
  makePrintEvents, makeGetRemotyUsersFactory, makeGetRemotyEventsFactory,
  makeGetRemotySectorsFactory, makeGetRemotyContactsFactory, makeGetMoniEventsFactory, makeGetMoniSectorsFactory, makeGetMoniUsersFactory, makeGetSowillSectorsFactory, makeGetSowillKeysFactory, makeGetSowillUsersFactory, makeGetSowillIdentifierFactory, makeGetSigmaEventsFactory, makeGetSowillEventsFactory, makeGetSigmaCloudSectorsFactory, makeGetSigmaCloudUsersFactory, makeGetSigmaCloudKeysFactory, makeGetSigmaDeskSectorsFactory, makeGetSigmaDeskContactsFactory
} from '@/main/factories/usecases'
import { makeDateAdapterFactory } from '@/main/adapters'
import { NavigationPagesProps, TabBottomNavigator } from '@/presentation/components'

export const makeEventsFactory = ({ navigation }: NavigationPagesProps): JSX.Element => {
  const printEvents = makePrintEvents()
  const dateAdapter = makeDateAdapterFactory()
  const getRemotyEvents = makeGetRemotyEventsFactory()
  const getRemotySectors = makeGetRemotySectorsFactory()
  const getRemotyUsers = makeGetRemotyUsersFactory()
  const getRemotyContacts = makeGetRemotyContactsFactory()
  const getMoniEvents = makeGetMoniEventsFactory()
  const getMoniUsers = makeGetMoniUsersFactory()
  const getMoniSectors = makeGetMoniSectorsFactory()
  const getSowillEvents = makeGetSowillEventsFactory()
  const getSowillSectors = makeGetSowillSectorsFactory()
  const getSowillUsers = makeGetSowillUsersFactory()
  const getSowillKeys = makeGetSowillKeysFactory()
  const getSowillIdentifier = makeGetSowillIdentifierFactory()
  const getSigmaEvents = makeGetSigmaEventsFactory()
  const getSigmaCloudSectors = makeGetSigmaCloudSectorsFactory()
  const getSigmaCloudUsers = makeGetSigmaCloudUsersFactory()
  const getSigmaCloudKeys = makeGetSigmaCloudKeysFactory()
  const getSigmaDeskSectors = makeGetSigmaDeskSectorsFactory()
  const getSigmaDeskContacts = makeGetSigmaDeskContactsFactory()

  return (
    <>

      <Events
        printEvents={printEvents}
        dateAdapter={dateAdapter}
        getRemotyEvents={getRemotyEvents}
        getRemotySectors={getRemotySectors}
        getRemotyUsers={getRemotyUsers}
        getRemotyContacts={getRemotyContacts}
        getMoniEvents={getMoniEvents}
        getMoniSectors={getMoniSectors}
        getMoniUsers={getMoniUsers}
        getSowillEvents={getSowillEvents}
        getSowillSectors={getSowillSectors}
        getSowillUsers={getSowillUsers}
        getSowillKeys={getSowillKeys}
        getSowillIdentifier={getSowillIdentifier}
        getSigmaEvents={getSigmaEvents}
        getSigmaCloudSectors={getSigmaCloudSectors}
        getSigmaCloudUsers={getSigmaCloudUsers}
        getSigmaCloudKeys={getSigmaCloudKeys}
        getSigmaDeskSectors={getSigmaDeskSectors}
        getSigmaDeskContacts={getSigmaDeskContacts}
      />
      <TabBottomNavigator navigation={navigation} />
    </>

  )
}
