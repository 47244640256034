import React from 'react'
import { StyleSheet } from 'react-native'
import { Box, Modal } from '@/presentation/components'
import { useMediaQueries } from '@/presentation/hooks'

type ModalBaseProps = {
  visible: boolean
  children: React.ReactNode | React.ReactNode[]
  onClose?: () => void
  maxWidth?: number
  maxHeight?: number | string
}

export const MARGIN_MODALS = 30

export const ModalBase: React.FC<ModalBaseProps> = ({
  visible,
  onClose,
  children,
  maxHeight,
  maxWidth
}) => {
  const { isMobile, width, height } = useMediaQueries()

  return (
    <Modal
      isVisible={visible}
      onSwipeComplete={onClose}
      onBackdropPress={onClose}
      swipeDirection="right"
      animationIn='fadeInUpBig'
      animationOut='fadeOutRightBig'
      style={{ alignItems: 'center' }}
    >
      <Box style={{
        ...styles.modalContainer,
        maxWidth: isMobile ? width - MARGIN_MODALS : (maxWidth || 600),
        maxHeight: isMobile ? height - MARGIN_MODALS : (maxHeight || 600)
      }}>
        {children}
      </Box >
    </Modal>
  )
}

const styles = StyleSheet.create({
  modalContainer: {
    width: '100%',
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 32,
    alignItems: 'stretch'
  }
})
