import { AcceptDigitalModel } from '@/domain/models'
import { GetDigitalAccepts } from '@/domain/usecases/accept-digital'
import { RemoteGetAcceptDigital } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'

export const makeGetAcceptDigital = (): GetDigitalAccepts => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<AcceptDigitalModel[] | string>()

  return new RemoteGetAcceptDigital(url, httpClient)
}
