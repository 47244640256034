import { LocalStorageAdapter } from '@/infra/cache'
import { HttpRequest, HttpResponse, HttpClient } from '@/data/protocols'
import axios, { AxiosResponse } from 'axios'

export class AxiosHttpClient<R = any> implements HttpClient<R> {
  constructor (private readonly localStorageAdapter: LocalStorageAdapter) {}

  async request (data: HttpRequest): Promise<HttpResponse> {
    let axiosResponse: AxiosResponse
    const token = (this.localStorageAdapter.get('access_token'))?.access_token as string

    try {
      axiosResponse = await axios.request({
        url: data.url,
        method: data.method,
        data: data.body,
        headers: {
          ...data.headers,
          ...(token && { Authorization: `bearer ${token}` })
        },
        params: data.params
      })
      return {
        statusCode: axiosResponse.status,
        body: axiosResponse.data
      }
    } catch (error: any) {
      const statusCode = ['ERR_NETWORK', 'ECONNRESET', 'ERR_CONNECTION_RESET'].includes(error.code) ? 404 : error?.response?.status
      return {
        statusCode: statusCode || 400,
        body: error?.response?.data || error.message
      }
    }
  }
}
