import { GenericEventModel } from '@/domain/models'

export type GetMoniEventsParams = {
  unidade: string
  empresaMoni: string
  codCliente: number
  dataInicial: string
}

export interface GetMoniEvents {
  getEvents: (params: GetMoniEventsParams) => Promise<GenericEventModel[]>
}

export namespace GetMoniEvents {
  export const RN = {
    DEFAULT_DAYS_LATER: 15,
    DEFAULT_DAYS_AGO: 15
  }
}
