import React from 'react'
import { GestureResponderEvent } from 'react-native'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { ScrollView } from 'react-native-gesture-handler'
import { ProspectRegistration, ProspectRegistrationParams } from '@/domain/usecases'
import { ModalBase, Box, Button, Text, TextInput, VStack, InputField, HStack, Select } from '@/presentation/components'
import { useErrorHandler, useFormat, useNotify, useTheme } from '@/presentation/hooks'
import { isLoadingProspectRegistrationState, isOpenInterestAreaState, isOpenProspectRegistrationState, prospectRegistrationState, selectedInterestAreaState } from '@/presentation/pages/Login/components'
import { styles } from './styles'
import { prospectConfigsState, prospectInterestAreaState } from '@/presentation/pages/ProspectSettings/components'

type ProspectRegistrationProps = {
  prospectRegistration: ProspectRegistration
}

export const ModalProspectRegistration: React.FC<ProspectRegistrationProps> = ({ prospectRegistration }) => {
  const theme = useTheme()
  const notify = useNotify()
  const { validateCPF, validateCNPJ, formatDocument } = useFormat()
  const [state, setState] = useRecoilState(prospectRegistrationState)
  const [isOpen, setOpen] = useRecoilState(isOpenProspectRegistrationState)
  const [selectedInteresetArea, setSelectedInterestArea] = useRecoilState(selectedInterestAreaState)
  const [isOpenAreaIntereset, setOpenAreaInterest] = useRecoilState(isOpenInterestAreaState)
  const [isLoading, setLoading] = useRecoilState(isLoadingProspectRegistrationState)
  const prospectConfigs = useRecoilValue(prospectConfigsState)
  const interesetArea = useRecoilValue(prospectInterestAreaState)
  const reset = useResetRecoilState(prospectRegistrationState)
  const resetInteresetArea = useResetRecoilState(selectedInterestAreaState)
  const errorHandler = useErrorHandler()

  const handleChangeInput = (name: keyof ProspectRegistrationParams, value: string | number): void => {
    const formattedValue = name === 'cpf' ? formatDocument(value as string) : value

    setState(currentValues => ({ ...currentValues, [name]: formattedValue }))

    if (name === 'cpf') {
      validateDocument(formattedValue as string)
    }
  }

  const handleRegister = (event: GestureResponderEvent): void => {
    event.preventDefault()
    setLoading(true)

    if (!isValidForm()) {
      setLoading(false)
      return
    }

    const params: ProspectRegistrationParams = {
      ...state,
      email: selectedInteresetArea.email,
      codUnidade: selectedInteresetArea.unidade
    }

    prospectRegistration
      .register(params)
      .then(() => {
        notify.show('Cadastro realizado com sucesso, aguarde o contato da empresa para continuar o acesso!')
        resetInteresetArea()
        reset()
      })
      .catch(error => notify.show(error.message, { variant: 'error' }))
      .finally(() => setLoading(false))

    handleCloseModal()
  }

  const isValidForm = (): boolean => {
    if (!selectedInteresetArea.email || !state.nome || (state.fone && state.fone.length < 9)) {
      return false
    }
    return true
  }

  const validateDocument = (value: string): void => {
    if (!(value.length <= 14 ? validateCPF(value) : validateCNPJ(value))) {
      errorHandler(new Error('Por favor, informe um CPF/CNPJ válido'))
    }
  }

  const handleCloseModal = (): void => setOpen(false)

  return (
    <ModalBase visible={isOpen} onClose={handleCloseModal}>
      <ScrollView style={{ maxHeight: '80vh' }}>

        <Box style={styles.container}>
          <Text variant='subtitle1' style={{ fontWeight: '500' }}>Seja bem-vindo</Text>

          <Select.Trigger
            label={prospectConfigs?.descricao || 'Selecione a área de interesse*'}
            description={selectedInteresetArea.descricao}
            onOpen={() => setOpenAreaInterest(true)}
          />
          <TextInput
            label='Qual seu nome completo?*'
            onChangeText={text => handleChangeInput('nome', text)}
            value={state.nome}
          />

          <TextInput
            label='Qual seu melhor telefone?'
            onChangeText={text => handleChangeInput('fone', text)}
            value={state.fone}
          />

          <TextInput
            label='Qual seu melhor e-mail?'
            onChangeText={text => handleChangeInput('emailUrl', text)}
            value={state.emailUrl}
          />

          <TextInput
            label='Informe seu CPF/CNPJ'
            onChangeText={text => handleChangeInput('cpf', text)}
            value={state.cpf}
            maxLength={18}
          />

          <VStack>
            <Text variant='subtitle1' style={{ fontWeight: '500', marginBottom: 12 }}>Endereço</Text>

            <TextInput
              label='CEP'
              onChangeText={text => handleChangeInput('cep', text)}
              value={state.cep}
              maxLength={9}
            />

            <TextInput
              label='Endereço'
              onChangeText={text => handleChangeInput('endereco', text)}
              value={state.endereco}
            />
            <InputField>
              <TextInput
                label='Número'
                onChangeText={text => handleChangeInput('numeroEndereco', text)}
                value={state.numeroEndereco}
                style={{ flex: 1 }}
              />

              <TextInput
                label='Complemento'
                onChangeText={text => handleChangeInput('complemento', text)}
                value={state.complemento}
                style={{ flex: 1 }}
              />
            </InputField>
            <InputField>
              <TextInput
                label='Cidade'
                onChangeText={text => handleChangeInput('cidade', text)}
                value={state.cidade}
                style={{ flex: 1 }}
              />
              <TextInput
                label='Estado'
                onChangeText={text => handleChangeInput('estado', text)}
                value={state.estado}
                style={{ flex: 1 }}
              />
            </InputField>
            <TextInput
              label='Bairro'
              onChangeText={text => handleChangeInput('bairro', text)}
              value={state.bairro}
            />
          </VStack>

        </Box>
      </ScrollView>
      <HStack justify='end' pt={12}>
        <Button
          color={theme.colors.shadow}
          variant="text"
          title="Cancelar"
          onPress={handleCloseModal}
          disabled={isLoading}
        />
        <Button
          variant="text"
          title="Enviar"
          onPress={handleRegister}
          color={theme.colors.success}
          tintColor={theme.colors.white}
          disabled={isLoading || !isValidForm()}
        />
      </HStack>
      <Select.Modal
        isOpen={isOpenAreaIntereset}
        onClose={() => setOpenAreaInterest(false)}
        onSelect={(id, data) => {
          handleChangeInput('codDEInteresse', id)
          setSelectedInterestArea(data)
        }}
        title={prospectConfigs?.descricao || 'Selecione'}
        items={interesetArea?.map(config => ({
          id: config.interesse.codInterno,
          descricao: config.descricao,
          isSelected: selectedInteresetArea.unidade === config.unidade &&
            selectedInteresetArea.interesse.codInterno === config.interesse.codInterno,
          data: config
        }))}
      />
    </ModalBase>
  )
}
