import { atom } from 'recoil'

export const statusAcceptState = atom({
  key: 'selectStatusAcceptState',
  default: [
    { _id: 'Ambos', value: 'Ambos' },
    { _id: 'Autorizados', value: 'Autorizados' },
    { _id: 'Pendentes', value: 'Pendentes' }
  ]
})
