import { CreditCardModel, MenuAccessPermissionsConfigs } from '@/domain/models'

export type ClientModel = {
  codCliente: number
  nomeCliente: string
  fantasia: string
  empresa: number
  unidade: number
  numContrato: string
  terminoContrato: string
  valorMensal: number
  telefone1: string
  telefone2: string
  enviaListaChave: boolean
  modalidade: ClientModality
  enderecoGeral: ClientAddress
  enderecoAlternativo: ClientAddress[]
  novaVersao: boolean
  primeiroFaturamento: string
  faturalote: boolean
  email: string
  tipoConexao: ClientMonitoringType
  centraisMonitoramento: CentralMonitoring[]
  portal: MenuAccessPermissionsConfigs
  cpfCnpj: string
  empresaMonitoramento: number
  listaCartoes: CreditCardModel[]
  diaVencimento: string
  servicosContratadosPortal: ContractedServices[]
  visualizaDetalhesContrato: boolean
  recebeNotificacoes: boolean
  codTecnico: number
  osSemTecnico: boolean
}

export type ContractedServices = {
  cliSerContCodigo: number
  cliSerContDescricao: string
  serCodigo: number
  cliSerContQuantidade: number
  cliSerContValor: number
  cliContNumeroContrato: string
  dataCadastro: string
}

export type ClientContractModel = {
  primeiroFaturamento: string
  terminoContrato: string
  faturaLote: boolean
  visualizaDetalhesContrato: boolean
  valorMonitoramento: number
  modalidade: ClientModality
  diaVencimento: number
  numContrato: string
  servicosContratados: ContractedServices[]
}

export enum ClientMonitoringType {
  SIGMA_DESK = 0,
  MONI = 1,
  SIGMA_CLOUD = 2,
  REMOTY = 3,
  SO_WILL = 5
}

export type ClientAddress = {
  bairro: string
  cep: string
  cidade: string
  codInterno: number
  complemento: string
  endereco: string
  estado: string
  identificacao: 'Principal' | string
  numero: string
  padrao: boolean
}

export enum ClientModality {
  Venda = 'V',
  Locacao = 'L',
  Rastreamento = 'R',
}

export type CentralMonitoring = {
  idEquipment: number
  nmEquipment: string
  nmAccount: string
  flActive: boolean
  flMonitoring: boolean
  flMaintenance: boolean
}
