import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    position: 'absolute',
    zIndex: 1,
    top: 0,
    right: 0,
    overflow: 'hidden',
    display: 'flex'
  },
  modalContainer: {
    backgroundColor: 'white',
    borderRadius: 10
  },
  hidden: {
    display: 'none'
  },
  content: {
    width: '100%',
    backgroundColor: '#FFF'
  },
  title: {
    margin: 12,
    fontSize: 20,
    fontWeight: '500'
  },
  subtitle: {
    marginLeft: 4,
    marginTop: 16,
    marginBottom: 8,
    fontSize: 16,
    fontWeight: '500'
  },
  filters: {
    flex: 1,
    margin: 16
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 12,
    width: 36,
    height: 36
  },
  actionsContent: {
    marginVertical: 8,
    marginRight: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  btnAction: {
    backgroundColor: 'none',
    textTransform: 'uppercase',
    fontSize: 12
  },
  inputDatePicker: {
    width: '100%',
    marginTop: 8
  }
})
