import React from 'react'
import { useRecoilState } from 'recoil'
import { PortalLogout } from '@/domain/usecases'
import { useAuth, useTheme } from '@/presentation/hooks'
import { Box, Button, ModalBase, Text, isOpenLogoutState } from '@/presentation/components'
import { styles } from './styles'
import { version } from '../../../../package.json'

type LogoutProps = {
  portalLogout: PortalLogout
}

export const Logout: React.FC<LogoutProps> = ({
  portalLogout
}) => {
  const theme = useTheme()
  const { logout, getCurrentClient } = useAuth()
  const [isOpen, setOpen] = useRecoilState(isOpenLogoutState)

  const handleLogout = (): void => {
    const codClient = getCurrentClient()
    logout()

    if (codClient?.toString().endsWith('@insidesistemas.com.br')) {
      portalLogout
        .logout({ cliente: codClient?.toString().replace('@insidesistemas.com.br', ''), version })
        .catch(console.error)
    } else {
      portalLogout
        .logout({ cliente: codClient, version })
        .catch(console.error)
    }
    setOpen(false)
  }

  const handleClose = (): void => setOpen(false)

  return (
    <ModalBase visible={isOpen} onClose={handleClose}>
      <Box style={styles.container}>
        <Text variant="subtitle1">Tem certeza que deseja sair?</Text>

        <Box style={styles.buttonArea}>
          <Button
            color={theme.colors.shadow}
            tintColor={theme.colors.white}
            variant="text"
            title="CANCELAR"
            onPress={handleClose}
          />
          <Button
            title="SAIR"
            onPress={handleLogout}
            tintColor={theme.colors.white}
            color={theme.colors.success}
          />
        </Box>
      </Box>
    </ModalBase>
  )
}
