import React, { useState } from 'react'
import { ScrollView } from 'react-native'
import { TextInput } from 'react-native-paper'
import { useRecoilState } from 'recoil'
import { BoletoInsideConfigs, FinancialConfigs } from '@/domain/models'
import { useThemeClient } from '@/presentation/hooks'
import { Box, CardSwitch, Loading } from '@/presentation/components'
import { PaymentAvailableToUpdate, HidePaymentMethods, financialConfigsState, ActionsButtons } from '@/presentation/pages/Settings/components'
import { styles } from './styles'

export const FinancialSettings: React.FC = () => {
  const { getThemeClient } = useThemeClient()
  const [financialConfigs, setFinancialConfig] = useRecoilState(financialConfigsState)
  const [initialFinancialConfigs] = useState(financialConfigs)

  const themeClient = getThemeClient()

  const handleChange = (param: keyof (FinancialConfigs & BoletoInsideConfigs), value: boolean | string): void => {
    setFinancialConfig(currentState => ({ ...currentState, [param]: value }))
  }

  const hasChanged = (): boolean => {
    return JSON.stringify(initialFinancialConfigs) !== JSON.stringify(financialConfigs)
  }

  return (
    <Box style={styles.container}>
      <ScrollView >
        {!financialConfigs
          ? <Loading />
          : <>
            {financialConfigs.permitePagamentoCartao &&
              <>
                <CardSwitch
                text='Validar dados do cartão?'
                boletoInside={true}
                value={financialConfigs.validaCartaoCredito}
                handleSwitch={() => handleChange('validaCartaoCredito', !financialConfigs.validaCartaoCredito)}
                />
                <CardSwitch
                  text='Realizar atualização automática nos dados de faturamento?'
                  boletoInside={true}
                  value={financialConfigs.atualizaAutomaticoCartao}
                  handleSwitch={() => handleChange('atualizaAutomaticoCartao', !financialConfigs.atualizaAutomaticoCartao)}
                />
                <CardSwitch
                  text='Visualizar faturas de cartão de crédito vencidas?'
                  boletoInside={true}
                  value={financialConfigs.visualizaFaturaCartaoVencida}
                  handleSwitch={() => handleChange('visualizaFaturaCartaoVencida', !financialConfigs.visualizaFaturaCartaoVencida)}
                />
              </>
            }
            <CardSwitch
              text='Exibir somente boletos com entrada confirmada?'
              value={financialConfigs.bolEntradaConfirmada}
              handleSwitch={() => handleChange('bolEntradaConfirmada', !financialConfigs.bolEntradaConfirmada)}
            />
            <CardSwitch
              text='Exibir opção de impressão de boletos originais?'
              value={financialConfigs.bolExibeOriginal}
              handleSwitch={() => handleChange('bolExibeOriginal', !financialConfigs.bolExibeOriginal)}
            />

            <CardSwitch
              text='Atualizar boletos?'
              value={financialConfigs.bolAtualizaBoletos}
              handleSwitch={() => handleChange('bolAtualizaBoletos', !financialConfigs.bolAtualizaBoletos)}
            >
              <PaymentAvailableToUpdate />
            </CardSwitch>

            <CardSwitch
              text='Selecionar Formas de Pagamento para serem ocultas?'
              value={financialConfigs.bolOcultaFormasPgto}
              handleSwitch={() => handleChange('bolOcultaFormasPgto', !financialConfigs.bolOcultaFormasPgto)}
            >
              <HidePaymentMethods />
            </CardSwitch>

            <CardSwitch
              text='Enviar informação de alteração de cartão de credito por e-mail'
              value={financialConfigs.exibeAlteracaoCartao}
              handleSwitch={() => handleChange('exibeAlteracaoCartao', !financialConfigs.exibeAlteracaoCartao)}
            >
              <TextInput
                mode="flat"
                activeUnderlineColor={themeClient.mainColor}
                label="E-mail destino"
                underlineColorAndroid="transparent"
                underlineColor="transparent"
                value={financialConfigs.emailAlteracaoCartao}
                onChangeText={text => handleChange('emailAlteracaoCartao', text)}
                maxLength={300}
              />
            </CardSwitch>

            {/* // TODO: adicionar configuração de email de pagamento com cartão */}
            {financialConfigs.permitePagamentoCartao &&
              <CardSwitch
                text='Permite que o cliente pague faturas utilizando cartão de crédito (Necessário Integração PJBank)?'
                value={financialConfigs.pagamentoCartaoAtivo}
                handleSwitch={() => handleChange('pagamentoCartaoAtivo', !financialConfigs.pagamentoCartaoAtivo)}
              />
            }
          </>
        }
      </ScrollView >
      {hasChanged() && <ActionsButtons />}
    </Box>
  )
}
