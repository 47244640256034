import { GenerateBankSlip, GenerateBankSlipParams, GenerateInsideBankSlip, GenerateInsideBankSlipParams } from '@/domain/usecases'
import { DateAdapter } from '@/data/protocols'

export class RemoteGenerateInsideBankSlip implements GenerateInsideBankSlip {
  constructor (
    private readonly dateAdapter: DateAdapter,
    private readonly generateBankSlip: GenerateBankSlip
  ) {}

  async generate (params: GenerateInsideBankSlipParams): Promise<string> {
    const { registrado, linkBoleto, dtVencimento, ...originalGenerateParams } = params
    const generateParams: GenerateBankSlipParams = originalGenerateParams

    if (registrado) {
      const dates = {
        startDate: this.dateAdapter.today(),
        endDate: this.dateAdapter.parse(dtVencimento)
      }

      if (this.dateAdapter.diffInDays(dates) <= GenerateInsideBankSlip.RN.TOTAL_DAYS_BEFORE_NEW_UPDATE) {
        generateParams.original = true
      }
    } else if (linkBoleto) {
      generateParams.original = true
    }

    return await this.generateBankSlip.generate(generateParams)
  }
}
