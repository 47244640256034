import React from 'react'
import { StyleSheet } from 'react-native'
import { useRecoilState } from 'recoil'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useCreditCard, useThemeClient } from '@/presentation/hooks'
import { Box, HStack, TextInput, VStack } from '@/presentation/components'
import { creditCardState } from '@/presentation/pages/Cartao/components'

type AddNewCreditCardProps = {
  isLoading: boolean
  isEditable: boolean
}

export const AddNewCreditCard: React.FC<AddNewCreditCardProps> = ({
  isEditable,
  isLoading
}) => {
  const { maskCreditCardValidity, maskCreditCardNumber, getCreditCardType, isValidExpiration } = useCreditCard()
  const { getThemeClient } = useThemeClient()
  const [creditCard, setCreditCard] = useRecoilState(creditCardState)

  const themeClient = getThemeClient()

  const handleChange = (name: string, value: string): void => {
    setCreditCard(currentState => ({
      ...currentState,
      [name]: value,
      type: getCreditCardType(currentState.numero)
    }))
  }

  return (
    <Box style={styles.formArea}>
      <VStack spacing={12}>
        <TextInput
          color={themeClient.mainColor}
          editable={!isLoading && isEditable}
          value={creditCard?.nome}
          label="Nome no cartão"
          testID='cartao-nome'
          onChangeText={(text) => handleChange('nome', text)}
        />
        <TextInput
          color={themeClient.mainColor}
          editable={!isLoading && isEditable}
          value={creditCard?.numero}
          keyboardType='numeric'
          label="Número do cartão"
          testID='carrtao-numero'
          maxLength={19}
          onChangeText={(text) => handleChange('numero', maskCreditCardNumber(text.replace(/[^0-9]/g, '')))}
        />
        <HStack spacing={16}>
          <TextInput
            color={themeClient.mainColor}
            maxLength={5}
            editable={!isLoading && isEditable}
            inputStyle={{ width: '100%' }}
            style={{ flex: 2 }}
            value={creditCard?.expiracao}
            label="Vencimento(MM/AA)"
            testID='cartao-vencimento'
            placeholder='MM/AA'
            keyboardType='numeric'
            helperText={(!isValidExpiration(creditCard?.expiracao) && creditCard?.expiracao?.length === 5)
              ? 'Data de expiração inválida'
              : '' }
            trailing={(props) => <MaterialCommunityIcons name="calendar-range" {...props} />}
            onChangeText={(text) => { handleChange('expiracao', maskCreditCardValidity(text)) }}
          />
          <TextInput
            color={themeClient.mainColor}
            editable={!isLoading && isEditable}
            inputStyle={{ width: '100%' }}
            style={{ flex: 1 }}
            value={creditCard?.ccv}
            testID='cartao-cvc'
            maxLength={4}
            keyboardType='numeric'
            label="CVC"
            onChangeText={(text) => handleChange('ccv', text.replace(/[^0-9]/g, ''))}
          />
        </HStack>
      </VStack>
    </Box>
  )
}

const styles = StyleSheet.create({
  formArea: {
    width: '100%',
    maxWidth: 428,
    padding: 16
  }
})
