import { layoutStyles } from '@/presentation/styles/globals'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'stretch'
  },
  content: {
    ...layoutStyles.pageContent
  },
  scroll: {
    paddingBottom: 60
  },

  headerContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },

  footerContainer: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
    height: '100%',
    padding: 16
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },

  selectorContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    borderRadius: 4
  },
  selectorContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-between',
    borderRadius: 4
  },
  selectBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  titleBox: {
    textAlign: 'center',
    fontWeight: '500',
    fontSize: 14
  },
  optionBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  optionSelectorChip: {
    border: '1px solid rgba(0, 0, 0, 0.40)',
    borderRadius: 4,
    width: '18.5rem',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: 8,
    fontWeight: '500',
    fontSize: 14
  },

  optionIcon: {
    color: 'rgba(0, 0, 0, 0.70)',
    marginTop: 12,
    marginBottom: 12,
    marginLeft: 16,
    marginRight: 16
  },

  optionText: {
    color: 'rgba(0, 0, 0, 0.70)',
    fontWeight: '500',
    fontSize: 14,
    overflow: 'hidden',
    whiteSpace: 'noWrap',
    textOverflow: 'ellipsis',
    marginRight: 16
  },

  tooltip: {
    marginTop: 32,
    fontWeight: '500',
    color: 'rgba(0, 0, 0, 0.32)',
    fontSize: 14
  }
})
