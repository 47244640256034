import { layoutStyles } from '@/presentation/styles/globals'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'stretch',
    position: 'relative'
  },
  content: {
    ...layoutStyles.pageContent
  },
  scroll: {
    paddingBottom: 60
  },
  btnContainer: {
    position: 'absolute',
    bottom: 16,
    right: 32
  }
})
