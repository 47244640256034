import React from 'react'
import { NavigationPagesProps, TabBottomNavigator } from '@/presentation/components'
import { makeOpenStatementOfDebts } from '@/main/factories/usecases'
import { StatementOfDebts } from '@/presentation/pages/StatementOfDebts'
import { makeDateAdapterFactory } from '@/main/adapters'

export const makeStatementOfDebtsFactory = ({ navigation }: NavigationPagesProps): JSX.Element => {
  return (
    <>
      <StatementOfDebts
        openStatementOfDebts={makeOpenStatementOfDebts()}
        dateAdapter={makeDateAdapterFactory()}
      />
      <TabBottomNavigator navigation={navigation} />
    </>
  )
}
