import { ValidatePasswordRecoveryResult, ValidatePasswordRecovery } from '@/domain/usecases'
import { RemoteValidatePasswordRecovery } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'

export const makeValidatePasswordRecovery = (): ValidatePasswordRecovery => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<ValidatePasswordRecoveryResult | string>()

  return new RemoteValidatePasswordRecovery(url, httpClient)
}
