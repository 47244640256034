import * as React from 'react'
import { useRecoilValue } from 'recoil'
import { ScrollView } from 'react-native-gesture-handler'
import { DrawerContentScrollView, DrawerItemList } from '@react-navigation/drawer'
import { useTheme, useThemeClient } from '@/presentation/hooks'
import { Box, SocialMidias, socialMediaState } from '@/presentation/components'

export function CustomDrawer (props: any): JSX.Element {
  const theme = useTheme()
  const { getThemeClient } = useThemeClient()
  const socialMidias = useRecoilValue(socialMediaState)

  const themeClient = getThemeClient()

  return (
    <Box style={{ flex: 1, padding: 0 }}>
      <DrawerContentScrollView
        {...props}
        contentContainerStyle={{ backgroundColor: theme.colors.backgroundMenu, flex: 1, paddingTop: 30 }}>
        <ScrollView>
          <Box style={{ padding: 0, flex: 1 }}>
            <DrawerItemList {...props} />
          </Box>
        </ScrollView>
        <Box pv={8}>
          <SocialMidias
            socialMidias={socialMidias}
            size={18}
            color={themeClient.mainColor}
          />
        </Box>
      </DrawerContentScrollView>
    </Box>
  )
}
