import React from 'react'
import { Divider } from 'react-native-paper'
import { Box, HStack, VStack, Text } from '@react-native-material/core'
import { GenericContactModel } from '@/domain/models'
import { useMediaQueries } from '@/presentation/hooks'
import { theme } from '@/presentation/styles'
import { styles } from './styles'

type CardContactProps = {
  contact: GenericContactModel
}

export const CardContact: React.FC<CardContactProps> = ({ contact }: CardContactProps) => {
  const { isMobile } = useMediaQueries()

  const containerStyles = {
    ...styles.container,
    ...(!isMobile && {
      flex: 1,
      maxWidth: 600
    }),
    backgroundColor: theme.colors.white,
    color: theme.colors.greyLighten
  }

  const textTitleStyles = {
    ...styles.contentText,
    color: theme.colors.texts
  }

  return (
    <Box style={containerStyles}>
      <HStack items='center' justify='between' spacing={8} mb={8} mt={8}>

        <Text variant='subtitle1' style={textTitleStyles} testID='contato-usuario'>
          <span style={styles.contentTitle}>
            Usuário:
          </span>
          {contact?.nomeUsuario}
        </Text>
        {contact?.idUsuario &&
          <Text variant='subtitle1' style={textTitleStyles} testID='contato-id-usuario'>
            <span style={styles.contentTitle}>
              Id:
            </span>
            {contact?.idUsuario}
          </Text>}

      </HStack>

      <VStack spacing={8} style={{ flex: 1 }}>
        <Divider style={styles.divider} />
        <>
          {contact.idDispositivo &&
            <Text variant='subtitle1' style={textTitleStyles} testID='contato-id-dispositivo'>
              <span style={styles.contentTitle}>
                Id:
              </span>
              {contact.idDispositivo}
            </Text>}
          {contact?.nomeDispositivo &&
            <Text variant='subtitle1' style={textTitleStyles} testID='contato-nome-dispositivo'>
              <span style={styles.contentTitle}>
                Dispositivo:
              </span>
              {contact.nomeDispositivo}
            </Text>}
          {contact.fone1 &&
            <Text variant='subtitle1' style={textTitleStyles} testID='contato-fone'>
              <span style={styles.contentTitle}>
                Fone:
              </span>
              {contact.fone1}
            </Text>}
          {contact.fone2 &&
            <Text variant='subtitle1' style={textTitleStyles}>
              <span style={styles.contentTitle}>
                Fone 2:
              </span>
              {contact?.fone2}
            </Text>}
        </>
      </VStack >
    </Box>
  )
}
