import { StyleSheet } from 'react-native'
export { default as OSIcon } from '@expo/vector-icons/MaterialIcons'
export { default as MarkerIcon } from '@expo/vector-icons/Foundation'

export const styles = StyleSheet.create({
  attendancesContainer: {
    flexDirection: 'row',
    marginTop: 16,
    maxHeight: '30vh'
  },
  title: {
    fontWeight: '700',
    marginBottom: 8
  },
  titleAttendances: {
    fontWeight: '700',
    marginBottom: 8,
    fontSize: 12
  },
  attendancesText: {
    fontSize: 12
  }
})
