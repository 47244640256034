import { TicketModel } from '@/domain/models'
import { atom } from 'recoil'

export const isLoadingFinancialState = atom({
  key: 'isLoadingFinancialState',
  default: true
})

export const isLoadingOpenBankSlip = atom({
  key: 'isLoadingOpenBankSlip',
  default: false
})

export const ticketsState = atom({
  key: 'ticketsState',
  default: [] as TicketModel[]
})
