import { GetMoniSectors } from '@/domain/usecases'
import { RemoteGetMoniSectors } from '@/data/usecases/events'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'

export const makeGetMoniSectorsFactory = (): GetMoniSectors => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<string>()

  return new RemoteGetMoniSectors(url, httpClient)
}
