import React from 'react'
import { ViewStyle, TextStyle } from 'react-native'
import { Chip as ChipPaper, Text } from 'react-native-paper'
import { useTheme } from '@/presentation/hooks'
import { styles } from './styles'

type ChipProps = {
  text: string
  color?: string
  variant?: 'filled' | 'outlined'
  containerStyle?: ViewStyle
  textStyle?: TextStyle
  borderRadius?: number
  onClick?: () => void
}

export const Chip: React.FC<ChipProps> = ({
  text,
  color,
  containerStyle,
  textStyle,
  onClick,
  variant = 'filled',
  borderRadius
}) => {
  const theme = useTheme()

  const containerStyles = {
    filled: {
      ...styles.container,
      borderRadius: borderRadius ?? 50,
      backgroundColor: color ?? theme.colors.primary,
      color: 'transparent',
      borderColor: 'transparent',
      ...containerStyle
    },
    outlined: {
      ...styles.container,
      borderRadius: borderRadius ?? 50,
      backgroundColor: 'transparent',
      color: color ?? theme.colors.error,
      borderColor: color ?? theme.colors.error,
      ...containerStyle
    }
  }[variant]

  const textStyles = {
    ...styles.title,
    color: variant === 'filled' ? theme.colors.white : color ?? theme.colors.error,
    ...textStyle
  }

  return (
    <ChipPaper onPress={onClick} style={containerStyles} testID='chip-text'>
      <Text style={textStyles} variant='labelLarge'>
        {text}
      </Text>
    </ChipPaper>
  )
}
