import { GetSowillKeys } from '@/domain/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import { RemoteGetSowillKeys } from '@/data/usecases/events'
import { SowillKeyResult } from '@/domain/models'
import env from '@/main/config/env'

export const makeGetSowillKeysFactory = (): GetSowillKeys => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<SowillKeyResult>()

  return new RemoteGetSowillKeys(url, httpClient)
}
