import React from 'react'
import { useSetRecoilState } from 'recoil'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { HStack, IconButton } from '@/presentation/components'
import { useThemeClient } from '@/presentation/hooks'
import { isPrintingServiceOrderDetailState } from '@/presentation/pages/ServiceOrderDetail/components'

export const PrintButton: React.FC = () => {
  const { getThemeClient } = useThemeClient()
  const setIsPrinting = useSetRecoilState(isPrintingServiceOrderDetailState)
  const themeClient = getThemeClient()

  const handleFormatPrint = async (): Promise<void> => {
    const style = document.createElement('style')
    style.innerHTML = `
      @media print {
        body {
          zoom: 60%;
        }
      }
    `

    document.head.appendChild(style)
    setIsPrinting(true)
    window.addEventListener('afterprint', () => setIsPrinting(false))
  }

  const handlePrint = async (): Promise<void> => {
    await handleFormatPrint()
    window.print()
  }

  return <HStack justify='end'>
    <IconButton
      color={themeClient.mainColor}
      onPress={handlePrint}
      icon={<MaterialCommunityIcons name='printer' size={28} color={themeClient.mainColor} />}
    />
  </HStack>
}
