import { TrackResult } from '@/domain/models'
import { GetTrackList } from '@/domain/usecases'
import { RemoteGetTrackList } from '@/data/usecases/track'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'

export const makeGetTrackListFactory = (): GetTrackList => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<TrackResult[]>()

  return new RemoteGetTrackList(url, httpClient)
}
