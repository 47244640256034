import React from 'react'
import { HStack, Text, VStack } from '@react-native-material/core'
import { MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons'
import { useTheme } from '@/presentation/hooks'

type SecurityPasswordProps = {
  minLenght: boolean
  hasLowerCase: boolean
  hasUpperCase: boolean
  hasNumber: boolean
  hasSpecialChar: boolean
}

export const SecurityPassword: React.FC<SecurityPasswordProps> = (props) => {
  const theme = useTheme()

  return (
    <VStack spacing={4}>
      <HStack items='center' spacing={8}>
        <MaterialCommunityIcons color={theme.colors.greyDark} name="shield-key" size={24} />
        <Text variant="body1" color={theme.colors.greyDark} style={{ fontWeight: '500'}}>
          Sua senha deve
        </Text>
      </HStack>

      <HStack mt={12} spacing={8}>
        <MaterialIcons
          name={props.minLenght ? 'check-circle' : 'radio-button-unchecked'}
          color={props.minLenght ? theme.colors.success : theme.colors.greyDark}
          size={18}
        />
        <Text
          variant="body2"
          color={theme.colors.greyDark}
          style={{ fontWeight: props.minLenght ? '600' : '300'}}
        >
          ter no mínimo 8 caracteres
        </Text>
      </HStack>

      <HStack spacing={8}>
        <MaterialIcons
          name={props.hasNumber ? 'check-circle' : 'radio-button-unchecked'}
          color={props.hasNumber ? theme.colors.success : theme.colors.greyDark}
          size={18}
        />
        <Text
          variant="body2"
          color={theme.colors.greyDark}
          style={{ fontWeight: props.hasNumber ? '600' : '300'}}
        >
          ter no mínimo 1 numero
        </Text>
      </HStack>

      <HStack spacing={8}>
        <MaterialIcons
          name={props.hasUpperCase ? 'check-circle' : 'radio-button-unchecked'}
          color={props.hasUpperCase ? theme.colors.success : theme.colors.greyDark}
          size={18}
        />
        <Text
          variant="body2"
          color={theme.colors.greyDark}
          style={{ fontWeight: props.hasUpperCase ? '600' : '300'}}
        >
          ter no mínimo 1 letra maiúscula
        </Text>
      </HStack>
      
      <HStack spacing={8}>
        <MaterialIcons
          name={props.hasLowerCase ? 'check-circle' : 'radio-button-unchecked'}
          color={props.hasLowerCase ? theme.colors.success : theme.colors.greyDark}
          size={18}
        />
        <Text
          variant="body2"
          color={theme.colors.greyDark}
          style={{ fontWeight: props.hasLowerCase ? '600' : '300'}}
        >
          ter no mínimo 1 letra minúscula
        </Text>
      </HStack>
      
      <HStack spacing={8}>
        <MaterialIcons
          name={props.hasSpecialChar ? 'check-circle' : 'radio-button-unchecked'}
          color={props.hasSpecialChar ? theme.colors.success : theme.colors.greyDark}
          size={18}
        />
        <Text
          variant="body2"
          color={theme.colors.greyDark}
          style={{ fontWeight: props.hasSpecialChar ? '600' : '300'}}
        >
          ter no mínimo 1 caractere especial
        </Text>
      </HStack>          
    </VStack>
  )
}
