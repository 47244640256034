import React from 'react'
import { NotaFiscal } from '@/presentation/pages/NotaFiscal'
import { NavigationPagesProps, TabBottomNavigator } from '@/presentation/components'
import { makeGetRemoteGetNFs, makeOpenDanfe, makeOpenNfse, makeOpenLeaseReceipt } from '@/main/factories/usecases'
import { makeDateAdapterFactory } from '@/main/adapters'

export const makeNFFactory = ({ navigation }: NavigationPagesProps): JSX.Element => {
  return (
    <>
      <NotaFiscal
        getNFs={makeGetRemoteGetNFs()}
        openDanfe={makeOpenDanfe()}
        openNfse={makeOpenNfse()}
        openLeaseReceipt={makeOpenLeaseReceipt()}
        dateAdapter={makeDateAdapterFactory()}
      />
      <TabBottomNavigator navigation={navigation} />
    </>
  )
}
