import * as React from 'react'
import { StyleSheet, View, ViewStyle } from 'react-native'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { Appbar } from 'react-native-paper'
import { NavigationRoute } from '@/main/config/router-navigation'
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import { MessagePayload, onMessage } from '@firebase/messaging'
import { messaging } from '@/main/config/firebaseConfig'
import { useAuth, useErrorHandler, useMediaQueries, useNotify, useTheme, useThemeClient } from '@/presentation/hooks'
import { Text, LogoTheme, NavigationPagesProps, isOpenUserMenuState, IconButton, Badge, notificationsState, isOpenNotificationsModalState, localStorageState, HStack } from '@/presentation/components'
import { ReceivedNotificationModel } from '@/domain/models'
import { GetClientNotifications, SaveNotificationToken } from '@/domain/usecases'

export const APP_BAR_HEIGTH = 64
export const PADDING_STATUS_BAR_IOS = 24

type HeaderProps = {
  saveNotificationToken: SaveNotificationToken
  getNotifications: GetClientNotifications
} & NavigationPagesProps

export const Header: React.FC<HeaderProps> = ({
  navigation,
  saveNotificationToken,
  getNotifications
}) => {
  const { isAdmin, getCurrentClient } = useAuth()
  const currentClient = getCurrentClient()
  const theme = useTheme()
  const { getThemeClient } = useThemeClient()
  const { openInPWA, openInIOS, isMobile } = useMediaQueries()
  const notify = useNotify()
  const handleError = useErrorHandler()
  const localStorageAdapter = useRecoilValue(localStorageState)
  const [notifications, setNotifications] = useRecoilState(notificationsState)
  const setOpenUserMenu = useSetRecoilState(isOpenUserMenuState)
  const setOpenNotificationsMenu = useSetRecoilState(isOpenNotificationsModalState)

  const themeClient = getThemeClient()

  const contentTitleStyle: ViewStyle = {
    alignItems: 'flex-start'
  }

  if (!isMobile || isAdmin()) {
    contentTitleStyle.alignItems = 'center'
  }

  React.useEffect(() => {
    if (!isAdmin()) {
      window.addEventListener('focus', handleSearchNotifications)
      handleSaveNotificationToken()

      return () => {
        window.removeEventListener('focus', handleSearchNotifications)
      }
    }
  }, [])

  const handleSaveNotificationToken = (): void => {
    const tokenCliente = localStorageAdapter.get(SaveNotificationToken.RN.LOCAL_STORAGE_TOKEN)

    if (tokenCliente) {
      saveNotificationToken
        .save({
          codigoCliente: parseInt(currentClient),
          tokenDispositivo: tokenCliente
        })
        .then(() => { handleSearchNotifications() })
        .catch(handleError)
    } else {
      handleSearchNotifications()
    }
  }

  const handleSearchNotifications = (): void => {
    getNotifications
      .get({ codigo: parseInt(currentClient) })
      .then(setNotifications)
      .catch(handleError)
  }

  const onMessageListener = async (): Promise<void> => {
    await new Promise(() => {
      messaging().then((messagingInstance) => {
        if (messagingInstance) {
          onMessage(messagingInstance, (payload: MessagePayload) => {
            const notification = payload.data as ReceivedNotificationModel

            if (notification) {
              handleSearchNotifications()

              notify.info(notification.body)
            }
          })
        }
      }).catch(error => {
        console.error('Error getting messaging instance:', error)
      })
    })
  }

  void onMessageListener()


  const ContentTitle: React.FC = () => (
    <Appbar.Content
      title={
        <View style={styles.logoArea}>
          <LogoTheme height={40} />
          <Text
            onPress={() => navigation.navigate(NavigationRoute.Home)}
            style={{
              ...styles.title,
              ...(isMobile && { fontSize: 16 })
            }}
            variant='h6'
          >
            Portal do Cliente
          </Text>
        </View>
      }
      style={ contentTitleStyle }
    />
  )

  return (
    <Appbar.Header style={{
      ...styles.appBar,
      backgroundColor: themeClient.mainColor,
      ...(openInPWA && openInIOS && { paddingTop: PADDING_STATUS_BAR_IOS })
    }}>
      <Appbar.Action
        icon={(props: any) => <MaterialCommunityIcons name='menu' {...props}/>}
        onPress={() => navigation.toggleDrawer()}
        isLeading
        testID="menu-geral"
        color={themeClient.menuColor}
      />
      <ContentTitle />
      <HStack>
        {!isAdmin() && <>
          <IconButton
            color={themeClient.menuColor}
            onPress={() => { setOpenNotificationsMenu(true) }}
            icon={props => <MaterialIcons name='notifications' {...props} />}
          />
          {notifications && <Badge
            label={notifications.length}
            labelStyle={{ fontSize: 8, color: theme.colors.white }}
            style={{ marginLeft: -24, marginTop: 16, paddingRight: 1, paddingLeft: 1, minWidth: 16, height: 16 }}
            color={theme.colors.error}
          />}
        </>
        }
        <IconButton
          style={{ marginLeft: 8 }}
          color={themeClient.menuColor}
          onPress={() => {
            setOpenUserMenu(true)
          }}
          testID="menu-usuario"
          icon={props => <MaterialIcons name='account-circle' {...props} />}
        />
      </HStack>
    </Appbar.Header>
  )
}

const styles = StyleSheet.create({
  appBar: {
    minHeight: APP_BAR_HEIGTH
  },
  logoArea: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },
  title: {
    color: '#FFF',
    flex: 1
  }
})
