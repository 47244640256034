import React from 'react'
import { useSetRecoilState } from 'recoil'
import { TouchableOpacity } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { TrackModel } from '@/domain/models'
import { GetTrackDetails, GetTrackDetailsParams } from '@/domain/usecases'
import { useAuth, useErrorHandler, useTheme } from '@/presentation/hooks'
import { Box, Text, HStack, VStack } from '@/presentation/components'
import { isLoadingTrackDetailsState, isOpenTrackDetailsState, trackDetailsState } from '@/presentation/pages/Track/components'
import { styles } from './styles'

type CardTrackProps = {
  track: TrackModel
  getTrackDetails: GetTrackDetails
}

export const CardTrack: React.FC<CardTrackProps> = ({ track, getTrackDetails }) => {
  const theme = useTheme()
  const { getClient } = useAuth()
  const client = getClient()
  const setLoading = useSetRecoilState(isLoadingTrackDetailsState)
  const setDetails = useSetRecoilState(trackDetailsState)
  const setOpenDetail = useSetRecoilState(isOpenTrackDetailsState)
  const handleError = useErrorHandler()

  const { codInterno, id, placa } = track

  const handleDetails = (codInterno: number): void => {
    const params: GetTrackDetailsParams = {
      codCliente: client.codCliente.toString(),
      codInterno: codInterno.toString()
    }

    setLoading(true)

    getTrackDetails
      .getDetails(params)
      .then(currentDetails => {
        setDetails(currentDetails)
        setOpenDetail(true)
      })
      .catch(handleError)
      .finally(() => setLoading(false))
  }

  return (
    <TouchableOpacity onPress={() => handleDetails(codInterno)}>
      <Box style={styles.container}>
        <HStack style={{ flex: 1 }} items='center' spacing={8} testID='rastreamento-card'>
          <MaterialCommunityIcons name="car-outline" size={24} color={theme.colors.grey} />
          <VStack>
            {placa &&
              <Text style={styles.title} variant='h3' testID='rastreamento-placa'>
                {placa}
              </Text>
            }
            {
              id &&
              <Text style={styles.textStatus} color={theme.colors.textsLight} testID='rastreamento-id'>
                {id}
              </Text>
            }
          </VStack>
        </HStack>
      </Box>
    </TouchableOpacity>
  )
}
