import { TicketModel } from '@/domain/models'
import { GetTickets, GetTicketsParams } from '@/domain/usecases'
import { AccessDeniedError, ForbiddenError, UnexpectedError, UnexpectedResultFormatError } from '@/domain/errors'
import { HttpClient, HttpRequest, HttpStatusCode } from '@/data/protocols'

export class RemoteGetTickets implements GetTickets {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<TicketModel[] | string>
  ) {}

  async get (params: GetTicketsParams): Promise<TicketModel[]> {
    const request: HttpRequest = {
      url: `${this.url}/api/lista-boletos`,
      method: 'get',
      params
    }

    const { statusCode, body } = await this.httpClient.request(request)

    switch (statusCode) {
      case HttpStatusCode.ok:
        if (!body) {
          throw new UnexpectedResultFormatError()
        }
        return body as TicketModel[]
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      case HttpStatusCode.forbidden: throw new ForbiddenError(body as string)
      default: throw new UnexpectedError()
    }
  }
}
