import React from 'react'
import { MaterialIcons } from '@expo/vector-icons'
import { NavigationRoute } from '@/main/config/router-navigation'
import { NotificationOnDBModel } from '@/domain/models'
import { Button, Card, Chip, HStack, IconButton, Stack, Text, VStack } from '@/presentation/components'
import { useMediaQueries, useTheme } from '@/presentation/hooks'
import { styles } from './styles'

type NotificationCardProps = {
  notification: NotificationOnDBModel
  onMarkAsRead: (notificationKey: number) => void
}

export const NotificationCard: React.FC<NotificationCardProps> = ({ notification, onMarkAsRead }) => {
  const theme = useTheme()
  const { isMobile } = useMediaQueries()

  const pageColor = {
    [NavigationRoute.Financial]: '#ed7809',
    [NavigationRoute.ServiceOrders]: '#14a10a',
    [NavigationRoute.SAC]: '#cf0707',
    [NavigationRoute.Invoices]: '#d6ba06',
    [NavigationRoute.Monitoring]: '#08a8dd',
    [NavigationRoute.CreditCard]: '#5209fb',
    [NavigationRoute.DigitalAccepts]: '#0a41ae',
    [NavigationRoute.TravelAlert]: '#9b0aae',
    [NavigationRoute.PhoneUtils]: '#05d3b7',
    [NavigationRoute.DocumentsUtils]: '#d7fb09',
    [NavigationRoute.Track]: '#0bfc03',
    [NavigationRoute.Contract]: '#fc4850'
  }[notification.screen]

  const screenName = {
    [NavigationRoute.Financial]: 'Financeiro',
    [NavigationRoute.ServiceOrders]: 'Ordens de Serviço',
    [NavigationRoute.SAC]: 'SAC',
    [NavigationRoute.Invoices]: 'Notas Fiscais',
    [NavigationRoute.Monitoring]: 'Monitoramento',
    [NavigationRoute.CreditCard]: 'Cartão',
    [NavigationRoute.DigitalAccepts]: 'Aceites Digitais',
    [NavigationRoute.TravelAlert]: 'Aviso de Viagem',
    [NavigationRoute.Track]: 'Rastreamento',
    [NavigationRoute.Contract]: 'Contrato'
  }[notification.screen]

  return (
    <Card
      style={{
        ...styles.cardBase,
        backgroundColor: theme.colors.backgroundNotification,
        shadowOpacity: 0,
        minWidth: 244,
        maxWidth: 600,
        marginBottom: isMobile ? 12 : 24,
        padding: isMobile ? 4 : 8
      }}
    >
      <Card.Content style={styles.cardContent}>
        <Stack direction={isMobile ? 'column' : 'row'} items={'center'} spacing={16}>
          <VStack style={{ flex: 1 }}>
            <Chip
              text={screenName ?? 'Notificação'}
              color={pageColor ? `${pageColor}CC` : theme.colors.grey}
              borderRadius={6}
            />
            <HStack items='center' spacing={4} pt={4}>
              <MaterialIcons name='schedule' style={{ color: theme.colors.greyLight }} />
              <Text variant="caption" color={theme.colors.greyLight}>{notification.dataEnvio}</Text>
            </HStack>
            <Text
              variant="subtitle1"
              style={{ marginTop: 6 }}
            >{notification.body}</Text>
          </VStack>
          {isMobile
            ? <Button
                leading={props => <MaterialIcons name='done' style={{ color: theme.colors.success }} {...props} />}
                title={'MARCAR LEITURA'}
                variant='text'
                color={theme.colors.success}
                onPress={() => { onMarkAsRead(notification.codInterno) }}
              />
            : <IconButton
              icon={props => <MaterialIcons name='done' style={{ color: theme.colors.success }} {...props} />}
              style={{ backgroundColor: theme.colors.successContainer }}
              onPress={() => { onMarkAsRead(notification.codInterno) }}
            />
          }
        </Stack>
      </Card.Content>
    </Card>
  )
}
