import React from 'react'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Text } from '@react-native-material/core'
import { useMediaQueries, useTheme } from '@/presentation/hooks'
import { Box } from '@/presentation/components'
import { styles } from './styles'

type NoResultMessageProps = {
  text?: string
}

export const NoResultMessage: React.FC<NoResultMessageProps> = ({
  text = 'Não há dados para exibir'
}) => {
  const theme = useTheme()
  const { isMobile } = useMediaQueries()

  return (
    <Box style={styles.container}>
      <Box style={styles.content}>
        <MaterialCommunityIcons name="information-off-outline" size={72} color="rgba(0, 0, 0, 0.32)" />
        <Text
          style={{
            ...styles.title,
            color: theme.colors.textsDark
          }}
          variant={isMobile ? 'h5' : 'h4'}
        >
          {text}
        </Text>
      </Box>
    </Box>
  )
}
