import { HttpClient, HttpStatusCode } from '@/data/protocols/http'
import { AccessDeniedError, ForbiddenError, UnexpectedError, UnexpectedResultFormatError } from '@/domain/errors'
import { CreditCardModel } from '@/domain/models'
import { SaveCreditCard } from '@/domain/usecases'

export class RemoteSaveCreditCard implements SaveCreditCard {
  constructor (
    private readonly url: string,
    private readonly httpCliente: HttpClient<string>
  ) {}

  async save (params: CreditCardModel): Promise<string> {
    const { expiracao, ...dataParams } = params

    const { statusCode, body } = await this.httpCliente.request({
      method: 'post',
      url: `${this.url}/api/portal/gravar-cartao-credito`,
      body: {
        ...dataParams,
        expiracao: this.formatExpiration(expiracao)
      }
    })

    switch (statusCode) {
      case HttpStatusCode.ok:
        if (!body) {
          throw new UnexpectedResultFormatError()
        }
        return body
      case HttpStatusCode.forbidden: throw new ForbiddenError(body)
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }

  formatExpiration (expiration: string): string {
    const currentYear = new Date()
      .getFullYear()
      .toString()
      .substring(0, 2)

    return `${expiration.split('/')[0]}/${currentYear}${expiration.split('/')[1]}`
  }
}
