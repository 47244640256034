import React from 'react'
import { Sac } from '@/presentation/pages/Sac'
import { NavigationPagesProps, TabBottomNavigator } from '@/presentation/components'
import { makeDateAdapterFactory } from '@/main/adapters'
import { makeCreateSac, makeGetSacList } from '@/main/factories/usecases'

export const makeSacFactory = ({ navigation }: NavigationPagesProps): JSX.Element => {
  return (
    <>
      <Sac
        dateAdapter={makeDateAdapterFactory()}
        getSacList={makeGetSacList()}
        createSac={makeCreateSac()}
      />
      <TabBottomNavigator navigation={navigation} />
    </>
  )
}
