import { HttpClient, HttpStatusCode } from '@/data/protocols/http'
import { AccessDeniedError, UnexpectedError } from '@/domain/errors'
import { GetSowillKeys, GetSowillKeysParams } from '@/domain/usecases'
import { SowillKeyResult } from '@/domain/models'

export class RemoteGetSowillKeys implements GetSowillKeys {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<SowillKeyResult>
  ) { }

  async getKeys (params: GetSowillKeysParams): Promise<SowillKeyResult> {
    const { body, statusCode } = await this.httpClient.request({
      url: `${this.url}/api/soWill/listaChavesCliente`,
      method: 'get',
      params
    })

    switch (statusCode) {
      case HttpStatusCode.ok: return body
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}
