import { HttpClient, HttpStatusCode } from '@/data/protocols/http'
import { AccessDeniedError, ResultWithoutRecordsError, UnexpectedError } from '@/domain/errors'
import { OpenLeaseReceipt, OpenLeaseReceiptParams } from '@/domain/usecases'

export class RemoteOpenLeaseReceipt implements OpenLeaseReceipt {
  constructor (
    private readonly url: string,
    private readonly httpGetClient: HttpClient<string>
  ) {}

  async open (params: OpenLeaseReceiptParams): Promise<void> {
    const { body, statusCode } = await this.httpGetClient.request({
      url: `${this.url}/api/portal/gerar-recibo-locacao`,
      method: 'get',
      params
    })

    switch (statusCode) {
      case HttpStatusCode.ok:
        if (!body) {
          throw new ResultWithoutRecordsError()
        }

        window.open(`/${OpenLeaseReceipt.RN.FOLDER_DEFAULT_LEASE_RECEIPT}/${body}`, '_blank')
        break
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}
