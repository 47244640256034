import { atom } from 'recoil'
import { EventsSettingsModel, GenericSigmaDeskConnectionModel } from '@/domain/models'

export const isLoadingState = atom({
  key: 'eventsSettingsLoadingState',
  default: true
})

export const initialEventsSettingsState = atom({
  key: 'initialEventsSettingsState',
  default: {} as EventsSettingsModel
})

export const eventsSettingsState = atom({
  key: 'eventsSettingsState',
  default: {} as EventsSettingsModel
})

export const sigmaDeskConnectionsState = atom({
  key: 'sigmaDeskConnectionsState',
  default: [] as unknown as GenericSigmaDeskConnectionModel[]
})

export const isOpenConnectionMenuState = atom({
  key: 'isOpenConnectionMenuState',
  default: false
})
