import React, { useCallback } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { ShowEvents } from '@/domain/usecases'
import { Box, Button, Text, ModalBase, Loading } from '@/presentation/components'
import { useErrorHandler, useNotify, useEventsSettings, useTheme } from '@/presentation/hooks'
import { eventsSettingsState, isLoadingSaveSettingsState, isOpenSaveSettingsState } from '@/presentation/pages/EventsSettings/components'
import { styles } from './styles'

type ModalOnSaveChangesProps = {
  showEvents: ShowEvents
}

export const ModalOnSaveChanges: React.FC<ModalOnSaveChangesProps> = ({ showEvents }) => {
  const theme = useTheme()
  const notify = useNotify()
  const { startConfig } = useEventsSettings()
  const eventsSettings = useRecoilValue(eventsSettingsState)
  const [isLoading, setLoading] = useRecoilState(isLoadingSaveSettingsState)
  const [isOpen, setOpen] = useRecoilState(isOpenSaveSettingsState)
  const handlerError = useErrorHandler()

  const handleSaveChanges = useCallback((): void => {
    setLoading(true)

    showEvents
      .show(eventsSettings.listaExibidos)
      .then(() => {
        notify.show('As configurações foram gravadas com sucesso')

        startConfig(eventsSettings)
        handleClose()
      })
      .catch(error => {
        handlerError(error)
        handleClose()
      })
      .finally(() => setLoading(false))
  }, [eventsSettings])

  const handleClose = (): void => setOpen(false)

  return (
    <ModalBase visible={isOpen} onClose={handleClose}>
      {isLoading
        ? <Loading text='Gravando configurações da unidade' />
        : <Box style={styles.container}>
          <Text variant="subtitle1">Deseja realmente gravar as alterações?</Text>

          <Box style={styles.buttonArea}>
            <Button
              color={theme.colors.shadow}
              variant="text"
              title="CANCELAR"
              onPress={handleClose}
            />
            <Button
              title="GRAVAR"
              color={theme.colors.success}
              tintColor={theme.colors.white}
              onPress={handleSaveChanges}
              disabled={isLoading}
            />
          </Box>
        </Box>
      }
    </ModalBase>
  )
}
