import React from 'react'
import { useRecoilState } from 'recoil'
import { Box, Button, Text, ModalBase } from '@/presentation/components'
import { useNotify, useProspectSettings, useTheme } from '@/presentation/hooks'
import { isOpenDiscardConfigState } from '@/presentation/pages/ProspectSettings/components'
import { styles } from './styles'

export const ModalOnDiscardChanges: React.FC = () => {
  const theme = useTheme()
  const notify = useNotify()
  const [isOpen, setOpen] = useRecoilState(isOpenDiscardConfigState)
  const { discardChanges } = useProspectSettings()

  const handleDiscardChanges = (): void => {
    discardChanges()
    setOpen(false)
    notify.show('As alterações foram descartadas')
  }

  const handleClose = (): void => setOpen(false)

  return (
    <ModalBase visible={isOpen} onClose={handleClose}>
      <Box style={styles.container}>
        <Text variant="subtitle1">Deseja realmente desfazer as alterações?</Text>

        <Box style={styles.buttonArea}>
          <Button
            color={theme.colors.shadow}
            variant="text"
            title="CANCELAR"
            onPress={handleClose}
          />
          <Button
            title="DESFAZER"
            onPress={handleDiscardChanges}
            color={theme.colors.success}
            tintColor={theme.colors.white}
          />
        </Box>
      </Box>
    </ModalBase>
  )
}
