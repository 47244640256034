import React from 'react'
import { IconButton } from 'react-native-paper'
import { Color, TextInputProps } from '@react-native-material/core'
import { Box, Button, ModalBase, Text, TextInput } from '@/presentation/components'
import { useTheme, useThemeClient } from '@/presentation/hooks'
import { styles } from './styles'

type SelectItem = {
  id: number
  descricao: string
  isSelected: boolean
  data?: any
}

export type SelectModalProps = {
  title: string
  items: SelectItem[]
  onSelect: (id: number, data: any) => void
  onClose: () => void
  itemsColor?: Color
  isOpen: boolean
}

export const Modal: React.FC<SelectModalProps> = ({
  title,
  isOpen,
  items,
  itemsColor,
  onSelect,
  onClose
}) => {
  const theme = useTheme()
  const { getThemeClient } = useThemeClient()

  const themeClient = getThemeClient()

  const handleSelect = (id: number, data: any): void => {
    onSelect(id, data)
    onClose()
  }

  return (
    <ModalBase visible={isOpen} onClose={onClose}>
      <Text variant="subtitle1" style={{ marginBottom: 18, fontWeight: '500' }}>{title}</Text>
      <Box style={{
        ...styles.container,
        overflow: 'scroll'
      }}>
        {items?.map((item) => (
          <Button
            key={item.id}
            variant={item.isSelected ? 'contained' : 'outlined'}
            title={item.descricao}
            color={itemsColor ?? themeClient.mainColor}
            tintColor={theme.colors.white}
            onPress={() => handleSelect(item.id, item.data)}
            style={styles.buttons}
          />
        ))}
      </Box>
      <Box style={styles.buttonArea}>
        <Button
          color={theme.colors.shadow}
          variant="text"
          title="CANCELAR"
          onPress={onClose}
        />
        </Box>
    </ModalBase>
  )
}

type SelectTriggerProps = {
  label: string
  onOpen: () => void
  description: string
} & TextInputProps

export const Trigger: React.FC<SelectTriggerProps> = ({
  label,
  onOpen,
  description,
  ...TextInputProps
}) => {
  const { getThemeClient } = useThemeClient()

  const themeClient = getThemeClient()

  return (
    <TextInput
      style={{ flex: 1, maxHeight: 56, width: '100%' }}
      label={label || ''}
      value={description}
      editable={false}
      color={themeClient.mainColor}
      trailing={props =>
        <IconButton icon='chevron-down' {...props} onPress={onOpen} />
      }
      {...TextInputProps}
    />
  )
}
