import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    marginBottom: 8,
    paddingRight: 8
  },
  buttonArea: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 8
  },
  buttons: {
    marginBottom: 10,
    padding: 3
  }
})
