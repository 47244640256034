import { GetSigmaDeskConnections } from '@/domain/usecases'
import { AccessDeniedError, UnexpectedError, UnexpectedResultFormatError } from '@/domain/errors'
import { HttpClient, HttpRequest, HttpStatusCode } from '@/data/protocols'
import { GenericSigmaDeskConnectionModel, SigmaDeskConnectionModel } from '@/domain/models'

export class RemoteGetSigmaDeskConnections implements GetSigmaDeskConnections {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<SigmaDeskConnectionModel[]>
  ) { }

  async getAll (): Promise<GenericSigmaDeskConnectionModel[]> {
    const request: HttpRequest = {
      url: `${this.url}/api/portal/sigmadesk/listar-conexoes`,
      method: 'get'
    }

    const { statusCode, body } = await this.httpClient.request(request)

    switch (statusCode) {
      case HttpStatusCode.ok:
        if (!body) {
          throw new UnexpectedResultFormatError()
        }
        return this.mapToGeneric(body)
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }

  mapToGeneric (connections: SigmaDeskConnectionModel[]): GenericSigmaDeskConnectionModel[] {
    return connections.map((connection: SigmaDeskConnectionModel) => {
      return {
        id: connection.codigo,
        descricao: `${connection.banco} - ${connection.idSigma}`,
        isSelected: false
      }
    })
  }
}
