import { layoutStyles } from '@/presentation/styles/globals'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'stretch'
  },
  content: {
    ...layoutStyles.pageContent
  },
  scroll: {
    paddingBottom: 60
  },

  footerContainer: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
    height: '100%',
    padding: 16
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
})
