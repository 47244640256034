import * as React from 'react'
import { Box, Text, Divider, HStack, Modal, Button, TAB_BOTTOM_CONTAINER_HEIGTH } from '@/presentation/components'
import { useMediaQueries, useTheme } from '@/presentation/hooks'
import { GestureResponderEvent } from 'react-native'
import { styles } from './styles'

type SideFilterDrawerProps = {
  open: boolean
  loading: boolean
  onSubmit: (event: GestureResponderEvent) => void
  onClose: () => void
  resetState: () => void
  children: JSX.Element | JSX.Element[]
}

export const MARGIN_MODALS = 30

export const Drawer: React.FC<SideFilterDrawerProps> = ({ open, onSubmit, onClose, children, loading, resetState }) => {
  const theme = useTheme()
  const { isMobile, appBarHeight, width } = useMediaQueries()

  const containerStyles = {
    ...styles.modalContainer,
    maxWidth: isMobile ? width - MARGIN_MODALS : 600,
    ...(isMobile
      ? { height: `calc(100vh - ${(appBarHeight + TAB_BOTTOM_CONTAINER_HEIGTH)}px)` }
      : { height: `calc(100vh - ${(appBarHeight + 18)}px)` }),
    ...styles.container,
    padding: isMobile ? 8 : 32,
    margin: isMobile ? 15 : 67
  }

  const contentStyles = {
    ...styles.content,
    minWidth: width < 600 ? 'fit-content' : 264
  }

  const titleStyles = {
    ...styles.title,
    color: theme.colors.texts
  }

  const btnLimparStyles = {
    ...styles.btnAction,
    color: theme.colors.textsLight
  }

  return (
    <Modal
      isVisible={open}
      onBackdropPress={onClose}
      animationIn='fadeInRightBig'
      animationOut='fadeOutRightBig'
      style={containerStyles}
    >
      <Box style={contentStyles}>
        <Box>
          <Text style={titleStyles}>Filtros</Text>
          <Divider color={theme.colors.greyDarken} />
        </Box>
        <Box style={styles.filters}>
            {children}
        </Box>
        <Box>
            <Divider color={theme.colors.greyDarken} />
            <HStack style={styles.actionsContent} spacing={8}>
              <Divider color={theme.colors.greyDarken} />
              <Button
                variant='text'
                title='Limpar Filtros'
                testID='sidefilter-limpar-filtros'
                titleStyle={btnLimparStyles}
                contentContainerStyle={btnLimparStyles}
                style={{ display: 'none' }}
                disabled={loading}
                onPress={resetState}
              />
              <Button
                variant='text'
                title='Fechar'
                testID='sidefilter-fechar-filtros'
                titleStyle={btnLimparStyles}
                contentContainerStyle={btnLimparStyles}
                disabled={loading}
                onPress={onClose}
              />
              <Button
                title='Filtrar'
                testID='sidefilter-filtrar'
                color={theme.colors.success}
                tintColor={theme.colors.white}
                disabled={loading}
                onPress={onSubmit}
              />
            </HStack>
          </Box>
      </Box>
    </Modal>
  )
}

type TitleProps = {
  title: string
}

export const Title: React.FC<TitleProps> = ({ title }) => {
  return (
    <Text style={styles.subtitle}>{title}</Text>
  )
}
