import React from 'react'
import { useRecoilValue } from 'recoil'
import { Box, CardDetails, CardRow, DataTable, Text, VStack } from '@/presentation/components'
import { serviceOrderDetailState } from '@/presentation/pages/ServiceOrderDetail/components'
import { useFormat, useMediaQueries } from '@/presentation/hooks'
import { styles } from '../../styles'

export const OsProducts: React.FC = () => {
  const serviceOrderDetail = useRecoilValue(serviceOrderDetailState)
  const { formatCurrency } = useFormat()
  const { isMobile } = useMediaQueries()

  const totalBrutoProdutos = serviceOrderDetail.listaProdutos?.reduce(
    (acc, produto) => acc + (produto.unitario * produto.quantidade),
    0
  )

  return (
    <>
      {!!serviceOrderDetail.listaProdutos?.length &&
        <>
          <Text style={styles.pageInfo}>Produtos da OS</Text>
          <CardRow>
            <CardDetails minWidth='100%'>
              <DataTable>
                <DataTable.Header>
                  <DataTable.Title>Quantidade</DataTable.Title>
                  {serviceOrderDetail?.exibeValoresProdutosServicos && <DataTable.Title>Unitário</DataTable.Title>}
                  {serviceOrderDetail?.exibeValoresProdutosServicos && <DataTable.Title>Total</DataTable.Title>}
                  <DataTable.Title>Cobrança</DataTable.Title>
                  {!isMobile && <DataTable.Title>Motivo</DataTable.Title>}
                  <DataTable.Title>Entregues</DataTable.Title>
                </DataTable.Header>

                {serviceOrderDetail.listaProdutos.map((produto, index) => (
                  <VStack key={index} mb={24}>
                    <DataTable.Row>
                      <DataTable.Cell>{produto.codProduto} - {produto.descricao.toUpperCase()}</DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row>
                      <DataTable.Cell>{produto.quantidade.toString().replace('.', ',')} {produto.unidadeMedida}</DataTable.Cell>
                      {serviceOrderDetail?.exibeValoresProdutosServicos && <DataTable.Cell>{formatCurrency(produto.unitario)}</DataTable.Cell>}
                      {serviceOrderDetail?.exibeValoresProdutosServicos && <DataTable.Cell>{formatCurrency(produto.unitario * produto.quantidade)}</DataTable.Cell>}
                      <DataTable.Cell>{produto.cobraCliente ? 'SIM' : 'NÃO'}</DataTable.Cell>
                      {!isMobile && <DataTable.Cell>{produto.motivo ? produto.motivo : '-'}</DataTable.Cell>}
                      <DataTable.Cell>{produto.entregue}</DataTable.Cell>
                    </DataTable.Row>
                  </VStack>
                ))}
              </DataTable>
              {serviceOrderDetail?.exibeValoresProdutosServicos &&
                <Box style={styles.totalizerTextBox}>
                    <Text><Text style={styles.bold}>Valor Bruto de Produtos: </Text>{formatCurrency(totalBrutoProdutos)}</Text>
                    <Text><Text style={styles.bold}>Desconto Aplicado na OS: </Text>{serviceOrderDetail.pDesconto}% - {formatCurrency(serviceOrderDetail.descontoProd)}</Text>
                    <Text><Text style={styles.bold}>Total Líquido de Produtos: </Text>{formatCurrency(serviceOrderDetail.totalProdutos)}</Text>
                </Box>
              }
            </CardDetails>
          </CardRow>
        </>
      }
    </>
  )
}
