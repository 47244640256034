import React from 'react'
import { useRecoilState } from 'recoil'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Box, HStack, IconButton, ModalBase, Text } from '@/presentation/components'
import { ImageProps, selectedOsImageViewState } from './atom'
import { StyleSheet } from 'react-native'

type OsImageViewProps = {
}

export const OsImageView: React.FC<OsImageViewProps> = () => {
  const [imageView, setImageView] = useRecoilState(selectedOsImageViewState)

  const handleClose = (): void => {
    setImageView(null as unknown as ImageProps)
  }

  return (
    <ModalBase maxWidth={1280} maxHeight='90vh' visible={!!imageView} onClose={handleClose}>
      <Box style={styles.container}>
        <HStack justify='between' items='center'>
          <Text variant="subtitle1" style={{ fontWeight: '500' }}>{imageView?.description}</Text>
          <IconButton
            icon={<MaterialCommunityIcons name="close" size={24} />}
            onPress={handleClose}
          />
        </HStack>

        <img
          src={imageView?.uri}
          alt={imageView?.description}
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain'
          }}
        />
      </Box>
    </ModalBase>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: '100vh',
    gap: 8,
    overflow: 'hidden'
  },
  image: {
    flex: 1
  }
})
