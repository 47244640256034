import { GenericEventModel } from '@/domain/models'

export type GetRemotyEventsParams = {
  idEquipamento: number
  empresaMonitoramento: number
  dtInicio?: string
  dtFim?: string
}

export interface GetRemotyEvents {
  getEvents: (params: GetRemotyEventsParams) => Promise<GenericEventModel[]>
}

export namespace GetRemotyEvents {
  export const RN = {
    DEFAULT_DAYS: 30
  }
}
