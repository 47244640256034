import { ServiceOrderModality, ServiceOrderPriority, ServiceOrderStatus } from '@/domain/models'
import { AddTravelAlertOs, AddTravelAlertOsParams } from '@/domain/usecases'
import { ForbiddenError, AccessDeniedError, UnexpectedError, UnexpectedResultFormatError } from '@/domain/errors'
import { HttpClient, HttpRequest, HttpStatusCode } from '@/data/protocols'
import { TravelAlertBuilder } from '@/data/builders'

export class RemoteAddTravelAlertOs implements AddTravelAlertOs {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<number | string>,
    private readonly travelAlertBuilder: TravelAlertBuilder
  ) {}

  async add (params: AddTravelAlertOsParams): Promise<number> {
    const observacoes = this.travelAlertBuilder
      .setDates(params.dataSaida, params.horaSaida, params.dataRetorno, params.horaRetorno)
      .requester(params.solicitante)
      .emailRequester(params.email)
      .phone1Requester(params.telefone1)
      .phone2Requester(params.telefone2)
      .addResponsibles(params.chavesSomenteComCentral, params.responsaveisChave)
      .addAuthorized(params.possuiAutorizados, params.autorizados)
      .addVehicles(params.possuiVeiculosCuidadosCentral, params.veiculos)
      .addRecommendations(params.recomendacoes)
      .build()

    const bodyParams = {
      cliente: params.cliente,
      empresa: params.empresa,
      modalidade: params.modalidade,
      solicitante: params.solicitante,
      status: ServiceOrderStatus.Aberta,
      tipo: ServiceOrderModality.ManutencaoCorretiva,
      prioridade: ServiceOrderPriority.Normal,
      avisoViagem: true,
      observacoes
    }

    const request: HttpRequest = {
      url: `${this.url}/api/os`,
      method: 'post',
      body: bodyParams
    }

    const { statusCode, body } = await this.httpClient.request(request)

    switch (statusCode) {
      case HttpStatusCode.ok:
        if (!body) {
          throw new UnexpectedResultFormatError()
        }
        return body as number
      case HttpStatusCode.forbidden: throw new ForbiddenError(body as string)
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}
