import { AcceptDigitalModel } from '@/domain/models'

export type GetDigitalAcceptsParams = {
  codCliente: number
  dtInicial: string
  dtFinal: string
  status: 'Pendentes' | 'Autorizados' | 'Ambos'
}

export interface GetDigitalAccepts {
  get: (params: GetDigitalAcceptsParams) => Promise<AcceptDigitalModel[]>
}

export namespace GetDigitalAccepts {
  export const RN = {
    DEFAULT_DAYS_LATER: 90,
    DEFAULT_DAYS_AGO: 120,
    DEFAULT_STATUS: 'Ambos' as 'Pendentes' | 'Autorizados' | 'Ambos'
  }
}
