import React from 'react'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useRecoilState } from 'recoil'
import { FormaPagamento } from '@/domain/models'
import { Text, Box, Loading } from '@/presentation/components'
import { useMediaQueries } from '@/presentation/hooks'
import { financialConfigsState } from '@/presentation/pages/Settings/components'
import { styles } from './styles'

export const HidePaymentMethods: React.FC = () => {
  const { isMobile } = useMediaQueries()
  const [financialConfigs, setFinancialConfigs] = useRecoilState(financialConfigsState)

  const handleChoosePaymentForm = (codFormaPagamento: string, hide: boolean): void => {
    let formasPagamento: FormaPagamento[]
    let formasPagamentoOculta: FormaPagamento[]

    if (hide) {
      const formaPagamentoSelecionada = financialConfigs.formasPagamento
        .find(formaPagamento => formaPagamento.codigo === Number(codFormaPagamento)) as FormaPagamento

      formasPagamento = financialConfigs.formasPagamento
        .filter(formaPagamento => formaPagamento.codigo !== formaPagamentoSelecionada.codigo)

      formasPagamentoOculta = [...financialConfigs.formasPagamentoOcultas, formaPagamentoSelecionada]
    } else {
      const formaPagamentoSelecionada = financialConfigs.formasPagamentoOcultas
        .find(formaPagamento => formaPagamento.codigo === Number(codFormaPagamento)) as FormaPagamento

      formasPagamentoOculta = financialConfigs.formasPagamentoOcultas
        .filter(formaPagamento => formaPagamento.codigo !== formaPagamentoSelecionada.codigo)

      formasPagamento = [...financialConfigs.formasPagamento, formaPagamentoSelecionada]
    }

    const newConfig = Object.assign({}, financialConfigs)
    newConfig.formasPagamento = formasPagamento
    newConfig.formasPagamentoOcultas = formasPagamentoOculta

    setFinancialConfigs(newConfig)
  }

  const onDrag = (event: React.DragEvent<HTMLDivElement>, codFormaPagamento: string): void => {
    event.dataTransfer.setData('codFormaPagamento', codFormaPagamento)
  }

  const onDrop = (event: React.DragEvent<HTMLDivElement>, hide: boolean): void => {
    const codFormaPagamento = event.dataTransfer.getData('codFormaPagamento')
    handleChoosePaymentForm(codFormaPagamento, hide)
  }

  const handleClick = (codFormaPagamento: string, hide: boolean): void => {
    handleChoosePaymentForm(codFormaPagamento, hide)
  }

  const acceptDrop = (event: React.DragEvent<HTMLDivElement>): void => {
    event.preventDefault()
  }

  return (
    <Box>
      {!financialConfigs
        ? <Loading />
        : <>
            <Box style={isMobile ? styles.selectorContainerMobile : styles.selectorContainer}>
            <div
              style={styles.selectBox}
              onDrop={(event) => onDrop(event, false)}
              onDragOver={(event) => acceptDrop(event)}
            >
              <Text style={styles.titleBox}>Formas de pagamento a apresentar</Text>
              <div style={styles.optionBox}>
                {financialConfigs.formasPagamento.map((formaPagamento) =>
                  <div
                    draggable
                    key={formaPagamento.codigo}
                    onDragStart={(event) => onDrag(event, formaPagamento.codigo.toString())}
                    style={styles.optionSelectorChip}
                    onClick={() => handleClick(formaPagamento.codigo.toString(), true)}
                  >
                    <MaterialCommunityIcons name="drag" size={24} style={styles.optionIcon} />
                    <Text style={styles.optionText}>{`${formaPagamento.codigo} - ${formaPagamento.descricao}`}</Text>
                  </div>
                )}
              </div>
            </div>

            <div
              style={styles.selectBox}
              onDrop={(event) => onDrop(event, true)}
              onDragOver={(event) => acceptDrop(event)}
            >
              <Text style={styles.titleBox}>Formas de pagamento ocultas</Text>
              <div style={styles.optionBox}>
                {financialConfigs.formasPagamentoOcultas.map((formaPagamento) => (
                  <div
                    draggable
                    key={formaPagamento.codigo}
                    onDragStart={(event) => onDrag(event, formaPagamento.codigo.toString())}
                    onClick={() => handleClick(formaPagamento.codigo.toString(), false)}
                    style={styles.optionSelectorChip}
                  >
                    <MaterialCommunityIcons name="drag" size={24} style={styles.optionIcon} />
                    <Text style={styles.optionText}>{`${formaPagamento.codigo} - ${formaPagamento.descricao}`}</Text>
                  </div>
                ))}
              </div>
            </div>
          </Box>
          <Text style={styles.tooltip}>Clique e arraste o elemento pra a lista desejada.</Text>
        </>
      }
    </Box>
  )
}
