import React from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { DateAdapter } from '@/data/protocols'
import { Box, ChipFilter, HStack } from '@/presentation/components'
import { isOpenFinancialFiltersState, ticketsFiltersState } from '@/presentation/pages/Financial/components'
import { useMediaQueries } from '@/presentation/hooks'

type FiltersChipPanelProps = {
  dateAdapter: DateAdapter
}

export const FiltersChipPanel: React.FC<FiltersChipPanelProps> = ({ dateAdapter }) => {
  const { isMobile } = useMediaQueries()
  const setOpen = useSetRecoilState(isOpenFinancialFiltersState)
  const filters = useRecoilValue(ticketsFiltersState)

  const period = dateAdapter.format(filters.dataInicio, 'DD/MM/YYYY') +
    ' até ' +
    dateAdapter.format(filters.dataFim, 'DD/MM/YYYY')

  const situation = {
    'Em Aberto': isMobile ? 'Em Aberto' : 'Somente em Aberto',
    Todas: isMobile ? 'Todos' : 'Todos Status',
    Pagas: isMobile ? 'Pagas' : 'Somente Pagas'
  }[filters.situacao]

  return (
    <HStack spacing={6} mb={18} wrap>
      <Box>
        <ChipFilter
          title='Período'
          subtitle={period}
          onPress={() => setOpen(true)}
        />
      </Box>
      <Box>
        <ChipFilter
          title='Status'
          subtitle={situation}
          onPress={() => setOpen(true)}
          />
      </Box>
    </HStack>
  )
}
