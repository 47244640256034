import { UpdateDefaultPassword } from '@/domain/usecases'
import { RemoteUpdateDefaultPassword } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'

export const makeUpdateDefaultPassword = (): UpdateDefaultPassword => {
  const url = env.API_URL
  const httpClient = makeAxiosHttpClient<string>()

  return new RemoteUpdateDefaultPassword(url, httpClient)
}
