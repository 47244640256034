import React from 'react'
import { Financial } from '@/presentation/pages/Financial'
import { NavigationPagesProps, TabBottomNavigator } from '@/presentation/components'
import { makeGetTicketPix, makeGetTickets, makeOpenBankSlip, makePayWithCreditCard } from '@/main/factories/usecases'
import { makeDateAdapterFactory } from '@/main/adapters'

export const makeFinancialFactory = ({ navigation }: NavigationPagesProps): JSX.Element => {
  return (
    <>
      <Financial
        getTickets={makeGetTickets()}
        getTicketPix={makeGetTicketPix()}
        payWithCreditCard={makePayWithCreditCard()}
        openBankSlip={makeOpenBankSlip()}
        dateAdapter={makeDateAdapterFactory()}
      />
      <TabBottomNavigator navigation={navigation} />
    </>
  )
}
