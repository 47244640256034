import { AccessDeniedError, UnexpectedError } from '@/domain/errors'
import { GenericUserModel, MoniUserResult } from '@/domain/models'
import { GetMoniUsers, GetMoniUsersParams } from '@/domain/usecases'
import { HttpClient, HttpStatusCode } from '@/data/protocols/http'

export class RemoteGeMoniUsersEvents implements GetMoniUsers {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<string>
  ) { }

  async getUsers (params: GetMoniUsersParams): Promise<GenericUserModel[]> {
    const { body, statusCode } = await this.httpClient.request({
      url: `${this.url}/api/portal/moni/lista-usuarios`,
      method: 'get',
      params
    })

    switch (statusCode) {
      case HttpStatusCode.ok: return this.maptoGeneric(body)
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()

      default: throw new UnexpectedError()
    }
  }

  maptoGeneric (usersRaw: string): GenericUserModel[] {
    const users = usersRaw
      ? JSON.parse(usersRaw) as MoniUserResult
      : []

    return users?.map(user => ({
      nome: user.nome,
      codCliente: user.codCliente.toString(),
      dddFone: user.dddCelular,
      fone1: user.celular
    }))
  }
}
