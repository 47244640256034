import { Unidade } from '@/domain/models'
import { GetUnits } from '@/domain/usecases'
import { AccessDeniedError, NotFoundError, UnexpectedError, UnexpectedResultFormatError } from '@/domain/errors'
import { HttpClient, HttpRequest, HttpStatusCode } from '@/data/protocols'

export class RemoteGetUnits implements GetUnits {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<Unidade[]>
  ) { }

  async getAll (): Promise<Unidade[]> {
    const request: HttpRequest = {
      url: `${this.url}/api/portal/unidades`,
      method: 'get'
    }

    const { statusCode, body } = await this.httpClient.request(request)

    switch (statusCode) {
      case HttpStatusCode.ok:
        if (typeof body !== typeof []) {
          throw new UnexpectedResultFormatError()
        }
        return body
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      case HttpStatusCode.notFound: throw new NotFoundError('Endereço de API não está respondendo')
      default: throw new UnexpectedError()
    }
  }
}
