import React, { useCallback, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { GestureResponderEvent } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { NFModel } from '@/domain/models'
import { OpenNfse, OpenNfseParams } from '@/domain/usecases'
import { ActivityIndicator, Box, CardBase, dateAdapterAtom, IconButton, Menu, MenuActions, Text } from '@/presentation/components'
import { useErrorHandler, useFormat, useNotify } from '@/presentation/hooks'
import { isLoadingOpenNfState, nfsConfigState } from '../atom'
import { styles } from './styles'

type CardNfseProps = {
  nfse: NFModel
  openNfse: OpenNfse
  index: number
}

export const CardNfse: React.FC<CardNfseProps> = ({ nfse, openNfse, index }) => {
  const notify = useNotify()
  const { formatCurrency } = useFormat()
  const nfsConfigs = useRecoilValue(nfsConfigState)
  const dateAdapter = useRecoilValue(dateAdapterAtom)
  const [currentIndex, setCurrentIndex] = useState(-1)
  const [isLoadingOpenNfse, setLoadingOpenNfse] = useRecoilState(isLoadingOpenNfState)
  const handleError = useErrorHandler()

  const openMenu = (index: number): void => setCurrentIndex(index)
  const closeMenu = (): void => setCurrentIndex(-1)

  const handleOpenNfse = useCallback((event: GestureResponderEvent, nf: NFModel) => {
    event.preventDefault()
    setLoadingOpenNfse(true)
    const openNfseParams: OpenNfseParams = {
      codEmpresa: nf.empresa,
      codUnidade: nf.unidade,
      numeroRPS: nf.notaNumero,
      serieRps: nf.notaSerie,
      linkAutenticidade: nf.linkAutenticidade,
      utilizarDevExpress: nf.utilizarDevExpress
    }

    openNfse
      .open(openNfseParams)
      .then(() => notify.show(`A Nfse ${nf.notaNumero}/${nf.notaSerie} foi aberta em uma nova aba`))
      .catch(handleError)
      .finally(() => setLoadingOpenNfse(false))
  }, [])

  return (
    <CardBase>
      <Box style={styles.title}>
          <Text style={styles.bold}>{nfse.notaNumeroNFSE}/{nfse.notaSerie}</Text>
          {!isLoadingOpenNfse &&
            <MenuActions
              key={`menu-${nfse.notaNumeroNFSE}`}
              visible={currentIndex >= 0 && currentIndex === index}
              onDismiss={closeMenu}
              anchor={<IconButton
                onPress={() => openMenu(index)}
                testID='notas-servicos-opcoes'
                style={{ width: 32, height: 32 }}
                icon={(props: any) => <MaterialCommunityIcons name="dots-vertical" {...props} />}></IconButton>
              }>
                {((nfse.linkAutenticidade || nfse.utilizarDevExpress) && !nfsConfigs.ocultaLink)
                  ? <Menu.Item
                    onPress={(event) => handleOpenNfse(event, nfse)}
                    title="Abrir Link NFSe"
                    testID='notas-servicos-abrir-nfse'
                    leadingIcon={props => <MaterialCommunityIcons name="link" {...props} />}
                  />
                  : <></>
                }
                {nfse.linkConsultaNotaPortal
                  ? <Menu.Item
                    onPress={() => window.open(nfse.linkConsultaNotaPortal, '_blank')}
                    title="Link para consulta da NFSe"
                    testID='notas-servicos-consulta'
                    leadingIcon={props => <MaterialCommunityIcons name="link" {...props} />}
                  />
                  : <></>
                }
            </MenuActions>
            }
        </Box>
        {(isLoadingOpenNfse && currentIndex === index)
          ? <ActivityIndicator size='large' />
          : <>
            <Text><span style={styles.bold}>Valor: </span>{formatCurrency(nfse.notaValor)}</Text>
            <Text><span style={styles.bold}>Emissão: </span>{dateAdapter.format(dateAdapter.parse(nfse.notaData), 'DD/MM/YYYY')}</Text>
          </>
        }
    </CardBase>
  )
}
