import { atom } from 'recoil'
import { UserModel } from '@/domain/models'

export const isOpenUserSelect = atom({
  key: 'isOpenUserSelect',
  default: false
})

export const usersSelectState = atom({
  key: 'usersSelectState',
  default: [] as UserModel[]
})
