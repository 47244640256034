import { OpenBankSlip } from '@/domain/usecases'
import { RemoteOpenBankSlip } from '@/data/usecases'
import { makeGenerateBankSlip, makeGenerateInsideBankSlip } from '@/main/factories/usecases'

export const makeOpenBankSlip = (): OpenBankSlip => {
  const generateBankSlip = makeGenerateBankSlip()
  const generateInsideBankSlip = makeGenerateInsideBankSlip()

  return new RemoteOpenBankSlip(generateBankSlip, generateInsideBankSlip)
}
