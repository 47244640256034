import { GetCreditCard, GetCreditCardParams } from '@/domain/usecases/'
import { HttpClient, HttpStatusCode } from '@/data/protocols/http'
import { AccessDeniedError, UnexpectedError, UnexpectedResultFormatError } from '@/domain/errors'
import { CreditCardModel } from '@/domain/models'

export class RemoteGetCreditCard implements GetCreditCard {
  constructor (
    private readonly url: string,
    private readonly httpCliente: HttpClient<CreditCardModel>
  ) {}

  async getByClient (params: GetCreditCardParams): Promise<CreditCardModel> {
    const { statusCode, body } = await this.httpCliente.request({
      method: 'get',
      url: `${this.url}/api/portal/buscar-cartao-credito`,
      params
    })

    switch (statusCode) {
      case HttpStatusCode.ok:
        if (!body) {
          throw new UnexpectedResultFormatError()
        }
        body.expiracao = `${body.expiracao.split('/')[0]}/${body.expiracao.split('/')[1].substring(2)}`
        return body
      case HttpStatusCode.noContent: return null as unknown as CreditCardModel
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}
