export type OpenStatementOfDebtsParams = {
  codCliente: number
  ano: number
}

export interface OpenStatementOfDebts {
  openPaymentReport: (params: OpenStatementOfDebtsParams) => Promise<void>
}

export namespace OpenStatementOfDebts {
  export const RN = {
    FOLDER_DEFAULT_PDF: 'TempRelatorios'
  }
}
