import React from 'react'
import { useRecoilValue } from 'recoil'
import { Box, Text, CardDetails, CardRow } from '@/presentation/components'
import { serviceOrderDetailState } from '@/presentation/pages/ServiceOrderDetail/components'
import { styles } from '../../styles'

export const ClientInfo: React.FC = () => {
  const serviceOrderDetail = useRecoilValue(serviceOrderDetailState)

  return (
  <>
    <Text style={{ ...styles.pageInfo, marginTop: 0 }}>Informações do Cliente</Text>
    <CardRow>
      <CardDetails maxWidth={'100%'}>
        <Box style={styles.titleBox}>
          <Text style={styles.bold}>Geral</Text>
        </Box>
        <Text><span style={styles.bold}>Nome: </span>{serviceOrderDetail.codCliente} - {serviceOrderDetail.cliente}</Text>
        <Text><span style={styles.bold}>Fantasia: </span>{serviceOrderDetail.fantasia}</Text>
        <Text><span style={styles.bold}>CPF/CNPJ: </span>{serviceOrderDetail.cpf}</Text>
      </CardDetails>
      <CardDetails maxWidth={'100%'}>
        <Box style={styles.titleBox}>
          <Text style={styles.bold}>Contato & Endereço</Text>
        </Box>
        <Text><span style={styles.bold}>Email: </span>{serviceOrderDetail.emailCliente}</Text>
        <Text><span style={styles.bold}>Telefone: </span>{serviceOrderDetail.fone1}</Text>
        <Text><span style={styles.bold}>Endereço: </span>{serviceOrderDetail.endereco} - {serviceOrderDetail.bairro}, {serviceOrderDetail.cidade} - {serviceOrderDetail.estado}</Text>
      </CardDetails>
    </CardRow>
  </>
  )
}
