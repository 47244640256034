import { GetDigitalAccepts } from '@/domain/usecases'
import { atom } from 'recoil'

export const isOpenAcceptDigitalFiltersState = atom({
  key: 'isOpenAcceptDigitalFiltersState',
  default: false
})

export const acceptDigitalFiltersState = atom({
  key: 'filtersAcceptDigital',
  default: {
    status: GetDigitalAccepts.RN.DEFAULT_STATUS,
    dtInicial: '',
    dtFinal: ''
  }
})
