import React, { useState } from 'react'
import { ScrollView } from 'react-native'
import { TextInput } from 'react-native-paper'
import { useRecoilState } from 'recoil'
import { ServiceOrderConfigs } from '@/domain/models'
import { CardSwitch, Box, Loading } from '@/presentation/components'
import { ActionsButtons, serviceOrderConfigsState } from '@/presentation/pages/Settings/components'

export const ServiceOrderSettings: React.FC = () => {
  const [serviceOrderConfigs, setServiceOrderConfig] = useRecoilState(serviceOrderConfigsState)
  const [initialServiceOrderConfigs] = useState(serviceOrderConfigs)

  const utilizaEmpresaPadrao = serviceOrderConfigs?.empresaPadraoOS

  const handleChange = (param: keyof ServiceOrderConfigs, value: string | boolean): void => {
    setServiceOrderConfig(currentState => ({ ...currentState, [param]: value }))
  }

  const hasChanged = (): boolean => {
    return JSON.stringify(initialServiceOrderConfigs) !== JSON.stringify(serviceOrderConfigs)
  }

  return (
    <Box style={{ flex: 1 }} >
      {!serviceOrderConfigs
        ? <Loading />
        : <ScrollView>
          <CardSwitch
            text='Exibir Ordens de Serviços fechadas.'
            value={serviceOrderConfigs.exibirOsFechadas}
            handleSwitch={() => handleChange('exibirOsFechadas', !serviceOrderConfigs.exibirOsFechadas)}
          />

          <CardSwitch
            text='Gerar OS sem técnico (ignorar técnico padrão do cliente)'
            value={serviceOrderConfigs.osSemTecnico}
            handleSwitch={() => handleChange('osSemTecnico', !serviceOrderConfigs.osSemTecnico)}
          />
          <CardSwitch
            text='Bloqueia abertura de Ordem de Serviço quando já existir uma OS aberta para o cliente?'
            value={serviceOrderConfigs.limitaOs}
            handleSwitch={() => handleChange('limitaOs', !serviceOrderConfigs.limitaOs)}
          />

          <CardSwitch
            text='Permitir abertura de Ordem de Serviço para clientes com Faturamento em lote DESATIVADO?'
            value={serviceOrderConfigs.abrirOsSemFaturamento}
            handleSwitch={() => handleChange('abrirOsSemFaturamento', !serviceOrderConfigs.abrirOsSemFaturamento)}
          >
            <TextInput
              mode='flat'
              label='Empresa padrão'
              underlineColorAndroid='transparent'
              underlineColor='transparent'
              value={serviceOrderConfigs.osEmpresaPadrao}
              onChangeText={text => handleChange('osEmpresaPadrao', text)}
            />
          </CardSwitch>

          <CardSwitch
            text={'Exibir aviso ao abrir tela de OS no Portal do Cliente'}
            value={serviceOrderConfigs.exibeMensagemAbrirOs}
            handleSwitch={() => handleChange('exibeMensagemAbrirOs', !serviceOrderConfigs.exibeMensagemAbrirOs)}
          >
            <TextInput
              mode='flat'
              label='Mensagem a ser exibida ao cliente'
              underlineColorAndroid='transparent'
              underlineColor='transparent'
              value={serviceOrderConfigs.mensagemAberturaOs}
              onChangeText={text => handleChange('mensagemAberturaOs', text)}
            />
          </CardSwitch>

          <CardSwitch
            text={'Utilizar empresa padrão para todas as OSs?'}
            value={utilizaEmpresaPadrao}
            handleSwitch={() => handleChange('empresaPadraoOS', !utilizaEmpresaPadrao)}
          >
            <TextInput
              mode='flat'
              label='Empresa padrão'
              underlineColorAndroid='transparent'
              underlineColor='transparent'
              value={serviceOrderConfigs.osEmpresaPadrao}
              onChangeText={text => handleChange('osEmpresaPadrao', text)}
            />
          </CardSwitch>

          <CardSwitch
            text={'Enviar informação de abertura de Ordem de Serviço por e-mail'}
            value={serviceOrderConfigs.enviarAvisoAberturaOs}
            handleSwitch={() => handleChange('enviarAvisoAberturaOs', !serviceOrderConfigs.enviarAvisoAberturaOs)}
          >
            <TextInput
              mode='flat'
              label='E-mail'
              underlineColorAndroid='transparent'
              underlineColor='transparent'
              value={serviceOrderConfigs.emailOs}
              onChangeText={text => handleChange('emailOs', text)}
            />
          </CardSwitch>
        </ScrollView>
      }
      {hasChanged() && <ActionsButtons />}
    </Box>
  )
}
