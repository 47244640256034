import React from 'react'
import { Image } from 'react-native'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useTheme, useThemeClient } from '@/presentation/hooks'
import { Box, Button, Divider, HStack, KEY_TIME_EXPIRATION, ModalBase, Text, isOpenInstallationState, localStorageState } from '@/presentation/components'
import { styles } from './styles'

export const InstallationSuggestion: React.FC = () => {
  const theme = useTheme()
  const { getThemeClient } = useThemeClient()
  const localStorageAdapter = useRecoilValue(localStorageState)
  const [isOpen, setOpen] = useRecoilState(isOpenInstallationState)

  const handleDeclineInstall = (): void => {
    localStorageAdapter.set(KEY_TIME_EXPIRATION, { timestamp: new Date().getTime() })
    setOpen(false)
  }

  const themeClient = getThemeClient()

  const handleClose = (): void => setOpen(false)

  return (
    <ModalBase visible={isOpen} onClose={handleClose}>
      <Box style={styles.container}>
        <Text variant='subtitle1' style={{ fontWeight: '700' }}>Siga os passos para instalação</Text>
        <Divider color={theme.colors.greyLight} />
        <Image
          resizeMode='contain'
          source={require('../../../../assets/sugestao-instalacao.png')}
          style={{ width: '100%', height: '40vh' }}
        />

        <HStack fill>
          <Button
            style={{ width: '100%' }}
            title='Fechar'
            variant='outlined'
            color={themeClient.mainColor}
            onPress={handleDeclineInstall}
          />
        </HStack>
      </Box>
    </ModalBase>
  )
}
