import { HttpClient, HttpStatusCode } from '@/data/protocols/http'
import { AccessDeniedError, ForbiddenError, UnexpectedError } from '@/domain/errors'
import { GenericSectorModel, MoniSectorResult } from '@/domain/models'
import { GetMoniSectorsParams, GetMoniSectors } from '@/domain/usecases/events/get-moni-sectors'

export class RemoteGetMoniSectors implements GetMoniSectors {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<string>
  ) { }

  async getSectors (params: GetMoniSectorsParams): Promise<GenericSectorModel[]> {
    const { body, statusCode } = await this.httpClient.request({
      url: `${this.url}/api/portal/moni/lista-setores`,
      method: 'get',
      params
    })

    switch (statusCode) {
      case HttpStatusCode.ok: return this.mapToGeneric(body)
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      case HttpStatusCode.forbidden: throw new ForbiddenError(body)

      default: throw new UnexpectedError()
    }
  }

  mapToGeneric (sectorsRaw: string): GenericSectorModel[] {
    const sectors = sectorsRaw
      ? JSON.parse(sectorsRaw) as MoniSectorResult
      : []

    return sectors?.map(sector => ({
      nome: sector.descricao,
      idEquipamento: sector.equipamento,
      codigoCliente: sector.cliente.toString(),
      codigoZona: sector.equipamento
    }))
  }
}
