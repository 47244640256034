import { useCallback } from 'react'
import { BoletoInsideConfigs, ConfigModel, FinancialConfigs, GeneralConfigs, InvoiceConfigs, ServiceOrderConfigs } from '@/domain/models'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { initialConfigsState, generalConfigsState, financialConfigsState, invoiceConfigsState, menuAccessPermissionsConfigsState, serviceOrderConfigsState } from '@/presentation/pages/Settings/components'

type SettingsProps = {
  startConfig: (config: ConfigModel) => void
  discardChanges: () => void
  isValidMail: (mail: string) => boolean
}

export const useSettings = (): SettingsProps => {
  const [initialConfigs, setInitialConfigs] = useRecoilState(initialConfigsState)
  const setFinancialConfigs = useSetRecoilState(financialConfigsState)
  const setGeneralConfigs = useSetRecoilState(generalConfigsState)
  const setInvoiceConfigs = useSetRecoilState(invoiceConfigsState)
  const setServiceOrderConfigs = useSetRecoilState(serviceOrderConfigsState)
  const setMenuAccessPermissionsConfigs = useSetRecoilState(menuAccessPermissionsConfigsState)

  const discardChanges = useCallback((): void => {
    startConfig(initialConfigs)
  }, [initialConfigs])

  const isValidMail = useCallback((mail: string): boolean => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(mail)
  }, [])

  const startConfig = (config: ConfigModel): void => {
    setInitialConfigs(config)

    setFinancialConfigs({
      bolEntradaConfirmada: config.bolEntradaConfirmada,
      bolExibeOriginal: config.bolExibeOriginal,
      bolAtualizaBoletos: config.bolAtualizaBoletos,
      formasPagamentoAtualiza: config.formasPagamentoAtualiza,
      formasPagamentoNaoAtualiza: config.formasPagamentoNaoAtualiza,
      bolOcultaFormasPgto: config.bolOcultaFormasPgto,
      formasPagamentoOcultas: config.formasPagamentoOcultas,
      formasPagamento: config.formasPagamento,
      exibeAlteracaoCartao: config.exibeAlteracaoCartao,
      emailAlteracaoCartao: config.emailAlteracaoCartao,
      pagamentoCartaoAtivo: config.pagamentoCartaoAtivo,
      permitePagamentoCartao: config.permitePagamentoCartao,
      validaCartaoCredito: config.validaCartaoCredito,
      visualizaFaturaCartaoVencida: config.visualizaFaturaCartaoVencida,
      assuntoEmailConfirmacaoPgto: config.assuntoEmailConfirmacaoPgto,
      atualizaAutomaticoCartao: config.atualizaAutomaticoCartao,
      mensagemSMSConfirmacaoPgto: config.mensagemSMSConfirmacaoPgto,
      mensagemEmailConfirmacaoPgto: config.mensagemEmailConfirmacaoPgto
    } as FinancialConfigs & BoletoInsideConfigs)

    setGeneralConfigs({
      uniCodigo: config.uniCodigo,
      uniUnidade: config.uniUnidade,
      nome: config.nome,
      email: config.email,
      copia: config.copia,
      enviaEmailViagem: config.enviaEmailViagem,
      emailViagem: config.emailViagem,
      emailAlteracaoCadastro: config.emailAlteracaoCadastro,
      emailSac: config.emailSac,
      chaveResidencia: config.chaveResidencia,
      exibeChavesAvisoViagem: config.exibeChavesAvisoViagem,
      exibeAlteracaoCadastro: config.exibeAlteracaoCadastro,
      conPorCodigo: config.conPorCodigo,
      servidorSmtp: config.servidorSmtp,
      autentica: config.autentica,
      usuario: config.usuario,
      senha: config.senha,
      porta: config.porta,
      ssl: config.ssl,
      leitura: config.leitura,
      liberacoesPortal: config.liberacoesPortal,
      visualizaDetalhesContrato: config.visualizaDetalhesContrato,
      limiteDiasVisualizaCritica: config.limiteDiasVisualizaCritica,
      bloqueiaInativos: config.bloqueiaInativos
    } as GeneralConfigs)

    setInvoiceConfigs({
      nfsOcultarInfos: config.nfsOcultarInfos,
      nfsOcultarLink: config.nfsOcultarLink
    } as InvoiceConfigs)

    setServiceOrderConfigs({
      exibirOsFechadas: config.exibirOsFechadas,
      osSemTecnico: config.osSemTecnico,
      limitaOs: config.limitaOs,
      abrirOsSemFaturamento: config.abrirOsSemFaturamento,
      exibeMensagemAbrirOs: config.exibeMensagemAbrirOs,
      mensagemAberturaOs: config.mensagemAberturaOs,
      empresaPadraoOS: config.empresaPadraoOS,
      enviarAvisoAberturaOs: config.enviarAvisoAberturaOs,
      emailOs: config.emailOs,
      enviaListaChave: config.enviaListaChave,
      osEmpresaPadrao: config.osEmpresaPadrao,
      exibeAvisoAberturaOs: config.exibeAvisoAberturaOs,
      avisoAberturaOs: config.avisoAberturaOs
    } as ServiceOrderConfigs)

    setMenuAccessPermissionsConfigs(config.liberacoesPortal)
  }

  return {
    startConfig,
    discardChanges,
    isValidMail
  }
}
