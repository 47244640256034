import React from 'react'
import { SafeAreaView } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useTheme } from '@/presentation/hooks'
import { CompanyCard, ContractCard, ModalContractDetails } from '@/presentation/pages/Contract/components'
import { Box, PageHeader, Stack } from '@/presentation/components'
import { DateAdapter } from '@/data/protocols'
import { styles } from './styles'

type ContractProps = {
  dateAdapter: DateAdapter
}

export const Contract: React.FC<ContractProps> = ({ dateAdapter }) => {
  const theme = useTheme()

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={styles.scroll}>
        <Box style={styles.content}>
          <PageHeader
          text='Meu Contrato'
          icon={<MaterialCommunityIcons size={24} color={theme.colors.grey} name='file-document-edit' />}
          />
            <Stack direction={'column'}>
              <CompanyCard />
              <ContractCard dateAdapter={dateAdapter} />
            </Stack>
        </Box>
      </ScrollView>
      <ModalContractDetails dateAdapter={dateAdapter} />
    </SafeAreaView>
  )
}
