import React from 'react'
import { StyleSheet } from 'react-native'
import { Claim } from '@/domain/models/sac-model'
import { DateAdapter } from '@/data/protocols'
import { useMediaQueries } from '@/presentation/hooks'
import { theme } from '@/presentation/styles'
import { Text, Chip, VStack, Box, Divider, HStack } from '@/presentation/components'
import { sacState } from '@/presentation/pages/Sac/components'
import { CircleIcon } from '@/presentation/pages/ServiceOrders/components/CardOs/styles'
import { useRecoilValue } from 'recoil'

type CardSacProps = {
  sac: Claim
  dateAdapter: DateAdapter
}

export const CardSac: React.FC<CardSacProps> = ({ sac, dateAdapter }: CardSacProps) => {
  const { isMobile } = useMediaQueries()
  const { listaTipoAtendimento } = useRecoilValue(sacState)

  const containerStyles = {
    ...styles.container,
    ...(!isMobile && {
      flex: 1,
      maxWidth: 600
    }),
    backgroundColor: theme.colors.white,
    color: theme.colors.greyLighten
  }

  const colorType = {
    E: theme.colors.success,
    R: theme.colors.error,
    S: theme.colors.info
  }[sac.tipo]

  const sacType = listaTipoAtendimento?.find(tipo => tipo.codigo === sac.tipo)?.descricao ?? 'Não informado'

  const typeChipContainer = {
    ...styles,
    backgroundColor: colorType
  }

  const textTitleStyles = {
    ...styles.title,
    color: theme.colors.texts
  }

  return (
    <Box style={containerStyles}>
      <HStack items='center' spacing={4} mb={8}>
        <Text
          style={{
            ...styles.title,
            color: theme.colors.textsDark,
            flex: 1
          }}
          variant='h3'
        >
          {sac.reclamante.toUpperCase()}
        </Text>
        <Text
          style={{
            ...styles.textOrderStyles,
            color: theme.colors.textsDark
          }}
          variant='h3'
        >
          Nº {sac.codReclamacao}
        </Text>
        <CircleIcon
          size={12}
          color={colorType}
          name='circle'
        />

      </HStack>

      <VStack justify='between' style={{ flex: 1 }}>
        <VStack justify='between' spacing={8} >
          <Divider />
          <Text style={textTitleStyles} variant='h4'>
            Abertura:
            <span style={styles.textStyles}>
              {dateAdapter.format(sac.data, 'DD/MM/YYYY HH:mm:ss')}
            </span>
          </Text>
          <Text style={textTitleStyles} variant='h4'>
            Descrição:
            <span style={styles.textStyles}>
              {sac.reclamacao}
            </span>
          </Text>
        </VStack>

        <HStack spacing={4} mt={8}>
          <Text style={textTitleStyles} variant='h4' testID='sac-tipo'>
            Tipo:
          </Text>
          {sacType &&
            <Chip
              text={sacType}
              containerStyle={typeChipContainer}
            />
          }
        </HStack>
      </VStack>
    </Box>
  )
}

export const styles = StyleSheet.create({
  container: {
    padding: 16,
    minWidth: 320,
    fontSize: 14,
    display: 'flex',
    alignItems: 'stretch',
    overflow: 'hidden',
    borderRadius: 4,
    shadowColor: 'rgba(0, 0, 0, 0.04)',
    shadowOffset: {
      width: 4,
      height: 8
    },
    shadowRadius: 4
  },
  address: {
    height: 18,
    lineHeight: 18,
    fontSize: 10
  },
  headerStyles: {
    width: '100%',
    marginBottom: 4
  },
  textStyles: {
    marginLeft: 4,
    fontSize: 12,
    fontWeight: '400'
  },
  textOrderStyles: {
    paddingHorizontal: 4,
    fontSize: 12,
    fontWeight: '700'
  },
  title: {
    fontWeight: '700',
    fontSize: 12
  }
})
